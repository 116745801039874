import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { map, tap } from "rxjs/operators";
import { MadAuthService } from "src/app/core/mad-auth/mad-auth.service";
import { SubdomainService } from "src/app/core/_base/subdomain.service";

@Injectable({
  providedIn: 'root'
})
export class CityAPIService {
  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subdomainService: SubdomainService
  ) {}

  fetchListing(countryId?: number) {
    let params = new HttpParams()
    if (countryId) {
      params = params.set('country_id', countryId)
    }
    return this.http.get<{data: {
      id: number,
      name: string,
      country_id: number
    }[]}>(
      environment.baseApiUrl + `api/v2/${this.subdomainService.subDomain}/cities/listing`,
      {
        headers: this.authService.getAuthHeaders(),
        params
      }
    ).pipe(
      map(response => response.data)
    )
  }

  fetchAllCities(countryCode: string) {
    return this.http.get<{data: string[]}>(
      environment.baseApiUrl + `api/v2/${this.subdomainService.subDomain}/cities/all`,
      {
        headers: this.authService.getAuthHeaders(),
        params: new HttpParams().set('country_code', countryCode)
      }
    )
  }

  storeCity(city: {countryId: number, name: string}) {
    return this.http.post<unknown>(
      environment.baseApiUrl + `api/v2/${this.subdomainService.subDomain}/cities`,
      {
        country_id: city.countryId,
        name: city.name
      },
      {
        headers: this.authService.getAuthHeaders()
      }
    )
  }
}