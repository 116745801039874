// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {AppendixCategoriesService} from '../_services/appendix-category.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allAppendixCategoriesLoaded} from '../_selectors/appendix-category.selectors';
// Actions
import {
    AllAppendixCategoriesLoaded,
    AllAppendixCategoriesRequested,
    AppendixCategoryActionTypes,
    AppendixCategoriesPageRequested,
    AppendixCategoriesPageLoaded,
    AppendixCategoryUpdated,
    AppendixCategoriesPageToggleLoading,
    AppendixCategoryDeleted,
    AppendixCategoryOnServerCreated,
    AppendixCategoryCreated,
    AppendixCategoryAdminRestored,
    AppendixCategoryDeletedFromAdminTrash,
    AppendixCategoryDeletedFromTrash,
    AppendixCategoryOnServerAdminRestored,
    AppendixCategoryOnServerRestored,
    AppendixCategoryRestored,
    AppendixCategoryTrashFlushed,
    AppendixCategoriesActionToggleLoading,
    AllAppendixCategoriesRequestedMultimedia
} from '../_actions/appendix-category.actions';

@Injectable()
export class AppendixCategoryEffects {
    showPageLoadingDispatcher = new AppendixCategoriesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AppendixCategoriesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AppendixCategoriesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAppendixCategories$ = this.actions$
        .pipe(
            ofType<AllAppendixCategoriesRequested>(AppendixCategoryActionTypes.AllAppendixCategoriesRequested),
            withLatestFrom(this.store.pipe(select(allAppendixCategoriesLoaded))),
            filter(([action, isAllAppendixCategoriesLoaded]) => !isAllAppendixCategoriesLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllAppendixCategoriesLoaded({appendixCategories: res.data});
            })
        );

    @Effect()
    loadAllAppendixCategoriesMultimedia$ = this.actions$
        .pipe(
            ofType<AllAppendixCategoriesRequestedMultimedia>(AppendixCategoryActionTypes.AllAppendixCategoriesRequestedMultimedia),
            withLatestFrom(this.store.pipe(select(allAppendixCategoriesLoaded))),
            filter(([action, isAllAppendixCategoriesLoaded]) => !isAllAppendixCategoriesLoaded),
            mergeMap(() => this.service.getAllMultimedia()),
            map(res => {
                return new AllAppendixCategoriesLoaded({appendixCategories: res.data});
            })
        );

    @Effect()
    loadAppendixCategoriesPage$ = this.actions$
        .pipe(
            ofType<AppendixCategoriesPageRequested>(AppendixCategoryActionTypes.AppendixCategoriesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.find(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AppendixCategoriesPageLoaded({
                    appendixCategories: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAppendixCategory$ = this.actions$
        .pipe(
            ofType<AppendixCategoryUpdated>(AppendixCategoryActionTypes.AppendixCategoryUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.appendixCategory);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAppendixCategory$ = this.actions$
        .pipe(
            ofType<AppendixCategoryOnServerCreated>(AppendixCategoryActionTypes.AppendixCategoryOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.appendixCategory).pipe(
                    tap(res => {
                        this.store.dispatch(new AppendixCategoryCreated({appendixCategory: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushAppendixCategoryTrash$ = this.actions$
        .pipe(
            ofType<AppendixCategoryTrashFlushed>(AppendixCategoryActionTypes.AppendixCategoryTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAppendixCategory$ = this.actions$
        .pipe(
            ofType<AppendixCategoryOnServerRestored>(AppendixCategoryActionTypes.AppendixCategoryOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AppendixCategoryRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAppendixCategory$ = this.actions$
        .pipe(
            ofType<AppendixCategoryOnServerAdminRestored>(AppendixCategoryActionTypes.AppendixCategoryOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AppendixCategoryAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAppendixCategory$ = this.actions$
        .pipe(
            ofType<AppendixCategoryDeleted>(AppendixCategoryActionTypes.AppendixCategoryDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAppendixCategory$ = this.actions$
        .pipe(
            ofType<AppendixCategoryDeletedFromTrash>(AppendixCategoryActionTypes.AppendixCategoryDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAppendixCategory$ = this.actions$
        .pipe(
            ofType<AppendixCategoryDeletedFromAdminTrash>(AppendixCategoryActionTypes.AppendixCategoryDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: AppendixCategoriesService, private store: Store<AppState>) {
    }
}
