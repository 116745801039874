// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {
    KeyPlaceActionTypes,

    KeyPlaceActionToggleLoading,

    AllKeyPlacesRequested,
    KeyPlacesPageRequested,
    KeyPlacesPageLoaded,

    KeyPlaceOnServerCreated,
    KeyPlaceCreated,
    KeyPlaceUpdated,
    KeyPlaceDeleted,
    KeyPlaceOnServerAdminRestored,
    KeyPlaceAdminRestored,
    KeyPlaceOnServerRestored,
    KeyPlaceRestored,
    KeyPlaceDeletedFromTrash,
    KeyPlaceDeletedFromAdminTrash, KeyPlaceTrashFlushed, KeyPlacesStatusRequested, KeyPlacesStatusLoaded,
    KeyPlacesPageRequestedMultimedia
} from '../_actions/key-place.actions';
import {AppState} from '../../reducers';
import {QueryParamsModel} from '../../_base/crud';
import {KeyPlaceService} from '../_services/key-place.service';

@Injectable()
export class KeyPlaceEffects {

    showPageLoadingDispatcher = new KeyPlaceActionToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new KeyPlaceActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new KeyPlaceActionToggleLoading({isLoading: false});

    @Effect()
    loadPageKeyPlaces$ = this.actions$
        .pipe(
            ofType<KeyPlacesPageRequested>(KeyPlaceActionTypes.KeyPlacesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.getKeyPlaces(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map((response) => {

                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];

                return new KeyPlacesPageLoaded({
                    keyPlaces: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            })
        );

    @Effect()
    loadPageKeyPlacesMultimedia$ = this.actions$
        .pipe(
            ofType<KeyPlacesPageRequestedMultimedia>(KeyPlaceActionTypes.KeyPlacesPageRequestedMultimedia),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.getKeyPlacesMultimedia(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map((response) => {

                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];

                return new KeyPlacesPageLoaded({
                    keyPlaces: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            })
        );

    @Effect()
    loadAllKeyPlaces$ = this.actions$
        .pipe(
            ofType<AllKeyPlacesRequested>(KeyPlaceActionTypes.AllKeyPlacesRequested),
            mergeMap(() => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.getAllKeyPlaces();
            }),
            map((response) => {
                const result: any = response.data;
                return new KeyPlacesPageLoaded({
                    keyPlaces: result,
                    totalCount: result.length,
                    totalTrashed: 0,
                    totalAdminTrashed: 0,
                    page: new QueryParamsModel([])
                });
            })
        );

    @Effect()
    deleteKeyPlace$ = this.actions$
        .pipe(
            ofType<KeyPlaceDeleted>(KeyPlaceActionTypes.KeyPlaceDeleted),
            mergeMap(({payload}) => {
                    return this.service.deleteKeyPlace(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateKeyPlace$ = this.actions$
        .pipe(
            ofType<KeyPlaceUpdated>(KeyPlaceActionTypes.KeyPlaceUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateKeyPlace(payload.keyPlace);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createKeyPlace$ = this.actions$
        .pipe(
            ofType<KeyPlaceOnServerCreated>(KeyPlaceActionTypes.KeyPlaceOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createKeyPlace(payload.keyPlace).pipe(
                    tap(res => {
                        this.store.dispatch(new KeyPlaceCreated({keyPlace: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreKeyPlace$ = this.actions$
        .pipe(
            ofType<KeyPlaceOnServerRestored>(KeyPlaceActionTypes.KeyPlaceOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new KeyPlaceRestored({keyPlace: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminKeyPlace$ = this.actions$
        .pipe(
            ofType<KeyPlaceOnServerAdminRestored>(KeyPlaceActionTypes.KeyPlaceOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new KeyPlaceAdminRestored({keyPlace: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteFromTrashKeyPlace$ = this.actions$
        .pipe(
            ofType<KeyPlaceDeletedFromTrash>(KeyPlaceActionTypes.KeyPlaceDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashKeyPlace$ = this.actions$
        .pipe(
            ofType<KeyPlaceDeletedFromAdminTrash>(KeyPlaceActionTypes.KeyPlaceDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushKeyplaceTrash$ = this.actions$
        .pipe(
            ofType<KeyPlaceTrashFlushed>(KeyPlaceActionTypes.KeyPlaceTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    keyplacesStatusRequest$ = this.actions$
        .pipe(
            ofType<KeyPlacesStatusRequested>(KeyPlaceActionTypes.KeyPlacesStatusRequested),
            mergeMap(() => {
                return this.service.getStatus()
            }),
            map((status) => {
                return new KeyPlacesStatusLoaded({status})
            })
        )


    // @Effect()
    // init$: Observable<Action> = defer(() => {
    //     return of(new AllKeyPlacesRequested());
    // });

    constructor(private actions$: Actions, private service: KeyPlaceService, private store: Store<AppState>) {
    }
}
