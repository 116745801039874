// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {AppendicesService} from '../_services/appendix.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allAppendicesLoaded} from '../_selectors/appendix.selectors';
// Actions
import {
    AllAppendicesLoaded,
    AllAppendicesRequested,
    AppendixActionTypes,
    AppendicesPageRequested,
    AppendicesPageLoaded,
    AppendixUpdated,
    AppendicesPageToggleLoading,
    AppendixDeleted,
    AppendixOnServerCreated,
    AppendixCreated,
    AppendicesActionToggleLoading, AppendiceRestored, AppendiceAdminRestored,
    AllAppendicesRequestedMultimedia
} from '../_actions/appendix.actions';
import {
    AppendiceDeletedFromAdminTrash, AppendiceDeletedFromTrash, AppendiceOnServerAdminRestored, AppendiceOnServerRestored,
    AppendiceTrashFlushed
} from '../';


@Injectable()
export class AppendixEffects {
    showPageLoadingDispatcher = new AppendicesPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AppendicesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AppendicesActionToggleLoading({isLoading: false});

    @Effect()
    loadAllAppendices$ = this.actions$
        .pipe(
            ofType<AllAppendicesRequested>(AppendixActionTypes.AllAppendicesRequested),
            withLatestFrom(this.store.pipe(select(allAppendicesLoaded))),
            filter(([action, isAllAppendicesLoaded]) => !isAllAppendicesLoaded),
            mergeMap(() => this.service.getAll()),
            map(res => {
                return new AllAppendicesLoaded({appendices: res.data});
            })
        );

    @Effect()
    loadAllAppendicesMultimedia$ = this.actions$
        .pipe(
            ofType<AllAppendicesRequestedMultimedia>(AppendixActionTypes.AllAppendicesRequestedMultimedia),
            withLatestFrom(this.store.pipe(select(allAppendicesLoaded))),
            filter(([action, isAllAppendicesLoaded]) => !isAllAppendicesLoaded),
            mergeMap(() => this.service.getAllMultimedia()),
            map(res => {
                return new AllAppendicesLoaded({appendices: res.data});
            })
        );

    @Effect()
    loadAppendicesPage$ = this.actions$
        .pipe(

            ofType<AppendicesPageRequested>(AppendixActionTypes.AppendicesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.find(payload.page, payload.catId);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new AppendicesPageLoaded({
                    appendices: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            }),
        );

    @Effect()
    updateAppendix$ = this.actions$
        .pipe(
            ofType<AppendixUpdated>(AppendixActionTypes.AppendixUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.update(payload.appendix, payload.fileList);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createAppendix$ = this.actions$
        .pipe(
            ofType<AppendixOnServerCreated>(AppendixActionTypes.AppendixOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.create(payload.appendix, payload.fileList).pipe(
                    tap(res => {
                        this.store.dispatch(new AppendixCreated({appendix: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushAppendiceTrash$ = this.actions$
        .pipe(
            ofType< AppendiceTrashFlushed>(AppendixActionTypes.AppendiceTrashFlushed),
            mergeMap(({payload}) => {
                    return this.service.flushTrash(payload.catId);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAppendix$ = this.actions$
        .pipe(
            ofType<AppendiceOnServerRestored>(AppendixActionTypes.AppendiceOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AppendiceRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminAppendix$ = this.actions$
        .pipe(
            ofType<AppendiceOnServerAdminRestored>(AppendixActionTypes.AppendiceOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AppendiceAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAppendix$ = this.actions$
        .pipe(
            ofType<AppendixDeleted>(AppendixActionTypes.AppendixDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.delete(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromTrashAppendix$ = this.actions$
        .pipe(
            ofType<AppendiceDeletedFromTrash>(AppendixActionTypes.AppendiceDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashAppendix$ = this.actions$
        .pipe(
            ofType<AppendiceDeletedFromAdminTrash>(AppendixActionTypes.AppendiceDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: AppendicesService, private store: Store<AppState>) {
    }
}
