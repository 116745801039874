<div class="col-lg-12">
  <h5 class="text-mad text-uppercase">
    {{ title }}
  </h5>
  <hr class="active"/>
</div>
<div class="col-lg-12">
  <mat-form-field class="mat-form-field-fluid">
    <mat-select [formControl]="sizeUnitControl" [placeholder]="placeholder">
      <mat-option *ngFor="let item of (unitAreaMeasurements$|async)" [value]="item.id">
        {{ item.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div class="col-lg-4" *ngIf="sizeRangeLabel$|async as vm">
  {{ rangePlaceholder }} - ({{vm.minRange}} - {{vm.maxRange}} {{sizeUnitAcronym}})
</div> 
<div class="col-lg-8">
  <npn-slider 
    [min]="0" 
    [max]="max$|async"
    [minSelected]="minRange$|async"
    [maxSelected]="maxSelected$|async"
    [step]="10"
    (onChange)="onSliderChange($event)"></npn-slider>
</div>

