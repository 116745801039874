import { getMatAutocompleteMissingPanelError } from '@angular/material/autocomplete'
import * as _ from 'lodash'

export function getGeocoderResult(results: google.maps.GeocoderResult[]) {
  const filteredResult = results.filter(result => !contains(result, ['plus_code']))
  if (filteredResult.length == 0) return null
  const result = filteredResult[0]
  const countryCode = getComponent(result, ['country'], false)
  const city = getComponent(result, ['locality', 'administrative_level_1', 'administrative_area_level_1'])
  const zipcode = getComponent(result, ['postal_code'])
  return {
    countryCode,
    city,
    zipcode,
    address: result.formatted_address
  }
}

function contains(item: google.maps.GeocoderResult, componentTypes: string[]): boolean {
  return _.flatten(item
    .address_components
    .map(component => component.types))
    .some(value => componentTypes.includes(value))
}

function getComponent(item: google.maps.GeocoderResult, componentTypes: string[], longName: boolean = true): string {
  const component = item.address_components.filter(component => {
    const includes = componentTypes.map(type => component.types.includes(type)).filter(value => value === true)
    return includes.length > 0
  })    
  if (component.length == 0) return null
  if (longName) return component[0].long_name
  return component[0].short_name
}