import { Component, Input } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { FormError, FormErrorGrouped, FormErrorService } from "./form-error.service";


interface State {
  error: FormError,
  fields: FormErrorGrouped[]
}

@Component({
  selector: 'interval-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
  providers: [
    ComponentStore,
    FormErrorService
  ]
})
export class FormErrorComponent {
  @Input()
  set error(value: FormError) {
    this.store.patchState({
      error: value,
      fields: this.service.getFields(value)
    })
  }

  hasError$ = this.store.select(state => {
    if (state.error) return true
    return false
  })
  errorMessage$ = this.store.select(state => {
    if (!state.error) return 'No Error'
    if (state.error.messageType === 'missing-fields') {
      return `${state.error.fields.length} Missing Fields in total`
    }
    return 'Error occurred'
  })
  fields$ = this.store.select(state => state.fields)

  constructor(
    private store: ComponentStore<State>,
    private service: FormErrorService
  ) {
    this.store.setState({error: null, fields: []})
  }

  onAlertClose() {
    this.store.patchState({error: null})
  }
}