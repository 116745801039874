import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MadAuthService } from "../mad-auth/mad-auth.service";
import { SubdomainService } from "../_base/subdomain.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class LivechatService {

  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subDomainService: SubdomainService
  ) {}

  fetchToken() {
    return this.http.get(
      `${environment.baseApiUrl}` + `api/v2/${this.subDomainService.subDomain}/lc/token`,
      {
        headers: this.authService.getAuthHeaders()
      }
    )
  }

  getIdentityProvider() {
    let tokenPromise: Promise<any> | null = null
    const fetchToken = () => {
      tokenPromise = this.fetchToken().toPromise()
        .then(data => {
          tokenPromise = null
          return data
        })
        .catch(_err => {

        })

      return tokenPromise
    }
    return () => ({
      getToken: () => tokenPromise || fetchToken(),
      getFreshToken: () => Promise.resolve(),
      hasToken: () => Promise.resolve(false),
      invalidate: () => Promise.resolve()
    })
  }
}