import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { EMPTY, Observable, Subject } from "rxjs";
import { catchError, concatMap, map, takeUntil, withLatestFrom } from "rxjs/operators";
import { FileUploadService } from "src/app/core/file-upload";

interface State {
  path: string
  status: 'ready' | 'failed' | 'requested' | 'started' | 'completed'
  error: string
  progress: number,
  file: any
}

@Component({
  selector: 'kt-file-upload-field',
  templateUrl: './file-upload-field.component.html',
  styleUrls: ['./file-upload-field.component.scss'],
  providers: [ComponentStore]
})
export class FileUploadFieldComponent {
  @Input() 
  set file(value: {name: string, path: string}) {
    this.store.patchState({
      file: value
    })
  } 
  @Output() fileChange = new EventEmitter<{name: string, path: string}>()
  uploadFileCancelEvent$ = new Subject<void>()
  uploadedFile$ = this.store.select(state => state.file)
  hasUploadedFile$ = this.uploadedFile$.pipe(
    map(file => {
      if (!file) return {status: false, file: null}
      return {status: true, file}
    })
  )

  constructor(
    private store: ComponentStore<State>,
    private fileUploadService: FileUploadService
  ) {
    this.store.setState({
      path: '',
      status: 'ready',
      error: null,
      progress: null,
      file: null
    })
  }

  onUploadFileClick(fileInput: HTMLInputElement) {
    fileInput.click()
  }

  onRemoveFile() {
    this.fileChange.emit(null)
    this.store.patchState({
      status: 'ready',
      error: null,
      progress: null,
      file: null
    })
  }

  onCancelUpload() {
    this.uploadFileCancelEvent$.next()
    this.store.patchState({
      status: 'ready',
      error: null,
      progress: null,
      file: null
    })
  }

  onUploadFile(event: any) {
    const files: FileList = event.target.files
    if (files.length == 0) return
    const formData = new FormData()
    Array.from(files).forEach((file, i) => {
      formData.append('files[' + i + ']', file, file.name)
    })
    this.uploadFileEffect(formData)
    event.srcElement.value = null
  }

  private uploadFileEffect = this.store.effect((formData$: Observable<FormData>) => 
    formData$.pipe(
      withLatestFrom(this.store.state$),
      concatMap(([formData, state]: [FormData, State]) => {
        formData.append('path', state.path)
        return this.fileUploadService.uploadFile(formData).pipe(
          takeUntil(this.uploadFileCancelEvent$),
          map(event => this.handleHttpEvent(event)),
          catchError(error => {
            this.uploadFailure('Too large file. Max file upload is: 16mb')
            return EMPTY
          })
        )
      })
    )
  )

  private handleHttpEvent(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.Sent: {
        this.store.patchState({
          status: 'started',
          progress: 0
        })
        return
      }
      case HttpEventType.UploadProgress: {
        const progress = Math.round((100 * event.loaded) / event.total)
        this.store.patchState({
          progress
        })
        return
      }
      case HttpEventType.ResponseHeader:
      case HttpEventType.Response: {
        if (event.status === 200) {
          let response_body
          if (event.type == HttpEventType.Response) {
            response_body = event.body
          }
          this.store.patchState((state) => {
            if (response_body && response_body.uploaded_files.length > 0) {
              this.fileChange.emit({
                name: response_body.uploaded_files[0].name, 
                path: response_body.uploaded_files[0].path
              })
              return {
                progress: 100,
                error: null,
                status: 'completed',
                file: response_body.uploaded_files[0]
              }
            }
            return {
              progress: 100,
              error: null,
              status: 'completed'
            }
          })
        }
        if (event.status == 400) {
          this.uploadFailure('Bad Request, Please check your form data.')
          return;
        }
        if (event.status === 403) {
          this.uploadFailure('The Server is refusing action, Please check your form data.')
          return;
        }
        if (event.status === 404) {
          this.uploadFailure('Not found, API have not developed yet.')
          return;
        }
        if (event.status === 500) {
          this.uploadFailure('Internal Server Error, Please inform us by email.')
          return;
        }
        if (event.status >= 500) {
          this.uploadFailure(`Server error ${event.status}, Please inform us by email`)
          return;
        }
        if (event.status >= 400) {
          this.uploadFailure(`Client error ${event.status}, Please check your inserted data`)
          return;
        }
        this.uploadFailure(`Request error ${event.status}, Please check action`)
        return;
      }
      default: {
        this.uploadFailure(`Unknown event: ${JSON.stringify(event)}`)
        return;
      }
    }
  }

  private uploadFailure(error: string) {
    this.store.patchState({
      status: 'failed',
      error,
      progress: null
    })
  }
}