import { Component, EventEmitter, Input, OnDestroy, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ComponentStore } from "@ngrx/component-store";
import { select, Store } from "@ngrx/store";
import { combineLatest, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { selectAllUnitAreaMeasurements } from "src/app/core/linked-tables";
import { formControlValueChanges } from "src/app/core/v2/utils";

interface State {
  max: number,
  sizeUnitId: number,
  minRange: number,
  maxRange: number
}

@Component({
  selector: 'kt-size-selection',
  templateUrl: './size-selection.component.html',
  providers: [ComponentStore]
})
export class SizeSelectionComponent implements OnDestroy {
  @Input() title: string = ''
  @Input() placeholder: string = ''
  @Input() rangePlaceholder: string = ''

  @Input()
  set sizeUnitId(value: number) {
    this.componentStore.patchState({
      sizeUnitId: value,
      max: value == 2 ? 2690 : 250 
    })

    this.sizeUnitControl.patchValue(value)
  }
  @Output() sizeUnitChange = new EventEmitter<number>()
  @Input()
  set sizeRanges(value: number[]) {
    this.componentStore.patchState({
      minRange: value[0] ,
      maxRange: value[1]
    })
  }
  @Output() sizeRangeChange = new EventEmitter<number[]>()

  sizeUnitControl = new FormControl()
  sizeUnitAcronym: string = ''

  // Selectors
  unitAreaMeasurements$ = this.store.pipe(select(selectAllUnitAreaMeasurements))
  minRange$ = this.componentStore.select(state => state.minRange)
  maxRange$ = this.componentStore.select(state => state.maxRange)
  sizeUnitId$ = this.componentStore.select(state => state.sizeUnitId)
  max$ = this.componentStore.select(state => state.max)

  sizeRangeLabel$ = this.componentStore.select(
    this.sizeUnitId$,
    this.minRange$,
    this.maxRange$,
    (sizeUnitId, minRange, maxRange) => {
      const _maxRange = sizeUnitId == 2
        ? (maxRange >= 2690 ? '2690+' : maxRange) 
        : (maxRange >= 250 ? '250+' : maxRange)
      return {
        maxRange: _maxRange,
        minRange
      }
    }
  )

  maxSelected$ = this.componentStore.select(
    this.sizeUnitId$,
    this.maxRange$,
    (sizeUnitId, maxRange) => {
      if (sizeUnitId == 2) {
        if (maxRange >= 2690) return 2690
        return maxRange
      }
      if (maxRange >= 250) return 250
      return maxRange
    }
  )

  private _onDestroy$ = new Subject<void>()

  constructor(
    private store: Store,
    private componentStore: ComponentStore<State>
  ) {
    this.componentStore.setState({
      max: null,
      sizeUnitId: null,
      minRange: 0,
      maxRange: null
    })

    // Effects
    combineLatest([
      this.unitAreaMeasurements$,
      formControlValueChanges(this.sizeUnitControl)
    ]).pipe(
      takeUntil(this._onDestroy$)
    ).subscribe(([uams, value]) => {
      this.sizeUnitChange.emit(value)

      const uam = uams.find(uam => uam.id == value)
      if (uam) {
        this.sizeUnitAcronym = uam.acronym
      }
    })
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(),
    this._onDestroy$.complete()
  }

  onSliderChange(event: number[]) {
    this.componentStore.patchState({
      minRange: event[0],
      maxRange: event[1]
    })

    this.sizeRangeChange.emit(event)
  }
}