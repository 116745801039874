import { NgModule } from "@angular/core";
import { EsgIndicatorsComponent } from "./esg-indicators.component";
import { MatButtonModule } from "@angular/material/button";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { CommonModule } from "@angular/common";
import { MatInputModule } from "@angular/material/input";
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { MadFormsModule } from "../../mad-forms/mad-form.module";
import { MatIconModule } from "@angular/material/icon";
import { MatTableModule } from "@angular/material/table";
import { LabelEditDialogComponent } from "./label-edit-dialog.component";
import { MatDialogModule } from "@angular/material/dialog";
import { ImageViewerModule } from "../image-viewer/image-viewer.module";

const options: Partial<IConfig | (() => Partial<IConfig>)> = {}

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MadFormsModule,
    ImageViewerModule
  ],
  declarations: [EsgIndicatorsComponent, LabelEditDialogComponent],
  exports: [EsgIndicatorsComponent],
  providers: [
    provideNgxMask(options)
  ]
})
export class EsgIndicatorsModule {}