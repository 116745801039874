import * as moment from "moment"
import { ConvertedCostRevenue } from "../../types"
import { 
  totalNonRecoverable as calculateTNR, 
  totalRecoverable as calculateTR
} from "../../utils"
import { EffectiveRentUtil } from "../headline-rent"
import { ModelAssumptionsUtil } from "./model-assumptions-util"
import { basisMultiplier, miniFs, rounddown4, yieldPurchaseOnPerpetuityAnnually, yieldPurchaseOnPerpetuityQuarterly, yieldPurchaseUntilReviewAnnually, yieldPurchaseUntilReviewQuarterly } from "./utils"

export function calculateFreeholdWithTerminableLifeBuildingReversionToSiteValue(
  costRevenue: ConvertedCostRevenue,
  valuationDate: Date,
  {
    activeBreakOffOption,
    allRiskYield,
    timingId,
    securedRent,
    estimatedRentForLand,
    annualSinkingFund,
    taxRate,
    allRiskYieldForASF,
    allRiskYieldOnPerpetuity,
    estimatedVoidPeriod,
    purchaserCost
  }: {
    activeBreakOffOption: boolean
    allRiskYield: number,
    timingId: number,
    securedRent: number,
    estimatedRentForLand: number,
    annualSinkingFund: number,
    taxRate: number,
    allRiskYieldForASF: number,
    allRiskYieldOnPerpetuity: number,
    estimatedVoidPeriod: number,
    purchaserCost: number
  }
) {
  const _valuationDate = valuationDate ? moment(valuationDate) : null
  const unexpiredLeaseTerm = _valuationDate ? ModelAssumptionsUtil.unexpiredLeaseTerm(costRevenue, _valuationDate) : null
  const yearsToReview = _valuationDate ? ModelAssumptionsUtil.yearsToReview(costRevenue, _valuationDate) : null
  const yearsToWriteOff = _valuationDate ? ModelAssumptionsUtil.yearsToWriteOff(costRevenue, _valuationDate) : null
  const yearsToBreakOff = _valuationDate ? ModelAssumptionsUtil.yearsToBreakOff(costRevenue, _valuationDate) : null

  const _basisMultiplier = basisMultiplier(costRevenue.rentBasis)
  const effectiveRent = EffectiveRentUtil.getValueFromConvertedCostRevenue(costRevenue)
  const totalRecoverable = calculateTR(costRevenue) * _basisMultiplier
  const totalNonRecoverable = calculateTNR(costRevenue) * _basisMultiplier
  const passingRent = effectiveRent + totalRecoverable - totalNonRecoverable

  const minYearWithUE = miniFs(
    activeBreakOffOption
      ? {'Unexpired Lease term': unexpiredLeaseTerm, 'Years to Review': yearsToReview, 'Years to Write off': yearsToWriteOff, 'Years to Break off': yearsToBreakOff}
      : {'Unexpired Lease term': unexpiredLeaseTerm, 'Years to Review': yearsToReview, 'Years to Write off': yearsToWriteOff}
  )
  const yieldPurchaseUntilTerm = timingId == 1
    ? yieldPurchaseUntilReviewAnnually(allRiskYield, minYearWithUE.value)
    : timingId == 2
      ? yieldPurchaseUntilReviewQuarterly(allRiskYield, minYearWithUE.value)
      : undefined
  const capitalisedSecuredRent = securedRent * yieldPurchaseUntilTerm

  const remainderOfRent = passingRent - securedRent
  const yieldPurchaseOfRemainderRent = yieldPurchase(
    allRiskYieldForASF,
    annualSinkingFund,
    taxRate,
    minYearWithUE.value,
    timingId
  )
  const capitalisedRemainderOfRent = remainderOfRent * yieldPurchaseOfRemainderRent

  const yieldPurchaseOnPerpetuity = timingId == 1
    ? rounddown4(yieldPurchaseOnPerpetuityAnnually(allRiskYieldOnPerpetuity))
    : timingId == 2
      ? rounddown4(yieldPurchaseOnPerpetuityQuarterly(allRiskYieldOnPerpetuity))
      : undefined
  const _presentValue = presentValue(
    allRiskYieldOnPerpetuity,
    activeBreakOffOption,
    yearsToBreakOff,
    estimatedVoidPeriod,
    minYearWithUE,
    unexpiredLeaseTerm
  )
  const capitalisedSecuredRentOnPerpetuity = estimatedRentForLand * yieldPurchaseOnPerpetuity * _presentValue.value

  const capitalisedRent = capitalisedSecuredRentOnPerpetuity + capitalisedRemainderOfRent + capitalisedSecuredRent
  const lessPurchaserCost = capitalisedRent
    ? purchaserCost
      ? (purchaserCost / 100) * capitalisedRent
      : 0
    : undefined
  const value = capitalisedRent - lessPurchaserCost


  return {
    effectiveRent,
    basisMultiplier: _basisMultiplier,
    recoverable: {
      total: totalRecoverable,
      recoverablePropertyManagement: costRevenue.recoverablePropertyManagement * _basisMultiplier,
      recoverableLeasingExpenses: costRevenue.recoverableLeasingExpenses * _basisMultiplier, 
      recoverableUtilities: costRevenue.recoverableUtilities * _basisMultiplier,
      recoverableMaintenance: costRevenue.recoverableMaintenance * _basisMultiplier,
      recoverableInsurance: costRevenue.recoverableInsurance * _basisMultiplier,
      recoverableJanitorial: costRevenue.recoverableJanitorial * _basisMultiplier,
      recoverablePropertyTaxes: costRevenue.recoverablePropertyTaxes * _basisMultiplier,
      recoverableBusiness: costRevenue.recoverableBusiness * _basisMultiplier,
      recoverableOthers: costRevenue.recoverableOthers * _basisMultiplier,
    },
    nonRecoverable: {
      total: totalNonRecoverable,
      nonRecoverablePropertyManagement: costRevenue.nonRecoverablePropertyManagement * _basisMultiplier,
      nonRecoverableLeasingExpenses: costRevenue.nonRecoverableLeasingExpenses * _basisMultiplier, 
      nonRecoverableUtilities: costRevenue.nonRecoverableUtilities * _basisMultiplier,
      nonRecoverableMaintenance: costRevenue.nonRecoverableMaintenance * _basisMultiplier,
      nonRecoverableInsurance: costRevenue.nonRecoverableInsurance * _basisMultiplier,
      nonRecoverableJanitorial: costRevenue.nonRecoverableJanitorial * _basisMultiplier,
      nonRecoverablePropertyTaxes: costRevenue.nonRecoverablePropertyTaxes * _basisMultiplier,
      nonRecoverableBusiness: costRevenue.nonRecoverableBusiness * _basisMultiplier,
      nonRecoverableOthers: costRevenue.nonRecoverableOthers * _basisMultiplier,
    },
    passingRent: passingRent,

    yieldPurchaseUntilTerm: {
      value: yieldPurchaseUntilTerm,
      year: minYearWithUE
    },
    capitalisedSecuredRent,

    remainderOfRent,
    yieldPurchaseOfRemainderRent: {
      value: yieldPurchaseOfRemainderRent,
      year: minYearWithUE 
    },
    capitalisedRemainderOfRent,

    yieldPurchaseOnPerpetuity,
    presentValue: _presentValue,
    capitalisedSecuredRentOnPerpetuity,

    capitalisedRent,
    lessPurchaserCost,

    value
  }
}

function yieldPurchase(
  allRiskYield: number,
  annualSinkingFund: number,
  taxRate: number,
  year: number,
  timingId: number
) {
  if (year === null) return undefined
  const ary = allRiskYield / 100
  const asf = annualSinkingFund / 100
  const tax = 100/(100 - taxRate)

  const denom = Math.pow(1 + asf, year) - 1

  const aryV = timingId == 1 
    ? ary
    : timingId == 2
      ? 4 * (1 - Math.pow(1 + ary, -0.25))
      : undefined
  const asfV = timingId == 1
    ? asf
    : timingId == 2
      ? 4 * (1 - Math.pow(1 + asf, -0.25))
      : undefined

  const temp1 = asfV / denom 
  const temp2 = temp1 * tax 
  const temp3 = aryV + temp2
  if (temp3 == 0) return undefined
  const value = 1 / temp3
  return rounddown4(value)
}

function presentValue(
  allRiskYield: number,
  activeBreakOption: boolean,
  yearsToBreakOff: number,
  estimatedVoidPeriod: number,
  minYear: {key: string ,value: number},
  unexpiredLeaseTerm: Number
) {
  const ary = allRiskYield / 100
  const year = activeBreakOption
    ? {
      value: yearsToBreakOff + estimatedVoidPeriod,
      firstYear: {
        name: 'Break option after',
        value: yearsToBreakOff
      },
      secondYear: {
        name: 'Estimated Void period',
        value: estimatedVoidPeriod == null ? 0 : estimatedVoidPeriod
      }
    }
    : minYear.value == unexpiredLeaseTerm
      ? {
        value: minYear.value + estimatedVoidPeriod,
        firstYear: {
          name: minYear.key,
          value: minYear.value
        },
        secondYear: {
          name: 'Estimated Void period',
          value: estimatedVoidPeriod == null ? 0 : estimatedVoidPeriod
        }
      }
      : {
        value: minYear.value,
        firstYear: {
          name: minYear.key,
          value: minYear.value
        }
      }
    if (allRiskYield == 0) return {value: undefined, year}

    const value = Math.pow(1 + ary, -year.value)
    return {
      value: rounddown4(value),
      year
    }
}