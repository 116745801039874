// "use strict";

var checkCorporateEmail = {
    checkEmailFunc: () => {
        var blocked = [
            "gmail",
            "yahoo",
            "outlook",
            "hotmail",
            "aol",
            "icloud",
            "protonmail",
            "mail",
            "yandex",
            "zoho",
            "gmx",
            "msn",
            "live",
            "orange",
            "laposte",
            "free",
            "web",
            "gmx",
            "t-online",
            "libero",
            "virgilio",
            "qq",
            "163",
            "126",
            "sina",
            "naver",
            "hanmail",
            "daum",
            "uol",
            "bol",
            "tutanota",
            "fastmail",
            "hushmail",
            "inbox",
            "rocketmail",
            "rediffmail"
        ];

        document.querySelector('body').addEventListener("click", (event) => {
            // NOTE: dont add more than 1 body click event
            if( event.target.name == 'Person.Email' || event.target.type == 'email' ) {
                event.target.addEventListener("change", (event) => {
                    var email_slice =  event.target.value.split('@')[1]
                    var documentElement = document.createElement('div');
                    documentElement.id = 'corporate-email'
                    documentElement.classList.add("o--ErrorMessages--errorMessages","o--ErrorMessages--single","o--ErrorMessages--cornerShape-rounded");
                    documentElement.innerHTML= '<ul><li>Email addresses from public domains (e.g., Gmail, Yahoo) are not permitted.</li></ul>';

                    if (blocked.includes(email_slice.substr(0, email_slice.indexOf('.')))) {
                        if (document.getElementById("corporate-email")) {
                            document.getElementById("corporate-email").remove();
                        }
                        if (document.querySelector('.o--plan-info')) {
                            document.querySelector('.o--plan-info').append(documentElement);
                            document.querySelector('.o--Register--nextButton').disabled = true;
                        }
                    } else {
                        if (document.getElementById("corporate-email")) {
                            document.getElementById("corporate-email").remove();
                        }
                        document.querySelector('.o--Register--nextButton').disabled = false;
                    }
                });
            }
        });
    },

    outsetaModify: () => {

        document.querySelector('body').removeEventListener("click", clickFunction, true);
        document.querySelector('body').addEventListener("click", clickFunction, true);

        function clickFunction(event) {
            if (event.target.innerHTML == 'Invite new user' || event.target.innerHTML == '<span class="o--Button--children">Invite new user</span>') {
                const emailId = '#email'
                const nameId = '#given-name'
                const lastNameId = '#family-name'

                if (document.querySelector(emailId) && document.querySelector(emailId).value.length > 0 &&
                    document.querySelector(nameId) && document.querySelector(nameId).value.length > 0 &&
                    document.querySelector(lastNameId) && document.querySelector(lastNameId).value.length > 0
                ) {
                    let invObj = {"email": document.querySelector(emailId).value, "firstname": document.querySelector(nameId).value, "lastname":document.querySelector(lastNameId).value};

                    let arr = JSON.parse(localStorage.getItem('inv')) || [];
                    arr.indexOf(invObj) === -1 ? arr.push(invObj) : console.log("inv duplication");
                    localStorage.setItem('inv', JSON.stringify(arr));
                }
            }

            if (event.target.innerHTML == 'close' &&
                event.target.closest('.o--Team--personAccountActions') &&
                event.target.closest('.o--Team--personAccountActions').parentNode.previousSibling.querySelector('.o--text-sm')
            ) {
                let rmObj = {"email": event.target.closest('.o--Team--personAccountActions').parentNode.previousSibling.querySelector('.o--text-sm').innerHTML, "confirm": false};
                let arrRm = JSON.parse(localStorage.getItem('removedUser')) || [];
                arrRm.indexOf(rmObj) === -1 ? arrRm.push(rmObj) : console.log("remove duplication");

                localStorage.setItem('removedUser', JSON.stringify(arrRm))
            }

            if (event.target.innerHTML == 'Remove team member' || event.target.innerHTML == '<span class="o--Button--children">Remove team member</span>') {

                let arrRm = JSON.parse(localStorage.getItem('removedUser')) || [];
                if (arrRm.length > 0) {
                    arrRm[arrRm.length - 1].confirm = true;
                }
                localStorage.setItem('removedUser', JSON.stringify(arrRm));
            }

            if (event.target.innerHTML == 'Make primary contact') {
                const outseta = window['Outseta'];
                outseta.setAccessToken(null);
                window.zE('messenger', 'logoutUser')
            }
        }
    }
}

if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = { checkCorporateEmail }
}
