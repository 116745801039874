<form [formGroup]="formGroup">
  <div class="row">
    <div class="col-12">
      <div class="action-btn-container">
        <button type="button" class="btn btn-primary reset-btn" mat-raised-button (click)="onReset()">Reset
          Tab</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h5 class="my-3 text-mad text-uppercase">
        Energy rating and performance & greenhouse gas emissions
      </h5>
      <hr class="active" />
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field class="mat-form-field-fluid">
        <mat-select placeholder="Energy Performance Certificate" formControlName="energy_performance_certificate">
          <mat-option *ngFor="let option of (grades$|async)" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="mat-form-field-fluid">
        <input matInput placeholder="Primary energy consumption" formControlName="primary_energy_consumption"
          mask="separator.2" thousandSeparator="," />
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field class="mat-form-field-fluid">
        <mat-select formControlName="primary_energy_consumption_rate">
          <mat-option *ngFor="let option of (energyRates$|async)" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <mat-form-field class="mat-form-field-fluid">
        <input matInput placeholder="Performance" formControlName="performance" mask="separator.2"
          thousandSeparator="," />
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field class="mat-form-field-fluid">
        <mat-select formControlName="performance_rate">
          <mat-option *ngFor="let option of (energyRates$|async)" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="mat-form-field-fluid">
        <input matInput placeholder="Final energy consumption" formControlName="final_energy_consumption"
          mask="separator.2" thousandSeparator="," />
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field class="mat-form-field-fluid">
        <mat-select formControlName="final_energy_consumption_rate">
          <mat-option *ngFor="let option of (energyRates$|async)" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field class="mat-form-field-fluid">
        <kt-datepicker-tz-input #expiryDate placeholder="Expiry Date" formControlName="expiry_date" />
        <button mat-button mat-icon-button matSuffix type="button">
          <mat-icon>today</mat-icon>
        </button>
        <button *ngIf="formGroup.controls.expiry_date.value" mat-button mat-icon-button matSuffix type="button"
          (click)="onClearExpiryDate(expiryDate)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="mat-form-field-fluid">
        <input matInput placeholder="Energy intensity" formControlName="energy_intensity" mask="separator.2"
          thousandSeparator="," />
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field class="mat-form-field-fluid">
        <mat-select formControlName="energy_intensity_rate">
          <mat-option *ngFor="let option of (energyRates$|async)" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <mat-form-field class="mat-form-field-fluid">
        <input matInput placeholder="Improvements since last energy rating?"
          formControlName="improvements_since_last_energy_rating" />
      </mat-form-field>
    </div>
    <div class="col-4">
      <mat-form-field class="mat-form-field-fluid">
        <input matInput placeholder="CO2 emissions" formControlName="co2_emissions" mask="separator.2"
          thousandSeparator="," />
      </mat-form-field>
    </div>
    <div class="col-2">
      <mat-form-field class="mat-form-field-fluid">
        <mat-select formControlName="co2_emissions_rate">
          <mat-option *ngFor="let option of (emissionRates$|async)" [value]="option.value">
            {{option.label}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-form-field class="mat-form-field-fluid">
        <textarea matInput placeholder="Any comments or justifications" formControlName="comments_or_justifications"
          rows="5"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="row section-margin-top-60">
    <div class="col-12">
      <h5 class="my-3 text-mad text-uppercase">
        Renewable energy production (onsite)
      </h5>
      <hr class="active" />
    </div>
  </div>
  <div class="row">
    <ng-container *ngFor="let renewableEnergyProductionForm of renewableEnergyProductionFormArray.controls; let i = index">
      <ng-container [formGroup]="renewableEnergyProductionForm">
        <div class="col-12 mb-4">
          <div class="row">
            <div class="col-6">
              <mat-form-field class="mat-form-field-fluid">
                <mat-select formControlName="energy_generation_method_id" placeholder="Method of energy generation">
                  <mat-option *ngFor="let option of (energyGenerationMethods$|async)" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Energy generation method is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-6">
              <div class="add_remove_btn_container">
                <button type="button" mat-icon-button color="primary" class="add_btn" (click)="onAddRenewableEnergyProduction(i)" disableRipple>
                  <mat-icon>add_circle</mat-icon>
                </button>
                <button *ngIf="renewableEnergyProductionFormArray.length > 1" type="button" mat-icon-button color="primary" class="remove_btn" (click)="onRemoveRenewableEnergyProduction(i)">
                  <mat-icon>do_not_disturb_on</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Total production" formControlName="total_production" mask="separator.2"
                  thousandSeparator="," />
              </mat-form-field>
            </div>
            <div class="col-2">
              <mat-form-field class="mat-form-field-fluid">
                <mat-select formControlName="total_production_rate">
                  <mat-option *ngFor="let option of (energyRates$|async)" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Share of primary energy demand" formControlName="share_of_primary_energy_demand" 
                  mask="percent.2"
                  suffix="%"/>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Share of final energy demand" formControlName="share_of_final_energy_demand" 
                  mask="percent.2"
                  suffix="%"/>
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Share of energy used on-site" formControlName="share_of_energy_used_onsite" 
                  mask="percent.2"
                  suffix="%"/>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Share of energy delivered back" formControlName="share_of_energy_delivered_back" 
                  mask="percent.2"
                  suffix="%"/>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-form-field class="mat-form-field-fluid">
        <textarea matInput placeholder="Notes (heating source, usage of energy, etc)" formControlName="notes"
          rows="5"></textarea>
      </mat-form-field>
    </div>
  </div>

  <div class="row section-margin-top-60">
    <div class="col-12">
      <h5 class="my-3 text-mad text-uppercase">
        Labels and certificates
      </h5>
      <hr class="active" />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="action-btn-container pb-4">
        <button type="button" mat-raised-button class="btn btn-primary" (click)="onAddLabel()">
          Add a label
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-table [dataSource]="labelDataSource$" [trackBy]="labelTrackBy">
        <ng-container matColumnDef="label_name">
          <mat-header-cell *matHeaderCellDef class="pr-4">Label Name</mat-header-cell>
          <mat-cell *matCellDef="let item" class="pr-4">
            <mat-form-field class="mat-form-field-fluid w-100" floatLabel="never">
              <mat-select [formControl]="item.form.controls.label" placeholder="Label name">
                <mat-option *ngFor="let option of (epcLabels$|async)" [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rating">
          <mat-header-cell *matHeaderCellDef class="pr-4">Rating</mat-header-cell>
          <mat-cell *matCellDef="let item" class="pr-4">
            <mat-form-field class="mat-form-field-fluid w-100" floatLabel="never">
              <mat-select [formControl]="item.form.controls.rating" placeholder="Rating">
                <mat-option *ngFor="let option of (grades$|async)" [value]="option.value">
                  {{option.label}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="details">
          <mat-header-cell *matHeaderCellDef class="pr-4">Details</mat-header-cell>
          <mat-cell *matCellDef="let item" class="pr-4">
            <mat-form-field class="mat-form-field-fluid w-100" floatLabel="never">
              <input matInput [formControl]="item.form.controls.details" placeholder="Details" />
              <mat-error>
                Cannot be longer than 200 characters
              </mat-error>
            </mat-form-field>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="expiry_date">
          <mat-header-cell *matHeaderCellDef>Date of expiry</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <ng-container *ngIf="item.form.controls.expiry_date.value">
              {{ item.form.controls.expiry_date.value | date: 'dd MMM yyy' }}
            </ng-container>
            <ng-container *ngIf="!item.form.controls.expiry_date.value">
              N/A
            </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="documents">
          <mat-header-cell *matHeaderCellDef>Documents</mat-header-cell>
          <mat-cell *matCellDef="let item">
            <ng-container *ngIf="item.icon">
              <img [attr.src]="item.icon" />
            </ng-container>
            <ng-container *ngIf="!item.icon">
              N/A
            </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
          <mat-cell *matCellDef="let item; let i = index">
            <button type="button" mat-icon-button color="primary" (click)="onEditLabel(i)">
              <mat-icon>create</mat-icon>
            </button>
            <button type="button" mat-icon-button color="warn" (click)="onRemoveLabel(i)">
              <mat-icon>delete</mat-icon>
            </button>
            <button type="button" mat-icon-button color="primary" [disabled]="!item.form.controls.documents.value"
              (click)="onSeeLabelDocument(item.form.controls.documents.value)">
              <mat-icon>remove_red_eye</mat-icon>
            </button>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="['label_name', 'rating', 'details', 'expiry_date', 'documents', 'actions']"></mat-header-row>
        <mat-row *matRowDef="let rows; columns: ['label_name', 'rating', 'details', 'expiry_date', 'documents', 'actions']"></mat-row>
      </mat-table>
    </div>
  </div>

  <div class="row section-margin-top-60">
    <div class="col-12">
      <h5 class="my-3 text-mad text-uppercase">
        Emissions pathway analysis and physical climate risks
      </h5>
      <hr class="active" />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <mat-form-field class="mat-form-field-fluid">
        <textarea matInput 
          formControlName="comments"
          placeholder="Comment on whether the property performance is on the pathway and in line with future targets"
          rows="5"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="row mt-4 mb-4">
    <div class="col-12">
      <span>Is the property exposed to a significant climate risk from now to 2050 (and potentially to 2100)?</span>
    </div>
  </div>
  <div class="row">
    <ng-container *ngFor="let exposedRiskForm of exposedRiskFormArray.controls; let i = index">
      <ng-container [formGroup]="exposedRiskForm">
        <div class="col-12 mb-4">
          <div class="row">
            <div class="col-6">
              <mat-form-field class="mat-form-field-fluid">
                <mat-select placeholder="Climate Risk" formControlName="climate_risk_id">
                  <mat-option *ngFor="let option of (climateRisks$|async)" [value]="option.value">
                    {{option.label}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-6">
              <div class="add_remove_btn_container">
                <button type="button" mat-icon-button color="primary" class="add_btn" (click)="onAddExposedRisk(i)" disableRipple>
                  <mat-icon>add_circle</mat-icon>
                </button>
                <button *ngIf="exposedRiskFormArray.length > 1" type="button" mat-icon-button color="primary" class="remove_btn" (click)="onRemoveExposedRisk(i)">
                  <mat-icon>do_not_disturb_on</mat-icon>
                </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field class="mat-form-field-fluid">
                <textarea matInput placeholder="Mitigation measure" formControlName="mitigation_measure" rows="5"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</form>