import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {KeyPlaceModel, KeyplacesStatus} from '../_models/key-place.model';
import {HttpUtilsService, QueryParamsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class KeyPlaceService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/key-places`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    // KeyPlaces
    getKeyPlaces(queryParams: QueryParamsModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
        if (queryParams.filter) {
            Object.keys(queryParams.filter).forEach(key => {
                const val = queryParams.filter[key];
                if (typeof val == 'number')  {
                    params = params.set(`filter[${key}]`, val.toString());
                }
                if (typeof val == 'string' && val != '') {
                    params = params.set(`filter[${key}]`, val);
                } else if (key == 'key_categories') {
                    params = params.set(`filter[${key}]`, val)
                }
                if (key == 'bounds' && val != null) {
                    params = params.set(`filter[bounds][sw_lat]`, val.sw_lat);
                    params = params.set(`filter[bounds][sw_lng]`, val.sw_lng);
                    params = params.set(`filter[bounds][ne_lng]`, val.ne_lng);
                    params = params.set(`filter[bounds][ne_lat]`, val.ne_lat);
                }
            })
        };

        return this.http.get<any>(this.API_URL, {
            headers: httpHeaders,
            params: params
        });
    }

    getKeyPlacesMultimedia(queryParams: QueryParamsModel): Observable<any> {
        let params = new HttpParams()
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
        if (queryParams.filter) {
            Object.keys(queryParams.filter).forEach(key => {
                const val = queryParams.filter[key];
                if (typeof val == 'number')  {
                    params = params.set(`filter[${key}]`, val.toString());
                }
                if (typeof val == 'string' && val != '') {
                    params = params.set(`filter[${key}]`, val);
                } else if (key == 'key_categories') {
                    params = params.set(`filter[${key}]`, val)
                }
                if (key == 'bounds' && val != null) {
                    params = params.set(`filter[bounds][sw_lat]`, val.sw_lat);
                    params = params.set(`filter[bounds][sw_lng]`, val.sw_lng);
                    params = params.set(`filter[bounds][ne_lng]`, val.ne_lng);
                    params = params.set(`filter[bounds][ne_lat]`, val.ne_lat);
                }
            })
        };

        return this.http.get<any>(
            `${environment.baseApiUrl}api/${this.subDomainService.subDomain}/multimedia/key-places`,
        {
            params: params
        });
    }

    getStatus(): Observable<KeyplacesStatus> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<{data: KeyplacesStatus}>(this.API_URL + '/status', {headers: httpHeaders})
            .pipe(map(res => res.data))
    }

    getAllKeyPlaces(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL, {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new keyPlace to the server
    createKeyPlace(keyPlace: KeyPlaceModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_URL, keyPlace, {headers: httpHeaders});
    }


    // UPDATE => PUT: update the keyPlace on the server
    updateKeyPlace(keyPlace: KeyPlaceModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_URL + `/${keyPlace.id}`, keyPlace, {headers: httpHeaders});
    }

    // DELETE => delete the keyPlace from the server
    deleteKeyPlace(keyPlaceId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = `${this.API_URL}/${keyPlaceId}`;
        return this.http.delete(url, {headers: httpHeaders});
    }


    // TRASH (User)
    getTrashedKeyPlaces(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash', {headers: httpHeaders});
    }

    deleteFromTrash(keyPlaceId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/trash/${keyPlaceId}`, {headers: httpHeaders});
    }

    // TRASH (Admin)
    getAdminTrashedKeyPlaces(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_URL + `/admin/trash`, {headers: httpHeaders});
    }

    // delete from admin trash
    deleteFromAdminTrash(keyPlaceId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_URL + `/admin/trash/${keyPlaceId}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/trash/${id}`, {headers: httpHeaders});
    }

    flushTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash/flush', {headers: httpHeaders});
    }
}
