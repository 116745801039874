import { Component, NgZone, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter } from 'lodash';
import { BehaviorSubject, Observable, Subscription, combineLatest } from 'rxjs';
import { AssetClassLandDeleted, AssetClassLandDeletedFromTrash, AssetClassLandDuplicate, AssetClassLandPageRequested } from 'src/app/core/comparable/_actions/asset-class-land.actions';
import { AssetClassLandDataSource } from 'src/app/core/comparable/_data-sources/asset-class-land.datasource';
import { AssetClassLandModel } from 'src/app/core/comparable/_models/asset-class-land.model';
import { selectAdminTrashedAssetClassLandCount, selectTrashedAssetClassLandCount } from 'src/app/core/comparable/_selectors/asset-class-land.selectors';
import { AssetClassLandService } from 'src/app/core/comparable/_services/asset-class-land.service';
import { User, currentUser, hasPermission } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, QueryParamsModel } from 'src/app/core/_base/crud';
import { madCalculator } from 'src/app/core/_base/crud/utils/map-marker.util';
import { emptyFilter, FilterModel, FilterChange } from '../../../shared_components/filter-section/filter-section.component';
import { MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput } from '../../../shared_components/map-marker-info-window/map-marker-info-window.component';
import { ToMarkerItemConverterService } from '../../../shared_components/map-marker-info-window/to-marker-item-converter-service.service';
import { environment } from 'src/environments/environment';
import { MapsService } from 'src/app/core/map/maps.service';
import { map } from 'rxjs/operators';
import {AgmMap, MapsAPILoader} from '@agm/core';

@Component({
  selector: 'kt-lands-map',
  templateUrl: './lands-map.component.html',
  styleUrls: ['./lands-map.component.scss']
})
export class LandsMapComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort1', {static: true}) sort: MatSort;

  @ViewChild('search', {static: false})
  public searchElementRef: ElementRef;
  private geoCoder = new google.maps.Geocoder();

  calculator = madCalculator;
  loading$: Observable<boolean>;
  dataSource: AssetClassLandDataSource;
  assetClasses: AssetClassLandModel[] = [];
  markerData = new Map();
  markerData$ = new BehaviorSubject(new Map());
  previous;
  icon = {
      url: './assets/media/icons/my_marker.svg'
  };

  isFirst = true;
  private _centerLat$ = new BehaviorSubject<number>(null)
  centerLat$ = combineLatest([
    this._centerLat$,
    this.mapsService.latitude$
  ]).pipe(map(([initial, def]) => {
    return initial == null ? def : initial
  }))
  private _centerLng$ = new BehaviorSubject<number>(null)
  centerLng$ = combineLatest([
    this._centerLng$,
    this.mapsService.longitude$
  ]).pipe(map(([initial, def]) => {
    return initial == null ? def : initial
  }))
  zoom = 15;
  map: any;
  currentCenter: { lat: 0, lng: 0 };
  mapAction = new BehaviorSubject<boolean>(true);

  lastQueryParams: QueryParamsModel;
  filterModel: FilterModel = emptyFilter();
  filter$: BehaviorSubject<FilterModel> = new BehaviorSubject(this.filterModel);
  filterObs$ = this.filter$.asObservable();
  displayedColumns = ['id', 'sub_type', 'size', 'consideration', 'source_date', 'actions'];

  _shapes: Map<string, {
    center: google.maps.LatLng,
    label: any,
    data: any[]
  }> = new Map();
  subscriptions: Array<Subscription> = [];
    trashCnt = 0;
    adminTrashCnt = 0;

  get markers() { 
    const l = [];
    this._shapes.forEach((v) => {
      l.push(v);
    })
    return l;
  }

  canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))
  currentUser: User|null

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toMarkerItemService: ToMarkerItemConverterService,
    private dialog: MatDialog,
        private layoutService: LayoutUtilsService,
        private service: AssetClassLandService,
    private mapsService: MapsService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
  ) { }

  ngOnInit(): void {
    const userSub = this.store.select(currentUser).subscribe(user => this.currentUser = user)
    this.subscriptions.push(userSub)
    this.loading$ = this.mapAction.asObservable();

    this.lastQueryParams = new QueryParamsModel({});
    // const lastyQueryParamsSubscriptions = this.store.pipe(
    //     select(selectAssetClassLandsLastQuery),
    //     take(1)
    // ).subscribe(res => {
    //     if (res.isEmpty()) {
    //         return;
    //     }
    //     this.lastQueryParams = res;
    //     this.updateFilter(res.filter);
    // });
    // this.subscriptions.push(lastyQueryParamsSubscriptions);

    this.dataSource = new AssetClassLandDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.subscribe(res => {
      this.assetClasses = res;
      res.forEach(el => {
        if (this.map) {
          if (!this._shapes.has(el.ref_num)) {
            const bounds = new google.maps.LatLngBounds();
            const points = el.points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)}));
            const filtered = points.filter(p => p.lat != null && p.lng != null);
            filtered.forEach(p => bounds.extend(p))
            this._shapes.set(el.ref_num, {
              data: [el],
              center: bounds.getCenter(), 
              label: {
                color: '#000000',
                fontFamily: '',
                fontSize: '14px',
                fontWeight: 'bold',
                className: 'bg-op',
                text: el.ref_num,
              }});
          }
        }
      });

      this.mapAction.next(false);
    });
    this.subscriptions.push(entitiesSubscription);

        const trashSubscription = this.store.pipe(
            select(selectTrashedAssetClassLandCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(trashSubscription);

        const adminTrashSubscription = this.store.pipe(
            select(selectAdminTrashedAssetClassLandCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(adminTrashSubscription);
  }
  onFilterChange(event: FilterChange) {
      if (event.page != null) {
          this.paginator.pageIndex = event.page
      }
      this.filterModel = event.filter;
      this.loadList();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  mapReady(event: any) {
    this.map = event;
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));

    // this._initializeDrawingManager(this.map)
    this.map.addListener('dragend', () => {
      this._centerLat$.next(this.currentCenter.lat);
      this._centerLng$.next(this.currentCenter.lng);

      this.mapAction.next(true);
      this.paginator.pageIndex = 0;
      this.loadList();
    })

    this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder();
        if (this.searchElementRef) {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ['(cities)']
        });
        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
            // get the place result
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();
            // verify result
            if (place.geometry === undefined || place.geometry === null) {
                return;
            }

            // set latitude, longitude and zoom
            this._centerLat$.next(place.geometry.location.lat())
            this._centerLng$.next(place.geometry.location.lng())
            this.zoom = 15;
            });
        });
        }
    });

    // this.mapClickListener = this.map.addListener('click', (e: google.maps.MouseEvent) => {
    //     this.ngZone.run(() => {
    //         this._mapRightClick(e);
    //     })
    // })
  }

  // private _mapRightClick($event: google.maps.MouseEvent) {
  //     const lat = $event.latLng.lat();
  //     const lng = $event.latLng.lng();
  //     const refs = []
  //     this._shapes.forEach((value, _ref) => {
  //       if (google.maps.geometry.poly.containsLocation(new google.maps.LatLng(lat, lng), value.shape)) {
  //         refs.push(_ref);
  //       }
  //     });
  //     const infoData = this.assetClasses.filter(ac => refs.includes(ac.ref_num));
  //     if (infoData.length == 0) {
  //       return;
  //     }
  //     const data = this.toMarkerItemService.convert('comparable', infoData.map(id => ({...id, ac_type: 13})));
  //     this.dialog.open<MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput>(MapMarkerInfoWindowComponent, {
  //         data: {
  //             modal_title: data.modal_title,
  //             entities: data.entities
  //         }
  //     });
  // }

  // private _initializeDrawingManager(map: google.maps.Map) {
  //   const options: google.maps.drawing.DrawingManagerOptions = {
  //     drawingMode: null,
  //     drawingControl: false,
  //     drawingControlOptions: {
  //       position: google.maps.ControlPosition.TOP_CENTER,
  //       drawingModes: [
  //         google.maps.drawing.OverlayType.POLYGON
  //       ]
  //     },
  //     polygonOptions: {
  //       draggable: false,
  //       editable: true
  //     }
  //   }

  //   this._drawingManager = new google.maps.drawing.DrawingManager(options);
  //   this._drawingManager.setMap(map);

  //   google.maps.event.addListener(this._drawingManager, 'overlaycomplete', (event) => {
  //     // if (event.type === google.maps.drawing.OverlayType.POLYGON) {
  //     //   const points = this._getPointsFromPath(event.overlay.getPath());
  //     //   this._createPolygon(points);
  //     //   event.overlay.setMap(null);
  //     // }
  //     // if (event.type !== google.maps.drawing.OverlayType.MARKER) {
  //     //   this._disableDrawing();
  //     // }
  //   })
  // }

  boundsChanged(_latLngBound) {
      const _new = Object.assign({}, this.filterModel) as FilterModel;
      if (_new.bounds == null) {
          _new.bounds = {ne_lat: 0, sw_lat: 0, ne_lng: 0, sw_lng: 0}
      } else {
          _new.bounds = Object.assign({}, _new.bounds);
      }
      _new.bounds.sw_lat = _latLngBound.getSouthWest().lat();
      _new.bounds.ne_lat = _latLngBound.getNorthEast().lat();
      _new.bounds.sw_lng = _latLngBound.getSouthWest().lng();
      _new.bounds.ne_lng = _latLngBound.getNorthEast().lng();

      this.filterModel = _new;
    if (this.isFirst) {
      this.previous = null;
      this.mapAction.next(true);
      this.isFirst = !this.isFirst;
      this.paginator.pageIndex = 0;
    }
      this.loadList();
  }

  zoomChanged(event) {
    this.isFirst = true;
  }

  centerChange(event: any) {
    if (event) {
      this.currentCenter = {lat: event.lat, lng: event.lng};
    }
  }

  setLocation() {
    this.setCurrentLocation();
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position.coords.latitude && position.coords.longitude) {
          this._centerLat$.next(position.coords.latitude);
          this._centerLng$.next(position.coords.longitude);
          this.zoom = 15;
        }
      })
    } else {
      alert('Position not available');
    }
  }
    getItemCssClassByStatus(status: number): string {
        switch (status) {
            case 0:
                return 'warning';
            case 2:
                return 'info';
            default:
               return 'success';
        }
    }
    getItemStatusString(status: number): string {
        switch (status) {
            case 0:
                return 'Draft';
            case 2:
                return 'imported';
            default:
                return 'Validated';
        }
    }

  loadList() {
    let queryParams = null;
    if (this.lastQueryParams.isEmpty()) {
      queryParams = new QueryParamsModel(
        this.filterModel,
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
      )
    } else {
      queryParams = this.lastQueryParams;
    }
    this.lastQueryParams = new QueryParamsModel({});
    this.store.dispatch(new AssetClassLandPageRequested({page: queryParams}))
  }

    addComparable() {
        this.editComparable(null);
    }
    editComparable(ac: AssetClassLandModel) {
        if (ac) {
            this.router.navigate(['../edit', ac.id], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['../add'], {relativeTo: this.activatedRoute})
        }
    }
    deleteComparable(ac: AssetClassLandModel) {
        this.store.dispatch(new AssetClassLandDeleted({id: ac.id}));
    }

    // loadList() {
    //     let queryParams = null;
    //     if (this.lastQueryParams.isEmpty()) {
    //         queryParams = new QueryParamsModel(
    //             this.filterModel,
    //             this.sort.direction,
    //             this.sort.active,
    //             this.paginator.pageIndex + 1,
    //             this.paginator.pageSize
    //         );
    //     } else {
    //         queryParams = this.lastQueryParams;
    //     }
    //     // Call request from server
    //     this.lastQueryParams = new QueryParamsModel({});
    //     this.store.dispatch(new AssetClassMapPageRequested({page: queryParams}));
    // }
    getLabel(title) {
        return {
            color: '#000000',
            fontFamily: '',
            fontSize: '14px',
            fontWeight: 'bold',
            className: 'bg-op',
            text: title,
        };
    }

    showInfo(infoData) {
        // this.selectedId = mId;
        const data = this.toMarkerItemService.convert('comparable', infoData.map(id => ({...id, ac_type: 13})));
        this.dialog.open<MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput>(MapMarkerInfoWindowComponent, {
            data: {
                modal_title: data.modal_title,
                entities: data.entities
            }
        });
    }

    goList() {
        this.router.navigate(['../'], {relativeTo: this.activatedRoute});
    }

    trash() {
        this.service.getTrashed().subscribe(res => {
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.ref_num} - ${elem.name}`,
                    id: elem.id.toString(),
                    date: elem.user_deleted,
                    hasPermanentlyDelete: true,
                    deletedUser: elem.userDeletedBy
                });
            });
            this._showTrashed(items);
        })
    }
    adminTrash() {
        this.service.getAdminTrashed().subscribe(res => {
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.ref_num} - ${elem.name}`,
                    id: elem.id.toString(),
                    date: elem.deleted_at,
                    hasPermanentlyDelete: true,
                    deletedUser: elem.deletedBy
                });
            });
            this._showTrashed(items, true);
        })
    }

    // Acs
    delete(id: number) {
        this.store.dispatch(new AssetClassLandDeletedFromTrash({id}))
    }
    restore(id: number, isAdmin = false) {
        if (isAdmin) {

        } else {
            
        }
    }
    private _showTrashed(items, isAdmin = false) {
        let _title = 'Deleted Land Comparables';
        if (isAdmin) {
            _title = 'Admin Deleted Land Comparables';
        }
        this.layoutService.trashedEntities(_title, items, this, isAdmin);
    }

    duplicate(id: number) {
        this.store.dispatch(new AssetClassLandDuplicate({id}))
    }
}
