// NGRX
import { createFeatureSelector } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter, Update } from '@ngrx/entity';
// Actions
import { AuditTrailActions, AuditTrailActionTypes } from '../_actions/audit-trail.actions';
// Models
import { AuditTrailModel } from '../_models/audit-trail.model';
import { QueryParamsModel } from '../../_base/crud';

export interface AuditTrailsState extends EntityState<AuditTrailModel> {
    listLoading: boolean;
    actionLoading: boolean;
    totalCount: number;
    totalTrashed: number;
    totalAdminTrashed: number;
    lastCreatedAuditTrailId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
}

export const adapter: EntityAdapter<AuditTrailModel> = createEntityAdapter<AuditTrailModel>();

export const initialAuditTrailsState: AuditTrailsState = adapter.getInitialState({
    auditTrailForEdit: null,
    listLoading: false,
    actionLoading: false,
    totalCount: 0,
    totalTrashed: 0,
    totalAdminTrashed: 0,
    lastCreatedAuditTrailId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true
});

export function auditTrailsReducer(state = initialAuditTrailsState, action: AuditTrailActions): AuditTrailsState {
    switch  (action.type) {
        case AuditTrailActionTypes.AuditTrailsPageToggleLoading: {
            return {
                ...state, listLoading: action.payload.isLoading, lastCreatedAuditTrailId: undefined
            };
        }
        case AuditTrailActionTypes.AuditTrailActionToggleLoading: {
            return {
                ...state, actionLoading: action.payload.isLoading
            };
        }
        case AuditTrailActionTypes.AuditTrailOnServerCreated: return {
            ...state,
            lastCreatedAuditTrailId: undefined
        };
        case AuditTrailActionTypes.AuditTrailCreated: return adapter.addOne(action.payload.item, {
            ...state, lastCreatedAuditTrailId: action.payload.item.id
        });
        case AuditTrailActionTypes.AuditTrailsPageCancelled: {
            return {
                ...state, listLoading: false, lastQuery: new QueryParamsModel({})
            };
        }
        case AuditTrailActionTypes.AuditTrailsPageLoaded: {
            return adapter.addMany(action.payload.items, {
                ...initialAuditTrailsState,
                totalCount: action.payload.totalCount,
                listLoading: false,
                totalTrashed: action.payload.totalTrashed,
                totalAdminTrashed: action.payload.totalAdminTrashed,
                lastQuery: action.payload.page,
                showInitWaitingMessage: false
            });
        }

        case AuditTrailActionTypes.AuditTrailsClear:
            return adapter.removeAll(initialAuditTrailsState);


        case AuditTrailActionTypes.AuditTrailDeleted:
            return adapter.removeOne(action.payload.id, {
                ...state, totalTrashed: state.totalTrashed + 1
            });

        case AuditTrailActionTypes.AuditTrailRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalTrashed: state.totalTrashed - 1
            });

        case AuditTrailActionTypes.AuditTrailDeletedFromTrash:
            return {
                ...state, totalTrashed: state.totalTrashed - 1, totalAdminTrashed: state.totalAdminTrashed + 1
            };

        // Admin Trash Action
        case AuditTrailActionTypes.AuditTrailAdminRestored:
            return adapter.addOne(action.payload.item, {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            });

        case AuditTrailActionTypes.AuditTrailDeletedFromAdminTrash:
            return {
                ...state, totalAdminTrashed: state.totalAdminTrashed - 1
            };
        case AuditTrailActionTypes.AuditTrailTrashFlushed:
            return {
                ...state, totalTrashed: 0, totalAdminTrashed: state.totalAdminTrashed + state.totalTrashed
            };
        case AuditTrailActionTypes.AuditTrailUpdated:
            return adapter.updateOne(action.payload.item, {...state})
        default: return state;
    }
}

export const getAuditTrailState = createFeatureSelector<AuditTrailModel>('auditTrails');

export const {
    selectAll,
    selectEntities,
    selectIds,
    selectTotal
} = adapter.getSelectors();
