<!-- begin:: Header Topbar -->
<ng-container>

    <!--display: flex;-->
    <!--align-items: stretch;-->
    <!-- <ng-container *ngIf="_counter > 0"> -->
        <div class="d-flex align-items-center">
            <!-- <span matTooltip="Remaining time to logout" class="cursor-pointer">
                <b>{{ transform(duration - _counter) }}</b>
            </span> -->
            <ng-container *ngIf="notificationsDisplay">
                <!--begin: Notifications -->
                <!-- <kt-notification [bgImage]="'./assets/media/misc/bg-1.jpg'" [pulse]="true" [skin]="'dark'" [icon]="'./assets/media/icons/svg/General/Notifications-1.svg'" [useSVG]="true"></kt-notification> -->
                <!--end: Notifications -->
            </ng-container>
        </div>
    <!-- </ng-container> -->

    <!-- <ng-container *ngIf="languagesDisplay">
        <kt-language-selector></kt-language-selector>
    </ng-container> -->

    <ng-container *ngIf="userDisplay">
        <ng-container *ngIf="userLayout === 'dropdown'">
            <kt-user-profile [userDropdownStyle]="userDropdownStyle"></kt-user-profile>
        </ng-container>
        <ng-container *ngIf="userLayout === 'offcanvas'">
            <!--begin: User bar -->
            <kt-user-profile></kt-user-profile>
            <!--end: User bar -->
        </ng-container>
    </ng-container>
</ng-container>
<!-- end:: Header Topbar -->
