import { Component, Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ComponentStore } from "@ngrx/component-store";
import { Option } from './types'
import { tap, withLatestFrom } from "rxjs/operators";
import { DatepickerTzInputComponent } from "../../mad-forms/datepicker-tz-input/datepicker-tz-input.component";

interface State {
  id: number
  title: string,
  epcLabels: Option[],
  grades: Option[]
}

@Component({
  selector: 'kt-label-edit-dialog',
  templateUrl: './label-edit-dialog.component.html',
  styleUrls: ['./label-edit-dialog.component.scss'],
  providers: [ComponentStore]
})
export class LabelEditDialogComponent {
  formGroup = new FormGroup({
    label: new FormControl<number>(null, [Validators.required]),
    rating: new FormControl<number>(null, [Validators.required]),
    details: new FormControl<string>(null, [Validators.maxLength(200)]),
    expiry_date: new FormControl<Date>(null),
    documents: new FormControl<{name: string, path: string}>(null)
  })

  title$ = this._store.select(state => state.title)
  epcLabels$ = this._store.select(state => state.epcLabels)
  grades$ = this._store.select(state => state.grades)

  constructor(
    private _store: ComponentStore<State>,
    public dialogRef: MatDialogRef<LabelEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      type: 'add' | 'edit',
      labels: Option[],
      grades: Option[],
      value: Partial<{
        id: number,
        label: number,
        rating: number,
        details: string,
        expiry_date: Date,
        documents: {
          name: string,
          path: string
        } 
      }>
    }
  ) {
    this._store.setState({
      id: data.value?.id,
      title: data.type === 'add' ? 'Add Label' : 'Edit Label',
      epcLabels: data.labels,
      grades: data.grades
    })

    if (data.value) {
      this.formGroup.patchValue({
        label: data.value.label,
        rating: data.value.rating,
        details: data.value.details,
        expiry_date: data.value.expiry_date,
        documents: data.value.documents
      })
    }
  }

  onClearExpiryDate(datePicker: DatepickerTzInputComponent) {
    datePicker.clearDate()
    this.formGroup.controls.expiry_date.patchValue(null)
  }

  onFileChange(file: {name: string, path: string}) {
    this.formGroup.controls.documents.patchValue(file)
  }

  onClose() {
    this.dialogRef.close()
  }
  readonly onSubmit = this._store.effect<void>(
    trigger$ => trigger$.pipe(
      withLatestFrom(this._store.state$),
      tap(([_, state]: [void, State]) => {
        let hasFormError = false
        if (this.formGroup.invalid) {
          Object.keys(this.formGroup.controls).forEach(controlName => {
            if (this.formGroup.controls[controlName].invalid) {
              console.log(controlName)
              this.formGroup.controls[controlName].markAsTouched()
            }
          })
          hasFormError = true
        }
        if (hasFormError) {
          return
        }
        this.dialogRef.close({
          id: state.id,
          label: this.formGroup.controls.label.value,
          rating: this.formGroup.controls.rating.value,
          details: this.formGroup.controls.details.value,
          expiryDate: this.formGroup.controls.expiry_date.value,
          documents: this.formGroup.controls.documents.value
        })
      })
    )
  )
}