import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {Update} from '@ngrx/entity';
import {select, Store} from '@ngrx/store';
import {map, mergeMap, take, takeUntil} from 'rxjs/operators';
import {AppState} from '../../reducers';
import {
    FetchValuationData,
    FetchValuationDataMultimedia,
    StoredValuationData,
    UpdateValuationData,
    ValuationDataActionTypes,
    ValuationDataLoaded,
    ValuationDataStore,
    ValuationDataUpdated
} from '../_actions/valuation-data.actions';
import {ValuationDataService} from '../_services/valuation-data.service';
import {ValuationDataModel} from '../_models/valuation-data.model';
import {combineLatest, of, Subject} from 'rxjs';
import {selectValuationData} from '../_selectors/valuation-data.selector';
import { selectIsCriterionLoading } from '../_selectors/criterion.selectors';
import { selectIsSizeCriterionLoading } from '../_selectors/size_criterion.selectors';
import { AddComparables, CriteriaDataLoaded } from '../_actions/criterion.actions';
import { SizeCriterionAddMultipleComparables, SizeCriterionComparableAdded } from '../_actions/size_criterion.actions';
import { AddMultipleComparableValuationAdjustment, ValuationAdjustmentComparableAdded } from '../_actions/valuation-adjustment.actions';
import * as valuationActions from '../_actions/valuation.actions';
import * as assumptionDepartureActions from '../_actions/assumption_departure.actions';
import * as valuationVpga10MattersActions from '../_actions/vpga10-matters.actions';
import * as valuationSRERActions from '../_actions/valuation-srer.actions';
import * as valuationNotesActions from '../_actions/valuation-notes.action';
import { ValuationAssumptionDeparture } from '../_models/valuation-assumption-departure.model';
import { ValuationVpga10Matter } from '../_models/vpga10-matter.model';
import {ValuationSRERModel} from '../_models/valuation-srer.model';
import { __decorate } from 'tslib';

@Injectable()
export class ValuationDataEffects {
    constructor(private actions$: Actions,
                private service: ValuationDataService,
                private store: Store<AppState>) {
    }

    @Effect()
    fetchValuationDataMultimedia$ = this.actions$.pipe(
        ofType<FetchValuationDataMultimedia>(ValuationDataActionTypes.FetchValuationDataMultimedia),
        mergeMap(({payload}) => {
            return this.service.fetchMultimedia(payload);
        }),
        map((response: { 
                asset_class: any, valuation: any, key_places: any, 
                tenures: {comId: number, selectedTenure: number}[],
                comparables: any, adjustment_tab_data: any, 
                notes: any,
                default_assumptions: ValuationAssumptionDeparture[], 
                special_assumptions: ValuationAssumptionDeparture[], 
                departures: ValuationAssumptionDeparture[],
                vpga10_matters: ValuationVpga10Matter[],
                sers: ValuationSRERModel[]
             }) => {
            const _item = new ValuationDataModel();
            _item.clear();
            _item.asset_class = response.asset_class;
            _item.valuation = response.valuation;
            _item.key_places = response.key_places.data;
            _item.comparables = response.comparables.data;
            _item.tenures = response.tenures;

            // Background
            this.store.dispatch(new valuationNotesActions.LoadData({
                data: response.notes
            }));

            // Assumptions
            this.store.dispatch(new assumptionDepartureActions.LoadData({
                default: response.default_assumptions,
                special: response.special_assumptions,
                departure: response.departures,
            }));
            this.store.dispatch(new valuationVpga10MattersActions.LoadData({
                data: response.vpga10_matters
            }));
            this.store.dispatch(new valuationSRERActions.LoadData({data: response.sers}));

            // Adjustment tab
            const destroy$ = new Subject<any>();
            combineLatest([
                of(response.adjustment_tab_data),
                this.store.select(selectIsCriterionLoading),
                this.store.select(selectIsSizeCriterionLoading)
            ]).pipe(takeUntil(destroy$)).subscribe(([data, isCLoading, isSCLoading]) => {
                if (isCLoading === false && isSCLoading === false) {
                    if (data) {
                        this.store.dispatch(new CriteriaDataLoaded({criterions: data.criterions, comparables: _item.comparables, type_id: _item.asset_class.type_id}));
                        this.store.dispatch(new SizeCriterionComparableAdded({criterions: data.sizeCriterions, default: data.defaultSize}))
                        this.store.dispatch(new ValuationAdjustmentComparableAdded({valuationAdjustments: data.adjustments}));
                        const lastId = data.valuations[data.valuations.length - 1].id;
                        this.store.dispatch(new valuationActions.MultipleValuationAdded({
                            valuations: data.valuations, 
                            id: lastId
                        }));
                        this.store.dispatch(new valuationActions.UpdateJustification({justification: data.justification}))
                        this.store.dispatch(new valuationActions.UpdateCapitalAllowance({capitalAllowance: data.capitalAllowance != undefined ? data.capitalAllowance : 0}))
                        this.store.dispatch(new valuationActions.UpdateCapitalAllowanceJustification({capitalAllowanceJustification: data.capitalAllowanceJustification}))
                        destroy$.next();
                    } else {
                        // Data is null
                        this.store.dispatch(new AddComparables({comparables: _item.comparables, tenures: _item.tenures, targetProperty: _item.asset_class, valuation: _item.valuation}));
                        this.store.dispatch(new SizeCriterionAddMultipleComparables({comparable: _item.comparables}))
                        this.store.dispatch(new AddMultipleComparableValuationAdjustment({com: _item.comparables}))
                        this.store.dispatch(new valuationActions.AddMultipleValuation({coms: _item.comparables}));
                        destroy$.next()
                    }
                }
            });

            return new ValuationDataLoaded({item: _item});
        })
    );

    @Effect()
    fetchValuationData$ = this.actions$.pipe(
        ofType<FetchValuationData>(ValuationDataActionTypes.FetchValuationData),
        mergeMap(({payload}) => {
            return this.service.fetch(payload);
        }),
        map((response: { 
                asset_class: any, valuation: any, key_places: any, 
                tenures: {comId: number, selectedTenure: number}[],
                comparables: any, adjustment_tab_data: any, 
                notes: any,
                default_assumptions: ValuationAssumptionDeparture[], 
                special_assumptions: ValuationAssumptionDeparture[], 
                departures: ValuationAssumptionDeparture[],
                vpga10_matters: ValuationVpga10Matter[],
                sers: ValuationSRERModel[]
             }) => {
            const _item = new ValuationDataModel();
            _item.clear();
            _item.asset_class = response.asset_class;
            _item.valuation = response.valuation;
            _item.key_places = response.key_places.data;
            _item.comparables = response.comparables.data;
            _item.tenures = response.tenures;

            // Background
            this.store.dispatch(new valuationNotesActions.LoadData({
                data: response.notes
            }));

            // Assumptions
            this.store.dispatch(new assumptionDepartureActions.LoadData({
                default: response.default_assumptions,
                special: response.special_assumptions,
                departure: response.departures,
            }));
            this.store.dispatch(new valuationVpga10MattersActions.LoadData({
                data: response.vpga10_matters
            }));
            this.store.dispatch(new valuationSRERActions.LoadData({data: response.sers}));

            // Adjustment tab
            const destroy$ = new Subject<any>();
            combineLatest([
                of(response.adjustment_tab_data),
                this.store.select(selectIsCriterionLoading),
                this.store.select(selectIsSizeCriterionLoading)
            ]).pipe(takeUntil(destroy$)).subscribe(([data, isCLoading, isSCLoading]) => {
                if (isCLoading === false && isSCLoading === false) {
                    if (data) {
                        this.store.dispatch(new CriteriaDataLoaded({criterions: data.criterions, comparables: _item.comparables, type_id: _item.asset_class.type_id}));
                        this.store.dispatch(new SizeCriterionComparableAdded({criterions: data.sizeCriterions, default: data.defaultSize}))
                        this.store.dispatch(new ValuationAdjustmentComparableAdded({valuationAdjustments: data.adjustments}));
                        const lastId = data.valuations[data.valuations.length - 1].id;
                        this.store.dispatch(new valuationActions.MultipleValuationAdded({
                            valuations: data.valuations, 
                            id: lastId
                        }));
                        this.store.dispatch(new valuationActions.UpdateJustification({justification: data.justification}))
                        this.store.dispatch(new valuationActions.UpdateCapitalAllowance({capitalAllowance: data.capitalAllowance != undefined ? data.capitalAllowance : 0}))
                        this.store.dispatch(new valuationActions.UpdateCapitalAllowanceJustification({capitalAllowanceJustification: data.capitalAllowanceJustification}))
                        destroy$.next();
                    } else {
                        // Data is null
                        this.store.dispatch(new AddComparables({comparables: _item.comparables, tenures: _item.tenures, targetProperty: _item.asset_class, valuation: _item.valuation}));
                        this.store.dispatch(new SizeCriterionAddMultipleComparables({comparable: _item.comparables}))
                        this.store.dispatch(new AddMultipleComparableValuationAdjustment({com: _item.comparables}))
                        this.store.dispatch(new valuationActions.AddMultipleValuation({coms: _item.comparables}));
                        destroy$.next()
                    }
                }
            });

            return new ValuationDataLoaded({item: _item});
        })
    );


    @Effect()
    storeValuationData$ = this.actions$.pipe(
        ofType<ValuationDataStore>(ValuationDataActionTypes.ValuationDataStore),
        mergeMap(({payload}) => 
            combineLatest([
                of(payload),
                this.store.pipe(select(selectValuationData), take(1))
            ])
        ),
        mergeMap(([_payload, data]) => {
            const _data: ValuationDataModel = Object.assign({}, data);
            const payload = {
                key_place_data: _data.key_places.map(el => el.id),
                com_data: _data.comparables.map(el => el.id),
                tenures: _data.tenures.map(el => ({comId: el.comId, tenure: el.selectedTenure})), 
                asset_class_id: _data.asset_class.id,
                valuation_id: _data.valuation.id,
                adjustment_tab_data: _payload.adjustmentTabData,
                assumption_tab_data: _payload.assumptionTabData,
                notes: _payload.notes,
                validation: _payload.validation,
                toeID: _payload.toeID,
                userId: _payload.userId,
            };
            return this.service.save(payload);
        }),
        map(res => {
            return new StoredValuationData({message: 'done'});
        })
    );

    @Effect()
    updateValuationData$ = this.actions$.pipe(
        ofType<UpdateValuationData>(ValuationDataActionTypes.UpdateValuationData),

        map(({payload}) => {
            const _changedItem: Update<ValuationDataModel> = {
                id: 1,
                changes: {
                    ...payload.item
                }
            };
            return new ValuationDataUpdated({
                item: _changedItem
            })
        })
    )
}