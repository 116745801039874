import { ConvertedCostRevenue } from "../../types";
import { EffectiveRentUtil } from "../headline-rent";
import { 
  totalNonRecoverable as calculateTNR, 
  totalRecoverable as calculateTR
} from "../../utils"
import { basisMultiplier, miniFs, rounddown4, yieldPurchaseOnPerpetuityAnnually, yieldPurchaseOnPerpetuityQuarterly } from "./utils";
import { ModelAssumptionsUtil } from "./model-assumptions-util";
import * as moment from "moment";

export function calculateFreeholdWithTerminableLifeBuildingProvisionForRebuilding(
  costRevenue: ConvertedCostRevenue,
  valuationDate: Date,
  {
    timingId,
    allRiskYield,
    costOfRebuilding,
    estimatedBuildingTerminableLife,
    purchaserCost
  }: {
    timingId: number,
    allRiskYield: number,
    costOfRebuilding: number,
    estimatedBuildingTerminableLife: number,
    purchaserCost: number
  }
) {
  const _valuationDate = valuationDate ? moment(valuationDate) : null
  const _basisMultiplier = basisMultiplier(costRevenue.rentBasis)
  const unexpiredLeaseTerm = _valuationDate ? ModelAssumptionsUtil.unexpiredLeaseTerm(costRevenue, _valuationDate) : null
  const effectiveRent = EffectiveRentUtil.getValueFromConvertedCostRevenue(costRevenue)
  const totalRecoverable = calculateTR(costRevenue) * _basisMultiplier
  const totalNonRecoverable = calculateTNR(costRevenue) * _basisMultiplier
  const passingRent = effectiveRent + totalRecoverable - totalNonRecoverable

  const yieldPurchaseOnPerpetuity = timingId == 1
    ? rounddown4(yieldPurchaseOnPerpetuityAnnually(allRiskYield))
    : timingId == 2
      ? rounddown4(yieldPurchaseOnPerpetuityQuarterly(allRiskYield))
      : undefined
  const capitalisedRent = yieldPurchaseOnPerpetuity * passingRent

  const minYear = miniFs(
    {"Estimated Building terminable life": estimatedBuildingTerminableLife, "Unexpired Lease Term": unexpiredLeaseTerm}
  )
  const _presentValue = presentValue(allRiskYield, minYear) 

  const capitalisedPresentValueOfRebuildingCost = _presentValue.value * costOfRebuilding
  const totalCapitalisedRent = capitalisedRent - capitalisedPresentValueOfRebuildingCost
  const lessPurchaserCost = (purchaserCost / 100) * (capitalisedRent - capitalisedPresentValueOfRebuildingCost)
  const value = totalCapitalisedRent - lessPurchaserCost

  return {
    effectiveRent,
    basisMultiplier: _basisMultiplier,
    recoverable: {
      total: totalRecoverable,
      recoverablePropertyManagement: costRevenue.recoverablePropertyManagement * _basisMultiplier,
      recoverableLeasingExpenses: costRevenue.recoverableLeasingExpenses * _basisMultiplier, 
      recoverableUtilities: costRevenue.recoverableUtilities * _basisMultiplier,
      recoverableMaintenance: costRevenue.recoverableMaintenance * _basisMultiplier,
      recoverableInsurance: costRevenue.recoverableInsurance * _basisMultiplier,
      recoverableJanitorial: costRevenue.recoverableJanitorial * _basisMultiplier,
      recoverablePropertyTaxes: costRevenue.recoverablePropertyTaxes * _basisMultiplier,
      recoverableBusiness: costRevenue.recoverableBusiness * _basisMultiplier,
      recoverableOthers: costRevenue.recoverableOthers * _basisMultiplier,
    },
    nonRecoverable: {
      total: totalNonRecoverable,
      nonRecoverablePropertyManagement: costRevenue.nonRecoverablePropertyManagement * _basisMultiplier,
      nonRecoverableLeasingExpenses: costRevenue.nonRecoverableLeasingExpenses * _basisMultiplier, 
      nonRecoverableUtilities: costRevenue.nonRecoverableUtilities * _basisMultiplier,
      nonRecoverableMaintenance: costRevenue.nonRecoverableMaintenance * _basisMultiplier,
      nonRecoverableInsurance: costRevenue.nonRecoverableInsurance * _basisMultiplier,
      nonRecoverableJanitorial: costRevenue.nonRecoverableJanitorial * _basisMultiplier,
      nonRecoverablePropertyTaxes: costRevenue.nonRecoverablePropertyTaxes * _basisMultiplier,
      nonRecoverableBusiness: costRevenue.nonRecoverableBusiness * _basisMultiplier,
      nonRecoverableOthers: costRevenue.nonRecoverableOthers * _basisMultiplier,
    },
    passingRent: passingRent,
    yieldPurchaseOnPerpetuity,
    capitalisedRent,

    presentValue: _presentValue,
    capitalisedPresentValueOfRebuildingCost,

    totalCapitalisedRent,
    lessPurchaserCost: isNaN(lessPurchaserCost) ? undefined : lessPurchaserCost,
    value
  }
}

function presentValue(
  allRiskYield: number,
  minYear: {key: string ,value: number},
) {
  const ary = allRiskYield / 100
  const year = minYear
  if (allRiskYield == 0) return {value: undefined, year}

  const value = Math.pow(1 + ary, -year.value)
  return {
    value: rounddown4(value),
    year
  }
}