import { Injectable } from '@angular/core';
import { ReportFont, ReportScheme, ReportType } from 'src/app/core/admin/_models/report-scheme.model';
import * as _ from 'lodash';

@Injectable()
export class ReportSchemeService {

  private readonly _fonts: ReportFont[] = [
    {id: 1, name: 'Arial'},
    {id: 2, name: 'Helvetica'},
    {id: 3, name: 'Verdana'},
    {id: 4, name: 'Calibri'},
    {id: 5, name: 'Times New Roman'},
    {id: 6, name: 'Garamond'},
    {id: 7, name: 'Bookman Old Style'},
    {id: 8, name: 'Courier'}
  ]

  private readonly _defaultValue: ReportScheme[] = [
    {type: ReportType.InformedConsent, name: 'Informed Consent', primaryColor: '#3a81c3', footerColor: null, fontId: 1},
    {type: ReportType.ToE, name: 'Terms of Engagement', primaryColor: '#21517f', footerColor: null, fontId: 1},
    {type: ReportType.Amendment, name: 'Amendment', primaryColor: '#7888ae', footerColor: null, fontId: 1},
    {type: ReportType.Investigation, name: 'Investigation', primaryColor: '#337f3e', footerColor: null, fontId: 1},
    {type: ReportType.DraftStatement, name: 'Draft Statement', primaryColor: '#98792d', footerColor: null, fontId: 1},
    {type: ReportType.Valuation, name: 'Valuation', primaryColor: '#982520', footerColor: null, fontId: 1},
    {type: ReportType.AuditTrail, name: 'Audit Trail', primaryColor: '#4d2565', footerColor: null, fontId: 1},
    {type: ReportType.Invoices, name: 'Invoices', primaryColor: '#0d5a54', footerColor: '#bbd1cf', fontId: 1},
    {type: ReportType.Quotes, name: 'Quote', primaryColor: '#141d59', footerColor: null, fontId: 1}
  ]

  constructor() { }

  reset(type: ReportType): ReportScheme {
    const scheme = this._defaultValue.find(item => item.type == type);
    if (!scheme) {
      return {type: ReportType.Default, name: 'Default', primaryColor: '#000000', footerColor: null, fontId: 1};
    }
    return _.cloneDeep(scheme);
  }

  resetAll(): ReportScheme[] {
    return _.cloneDeep(this._defaultValue);
  }

  getFonts(): ReportFont[] {
    return _.cloneDeep(this._fonts);
  }
}
