import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, EMPTY} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {CityModel} from '../_models/city.model';
import {HttpUtilsService} from '../../_base/crud';
import {catchError} from 'rxjs/operators';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class CityService {
    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/cities`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    // City
    getAll(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_URL,
            {headers: httpHeaders});
    }

    getAllMultimedia(): Observable<any> {
        return this.http.get(
            `${environment.baseApiUrl}api/${this.subDomainService.subDomain}/multimedia/cities`
        )
    }

    // DELETE => delete the Country from the server
    delete(cityId: number): Observable<any> {
        const url = `${this.API_URL}/admin/trash/${cityId}`;
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(url,
            {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new City to the server
    create(city: CityModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_URL, city,
            {headers: httpHeaders});
    }

    // UPDATE => PUT: update the City on the server
    update(city: CityModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_URL + `/${city.id}`, city,
            {headers: httpHeaders});
    }

    flush(countryId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/trash/flush/' + countryId,
            {headers: httpHeaders}).pipe(catchError(err => {
                return EMPTY;
            }));
    }


    private handleError<T>(operation = 'operation', err?: any) {
        if (err.status == 500) {

            return Observable.throw(new Error(''));

        } else if (err.status == 400) {

            return Observable.throw(new Error(''));
        } else if (err.status < 500 && err.status >= 400) {

            return Observable.throw(new Error(''))
            ;
        }

        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(err);
        };
    }
}
