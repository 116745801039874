import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpUtilsService} from '../../_base/crud';
import {HttpClient} from '@angular/common/http';
import { CriterionModel } from '../_models/criterion.model';
import { ValuationAdjustmentModel } from '../_models/valuation-adjustment.model';
import { AdjustmentValuation } from '../_models/valuation.model';
import { AssumptionTabData } from '../_models/tab_data.model';
import { ValuationNoteModel } from '../_models/valuation-note.model';
import { Observable } from 'rxjs';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';



@Injectable()
export class ValuationDataService {


    API_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/valuation-data`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    fetch(payload: {
        asset_class_id: number,
        valuation_id: number,
    }): any {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + '/' + payload.asset_class_id + '/' + payload.valuation_id, {
            headers: httpHeaders,
        });
    }

    fetchMultimedia(payload: {
        asset_class_id: number,
        valuation_id: number,
    }): any {
        return this.http.get<any>(
            `${environment.baseApiUrl}api/${this.subDomainService.subDomain}/multimedia/valuation-data` + '/' + payload.asset_class_id + '/' + payload.valuation_id
        );
    }

    save(payload: {
        key_place_data: number[],
        com_data: number[],
        tenures: {comId: number, tenure: number}[]
        asset_class_id: number,
        valuation_id: number,
        adjustment_tab_data: {
            criterions: CriterionModel[],
            sizeCriterions: CriterionModel[],
            defaultSize: CriterionModel,
            adjustments: ValuationAdjustmentModel[],
            valuations: AdjustmentValuation[],
            justification: string,
        },
        assumption_tab_data: AssumptionTabData,
        notes: ValuationNoteModel[],
        validation: boolean,
        toeID: number,
        userId: number
    }): any {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post<any>(this.API_URL + '/' + payload.asset_class_id + '/' + payload.valuation_id, payload, {
            headers: httpHeaders,
        });
    }

    fetchSummary(tp_id: number, val_id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_URL + `/${tp_id}/${val_id}/subdata`, {
            headers: httpHeaders
        });
    }

    fetchSummaryMultimedia(tp_id: number, val_id: number): Observable<any> {
        return this.http.get<any>(
            `${environment.baseApiUrl}api/${this.subDomainService.subDomain}/multimedia/valuation-data/${tp_id}/${val_id}/subdata`
        );
    }

    //
    // updateToe(body: {
    //     toe: ToeModel,
    //     rdms: number [],
    //     tp_list: AssetClassModel [],
    //     coi_properties: CoiDetailsTPModel [],
    //     payment: PaymentModel,
    //     paymentTaxes: PaymentTaxModel[],
    //     valuations: ValuationModel[],
    //     paymentTerms: PaymentTermModel[],
    //     standards: any[],
    //     fileList: any[],
    // }): Observable<any> {
    //     const httpHeaders = this.authService.getAuthHeaders();
    //     return this.http.put(API_TOE_URL + '/' + body.toe.id, body, {headers: httpHeaders});
    // }
}