// NGRX
import {Action} from '@ngrx/store';
// Models
import {KeyPlaceModel, KeyplacesStatus} from '../_models/key-place.model';
import {Update} from '@ngrx/entity';
import {QueryParamsModel} from '../../_base/crud';

export enum KeyPlaceActionTypes {
    AllKeyPlacesRequested = '[Init] All KeyPlaces Requested',
    AllKeyPlacesLoaded = '[Init] All KeyPlaces Loaded',

    KeyPlacesPageRequested = '[KeyPlaces List Page] KeyPlaces Page Requested',
    KeyPlacesPageRequestedMultimedia = '[KeyPlaces List Page] KeyPlaces Page Requested Multimedia',
    KeyPlacesPageLoaded = '[KeyPlaces API] KeyPlaces Page Loaded',

    KeyPlaceOnServerCreated = '[Edit KeyPlace Dialog] KeyPlace On Server Created',
    KeyPlaceCreated = '[Edit KeyPlace Dialog] KeyPlace Created',
    KeyPlaceUpdated = '[Edit KeyPlace Dialog] KeyPlace Updated',

    KeyPlaceOnServerRestored = '[KeyPlace Trash] KeyPlace On Server Restored',
    KeyPlaceRestored = '[KeyPlace Trash] Agency Restored',

    KeyPlaceOnServerAdminRestored = '[KeyPlace Admin Trash] KeyPlace On Server Restored',
    KeyPlaceAdminRestored = '[KeyPlace Admin Trash] Agency Restored',

    KeyPlaceDeletedFromTrash = '[KeyPlace Trash] KeyPlace deleted',
    KeyPlaceDeletedFromAdminTrash = '[KeyPlace Admin Trash] KeyPlace deleted',

    KeyPlaceTrashFlushed = 'KeyPlace Trash Flushed',

    KeyPlaceActionToggleLoading = '[KeyPlace] KeyPlaces Action Toggle Loading',

    KeyPlaceDeleted = '[KeyPlaces List Page] KeyPlace Deleted',

    UpdatePreviouslyCreatedKeyPlaceId = '[KeyPlaces Edit] Update previously created key place id',

    KeyPlacesStatusRequested = '[KeyPlaces List Page] KeyPlace Module Status Requested',
    KeyPlacesStatusLoaded = '[KeyPlaces Effect] KeyPlace Module Status Loaded'
}

export class AllKeyPlacesRequested implements Action {
    readonly type = KeyPlaceActionTypes.AllKeyPlacesRequested;
}

export class AllKeyPlacesLoaded implements Action {
    readonly type = KeyPlaceActionTypes.AllKeyPlacesLoaded;

    constructor(public payload: { keyPlaces: KeyPlaceModel[] }) {
    }
}

export class KeyPlacesPageRequested implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlacesPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class KeyPlacesPageRequestedMultimedia implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlacesPageRequestedMultimedia;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class KeyPlacesPageLoaded implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlacesPageLoaded;

    constructor(public payload: {
        keyPlaces: KeyPlaceModel[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel
    }) {
    }
}


export class KeyPlaceOnServerCreated implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceOnServerCreated;

    constructor(public payload: { keyPlace: KeyPlaceModel }) {
    }
}

export class KeyPlaceCreated implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceCreated;

    constructor(public payload: { keyPlace: KeyPlaceModel }) {
    }
}

export class KeyPlaceUpdated implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceUpdated;

    constructor(public payload: {
        partialKeyPlace: Update<KeyPlaceModel>,
        keyPlace: KeyPlaceModel
    }) {
    }
}

export class KeyPlaceDeleted implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class KeyPlaceOnServerAdminRestored implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class KeyPlaceAdminRestored implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceAdminRestored;

    constructor(public payload: { keyPlace: KeyPlaceModel }) {
    }
}

export class KeyPlaceDeletedFromTrash implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}


export class KeyPlaceTrashFlushed implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceTrashFlushed;

    constructor() {
    }
}

export class KeyPlaceDeletedFromAdminTrash implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export class KeyPlaceOnServerRestored implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class KeyPlaceRestored implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceRestored;

    constructor(public payload: { keyPlace: KeyPlaceModel }) {
    }
}


export class KeyPlaceActionToggleLoading implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlaceActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class UpdatePreviouslyCreatedKeyPlaceId implements Action {
    readonly type = KeyPlaceActionTypes.UpdatePreviouslyCreatedKeyPlaceId;
    constructor() {}
}

export class KeyPlacesStatusRequested implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlacesStatusRequested;
}

export class KeyPlacesStatusLoaded implements Action {
    readonly type = KeyPlaceActionTypes.KeyPlacesStatusLoaded;
    constructor(public payload: {status: KeyplacesStatus}) {}
}

export type KeyPlaceActions = AllKeyPlacesRequested |
    AllKeyPlacesLoaded |

    KeyPlacesPageRequested |
    KeyPlacesPageLoaded |
    KeyPlaceActionToggleLoading |

    KeyPlaceOnServerAdminRestored |
    KeyPlaceAdminRestored |

    KeyPlaceOnServerRestored |
    KeyPlaceRestored |

    KeyPlaceDeletedFromTrash |
    KeyPlaceDeletedFromAdminTrash |

    KeyPlaceTrashFlushed |

    KeyPlaceOnServerCreated |
    KeyPlaceCreated |

    KeyPlaceUpdated |
    KeyPlaceDeleted |
    
    UpdatePreviouslyCreatedKeyPlaceId |

    KeyPlacesStatusRequested |
    KeyPlacesStatusLoaded;
