import { ToeTemplateModel } from '../../template';
import {BaseModel} from '../../_base/crud';
import { PostValuationDeliveryServicetData, newPostValuationDeliveryServicetData } from '../../admin/_models/post-valuation-delivery-service.model';

export class ToeModel extends BaseModel {
    id: number;
    from_quote: number;

    user_id: number;
    timezone: string | null

    name: string;
    status: number;
    status_of_valuer: number;
    percent: number;
    is_complete: number;
    assignment_id: number;
    assignment: any;

    client_id: number;
    client_name: string;
    contact_id: number;
    client_acting_as: string;
    valuer_id: number;
    valuer_previous_involvment: string;
    purpose_of_valuation_id: number;
    purpose_of_valuation_name: string;
    base_of_value_id: number;
    base_of_value_name: string;

    purpose_of_valuation_reason: string;
    restrictions_on_use: number;
    restrictions_on_use_intended_users: string;
    restrictions_on_use_descr: string;
    responsibilities_liabilities: number;
    responsibilities_liabilities_descr: string;
    limitations_on_liability: number;
    limitations_on_liability_descr: string;
    generic_one: number;
    generic_one_descr: string;

    ivs_standard_id: number;
    rics_standard_id: number;
    unit_of_measurement_id: number;
    law_country_id: number;

    selected_agency_documents: any[];
    selected_tp_multimedias: any[];

    has_prev_knowledge: number;
    has_financial_interest: number; 

    previous_work: number;
    previous_work_description: string;

    personal_work: number;
    personal_work_description: string;

    employees_knows_each_other: number;
    employees_knows_each_other_description: string;

    interest_valuation: number;
    interest_valuation_description: string;

    mad_acting: number;
    mad_acting_description: string;

    asset_previously_valuated: number;
    asset_previously_valuated_description: string;

    mad_third_party: number;
    mad_third_party_description: string;

    employees_knowledge: number;
    employees_knowledge_description: string;

    conflicted_projects: number;
    conflicted_projects_description: string;
    conflicted_projects_b: number;

    common_staff: number;
    common_staff_description: string;

    fee_sharing: number;
    fee_sharing_description: string;

    valuation_significant_revenue: number;
    valuation_significant_revenue_description: string;

    verbal_value: number;
    verbal_value_description: string;

    valuing_for_lender: number;
    valuing_for_lender_description: string;

    long_standing_professional: number;
    long_standing_professional_description: string;

    introducing_transaction: number;
    introducing_transaction_description: string;

    financial_interest: number;
    financial_interest_description: string;
    financial_interest_b: number;

    acting_for: number;
    acting_for_description: string;

    acting_for_borrower: number;
    acting_for_borrower_description: string;

    retained_to_act: number;
    retained_to_act_description: string;

    recently_acted: number;
    recently_acted_description: string;

    professional_advice: number;
    professional_advice_description: string;

    providing_development_consultancy: number;
    providing_development_consultancy_description: string;

    client_informed_complain: number;
    client_informed_complain_description: string;

    valuer_identify_conflict: string;
    valuer_identify_conflict_description: string;


    // Disclosure
    valuer_doubts_ability: number;
    valuer_doubts_ability_description: string;

    report_delivery_methods: number;
    report_delivery_methods_description: string;

    source_external_references: number;
    source_external_references_description: string;

    not_having_the_level: number;
    not_having_the_level_description: string;

    valuer_employ_another_firm: number;
    valuer_employ_another_firm_description: string;

    valuation_relied_on_other_parties: number;
    valuation_relied_on_other_parties_description: string;

    asset_previously_valued: number;
    asset_previously_valued_description: string;

    valuer_involved_in_purchase: number;
    valuer_involved_in_purchase_description: string;

    valuer_provided_valuations: number;
    valuer_provided_valuations_description: string;

    valuer_signatory: number;
    valuer_signatory_description: string;

    valuer_involved_professional_services: string;
    valuer_involved_professional_services_description: string;
    asset_previously_valued_by_the_member: string;
    disclose_extent_duration: string;
    valuer_involved_12_month_before: number;
    valuer_involved_12_month_before_description: string;

    purpose_of_valuation: number;
    purpose_of_valuation_description: string;

    support_clarifications: string;
    instruction_date: string;
    draft_delivery_date: string;
    final_delivery_date: string;
    payment_terms: number;

    valuer_involved_in_purchase_files: any[];
    additional_files: any[];
    additional_documents: string;
    payment: any;
    payment_taxes: any[];
    payment_terms_ac: any[];
    signed_date: string;
    signedReportUrl: string;
    leadValuer: any;
    lead_valuer_name: string;

    tp_cnt: number;
    val_cnt: number;
    audit_cnt: number;

    previous_work_coi: number;
    personal_work_coi: number;
    employees_knows_each_other_coi: number;
    mad_third_party_coi: number;
    conflicted_projects_coi: number;
    common_staff_coi: number;
    fee_sharing_coi: number;
    valuation_significant_revenue_coi: number;
    valuing_for_lender_coi: number;
    financial_interest_coi: number;
    acting_for_coi: number;
    providing_development_consultancy_coi: number;
    valuer_identify_conflict_coi: number;

    previous_work_type_of_conflict_id: number;
    personal_work_type_of_conflict_id: number;
    employees_knows_each_other_type_of_conflict_id: number;
    mad_third_party_type_of_conflict_id: number;
    conflicted_projects_type_of_conflict_id: number;
    common_staff_type_of_conflict_id: number;
    fee_sharing_type_of_conflict_id: number;
    valuation_significant_revenue_type_of_conflict_id: number;
    valuing_for_lender_type_of_conflict_id: number;
    financial_interest_type_of_conflict_id: number;
    acting_for_type_of_conflict_id: number;
    providing_development_consultancy_type_of_conflict_id: number;
    valuer_identify_conflict_type_of_conflict_id: number;
    valuer_doubts_ability_type_of_conflict_id: number;

    previous_work_appropriate_parties: string;
    personal_work_appropriate_parties: string;
    employees_knows_each_other_appropriate_parties: string;
    mad_third_party_appropriate_parties: string;
    conflicted_projects_appropriate_parties: string;
    common_staff_appropriate_parties: string;
    fee_sharing_appropriate_parties: string;
    valuation_significant_revenue_appropriate_parties: string;
    valuing_for_lender_appropriate_parties: string;
    financial_interest_appropriate_parties: string;
    acting_for_appropriate_parties: string;
    providing_development_consultancy_appropriate_parties: string;
    valuer_identify_conflict_appropriate_parties: string;
    valuer_doubts_ability_appropriate_parties: string;

    previous_work_reason_continue: string;
    personal_work_reason_continue: string;
    employees_knows_each_other_reason_continue: string;
    mad_third_party_reason_continue: string;
    conflicted_projects_reason_continue: string;
    common_staff_reason_continue: string;
    fee_sharing_reason_continue: string;
    valuation_significant_revenue_reason_continue: string;
    valuing_for_lender_reason_continue: string;
    financial_interest_reason_continue: string;
    acting_for_reason_continue: string;
    providing_development_consultancy_reason_continue: string;
    valuer_identify_conflict_reason_continue: string;
    valuer_doubts_ability_reason_continue: string;

    previous_work_description_b: string;
    personal_work_description_b: string;
    employees_knows_each_other_description_b: string;
    mad_third_party_description_b: string;
    conflicted_projects_description_b: string;
    common_staff_description_b: string;
    fee_sharing_description_b: string;
    valuation_significant_revenue_description_b: string;
    valuing_for_lender_description_b: string;
    financial_interest_description_b: string;
    acting_for_description_b: string;
    providing_development_consultancy_description_b: string;
    valuer_identify_conflict_description_b: string;
    valuer_doubts_ability_description_b: string;

    properties_coi: any[];
    standards: any[];

    has_multimedia_support: boolean;

    //hourly-rates
    postValuationDeliveryServicetData: PostValuationDeliveryServicetData;

    esg_reporting_energy_rating: boolean = false;
    esg_reporting_renewable_energy_production: boolean = false;
    esg_reporting_labels_and_certificates: boolean = false;
    esg_reporting_exposed_risks: boolean = false;
    esg_reporting_other: boolean = false;
    esg_reporting_other_description: string;

    core_standard_id: number;

    createdBy: string;
    lastUpdatedBy: string;

    created_at: Date;
    deleted_at: Date;
    user_deleted: Date;
    updated_at: Date;

    clear() {
        this.id = undefined;
        this.from_quote = null;

        this.user_id = undefined;
        this.timezone = null

        this.name = '';
        this.status = 0;
        this.status_of_valuer = 0;
        this.is_complete = 0;

        this.assignment_id = undefined;
        this.client_id = undefined;
        this.contact_id = undefined;
        this.valuer_id = undefined;

        this.ivs_standard_id = undefined;
        this.rics_standard_id = undefined;
        this.unit_of_measurement_id = undefined;
        this.law_country_id = undefined;

        this.selected_agency_documents = [];
        this.selected_tp_multimedias = [];

        this.client_acting_as = '';
        this.valuer_previous_involvment = '';
        this.support_clarifications = '';
        this.purpose_of_valuation_id = undefined;
        this.purpose_of_valuation_name = '';
        this.base_of_value_id = undefined;
        this.base_of_value_name = '';

        this.purpose_of_valuation_reason = '';
        this.restrictions_on_use = 0;
        this.restrictions_on_use_descr = '';
        this.responsibilities_liabilities = 0;
        this.responsibilities_liabilities_descr = '';
        this.limitations_on_liability = 0;
        this.limitations_on_liability_descr = '';
        this.generic_one = 0;
        this.generic_one_descr = '';

        this.instruction_date = '';
        this.draft_delivery_date = '';
        this.final_delivery_date = '';
        this.payment_terms = 1;

        this.previous_work = 0;
        this.previous_work_description = '';
        this.personal_work = 0;
        this.personal_work_description = '';
        this.employees_knows_each_other = 0;
        this.employees_knows_each_other_description = '';
        this.interest_valuation = 0;
        this.interest_valuation_description = '';
        this.mad_acting = 0;
        this.mad_acting_description = '';
        this.asset_previously_valuated = 0;
        this.asset_previously_valuated_description = '';
        this.mad_third_party = 0;
        this.mad_third_party_description = '';
        this.employees_knowledge = 0;
        this.employees_knowledge_description = '';
        this.conflicted_projects = 0;
        this.conflicted_projects_description = '';
        this.conflicted_projects_b = 0;
        this.common_staff = 0;
        this.common_staff_description = '';
        this.fee_sharing = 0;
        this.fee_sharing_description = '';
        this.valuation_significant_revenue = 0;
        this.valuation_significant_revenue_description = '';
        this.verbal_value = 0;
        this.verbal_value_description = '';
        this.valuing_for_lender = 0;
        this.valuing_for_lender_description = '';
        this.long_standing_professional = 0;
        this.long_standing_professional_description = '';
        this.introducing_transaction = 0;
        this.introducing_transaction_description = '';
        this.financial_interest = 0;
        this.financial_interest_description = '';
        this.financial_interest_b = 0;
        this.acting_for = 0;
        this.acting_for_description = '';
        this.acting_for_borrower = 0;
        this.acting_for_borrower_description = '';
        this.retained_to_act = 0;
        this.retained_to_act_description = '';
        this.recently_acted = 0;
        this.recently_acted_description = '';
        this.professional_advice = 0;
        this.professional_advice_description = '';
        this.providing_development_consultancy = 0;
        this.providing_development_consultancy_description = '';
        this.client_informed_complain = 1;

        this.valuer_identify_conflict = '';
        this.valuer_identify_conflict_description = '';
        this.valuer_doubts_ability = 0;
        this.valuer_doubts_ability_description = '';
        this.report_delivery_methods = 0;
        this.source_external_references = 0;
        this.not_having_the_level = 0;
        this.not_having_the_level_description = '';
        this.valuer_employ_another_firm = 0;
        this.valuer_employ_another_firm_description = '';
        this.valuation_relied_on_other_parties = 0;
        this.valuation_relied_on_other_parties_description = '';
        this.asset_previously_valued = 0;
        this.asset_previously_valued_description = '';
        this.valuer_involved_in_purchase = 0;
        this.valuer_involved_in_purchase_description = '';
        this.valuer_provided_valuations = 0;
        this.valuer_provided_valuations_description = '';
        this.valuer_signatory = 0;
        this.valuer_signatory_description = '';

        this.valuer_involved_professional_services = '';
        this.valuer_involved_professional_services_description = '';

        this.asset_previously_valued_by_the_member = '';
        this.disclose_extent_duration = '';
        this.valuer_involved_12_month_before = 0;
        this.valuer_involved_12_month_before_description = '';

        this.purpose_of_valuation = 0;
        this.purpose_of_valuation_description = '';

        this.valuer_involved_in_purchase_files = [];
        this.additional_files = [];
        this.additional_documents = '';
        this.payment = {};
        this.payment_taxes = [];
        this.payment_terms_ac = [];

        this.tp_cnt = 0;
        this.val_cnt = 0;
        this.audit_cnt = 0;

        this.previous_work_coi = 0;
        this.personal_work_coi = 0;
        this.employees_knows_each_other_coi = 0;
        this.mad_third_party_coi = 0;
        this.conflicted_projects_coi = 0;
        this.common_staff_coi = 0;
        this.fee_sharing_coi = 0;
        this.valuation_significant_revenue_coi = 0;
        this.valuing_for_lender_coi = 0;
        this.financial_interest_coi = 0;
        this.acting_for_coi = 0;
        this.providing_development_consultancy_coi = 0;
        this.valuer_identify_conflict_coi = 0;

        this.previous_work_type_of_conflict_id = 0;
        this.personal_work_type_of_conflict_id = 0;
        this.employees_knows_each_other_type_of_conflict_id = 0;
        this.mad_third_party_type_of_conflict_id = 0;
        this.conflicted_projects_type_of_conflict_id = 0;
        this.common_staff_type_of_conflict_id = 0;
        this.fee_sharing_type_of_conflict_id = 0;
        this.valuation_significant_revenue_type_of_conflict_id = 0;
        this.valuing_for_lender_type_of_conflict_id = 0;
        this.financial_interest_type_of_conflict_id = 0;
        this.acting_for_type_of_conflict_id = 0;
        this.providing_development_consultancy_type_of_conflict_id = 0;
        this.valuer_identify_conflict_type_of_conflict_id = 0;
        this.valuer_doubts_ability_type_of_conflict_id = 0;

        this.previous_work_appropriate_parties = '';
        this.personal_work_appropriate_parties = '';
        this.employees_knows_each_other_appropriate_parties = '';
        this.mad_third_party_appropriate_parties = '';
        this.conflicted_projects_appropriate_parties = '';
        this.common_staff_appropriate_parties = '';
        this.fee_sharing_appropriate_parties = '';
        this.valuation_significant_revenue_appropriate_parties = '';
        this.valuing_for_lender_appropriate_parties = '';
        this.financial_interest_appropriate_parties = '';
        this.acting_for_appropriate_parties = '';
        this.providing_development_consultancy_appropriate_parties = '';
        this.valuer_identify_conflict_appropriate_parties = '';
        this.valuer_doubts_ability_appropriate_parties = '';

        this.previous_work_reason_continue = '';
        this.personal_work_reason_continue = '';
        this.employees_knows_each_other_reason_continue = '';
        this.mad_third_party_reason_continue = '';
        this.conflicted_projects_reason_continue = '';
        this.common_staff_reason_continue = '';
        this.fee_sharing_reason_continue = '';
        this.valuation_significant_revenue_reason_continue = '';
        this.valuing_for_lender_reason_continue = '';
        this.financial_interest_reason_continue = '';
        this.acting_for_reason_continue = '';
        this.providing_development_consultancy_reason_continue = '';
        this.valuer_identify_conflict_reason_continue = '';
        this.valuer_doubts_ability_reason_continue = '';

        this.signedReportUrl = '';
        this.leadValuer = null;

        this.properties_coi = [];

        this.previous_work_description_b = '';
        this.personal_work_description_b = '';
        this.employees_knows_each_other_description_b = '';
        this.mad_third_party_description_b = '';
        this.conflicted_projects_description_b = '';
        this.common_staff_description_b = '';
        this.fee_sharing_description_b = '';
        this.valuation_significant_revenue_description_b = '';
        this.valuing_for_lender_description_b = '';
        this.financial_interest_description_b = '';
        this.acting_for_description_b = '';
        this.providing_development_consultancy_description_b = '';
        this.valuer_identify_conflict_description_b = '';

        this.has_multimedia_support = true;

        //hourly-rates
        this.postValuationDeliveryServicetData = newPostValuationDeliveryServicetData();

        this.core_standard_id = null;

        this.created_at = new Date();
        this.updated_at = new Date();
        this.deleted_at = new Date();
        this.user_deleted = new Date();

        this.has_prev_knowledge = 0
        this.has_financial_interest = 0;
    }

    copyCOIData(t: ToeTemplateModel) {
        this.previous_work = t.previous_work;
        this.previous_work_description = t.previous_work_description;
        this.previous_work_coi = t.previous_work_coi;
        this.previous_work_type_of_conflict_id = t.previous_work_type_of_conflict_id;
        this.previous_work_appropriate_parties = t.previous_work_appropriate_parties;
        this.previous_work_reason_continue = t.previous_work_reason_continue;
        this.previous_work_description_b = t.previous_work_description_b;

        this.personal_work = t.personal_work;
        this.personal_work_description = t.personal_work_description;
        this.personal_work_coi = t.personal_work_coi ;
        this.personal_work_type_of_conflict_id = t.personal_work_type_of_conflict_id;
        this.personal_work_appropriate_parties = t.personal_work_appropriate_parties;
        this.personal_work_reason_continue = t.personal_work_reason_continue;
        this.personal_work_description_b = t.personal_work_description_b;

        this.employees_knows_each_other = t.employees_knows_each_other;
        this.employees_knows_each_other_description = t.employees_knows_each_other_description;
        this.employees_knows_each_other_coi = t.employees_knows_each_other_coi;
        this.employees_knows_each_other_type_of_conflict_id = t.employees_knows_each_other_type_of_conflict_id;
        this.employees_knows_each_other_appropriate_parties = t.employees_knows_each_other_appropriate_parties;
        this.employees_knows_each_other_reason_continue = t.employees_knows_each_other_reason_continue;
        this.employees_knows_each_other_description_b = t.employees_knows_each_other_description_b;

        this.interest_valuation = t.interest_valuation;
        this.interest_valuation_description = t.interest_valuation_description;

        this.mad_acting = t.mad_acting;
        this.mad_acting_description = t.mad_acting_description;

        this.asset_previously_valuated = t.asset_previously_valuated;
        this.asset_previously_valuated_description = t.asset_previously_valuated_description;

        this.mad_third_party = t.mad_third_party;
        this.mad_third_party_description = t.mad_third_party_description;
        this.mad_third_party_coi = t.mad_third_party_coi;
        this.mad_third_party_type_of_conflict_id = t.mad_third_party_type_of_conflict_id;
        this.mad_third_party_appropriate_parties = t.mad_third_party_appropriate_parties;
        this.mad_third_party_reason_continue = t.mad_third_party_reason_continue;
        this.mad_third_party_description_b = t.mad_third_party_description_b;

        this.employees_knowledge = t.employees_knowledge;
        this.employees_knowledge_description = t.employees_knowledge_description;

        this.conflicted_projects = t.conflicted_projects;
        this.conflicted_projects_description = t.conflicted_projects_description;
        this.conflicted_projects_b = t.conflicted_projects_b;
        this.conflicted_projects_coi = t.conflicted_projects_coi;
        this.conflicted_projects_type_of_conflict_id = t.conflicted_projects_type_of_conflict_id;
        this.conflicted_projects_appropriate_parties = t.conflicted_projects_appropriate_parties;
        this.conflicted_projects_reason_continue = t.conflicted_projects_reason_continue;
        this.conflicted_projects_description_b = t.conflicted_projects_description_b;

        this.common_staff = t.common_staff;
        this.common_staff_description = t.common_staff_description;
        this.common_staff_coi = t.common_staff_coi;
        this.common_staff_type_of_conflict_id = t.common_staff_type_of_conflict_id;
        this.common_staff_appropriate_parties = t.common_staff_appropriate_parties;
        this.common_staff_reason_continue = t.common_staff_reason_continue;
        this.common_staff_description_b = t.common_staff_description_b;

        this.fee_sharing = t.fee_sharing;
        this.fee_sharing_description = t.fee_sharing_description;
        this.fee_sharing_coi = t.fee_sharing_coi;
        this.fee_sharing_type_of_conflict_id = t.fee_sharing_type_of_conflict_id;
        this.fee_sharing_appropriate_parties = t.fee_sharing_appropriate_parties;
        this.fee_sharing_reason_continue = t.fee_sharing_reason_continue;
        this.fee_sharing_description_b = t.fee_sharing_description_b;

        this.valuation_significant_revenue = t.valuation_significant_revenue;
        this.valuation_significant_revenue_description = t.valuation_significant_revenue_description;
        this.valuation_significant_revenue_coi = t.valuation_significant_revenue_coi;
        this.valuation_significant_revenue_type_of_conflict_id = t.valuation_significant_revenue_type_of_conflict_id;
        this.valuation_significant_revenue_appropriate_parties = t.valuation_significant_revenue_appropriate_parties;
        this.valuation_significant_revenue_reason_continue = t.valuation_significant_revenue_reason_continue;
        this.valuation_significant_revenue_description_b = t.valuation_significant_revenue_description_b;

        this.verbal_value = t.verbal_value;
        this.verbal_value_description = t.verbal_value_description;

        this.valuing_for_lender = t.valuing_for_lender;
        this.valuing_for_lender_description = t.valuing_for_lender_description;
        this.valuing_for_lender_coi = t.valuing_for_lender_coi;
        this.valuing_for_lender_type_of_conflict_id = t.valuing_for_lender_type_of_conflict_id;
        this.valuing_for_lender_appropriate_parties = t.valuing_for_lender_appropriate_parties;
        this.valuing_for_lender_reason_continue = t.valuing_for_lender_reason_continue;
        this.valuing_for_lender_description_b = t.valuing_for_lender_description_b;

        this.long_standing_professional = t.long_standing_professional;
        this.long_standing_professional_description = t.long_standing_professional_description;

        this.introducing_transaction = t.introducing_transaction;
        this.introducing_transaction_description = t.introducing_transaction_description;

        this.financial_interest = t.financial_interest;
        this.financial_interest_description = t.financial_interest_description;
        this.financial_interest_b = t.financial_interest_b;
        this.financial_interest_coi = t.financial_interest_coi;
        this.financial_interest_type_of_conflict_id = t.financial_interest_type_of_conflict_id;
        this.financial_interest_appropriate_parties = t.financial_interest_appropriate_parties;
        this.financial_interest_reason_continue = t.financial_interest_reason_continue;
        this.financial_interest_description_b = t.financial_interest_description_b;

        this.acting_for = t.acting_for;
        this.acting_for_description = t.acting_for_description;
        this.acting_for_coi = t.acting_for_coi;
        this.acting_for_type_of_conflict_id = t.acting_for_type_of_conflict_id;
        this.acting_for_appropriate_parties = t.acting_for_appropriate_parties;
        this.acting_for_reason_continue = t.acting_for_reason_continue;
        this.acting_for_description_b = t.acting_for_description_b;

        this.acting_for_borrower = t.acting_for_borrower;
        this.acting_for_borrower_description = t.acting_for_borrower_description;

        this.retained_to_act = t.retained_to_act;
        this.retained_to_act_description = t.retained_to_act_description;

        this.recently_acted = t.recently_acted;
        this.recently_acted_description = t.recently_acted_description;

        this.professional_advice = t.professional_advice;
        this.professional_advice_description = t.professional_advice_description;

        this.providing_development_consultancy = t.providing_development_consultancy;
        this.providing_development_consultancy_description = t.providing_development_consultancy_description;
        this.providing_development_consultancy_coi = t.providing_development_consultancy_coi;
        this.providing_development_consultancy_type_of_conflict_id = t.providing_development_consultancy_type_of_conflict_id;
        this.providing_development_consultancy_appropriate_parties = t.providing_development_consultancy_appropriate_parties;
        this.providing_development_consultancy_reason_continue = t.providing_development_consultancy_reason_continue;
        this.providing_development_consultancy_description_b = t.providing_development_consultancy_description_b;

        this.valuer_identify_conflict = t.valuer_identify_conflict;
        this.valuer_identify_conflict_description = t.valuer_identify_conflict_description;
        this.valuer_identify_conflict_coi = t.valuer_identify_conflict_coi;
        this.valuer_identify_conflict_type_of_conflict_id = t.valuer_identify_conflict_type_of_conflict_id;
        this.valuer_identify_conflict_appropriate_parties = t.valuer_identify_conflict_appropriate_parties;
        this.valuer_identify_conflict_reason_continue = t.valuer_identify_conflict_reason_continue;
        this.valuer_identify_conflict_description_b = t.valuer_identify_conflict_description_b;

        this.valuer_doubts_ability = t.valuer_doubts_ability;
        this.valuer_doubts_ability_description = t.valuer_doubts_ability_description;
        this.valuer_doubts_ability_type_of_conflict_id = t.valuer_doubts_ability_type_of_conflict_id;
        this.valuer_doubts_ability_appropriate_parties = t.valuer_doubts_ability_appropriate_parties;
        this.valuer_doubts_ability_reason_continue = t.valuer_doubts_ability_reason_continue;
    }

    copyDData(t: ToeTemplateModel) {
        this.client_informed_complain = t.client_informed_complain;

        this.not_having_the_level = t.not_having_the_level;
        this.not_having_the_level_description = t.not_having_the_level_description;

        this.valuer_employ_another_firm = t.valuer_employ_another_firm;
        this.valuer_employ_another_firm_description = t.valuer_employ_another_firm_description;

        this.valuation_relied_on_other_parties = t.valuation_relied_on_other_parties; 
        this.valuation_relied_on_other_parties_description = t.valuation_relied_on_other_parties_description;

        this.asset_previously_valued = t.asset_previously_valued;
        this.asset_previously_valued_description = t.asset_previously_valued_description;
        this.asset_previously_valued_by_the_member = t.asset_previously_valued_by_the_member;

        this.valuer_involved_in_purchase = t.valuer_involved_in_purchase;
        this.valuer_involved_in_purchase_description = t.valuer_involved_in_purchase_description;

        this.valuer_provided_valuations = t.valuer_provided_valuations;
        this.valuer_provided_valuations_description = t.valuer_provided_valuations_description;

        this.valuer_signatory = t.valuer_signatory;
        this.valuer_signatory_description = t.valuer_signatory_description;

        this.valuer_involved_professional_services = t.valuer_involved_professional_services;
        this.valuer_involved_professional_services_description = t.valuer_involved_professional_services_description;

        this.valuer_involved_12_month_before = t.valuer_involved_12_month_before;
        this.valuer_involved_12_month_before_description = t.valuer_involved_12_month_before_description;

        this.purpose_of_valuation = t.purpose_of_valuation;
        this.purpose_of_valuation_description = t.purpose_of_valuation_description;
    }
}
