import { createAction, props } from "@ngrx/store"
import { ValuationProcess } from "../types/valuation-process"
import { ValuationProcessTargetPropertyModel } from "../types/asset-class/target-property"
import { ValuationModel } from "../types/valuation"
import { VComparable } from "../types/asset-class/comparable"

export const loadData = createAction(
  '[Valuation Process Page - V2] Load Data',
  props<{valuationId: number}>()
)

export const dataLoaded = createAction(
  '[Valuation Process Effects - V2] Data Loaded',
  props<{
    valuationProcess: ValuationProcess,
    targetProperty: ValuationProcessTargetPropertyModel,
    valuation: ValuationModel,
    additional: any,
    assumptionDepartures: any,
    vpga10Matters: any,
    sers: any,
    notes: any,
    propertySubTypeIds: number[],
    fullComparables: VComparable[]
  }>()
)

export const storeData = createAction(
  '[Valuation Process Page - V2] Store Data',
  props<{
    isComplete: boolean
  }>()
)

export const dataStored = createAction(
  '[Valuation Process Effects - V2] Data Stored',
  props<{
    success: boolean
  }>()
)