/// <reference path="../../node_modules/anychart/dist/index.d.ts"/>

import {CommonModule} from '@angular/common';
// Angular
import {BrowserModule, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// import {GestureConfig} from '@angular/material/core';
import {OverlayModule} from '@angular/cdk/overlay';
// Angular in memory
import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
// Perfect Scroll bar
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface} from 'ngx-perfect-scrollbar';
// SVG inline
import {InlineSVGModule} from 'ng-inline-svg';
// Env
import {environment} from '../environments/environment';
// Hammer JS
import 'hammerjs';
// NGX Permissions
import {NgxPermissionsModule} from 'ngx-permissions';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
// State
import {metaReducers, reducers} from './core/reducers';
// Components
import {AppComponent} from './app.component';
// Modules
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {ThemeModule} from './views/theme/theme.module';
// Partials
import {PartialsModule} from './views/partials/partials.module';
// Layout Services
import {
    DataTableService,
    KtDialogService,
    LayoutConfigService,
    LayoutRefService,
    MenuAsideService,
    MenuConfigService,
    MenuHorizontalService,
    PageConfigService,
    SplashScreenService,
    SubheaderService
} from './core/_base/layout';
// Auth
import {AuthModule} from './views/pages/auth/auth.module';
// import {AuthService} from './core/auth';
// CRUD
import {
    HttpUtilsService,
    // InterceptService,
    LayoutUtilsService,
    TypesUtilsService
} from './core/_base/crud';
// Config
import {LayoutConfig} from './core/_config/layout.config';
// Highlight JS
import {HighlightModule, HIGHLIGHT_OPTIONS} from 'ngx-highlightjs';
import xml from 'highlight.js/lib/languages/xml';
import json from 'highlight.js/lib/languages/json';
import scss from 'highlight.js/lib/languages/scss';
import typescript from 'highlight.js/lib/languages/typescript';
import {InitializeTranslationService} from './core/_base/translation/initialize-translation.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {
    DeleteEntityDialogComponent,
    DeleteAgencyDialogComponent,
    ErrorDialogComponent,
    SaveEntityDialogComponent,
    SaveEntityWithDescrDialogComponent,
    TermsDialogComponent,
    TrashAuditTrailDialogComponent,
    TrashDialogComponent
} from './views/partials/content/crud';
import {AgmCoreModule} from '@agm/core';
import Echo from 'laravel-echo';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_LOCALE, NativeDateModule} from '@angular/material/core';
import {MAT_DATETIME_FORMATS} from '@mat-datetimepicker/core';

import {Globals} from './core/_base/crud/utils/globals.service';
import {IdleTimeoutService} from './core/auth/_services/idleTimeout.service';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import { MadAuthService } from './core/mad-auth/mad-auth.service';
import { MadAuthModule } from './views/pages/mad-auth/mad-auth.module';
import { MadDateAdapter } from './core/date/mad-date-adapter';
import { LiveChatWidgetModule } from '@livechat/widget-angular'

(window as any).global = window;
declare var require: any;

declare global {
    interface Window {
        Echo: any;
        ReportGenerating: boolean;
        isConnected: boolean;
    }
}

window.Echo = window.Echo || {};
//
if (environment.production) {

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'ABCDEFG',
        cluster: 'mt1',
        encrypted: true,
        wsHost: 'demo.interval-soft.com',
        wssPort: 444
    });
    window.Echo.connector.pusher.connection.bind('connected', () => {
        console.log('connected');
    });
    window.Echo.connector.pusher.connection.bind('disconnected', () => {
        console.log('disconnected');
    });
    window.Echo.connector.pusher.connection.bind('reconnecting', () => {
        console.log('reconnecting');
    });


    window.Echo.connector.pusher.connection.bind('initialized', () => {
        console.log('initialized');
    });


    window.Echo.connector.pusher.connection.bind('unavailable', () => {
        console.log('unavailable');
    });


    window.Echo.connector.pusher.connection.bind('failed', () => {
        console.log('failed');
        window.Echo.reconnect();
    });

} else {
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'ABCDEFG',
        cluster: 'mt1',
        forceTLS: false,
        encrypted: false,
        wsHost: environment.wsHost,
        wsPort: environment.wsPort
    });

    window.Echo.connector.pusher.connection.bind('connected', () => {
        console.log('connected');
    });
    window.Echo.connector.pusher.connection.bind('disconnected', () => {
        console.log('disconnected');
    });
    window.Echo.connector.pusher.connection.bind('reconnecting', () => {
        console.log('reconnecting');
    });


    window.Echo.connector.pusher.connection.bind('initialized', () => {
        console.log('initialized');
    });


    window.Echo.connector.pusher.connection.bind('unavailable', () => {
        console.log('unavailable');
    });


    window.Echo.connector.pusher.connection.bind('failed', () => {
        console.log('failed');
        window.Echo.reconnect();
    });
}

window.Echo.channel('toe-report.1')
    .listen('ToeGenerateReport', (e) => {
        console.log('From laravel echo: ', e.message);
    });
window.Echo.channel('toe-report.2')
    .listen('ToeGenerateReport', (e) => {
        console.log('From laravel echo: ', e.message);
    });

window.Echo.channel('user-notify.1')
    .listen('.server.notify', (e) => {
        console.log('From laravel server.notify : ', e.message);
    });

// tslint:disable-next-line:class-name
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelSpeed: 0.5,
    swipeEasing: true,
    minScrollbarLength: 40,
    maxScrollbarLength: 300
};

export function initializeTranslation(service: InitializeTranslationService) {
    return () => service.init();
}

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
    // initialize app by loading default demo layout config
    return () => {
        if (appConfig.getConfig() === null) {
            appConfig.loadConfigs(new LayoutConfig().configs);
        }
    };
}

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
    return [
        {name: 'typescript', func: typescript},
        {name: 'scss', func: scss},
        {name: 'xml', func: xml},
        {name: 'json', func: json}
    ];
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        CommonModule,
        NativeDateModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxPermissionsModule.forRoot(),
        HighlightModule,
        PartialsModule,
        CoreModule,
        OverlayModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({ stateKey: 'router' }),
        StoreDevtoolsModule.instrument(),
        AuthModule.forRoot(),
        MadAuthModule.forRoot(),
        TranslateModule.forRoot(),
        MatProgressSpinnerModule,
        InlineSVGModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: environment.agMapKey,
            libraries: ['places', 'drawing', 'geometry']
        }),
        ThemeModule,
        NgbModule,
        LiveChatWidgetModule
        // Optionally you can set time for `idle`, `timeout` and `ping` in seconds.
        // Default values: `idle` is 600 (10 minutes), `timeout` is 300 (5 minutes)
        // and `ping` is 120 (2 minutes).
        // PushNotificationsModule,
    ],
    exports: [],
    providers: [
        // AuthService,
        MadAuthService,
        IdleTimeoutService,
        Globals,
        LayoutConfigService,
        LayoutRefService,
        MenuConfigService,
        PageConfigService,
        KtDialogService,
        DataTableService,
        SplashScreenService,
        // InterceptService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: InterceptService,
        //     multi: true
        // },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                maxHeight: '800px',
                width: '900px'
            }
        },
        // {
        //     provide: HAMMER_GESTURE_CONFIG,
        //     useClass: GestureConfig
        // },
        {
            // layout config initializer
            provide: APP_INITIALIZER,
            useFactory: initializeLayoutConfig,
            deps: [LayoutConfigService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeTranslation,
            deps: [InitializeTranslationService],
            multi: true
        },
        {
            provide: HIGHLIGHT_OPTIONS,
            useValue: {
                languages: getHighlightLanguages
            }
        },
        {
            provide: DateAdapter,
            useClass: MadDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATETIME_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD MMM YYYY',
                },
                display: {
                    dateInput: 'DD MMM YYYY',
                    // dateInput: {year: 'numeric', month: '2-digit', day: '2-digit'},
                    monthInput: { month: 'long' },
                    datetimeInput: { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' },
                    timeInput: { hour: '2-digit', minute: '2-digit' },
                    monthYearLabel: { year: 'numeric', month: 'short' },
                    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
                    monthYearA11yLabel: { year: 'numeric', month: 'long' },
                    popupHeaderDateLabel: { weekday: 'short', month: 'short', day: '2-digit' }
                }
            }
        },
        // template services
        SubheaderService,
        MenuHorizontalService,
        MenuAsideService,
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService,
        InitializeTranslationService,
        // EchoService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
