import { CurrencyExchange } from "src/app/core/valuation-process/_models/currency-exchange";
import { calculateNonRecoverable, calculateRecoverable } from "../../../core";
import { durationCalculation } from "../../../valuation";
import { HeadlineToEffectiveMeta } from "../types";
import { VAssetClassConsideration } from "../types/asset-class/common";
import { VComparable } from "../types/asset-class/comparable";
import { IncomeValuationProcess, ValuationProcess } from "../types/valuation-process";
import * as _ from 'lodash'
import { AssetClassTenure } from "src/app/core/comparable";

export function addFullComparables(comparables: VComparable[], _comparables: VComparable[]): VComparable[] {
  return _comparables.reduce((acc, com) => {
    const exists = acc.find(item => item.refNum === com.refNum)
    if (exists) return acc
    return [...acc, com]
  }, comparables) 
}

export function addComparable(
  valuationProcess: ValuationProcess,
  comparables: VComparable[], 
  refNum: string,
): ValuationProcess {
  if (valuationProcess.kind === 'unknown') return valuationProcess
  if (valuationProcess.kind === 'market') return valuationProcess
  return addComparableInIncomeValuation(
    valuationProcess,
    comparables, 
    refNum
  )
}

function addComparableInIncomeValuation(
  process: IncomeValuationProcess,
  comparables: VComparable[], 
  refNum: string
): IncomeValuationProcess {
  const item = comparables.find(_item => _item.refNum === refNum)
  if (!item) return process
  if (process.data.comparables[item.propertySubType].length === 5) return process
  const _comparables = {
    ...process.data.comparables,
    [item.propertySubType]: [...process.data.comparables[item.propertySubType], item]
  }
  return {
    ...process,
    data: {
      ...process.data,
      comparables: _comparables
    }
  }
}

export function removeComparable(
  valuationProcess: ValuationProcess,
  refNum: string
): ValuationProcess {
  if (valuationProcess.kind === 'unknown') return valuationProcess
  if (valuationProcess.kind === 'market') return valuationProcess
  return removeComparableInIncomeValuation(valuationProcess, refNum)
}

function removeComparableInIncomeValuation(
  process: IncomeValuationProcess,
  refNum: string
): IncomeValuationProcess {
  const comparables = _.flatten(Object.values(process.data.comparables))
  const item = comparables.find(_item => _item.refNum === refNum)
  if (!item) return process
  const _comparables = {
    ...process.data.comparables,
    [item.propertySubType]: process.data.comparables[item.propertySubType].filter(_item => _item.refNum !== refNum)
  }
  return {
    ...process,
    data: {
      ...process.data,
      comparables: _comparables
    }
  }
}

export function comparableAverages(
  comparables: VComparable[], 
  selectedConsiderations: {refNum: string, id: number}[],
  countryCurrency: string
) {
  const considerationAndSizes = comparables.map(comparable => {
    const selectedConsideration = selectedConsiderations.find(item => item.refNum === comparable.refNum)
    if (selectedConsideration !== undefined) {
      return {
        refNum: comparable.refNum,
        consideration: comparable.considerations.find(item => item.id === selectedConsideration.id),
        size: comparable.sizes.length > 0 ? comparable.sizes[0].size : 0
      }
    }
    if (comparable.considerations.length > 0) {
      return {
        refNum: comparable.refNum,
        consideration: comparable.considerations[0],
        size: comparable.sizes.length > 0 ? comparable.sizes[0].size : 0
      }
    }
    return undefined
  })

  const considerations = considerationAndSizes.filter(item => item !== undefined)


  const leaseDuration = considerations.reduce((acc, item) => {
    if (item.consideration.considerationType === 'Rent') {
      const leaseDuration = durationCalculation(item.consideration.oldTenure.lease_duration, item.consideration.oldTenure.duration_type)
      return {
        total: acc.total + Number(leaseDuration),
        num: acc.num + 1
      }
    }
    return acc
  }, {
    total: 0,
    num: 0
  })

  const rentReview = considerations.reduce((acc, item) => {
    if (item.consideration.considerationType === 'Rent') {
      const rentReview = durationCalculation(item.consideration.oldTenure.rent_review, item.consideration.oldTenure.duration_type)
      return {
        total: acc.total + Number(rentReview),
        num: acc.num + 1
      }
    }
    return acc
  }, {total: 0, num: 0})

  const rentFreePeriod = considerations.reduce((acc, item) => {
    if (item.consideration.considerationType === 'Rent') {
      const rentReview = durationCalculation(item.consideration.oldTenure.rent_free_period, item.consideration.oldTenure.duration_type)
      return {
        total: acc.total + Number(rentReview),
        num: acc.num + 1
      }
    }
    return acc
  }, {total: 0, num: 0})

  const fittingOutPeriod = considerations.reduce((acc, item) => {
    if (item.consideration.considerationType === 'Rent') {
      const rentReview = durationCalculation(item.consideration.oldTenure.fitting_out_period, item.consideration.oldTenure.duration_type)
      return {
        total: acc.total + Number(rentReview),
        num: acc.num + 1
      }
    }
    return acc
  }, {total: 0, num: 0})

  const recoverable = avgRecoverable(considerationAndSizes, countryCurrency)
  const nonRecoverable = avgNonRecoverable(considerationAndSizes, countryCurrency)

  return {
    effectiveRent: avgEffectives(considerationAndSizes, countryCurrency),
    leaseDuration: leaseDuration.num > 0 ? (leaseDuration.total / leaseDuration.num) : undefined,
    rentReview: rentReview.num > 0 ? (rentReview.total / rentReview.num) : undefined,
    recoverable: recoverable.num > 0 ? (recoverable.total / recoverable.num) : undefined,
    nonRecoverable: nonRecoverable.num > 0 ? (nonRecoverable.total / nonRecoverable.num) : undefined,
    rentFreePeriod: rentFreePeriod.num > 0 ? (rentFreePeriod.total / rentFreePeriod.num) : undefined,
    fittingOutPeriod: fittingOutPeriod.num > 0 ? (fittingOutPeriod.total / fittingOutPeriod.num) : undefined
  }
}

export function selectConsideration(valuationProcess: ValuationProcess, refNum: string, id: number): ValuationProcess {
  if (valuationProcess.kind === 'unknown') return valuationProcess
  if (valuationProcess.kind === 'market') return valuationProcess
  return {
    ...valuationProcess,
    data: {
      ...valuationProcess.data,
      selectedConsiderations: updateSelectedConsiderations(valuationProcess.data.selectedConsiderations, refNum, id)
    }
  }
}

function updateSelectedConsiderations(
  selectedConsiderations: {refNum: string, id: number}[],
  refNum: string,
  id: number
): {refNum: string, id: number}[] {
  const index = selectedConsiderations.findIndex(item => item.refNum === refNum)
  if (index === -1) {
    return [...selectedConsiderations, {refNum, id}]
  }

  return selectedConsiderations.map(item => {
    if (item.refNum === refNum) {
      return {
        ...item,
        id
      }
    }
    return item
  })
}

function avgRecoverable(
  considerationAndSizes: {
    refNum: string,
    consideration: VAssetClassConsideration,
    size: number
  }[],
  countryCurrency: string
) {
  const result = considerationAndSizes.reduce((acc, item) => {
    if (item.consideration.considerationType !== 'Rent') return acc
    const totalRecoverable = calculateRecoverable(item.consideration.oldTenure)
    const recoverable = item.consideration.oldTenure.expenses_input_amount_type === 'per_units' ? totalRecoverable * item.size : totalRecoverable;
    if (item.consideration.currency !== countryCurrency) {
      const conv = item.consideration.conversion.find(conv => conv.quoteCurrency === countryCurrency)
      if (conv) {
        return {
          total: acc.total + (recoverable * conv.exchangeRate),
          num: acc.num + 1
        } 
      }
      return acc
    }
    return {
      total: acc.total + recoverable,
      num: acc.num + 1
    }

  }, {total: 0, num: 0})

  return result
}

function avgNonRecoverable(
  considerationAndSizes: {
    refNum: string,
    consideration: VAssetClassConsideration,
    size: number
  }[],
  countryCurrency: string
) {
  const result = considerationAndSizes.reduce((acc, item) => {
    if (item.consideration.considerationType !== 'Rent') return acc
    const totalNonRecoverable = calculateNonRecoverable(item.consideration.oldTenure)
    const nonRecoverable = item.consideration.oldTenure.expenses_input_amount_type === 'per_units' ? totalNonRecoverable * item.size : totalNonRecoverable;
    if (item.consideration.currency !== countryCurrency) {
      const conv = item.consideration.conversion.find(conv => conv.quoteCurrency === countryCurrency)
      if (conv) {
        return {
          total: acc.total + (nonRecoverable * conv.exchangeRate),
          num: acc.num + 1
        } 
      }
      return acc
    }
    return {
      total: acc.total + nonRecoverable,
      num: acc.num + 1
    }

  }, {total: 0, num: 0})

  return result
}

function avgEffectives(
  considerationAndSizes: {
    refNum: string, 
    consideration: VAssetClassConsideration, 
    size: number
  }[], 
  countryCurrency: string
) {
  let hasHeadlineRent = false;
  const effectiveRents = considerationAndSizes
    .filter(item => item !== undefined)
    .map(({refNum, consideration, size}) => {
      const value = _getConsiderationValue(consideration)
      if (
        consideration.considerationType === 'Sale' || 
        consideration.considerationType === 'Land' ||
        consideration.rentType === 'Effective'
      ) {
        hasHeadlineRent = false
      } else {
        hasHeadlineRent = true
      }

      if (consideration.currency !== countryCurrency) {
        const conv = consideration.conversion.find(conv => conv.quoteCurrency === countryCurrency)
        if (conv) {
          return value * conv.exchangeRate
        }
        return undefined
      }
      return value
    })

  const avg = effectiveRents.reduce((acc, item) => {
    return {
      total: acc.total + item,
      num: acc.num + 1
    }
  }, {total: 0, num: 0})

  return {
    value: avg.num > 0 ? avg.total / avg.num : undefined,
    hasHeadlineRent
  }
}

function _getConsiderationValue(consideration: VAssetClassConsideration): number {
  if (consideration.considerationType === 'Land') {
    return consideration.value
  }

  const basisMultiplier = getRentBasisMultiplier(consideration.oldTenure)

  if (consideration.considerationType === 'Sale') {
    return consideration.value * basisMultiplier
  }

  if (consideration.rentType === 'Effective') {
    return consideration.value * basisMultiplier
  }
  return consideration.value * basisMultiplier
}

function getRentBasisMultiplier(tenure: AssetClassTenure) {
  if (tenure.rent_basis_id == 1) {
    return 12
  }
  if (tenure.rent_basis_id == 2) {
    return 4
  }
  return 1
}

export function setComparableHeadlineToEffective(
  process: ValuationProcess,
  item: {refNum: string, meta: HeadlineToEffectiveMeta}
): ValuationProcess {
  if (process.kind === 'unknown') return process
  if (process.kind === 'market') return process
  const exists = process.data.comparableHeadlineToEffectives.find(_item => _item.refNum === item.refNum)
  if (exists) {
    const chte = process.data.comparableHeadlineToEffectives.map(_item => {
      if (_item.refNum === item.refNum) {
        return item
      }
      return _item
    })
    return {
      ...process,
      data: {
        ...process.data,
        comparableHeadlineToEffectives: chte
      }
    }
  }
  return {
    ...process,
    data: {
      ...process.data,
      comparableHeadlineToEffectives: [...process.data.comparableHeadlineToEffectives, item]
    }
  }
}

export function setCurrencyExchange(
  valuationProcess: ValuationProcess,
  data: {
    refNum: string,
    considerationId: number,
    currencyExchange: CurrencyExchange
  }
): ValuationProcess {
  if (valuationProcess.kind === 'unknown') return valuationProcess
  if (valuationProcess.kind === 'market') return valuationProcess
  const comparables = _.flatten(Object.values(valuationProcess.data.comparables))
  const comparable = comparables.find(comparable => comparable.refNum === data.refNum)
  if (!comparable) return valuationProcess
  const _validComparables = valuationProcess.data.comparables[comparable.propertySubType]
    .map(comparable => {
      return {
        ...comparable,
        considerations: comparable.considerations.map(consideration => {
          if (consideration.id != data.considerationId) return {...consideration}
          const newC = setConversions(consideration.conversion, data.currencyExchange)
          return {
            ...consideration,
            conversion: setConversions(consideration.conversion, data.currencyExchange)
          }
        })
      }
    })
  const _comparables = {
    ...valuationProcess.data.comparables,
    [comparable.propertySubType]: _validComparables
  }

  return {
    ...valuationProcess,
    data: {
      ...valuationProcess.data,
      comparables: _comparables
    }
  }
}


function setConversions(
  conversions: {id: number, quoteCurrency: string, exchangeRate: number}[], 
  currencyExchange: CurrencyExchange
) {
  if (currencyExchange.id) {
    return conversions.map(conversion => {
      if (conversion.id !== currencyExchange.id) return {...conversion}
      return {
        ...conversion,
        exchangeRate: currencyExchange.exchange_rate
      }
    })
  }

  const conversion = conversions.find(conv => conv.quoteCurrency === currencyExchange.quote_currency)
  if (conversion) {
    return conversions.map(conversion => {
      if (conversion.quoteCurrency !== currencyExchange.quote_currency) return {...conversion}
      return {
        ...conversion,
        quoteCurrency: currencyExchange.quote_currency,
        exchangeRate: currencyExchange.exchange_rate
      }
    })
  }

  return [...conversions, {id: undefined, quoteCurrency: currencyExchange.quote_currency, exchangeRate: currencyExchange.exchange_rate}]
}