// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {Appendix} from '../_models/appendix.model';

export enum AppendixActionTypes {

    AllAppendicesRequested = '[Appendices Home Page] All Appendices Requested',
    AllAppendicesRequestedMultimedia = '[Appendices Home Page] All Appendices Requested Multimedia',
    AllAppendicesLoaded = '[Appendices API] All Appendices Loaded',

    AppendixOnServerCreated = '[Edit Appendix Dialog] Appendix On Server Created',
    AppendixCreated = '[Edit Appendices Dialog] Appendices Created',
    AppendixUpdated = '[Edit Appendix Dialog] Appendix Updated',
    AppendixDeleted = '[Appendices List Page] Appendix Deleted',

    AppendicesPageRequested = '[Appendices List Page] Appendices Page Requested',
    AppendicesPageLoaded = '[Appendices API] Appendices Page Loaded',
    AppendicesPageCancelled = '[Appendices API] Appendices Page Cancelled',
    AppendicesClear = '[Appendices API] Appendices Page Clear',

    // trash system
    AppendiceOnServerRestored = '[Appendice Trash] Appendice On Server Restored',
    AppendiceRestored = '[Appendice Trash] Appendice Restored',

    AppendiceOnServerAdminRestored = '[Appendice Admin Trash] Appendice On Server Restored',
    AppendiceAdminRestored = '[Appendice Admin Trash] Appendice Restored',

    AppendiceDeletedFromTrash = '[Appendice Trash] Appendice deleted',
    AppendiceDeletedFromAdminTrash = '[Appendice Admin Trash] Appendice deleted',

    AppendiceTrash = 'Appendice Trashed',
    AppendiceTrashFlushed = 'Appendice Trash Flushed',

    AppendicesPageToggleLoading = '[Appendices page] Appendices Page Toggle Loading',
    AppendicesActionToggleLoading = '[Appendices] Appendices Action Toggle Loading'
}

export class AppendixOnServerCreated implements Action {
    readonly type = AppendixActionTypes.AppendixOnServerCreated;

    constructor(public payload: { appendix: Appendix, fileList: any[] }) {
    }
}

export class AppendixCreated implements Action {
    readonly type = AppendixActionTypes.AppendixCreated;

    constructor(public payload: { appendix: Appendix }) {
    }
}

export class AppendixUpdated implements Action {
    readonly type = AppendixActionTypes.AppendixUpdated;

    constructor(public payload: {
        partialappendix: Update<Appendix>,
        appendix: Appendix,
        fileList: any[]
    }) {
    }
}

export class AppendixDeleted implements Action {
    readonly type = AppendixActionTypes.AppendixDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class AppendicesPageRequested implements Action {
    readonly type = AppendixActionTypes.AppendicesPageRequested;

    constructor(public payload: { page: QueryParamsModel, catId: number }) {
    }
}

export class AppendicesPageLoaded implements Action {
    readonly type = AppendixActionTypes.AppendicesPageLoaded;

    constructor(public payload: { appendices: Appendix[],
        totalCount: number,
        totalTrashed: number,
        totalAdminTrashed: number,
        page: QueryParamsModel }) {
    }
}

export class AppendicesPageCancelled implements Action {
    readonly type = AppendixActionTypes.AppendicesPageCancelled;
}

export class AllAppendicesRequested implements Action {
    readonly type = AppendixActionTypes.AllAppendicesRequested;
}

export class AllAppendicesRequestedMultimedia implements Action {
    readonly type = AppendixActionTypes.AllAppendicesRequestedMultimedia;
}

export class AllAppendicesLoaded implements Action {
    readonly type = AppendixActionTypes.AllAppendicesLoaded;

    constructor(public payload: { appendices: Appendix[] }) {
    }
}

export class AppendicesClear implements Action {
    readonly type = AppendixActionTypes.AppendicesClear;

    constructor() {
    }
}

export class AppendicesPageToggleLoading implements Action {
    readonly type = AppendixActionTypes.AppendicesPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class AppendicesActionToggleLoading implements Action {
    readonly type = AppendixActionTypes.AppendicesActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}


// Trash

export class AppendiceDeletedFromAdminTrash implements Action {
    readonly type = AppendixActionTypes.AppendiceDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}


export class AppendiceDeletedFromTrash implements Action {
    readonly type = AppendixActionTypes.AppendiceDeletedFromTrash;

    constructor(public payload: { id: number }) {
    }
}


export class AppendiceOnServerRestored implements Action {
    readonly type = AppendixActionTypes.AppendiceOnServerRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AppendiceRestored implements Action {
    readonly type = AppendixActionTypes.AppendiceRestored;

    constructor(public payload: { item: Appendix }) {
    }
}

export class AppendiceOnServerAdminRestored implements Action {
    readonly type = AppendixActionTypes.AppendiceOnServerAdminRestored;

    constructor(public payload: { id: number }) {
    }
}

export class AppendiceAdminRestored implements Action {
    readonly type = AppendixActionTypes.AppendiceAdminRestored;

    constructor(public payload: { item: Appendix }) {
    }
}

export class AppendiceTrashFlushed implements Action {
    readonly type = AppendixActionTypes.AppendiceTrashFlushed;

    constructor(public payload: { catId: number }) {
    }
}

export type AppendixActions = AppendixCreated
    | AppendixUpdated
    | AppendixDeleted
    | AppendicesClear
    | AppendicesPageRequested
    | AppendicesPageLoaded
    | AppendicesPageCancelled
    | AllAppendicesLoaded
    | AllAppendicesRequested
    | AppendiceDeletedFromAdminTrash
    | AppendiceDeletedFromTrash
    | AppendixOnServerCreated
    | AppendiceOnServerRestored
    | AppendiceRestored
    | AppendiceOnServerAdminRestored
    | AppendiceAdminRestored
    | AppendiceTrashFlushed
    | AppendicesPageToggleLoading
    | AppendicesActionToggleLoading;
