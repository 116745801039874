import { DatePipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { concat } from 'lodash';
import { tenureAdder } from 'src/app/core/comparable/_data-sources/tenure_extension';
import { environment } from 'src/environments/environment';
import { capitalize } from '../../comparable/_sub/consideration-and-source/consideration-and-source.component';
import { MapMarkerInfoWindowDialogInput } from './map-marker-info-window.component';
import * as landDS from 'src/app/core/comparable/_data-sources/asset-class-land.datasource';
import { SubdomainService } from 'src/app/core/_base/subdomain.service';

type modalType = 'comparable' | 'landmark' | 'building';

@Injectable({
  providedIn: 'root'
})
export class ToMarkerItemConverterService {
  constructor(
    private datePipe: DatePipe,
    private subDomainService: SubdomainService
  ) { }

  convert(type: modalType, data: any[]): MapMarkerInfoWindowDialogInput {
    return {
      modal_title: this.getTitle(type, data.length),
      entities: this.getEntities(type, data)
    }
  }

  private getTitle(type: modalType, num: number): string {
    switch (type) {
      case 'comparable':
        return `COMPARABLE${num > 1 ? 'S' : ''}`
      case 'landmark':
        return `LANDMARK${num > 1 ? 'S' : ''}`
      case 'building':
        return `BUILDING${num > 1 ? 'S' : ''}`
    }
  }

  private getEntities(type: modalType, data: any[]): any[] {
    switch (type) {
      case 'comparable':
        return this.getComparableInfo(data);
      case 'landmark':
        return this.getLandmarkInfo(data);
      case 'building':
        return this.getBuildingInfo(data);
    }
  }

  private getBuildingInfo(data: any[]): any[] {
    return data.map(d => {
        const fields = this.getBuildingFields(d);
        return {
            id: d.id,
            ref: `B${d.id}`,
            title: d.building_name ? d.building_name : d.building_type_name,
            subtitle: d.building_name ? d.building_type_name : null,
            pictures: d.pictures.map(p => this.getImageURL(p)),
            fields: fields,
            status: d.status,
            used_times: d.linked_comparable,
            edit_url: '/comparable/buildings/edit/'
        }
    });
  }

  private getLandmarkInfo(data: any[]): any[] {
    return data.map(d => {
        return {
            id: d.id,
            ref: `L${d.id}`,
            title: d.name,
            subtitle: d.category_name,
            pictures: [environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + d.picture],
            fields: [{
                header: 'Description',
                value: {strong: d.description, simple: null},
                type: null
            }],
            status: -1,
            used_times: d.used_times ? d.used_times : 0,
            edit_url: '/admin-management/key-places/edit/'
        }
    });
  }

  private getComparableInfo(data: any[]): any[] {
    return data.map(d => {
      const fields = d.ac_type != 13 ? this.getFields(d, d.ac_type) : this.getLandFields(d);
      const edit_url = this.getComparableEditURL(d.ac_type)
      if (d.ac_type == 13) {
        const bounds = new google.maps.LatLngBounds();
        const points = d.points.map(p => ({lat: Number(p.lat), lng: Number(p.lng)}));
        const filtered = points.filter(p => p.lat != null && p.lng != null);
        filtered.forEach(p => bounds.extend(p))
        return {
          id: d.id,
          ref: d.ref_num,
          title: 'Land',
          subtitle: d.buildings.length > 0 ? `${d.buildings.length} building structure${d.buildings.length > 1 ? 's' : ''} on parcel` : null,
          pictures: concat(d.ac_pictures, d.building_pictures).map(p => this.getImageURL(p)),
          fields,
          status: d.status,
          used_times: 0,
          edit_url,
          points: points,
          hasPoints: true,
          center: bounds.getCenter() 
        }
      }
      return {
        id: d.id,
        ref: d.ref_num,
        title: d.sub_type_category_name,
        subtitle: d.sub_category_name,
        pictures: d.ac_pictures.map(p => this.getImageURL(p)),
        fields,
        status: d.status,
        used_times: d.used_times ? d.used_times : 0, 
        edit_url,
        points: [],
        hasPoints: false,
        center: null
      }
    })
  }

  private getImageURL(img: any): string {
    const path = img.path;
    const name = img.name;
    let _picture = path + '/' + name;
    if (_picture.charAt(0) == '/') {
      _picture = _picture.substring(1, _picture.length);
    }
    const imgURL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/files/download?path=` + _picture;
    return imgURL;
  }

  private getBuildingFields(b: any): any[] {
    const fields = [];
    const grade = {header: 'Grade', value: null, type: null};
    const stateRepair = {header: 'State of Repair', value: null, type: null};
    const completionyear = {header: 'Completion Year', value: null, type: null};
    if (b.building_grade_name) {
        grade.value = {strong: b.building_grade_name, simple: null};
    }
    if (b.internalAspectData && b.internalAspectData.state_of_repair_name) {
        stateRepair.value = {strong: b.internalAspectData.state_of_repair_name, simple: null};
    }
    if (b.completion_year) {
        completionyear.value = {strong: b.completion_year, simple: null};
    }
    fields.push(grade);
    fields.push(stateRepair);
    fields.push(completionyear);
    return fields;
  }

  private getLandFields(ac: any): any[] {
    ac = landDS.areaAdder(ac);
    ac = landDS.tenureAdder(ac);
    const fields = [];

    const size = {
      header: 'Size', 
      value: {
        strong: `${numberWithCommas(ac.area.toFixed(2))} m2`,
        simple: `(${numberWithCommas((ac.area * 10.7639).toFixed(2))} ft2)`
      },
      type: null
    }
    fields.push(size);
    const subType = {
      header: 'Sub-type',
      value: null,
      type: null
    }
    if (ac.property_sub_type_name) {
      subType.value = {
        strong: ac.property_sub_type_name,
        simple: null
      };
    }
    fields.push(subType);

    const compUse = {
      header: 'Comp. land use',
      value: null, 
      type: null
    }
    if (ac.land_use && ac.land_use.comparative_land_use_name) {
      compUse.value = {
        strong: ac.land_use.comparative_land_use_name,
        simple: null
      }
    }
    fields.push(compUse);

    const bs = (n: number, c: number): string => {
      if (n == 1) {
        return c.toString();
      } 
      if (n == 0) {
        return 'None'
      }
      return 'Information not available'
    }
    const buildingStructure = {
      header: 'Building Structure',
      value: {
        strong: ac.land_use 
                  ? bs(ac.land_use.building_exist, ac.land_use.building_count)
                  : null ,
        simple: null
      },
      type: null
    }
    if (ac.land_use) {
      buildingStructure.value = {
        strong: bs(ac.land_use.building_exist, ac.land_use.building_count),
        simple: null
      }
    }
    fields.push(buildingStructure);

    if (ac.display_tenures.length > 0) {
      const t = ac.display_tenures[0];
      const totalTenure = numberWithCommas(t.total_consideration.toFixed(2))
      fields.push({
        header: 'Consideration', 
        value: {
          tenure: t.tenure,
          consideration: `${totalTenure} ${t.currency} (${t.data.tenure_length == 0 ? 'In perpetuity' : (t.data.tenure_length + ' years')})`,
          date: t.data.source ? `${t.data.source.source_type_name} - ${this.datePipe.transform(t.data.source.source_date, "dd MMM yyyy")}` : null,
          source: t.data.source ? `${t.data.source.source_information_name}` : null
        },
        type: 'land-consideration'
      })
    } else {
      fields.push({header: 'Consideration', value: null, type: null})
    }

    return fields;
  }

  private getFields(ac: any, ac_type: number): any[] {
    ac = tenureAdder(ac);
    const size: string = ac.size;
    const idx = size.indexOf("(")
    const fields = [
        {header: 'Size', value: {strong: size.substring(0, idx), simple: size.substring(idx, size.length)}, type: null}
    ]
    if (ac_type == 1) {
        const bedroom = {header: 'Bedroom(s)', value: null, type: null}
        const bathroom = {header: 'Bathroom(s)', value: null, type: null}
        if (ac.bedroom) {
            bedroom.value = {strong: ac.bedroom, simple: null}
        } 
        if (ac.bathroom) {
            bathroom.value = {strong: ac.bathroom, simple: null}
        }
        fields.push(bedroom);
        fields.push(bathroom);
    }
    if (ac_type == 17) {
        const propertyGrade = {header: 'Property Grade', value: null, type: null}
        const stateRepair = {header: 'State of Repair', value: null, type: null}
        if (ac.property_grade_name) {
            propertyGrade.value = {strong: ac.property_grade_name, simple: null}
        } 
        if (ac.state_of_repair_name) {
            stateRepair.value = {strong: ac.state_of_repair_name, simple: null}
        }
        fields.push(stateRepair);
        fields.push(propertyGrade);
    }
    if (ac_type == 11) {
        const floors = {header: 'Floors', value: null, type: null}
        const propertyGrade = {header: 'Property Grade', value: null, type: null}
        if (ac.property_grade_name) {
            propertyGrade.value = {strong: ac.property_grade_name, simple: null}
        } 
        let totalFloors = null;
        if (ac.above_floors) {
            totalFloors = totalFloors != null ? (totalFloors + ac.above_floors) : ac.above_floors;
        }
        if (ac.below_floors) {
            totalFloors = totalFloors != null ? (totalFloors + ac.below_floors) : ac.below_floors;
        }
        if (totalFloors != null) {
            floors.value = {strong: totalFloors, simple: null}
        }
        fields.push(floors);
        fields.push(propertyGrade);
    }
    if (ac_type == 2) {
        const units = {header: 'Units', value: null, type: null}
        const types = {header: 'Parking Type', value: null, type: null}
        if (ac.num_of_units) {
            units.value = {strong: ac.num_of_units, simple: null}
        }
        if (ac.parking_type_name) {
            types.value = {strong: ac.parking_type_name, simple: null}
        }
        fields.push(units);
        fields.push(types);
    }
    if (ac_type == 7) {
        const completionYear = {header: 'Completion Year', value: null, type: null}
        const propertyGrade = {header: 'Property Grade', value: null, type: null}
        if (ac.completion_year != null && ac.completion_year.length > 0) {
            completionYear.value = {strong: ac.completion_year, simple: null}
        }
        if (ac.property_grade_name) {
            propertyGrade.value = {strong: ac.property_grade_name, simple: null}
        } 
        fields.push(completionYear);
        fields.push(propertyGrade);
    }
    if (ac_type == 1 || ac_type == 3) {
        const t = {header: 'Building', value: null, type: null}
        if (ac.building_info.building_name) {
            const typeName = ac.building_info.building_type_name;
            t.value = {strong: ac.building_info.building_name, simple: typeName ? `(${ac.building_info.building_type_name})` : null}
        }
        fields.push(t);
    }
    if (ac_type == 5) {
        const t = {header: 'Building', value: null, type: null}
        if (ac.building_name) {
            const typeName = ac.building_type_name;
            t.value = {strong: ac.building_name, simple: typeName ? `(${ac.building_type_name})` : null}
        }
        fields.push(t);
    }

    if (ac.display_tenures.length > 0) {
        ac.display_tenures.map(dt => {
            const tenure = capitalize(dt.tenure)
            if (dt.consideration_type_id == 1) {
              const totalTenure = numberWithCommas(dt.total_consideration.toFixed(2))
              const strong = `${tenure} - ${totalTenure} ${dt.currency}`
              // const simple = `${dt.source.source_type_name} - ${this.datePipe.transform(dt.source.source_date, "dd MMM yyyy")} ${ac.locationData.time_offset ? '(GMT ' + ac.locationData.time_offset + ')' : ''} - ${dt.source.source_information_name}`
              const simple = `${dt.source.source_type_name} - ${this.datePipe.transform(dt.source.source_date, "dd MMM yyyy")} - ${dt.source.source_information_name}`
              return {
                strong,
                simple
              }
            }
            if (dt.consideration_type_id == 2) {
              if (dt.rent_input_amount_type === 'total') {
                const totalTenure = numberWithCommas(dt.total_consideration.toFixed(2))
                const strong = `${tenure} - ${totalTenure} ${dt.currency}/${dt.rent_basis}`
                // const simple = `${dt.source.source_type_name} - ${this.datePipe.transform(dt.source.source_date, "dd MMM yyyy")} ${ac.locationData.time_offset ? '(GMT ' + ac.locationData.time_offset + ')' : ''} - ${dt.source.source_information_name}`
                const simple = `${dt.source.source_type_name} - ${this.datePipe.transform(dt.source.source_date, "dd MMM yyyy")} - ${dt.source.source_information_name}`
                return {
                  strong,
                  simple
                }
              }
              if (dt.rent_input_amount_type === 'per_units') {
                const total = dt.total_consideration * (dt.rent_size ? dt.rent_size.size : 0)
                const totalTenure = numberWithCommas(total.toFixed(2))
                const strong = `${tenure} - ${totalTenure} ${dt.currency}/${dt.rent_basis}`
                // const simple = `${dt.source.source_type_name} - ${this.datePipe.transform(dt.source.source_date, "dd MMM yyyy")} ${ac.locationData.time_offset ? '(GMT ' + ac.locationData.time_offset + ')' : ''} - ${dt.source.source_information_name}`
                const simple = `${dt.source.source_type_name} - ${this.datePipe.transform(dt.source.source_date, "dd MMM yyyy")} - ${dt.source.source_information_name}`
                return {
                  strong,
                  simple
                }
              }
            }
            return {
              strong: '',
              simple: ''
            }
        }).forEach((t, i) => {
            fields.push({header: `Consideration ${ac.display_tenures.length == 1 ? '' : (i + 1)}`, value: t, type: 'consideration'})
        });
    } else {
        fields.push({header: 'Consideration', value: null, type: null})
    }

    return fields; 
  }

  private getComparableEditURL(ac_type: number): string {
    switch (ac_type) {
        case 1:
            return '/comparable/apartments/edit/';
        case 3:
            return '/comparable/offices/edit/';
        case 17:
            return '/comparable/houses/edit/';
        case 5:
            return '/comparable/retail-shops/edit/';
        case 11:
            return '/comparable/retail-buildings/edit/';
        case 2:
            return '/comparable/parkings/edit/';
        case 7:
            return '/comparable/warehouses/edit/'
        case 13:
          return '/comparable/lands/edit/'
        default:
            return null
    }
  }
}

function numberWithCommas(x: number|string) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
