import {Update} from '@ngrx/entity';
import {Action} from '@ngrx/store';
import { AdjustmentTabData, AssumptionTabData } from '../_models/tab_data.model';
import {ValuationDataModel} from '../_models/valuation-data.model';
import { ValuationNoteModel } from '../_models/valuation-note.model';
import { ICurrencyExchange } from '../../admin/_services/currency_exchange.service';

export enum ValuationDataActionTypes {
    FetchValuationData = 'Fetch ValuationData for valuation',
    FetchValuationDataMultimedia = 'Fetch ValuationData for valuation Multimedia',
    
    ValuationDataStore = 'Store ValuationData for valuation',
    ValuationDataStoreActionLoading = 'Loading action of storing valuation data',
    StoredValuationData = 'ValuationData for valuation has saved',

    ValuationDataLoaded = 'Loaded ValuationData for valuation',
    UpdateValuationData = 'Update ValuationData values',
    ValuationDataUpdated = 'ValuationData value is updated',
    RestartValuationDataState = 'Restart ValuationData state to initial state',

    ChangeComparableTenure = 'Change Comparable Tenure with Conversion',
    ChangeComparableConsideration = '[Consideration info table] Change Comparable Consideration',
}

export class FetchValuationData implements Action {
    readonly type = ValuationDataActionTypes.FetchValuationData;

    constructor(public payload: {
        asset_class_id: number,
        valuation_id: number,
    }) {
    }
}

export class FetchValuationDataMultimedia implements Action {
    readonly type = ValuationDataActionTypes.FetchValuationDataMultimedia;

    constructor(public payload: {
        asset_class_id: number,
        valuation_id: number,
    }) {
    }
}

export class ValuationDataStore implements Action {
    readonly type = ValuationDataActionTypes.ValuationDataStore;

    constructor(public payload: {
        adjustmentTabData: AdjustmentTabData;
        assumptionTabData: AssumptionTabData;
        notes: ValuationNoteModel[];
        validation: boolean;
        toeID: number,
        userId: number
    }) {
    }
}

export class StoredValuationData implements Action {
    readonly type = ValuationDataActionTypes.StoredValuationData;

    constructor(public payload: { message: string }) {
    }
}

export class ValuationDataLoaded implements Action {
    readonly type = ValuationDataActionTypes.ValuationDataLoaded;

    constructor(public payload: { item: ValuationDataModel }) {
    }
}

export class UpdateValuationData implements Action {
    readonly type = ValuationDataActionTypes.UpdateValuationData;

    constructor(public payload: {
        item: ValuationDataModel
    }) {
    }
}

export class ValuationDataUpdated implements Action {
    readonly type = ValuationDataActionTypes.ValuationDataUpdated;

    constructor(public payload: {
        item: Update<ValuationDataModel>
    }) {
    }
}

export class RestartValuationDataState implements Action {
    readonly type = ValuationDataActionTypes.RestartValuationDataState;

    constructor() {}
}

export class ValuationDataStoreActionLoading implements Action {
    readonly type = ValuationDataActionTypes.ValuationDataStoreActionLoading;
    constructor() {}
}

export class ChangeConsiderationCurrencyExchange implements Action {
    readonly type = ValuationDataActionTypes.ChangeComparableTenure;
    constructor(public payload: {
        comId: number,
        tenureId: number,
        currencyExchange: ICurrencyExchange,
        sameCurrency: boolean,
    }) {}
}

export class ChangeComparableConsideration implements Action {
    readonly type = ValuationDataActionTypes.ChangeComparableConsideration;

    constructor(public payload: {
        comId: number,
        tenureId: number
    }) {}
}


export type ValuationDataActions = FetchValuationData
    | ValuationDataLoaded

    | ValuationDataStore
    | ValuationDataStoreActionLoading
    | StoredValuationData

    | ValuationDataUpdated
    | UpdateValuationData
    | RestartValuationDataState
    | ChangeConsiderationCurrencyExchange
    
    | ChangeComparableConsideration;
