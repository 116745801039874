import * as moment from "moment";
import { ConvertedCostRevenue } from "../../types/converted-cost-revenue";
import { ModelAssumptionsUtil } from "./model-assumptions-util";
import {
  totalRecoverable as calculateTR,
  totalNonRecoverable as calculateTNR
} from '../../utils'
import { basisMultiplier, miniFs, rounddown4, yieldPurchaseOnPerpetuityAnnually, yieldPurchaseOnPerpetuityQuarterly, yieldPurchaseUntilReviewAnnually, yieldPurchaseUntilReviewQuarterly } from "./utils";

export function calculateFreeholdCapOnShortTerm(
  effectiveRent: number,
  costRevenue: ConvertedCostRevenue,
  valuationDate: Date,
  {
    activeBreakOffOption, 
    allRiskYield, 
    timingId, 
    estimatedVoidPeriod, 
    growthRate,
    purchaserCost
  }: {
    timingId: number
    activeBreakOffOption: boolean,
    allRiskYield: number,
    estimatedVoidPeriod: number,
    growthRate: number,
    purchaserCost: number
  }
) {
  const today = moment(valuationDate)
  const _basisMultiplier = basisMultiplier(costRevenue.rentBasis)
  const unexpiredLeaseTerm = today ? ModelAssumptionsUtil.unexpiredLeaseTerm(costRevenue, today) : null
  const yearsToReview = today ? ModelAssumptionsUtil.yearsToReview(costRevenue, today) : null
  const yearsToWriteOff = today ? ModelAssumptionsUtil.yearsToWriteOff(costRevenue, today) : null
  const yearsToBreakOff = today ? ModelAssumptionsUtil.yearsToBreakOff(costRevenue, today) : null

  const totalRecoverable = calculateTR(costRevenue) * _basisMultiplier
  const totalNonRecoverable = calculateTNR(costRevenue) * _basisMultiplier
  const passingRent = effectiveRent + totalRecoverable - totalNonRecoverable

  const minYear = miniFs(
    activeBreakOffOption
      ? {'Years to Review': yearsToReview, 'Years to Write off': yearsToWriteOff, 'Years to Break off': yearsToBreakOff}
      : {'Years to Review': yearsToReview, 'Years to Write off': yearsToWriteOff}
  )

  const yieldPurchaseUntilReview = timingId == 1 
    ? yieldPurchaseUntilReviewAnnually(allRiskYield, minYear.value)
    : timingId == 2
      ? yieldPurchaseUntilReviewQuarterly(allRiskYield, minYear.value)
      : undefined
  const capitalValueOfTerm = yieldPurchaseUntilReview * passingRent

  const yieldPurchaseOnPerpetuity = timingId == 1
    ? yieldPurchaseOnPerpetuityAnnually(allRiskYield)
    : timingId == 2
      ? yieldPurchaseOnPerpetuityQuarterly(allRiskYield)
      : undefined
  const _presentValue = presentValue(allRiskYield, activeBreakOffOption, estimatedVoidPeriod, costRevenue.breakOptionAfter, unexpiredLeaseTerm, minYear)
  const _presentValueGrowth = presentValueGrowth(growthRate, activeBreakOffOption, estimatedVoidPeriod, costRevenue.breakOptionAfter, unexpiredLeaseTerm, minYear)
  const capitalValueOfReversion = passingRent * yieldPurchaseOnPerpetuity * _presentValue.value * _presentValueGrowth.value

  const capitalValueOfTermAndReversion = capitalValueOfTerm + capitalValueOfReversion
  const totalPurchaserCost = capitalValueOfTermAndReversion
    ? purchaserCost
      ? (purchaserCost / 100) * capitalValueOfTermAndReversion
      : 0
    : undefined
  const value = capitalValueOfTermAndReversion - totalPurchaserCost

  return {
    unexpiredLeaseTerm,
    yearsToReview,
    yearsToWriteOff,
    yearsToBreakOff,

    effectiveRent,
    basisMultiplier: _basisMultiplier,
    recoverable: {
      total: totalRecoverable,
      recoverablePropertyManagement: costRevenue.recoverablePropertyManagement * _basisMultiplier,
      recoverableLeasingExpenses: costRevenue.recoverableLeasingExpenses * _basisMultiplier, 
      recoverableUtilities: costRevenue.recoverableUtilities * _basisMultiplier,
      recoverableMaintenance: costRevenue.recoverableMaintenance * _basisMultiplier,
      recoverableInsurance: costRevenue.recoverableInsurance * _basisMultiplier,
      recoverableJanitorial: costRevenue.recoverableJanitorial * _basisMultiplier,
      recoverablePropertyTaxes: costRevenue.recoverablePropertyTaxes * _basisMultiplier,
      recoverableBusiness: costRevenue.recoverableBusiness * _basisMultiplier,
      recoverableOthers: costRevenue.recoverableOthers * _basisMultiplier,
    },
    nonRecoverable: {
      total: totalNonRecoverable,
      nonRecoverablePropertyManagement: costRevenue.nonRecoverablePropertyManagement * _basisMultiplier,
      nonRecoverableLeasingExpenses: costRevenue.nonRecoverableLeasingExpenses * _basisMultiplier, 
      nonRecoverableUtilities: costRevenue.nonRecoverableUtilities * _basisMultiplier,
      nonRecoverableMaintenance: costRevenue.nonRecoverableMaintenance * _basisMultiplier,
      nonRecoverableInsurance: costRevenue.nonRecoverableInsurance * _basisMultiplier,
      nonRecoverableJanitorial: costRevenue.nonRecoverableJanitorial * _basisMultiplier,
      nonRecoverablePropertyTaxes: costRevenue.nonRecoverablePropertyTaxes * _basisMultiplier,
      nonRecoverableBusiness: costRevenue.nonRecoverableBusiness * _basisMultiplier,
      nonRecoverableOthers: costRevenue.nonRecoverableOthers * _basisMultiplier,
    },
    passingRent,

    yieldPurchaseUntilReview: {
      value: yieldPurchaseUntilReview,
      year: minYear
    },
    capitalValueOfTerm,

    yieldPurchaseOnPerpetuity,
    presentValue: {
      value: _presentValue.value,
      year: _presentValue.year
    },
    presentValueGrowth: {
      value: _presentValueGrowth.value,
      year: _presentValueGrowth.year
    },
    capitalValueOfReversion,

    capitalValueOfTermAndReversion,
    totalPurchaserCost,
    value
  }
}

function presentValue(
  allRiskYield: number, 
  activeBreakOffOption: boolean, 
  estimatedVoidPeriod: number,
  breakOffOption: number, 
  unexpiredLeaseTerm: number,
  minYear: {key: string, value: number}
) {
  const year = activeBreakOffOption
    ? {value: breakOffOption + estimatedVoidPeriod, year1: 'Break option after', year1Value: breakOffOption, year2: 'Estimated Void period', year2Value: estimatedVoidPeriod}
    : minYear.value === unexpiredLeaseTerm
      ? {value: minYear.value + estimatedVoidPeriod, year1: minYear.key, year1Value: minYear.value, year2: 'Estimated Void period', year2Value: estimatedVoidPeriod}
      : {value: minYear.value, year1: minYear.key, year1Value: minYear.value}

  if (year.value === null) return {value: undefined, year}
  const rateInVal = allRiskYield / 100
  if (rateInVal === 0) return {value: undefined, year}
  let temp = 1 + rateInVal
  temp = Math.pow(temp, -year.value)
  return {value: rounddown4(temp), year}
}

function presentValueGrowth(
  growthRate: number, 
  activeBreakOffOption: boolean, 
  estimatedVoidPeriod: number,
  breakOffOption: number, 
  unexpiredLeaseTerm: number,
  minYear: {key: string, value: number}
) {
  const year = activeBreakOffOption
    ? {value: breakOffOption + estimatedVoidPeriod, year1: 'Break option after', year1Value: breakOffOption, year2: 'Estimated Void period', year2Value: estimatedVoidPeriod}
    : minYear.value === unexpiredLeaseTerm
      ? {value: minYear.value + estimatedVoidPeriod, year1: minYear.key, year1Value: minYear.value, year2: 'Estimated Void period', year2Value: estimatedVoidPeriod}
      : {value: minYear.value, year1: minYear.key, year1Value: minYear.value}

  if (year.value === null) return {value: undefined, year}
  const rateInVal = growthRate / 100
  if (rateInVal === 0) return {value: 1, year}
  let temp = 1 + rateInVal
  temp = Math.pow(temp, year.value)
  return {value: temp, year}
}