import * as moment from "moment";
import { ConvertedCostRevenue } from "../../types";
import { totalNonRecoverable, totalRecoverable } from "../../utils";
import { EffectiveRentUtil } from "../headline-rent";
import { ModelAssumptionsUtil } from "./model-assumptions-util";
import { basisMultiplier, miniFs, round, rounddown4, yieldPurchaseOnPerpetuityQuarterly, yieldPurchaseUntilReviewAnnually, yieldPurchaseUntilReviewQuarterly } from "./utils";
import { RentReviewDetailType, RentReviewTypes } from "src/app/core/v2/types";
import { ValueBreakdownComponent } from "src/app/views/pages/dashboard/components/value-breakdown/value-breakdown.component";

export function calculateLeaseholdDualRateWithASFUnderMRDualRateAndAllowanceForTaxParnellApproach(
  costRevenue: ConvertedCostRevenue,
  subCostRevenue: ConvertedCostRevenue,
  valuationDate: Date,
  {
    activeBreakOffOption, 
    allRiskYield, 
    annualSinkingFund, 
    taxRate, 
    timingId,
    subActiveBreakOffOption,
    marketRent,
    subGrowthRate,
    subEstimatedVoidPeriod,
  }: {
    activeBreakOffOption: boolean,
    allRiskYield: number,
    annualSinkingFund: number,
    taxRate: number,
    timingId: number,
    subActiveBreakOffOption: boolean,
    marketRent: number,
    subGrowthRate: number,
    subEstimatedVoidPeriod: number
  }
) {
  const _valuationDate = valuationDate ? moment(valuationDate) : null
  const unexpiredLeaseTerm = _valuationDate ? ModelAssumptionsUtil.unexpiredLeaseTerm(costRevenue, _valuationDate) : null
  const yearsToReview = _valuationDate ? ModelAssumptionsUtil.yearsToReview(costRevenue, _valuationDate) : null
  const yearsToWriteOff = _valuationDate ? ModelAssumptionsUtil.yearsToWriteOff(costRevenue, _valuationDate) : null
  const yearsToBreakOff = _valuationDate ? ModelAssumptionsUtil.yearsToBreakOff(costRevenue, _valuationDate) : null

  const subUnexpiredLeaseTerm = _valuationDate && subCostRevenue ? ModelAssumptionsUtil.unexpiredLeaseTerm(subCostRevenue, _valuationDate) : null
  const subYearsToReview = _valuationDate && subCostRevenue ? ModelAssumptionsUtil.yearsToReview(subCostRevenue, _valuationDate) : null
  const subYearsToWriteOff = _valuationDate && subCostRevenue ? ModelAssumptionsUtil.yearsToWriteOff(subCostRevenue, _valuationDate) : null
  const subYearsToBreakOff = _valuationDate && subCostRevenue ? ModelAssumptionsUtil.yearsToBreakOff(subCostRevenue, _valuationDate) : null
  
  const _basisMultiplier = basisMultiplier(costRevenue.rentBasis)
  const headEffectiveRent = EffectiveRentUtil.getValueFromConvertedCostRevenue(costRevenue)
  const headTotalRecoverable = totalRecoverable(costRevenue) * _basisMultiplier
  const headTotalNonRecoverable = totalNonRecoverable(costRevenue) * _basisMultiplier
  const passingRent = headEffectiveRent + headTotalRecoverable - headTotalNonRecoverable

  const _subBasisMultiplier = subCostRevenue ? basisMultiplier(subCostRevenue.rentBasis) : undefined
  const subEffectiveRent = subCostRevenue ? EffectiveRentUtil.getValueFromConvertedCostRevenue(subCostRevenue) : undefined
  const subTotalRecoverable = subCostRevenue ? totalRecoverable(subCostRevenue) * _subBasisMultiplier : undefined
  const subTotalNonRecoverable = subCostRevenue ? totalNonRecoverable(subCostRevenue) * _subBasisMultiplier : undefined
  const profitRent = subEffectiveRent + subTotalRecoverable - subTotalNonRecoverable - passingRent

  const subMinYearWithUE = miniFs(
    subActiveBreakOffOption
      ? {'Sub Lease Unexpired Lease term': subUnexpiredLeaseTerm, 'Sub Lease Years to Review': subYearsToReview, 'Sub Lease Years to Write off': subYearsToWriteOff, 'Sub Lease Years to Break off': subYearsToBreakOff}
      : {'Sub Lease Unexpired Lease term': subUnexpiredLeaseTerm,'Sub Lease Years to Review': subYearsToReview, 'Sub Lease Years to Write off': subYearsToWriteOff}
  )

  const _yieldPurchaseUntilReview = timingId == 1 
    ? yieldPurchaseUntilReviewAnnually(allRiskYield, subMinYearWithUE.value)
    : timingId == 2
      ? yieldPurchaseUntilReviewQuarterly(allRiskYield, subMinYearWithUE.value)
      : undefined

  const capitalValueOfProfitRentOf1stTerm = profitRent * _yieldPurchaseUntilReview

  const subMinYear = miniFs(
    subActiveBreakOffOption
      ? {'Years to Review': subYearsToReview, 'Years to Write off': subYearsToWriteOff, 'Years to Break off': subYearsToBreakOff}
      : {'Years to Review': subYearsToReview, 'Years to Write off': subYearsToWriteOff}
  )
  const _subLeaseReversion = subCostRevenue 
    ? subLeaseReversion(
      marketRent,
      subEffectiveRent,
      subGrowthRate,
      subActiveBreakOffOption,
      subEstimatedVoidPeriod,
      subMinYear,
      subUnexpiredLeaseTerm,
      subYearsToBreakOff,
      subCostRevenue.rentReviewType ? subCostRevenue.rentReviewType.id : undefined,
      subCostRevenue.rentReviewDetailType,
      subCostRevenue.rentReviewDetail
    )
    : {value: undefined}
  const _profitRent2ndTerm = subCostRevenue 
    ? profitRent2ndTerm(
      subCostRevenue.rentReviewType ? subCostRevenue.rentReviewType.id : undefined,
      _subLeaseReversion.value,
      passingRent,
      subTotalRecoverable,
      subTotalNonRecoverable,
      subEffectiveRent
    )
    : {value: undefined}

  const minYearWithUE = miniFs(
    activeBreakOffOption
      ? {'Head Lease Unexpired Lease term': unexpiredLeaseTerm, 'Head Lease Years to Review': yearsToReview, 'Head Lease Years to Write off': yearsToWriteOff, 'Head Lease Years to Break off': yearsToBreakOff}
      : {'Head Lease Unexpired Lease term': unexpiredLeaseTerm, 'Head Lease Years to Review': yearsToReview, 'Head Lease Years to Write off': yearsToWriteOff}
  )
  const yp2ndTerm = timingId == 1
    ? yieldPurchaseUntilReviewAnnually(allRiskYield, minYearWithUE.value - subMinYearWithUE.value)
    : timingId == 2
      ? yieldPurchaseUntilReviewQuarterly(allRiskYield, minYearWithUE.value - subMinYearWithUE.value)
      : undefined

  const _presentValue = presentValue(
    allRiskYield,
    subActiveBreakOffOption,
    subYearsToBreakOff,
    subEstimatedVoidPeriod,
    subMinYearWithUE,
    subUnexpiredLeaseTerm
  )
  const capitalValueOfProfitRentOf2ndTerm = _profitRent2ndTerm.value * yp2ndTerm * _presentValue.value
  const _ypDualRateToYpSingleRate = ypDualRateToYpSingleRate(
    allRiskYield,
    annualSinkingFund,
    taxRate,
    timingId,
    minYearWithUE,
    subMinYearWithUE
  )
  const value = capitalValueOfProfitRentOf1stTerm + capitalValueOfProfitRentOf2ndTerm * _ypDualRateToYpSingleRate.value

  return {
    headEffectiveRent,
    basisMultiplier: _basisMultiplier,
    headRecoverable: {
      total: headTotalRecoverable,
      recoverablePropertyManagement: costRevenue.recoverablePropertyManagement * _basisMultiplier,
      recoverableLeasingExpenses: costRevenue.recoverableLeasingExpenses * _basisMultiplier, 
      recoverableUtilities: costRevenue.recoverableUtilities * _basisMultiplier,
      recoverableMaintenance: costRevenue.recoverableMaintenance * _basisMultiplier,
      recoverableInsurance: costRevenue.recoverableInsurance * _basisMultiplier,
      recoverableJanitorial: costRevenue.recoverableJanitorial * _basisMultiplier,
      recoverablePropertyTaxes: costRevenue.recoverablePropertyTaxes * _basisMultiplier,
      recoverableBusiness: costRevenue.recoverableBusiness * _basisMultiplier,
      recoverableOthers: costRevenue.recoverableOthers * _basisMultiplier,
    },
    headNonRecoverable: {
      total: headTotalNonRecoverable,
      nonRecoverablePropertyManagement: costRevenue.nonRecoverablePropertyManagement * _basisMultiplier,
      nonRecoverableLeasingExpenses: costRevenue.nonRecoverableLeasingExpenses * _basisMultiplier, 
      nonRecoverableUtilities: costRevenue.nonRecoverableUtilities * _basisMultiplier,
      nonRecoverableMaintenance: costRevenue.nonRecoverableMaintenance * _basisMultiplier,
      nonRecoverableInsurance: costRevenue.nonRecoverableInsurance * _basisMultiplier,
      nonRecoverableJanitorial: costRevenue.nonRecoverableJanitorial * _basisMultiplier,
      nonRecoverablePropertyTaxes: costRevenue.nonRecoverablePropertyTaxes * _basisMultiplier,
      nonRecoverableBusiness: costRevenue.nonRecoverableBusiness * _basisMultiplier,
      nonRecoverableOthers: costRevenue.nonRecoverableOthers * _basisMultiplier,
    },
    passingRent,
    subEffectiveRent,
    subBasisMultiplier: _subBasisMultiplier,
    subRecoverable: {
      total: subTotalRecoverable,
      recoverablePropertyManagement: subCostRevenue ? subCostRevenue.recoverablePropertyManagement * _subBasisMultiplier : undefined,
      recoverableLeasingExpenses: subCostRevenue ? subCostRevenue.recoverableLeasingExpenses * _subBasisMultiplier : undefined, 
      recoverableUtilities: subCostRevenue ? subCostRevenue.recoverableUtilities * _subBasisMultiplier : undefined,
      recoverableMaintenance: subCostRevenue ? subCostRevenue.recoverableMaintenance * _subBasisMultiplier : undefined,
      recoverableInsurance: subCostRevenue ? subCostRevenue.recoverableInsurance * _subBasisMultiplier : undefined,
      recoverableJanitorial: subCostRevenue ? subCostRevenue.recoverableJanitorial * _subBasisMultiplier : undefined,
      recoverablePropertyTaxes: subCostRevenue ? subCostRevenue.recoverablePropertyTaxes * _subBasisMultiplier : undefined,
      recoverableBusiness: subCostRevenue ? subCostRevenue.recoverableBusiness * _subBasisMultiplier : undefined,
      recoverableOthers: subCostRevenue ? subCostRevenue.recoverableOthers * _subBasisMultiplier : undefined,
    },
    subNonRecoverable: {
      total: subTotalNonRecoverable,
      nonRecoverablePropertyManagement: subCostRevenue ? subCostRevenue.nonRecoverablePropertyManagement * _subBasisMultiplier : undefined,
      nonRecoverableLeasingExpenses: subCostRevenue ? subCostRevenue.nonRecoverableLeasingExpenses * _subBasisMultiplier : undefined, 
      nonRecoverableUtilities: subCostRevenue ? subCostRevenue.nonRecoverableUtilities * _subBasisMultiplier : undefined,
      nonRecoverableMaintenance: subCostRevenue ? subCostRevenue.nonRecoverableMaintenance * _subBasisMultiplier : undefined,
      nonRecoverableInsurance: subCostRevenue ? subCostRevenue.nonRecoverableInsurance * _subBasisMultiplier : undefined,
      nonRecoverableJanitorial: subCostRevenue ? subCostRevenue.nonRecoverableJanitorial * _subBasisMultiplier : undefined,
      nonRecoverablePropertyTaxes: subCostRevenue ? subCostRevenue.nonRecoverablePropertyTaxes * _subBasisMultiplier : undefined,
      nonRecoverableBusiness: subCostRevenue ? subCostRevenue.nonRecoverableBusiness * _subBasisMultiplier : undefined,
      nonRecoverableOthers: subCostRevenue ? subCostRevenue.nonRecoverableOthers * _subBasisMultiplier : undefined,
    },
    profitRent,
    yieldPurchaseUntilReview: {
      value: _yieldPurchaseUntilReview,
      year: subMinYearWithUE
    },
    capitalValueOfProfitRentOf1stTerm,
    subLeaseReversion: _subLeaseReversion,
    profitRent2ndTerm: _profitRent2ndTerm,
    yieldPurchase2ndTerm: {
      value: yp2ndTerm,
      headYear: minYearWithUE,
      subYear: subMinYearWithUE,
      year: minYearWithUE.value - subMinYearWithUE.value
    },
    presentValue: _presentValue,
    capitalValueOfProfitRentOf2ndTerm,
    ypDualRateToYpSingleRate: _ypDualRateToYpSingleRate,
    
    value
  }
}

function presentValue(
  allRiskYield: number,
  activeBreakOption: boolean,
  yearsToBreakOff: number,
  estimatedVoidPeriod: number,
  minYear: {key: string ,value: number},
  unexpiredLeaseTerm: Number
) {
  const ary = allRiskYield / 100
  const year = activeBreakOption
    ? {
      value: yearsToBreakOff + estimatedVoidPeriod,
      firstYear: {
        name: 'Sub lease Break option after',
        value: yearsToBreakOff
      },
      secondYear: {
        name: 'Estimated Void period',
        value: estimatedVoidPeriod
      }
    }
    : minYear.value == unexpiredLeaseTerm
      ? {
        value: minYear.value + estimatedVoidPeriod,
        firstYear: {
          name: minYear.key,
          value: minYear.value
        },
        secondYear: {
          name: 'Estimated Void period',
          value: estimatedVoidPeriod
        }
      }
      : {
        value: minYear.value,
        firstYear: {
          name: minYear.key,
          value: minYear.value
        }
      }

    const value = Math.pow(1 + ary, -year.value)
    return {
      value: rounddown4(value),
      year
    }
}

function subLeaseReversion(
  marketRent: number,
  effectiveRent: number,
  growthRate: number,
  activeBreakOffOption: boolean,
  estimatedVoidPeriod: number,
  minYear: {
    key: string,
    value: number
  },
  unexpiredLeaseTerm: number,
  yearsToBreakOff: number,
  rentReviewTypeId: number,
  rentReviewDetailType: RentReviewDetailType,
  rentReviewDetail: number
) {
  const growthRateInVal = growthRate / 100
  const year = activeBreakOffOption
    ? {
        value: yearsToBreakOff + estimatedVoidPeriod, 
        firstYear: {
          name: 'Break option after',
          value: yearsToBreakOff
        },
        secondYear: {
          name: 'Estimated Void period',
          value: estimatedVoidPeriod
        }
      }
    : minYear.value == unexpiredLeaseTerm 
      ? {
          value: minYear.value + estimatedVoidPeriod,
          firstYear: {
            name: minYear.key,
            value: minYear.value
          },
          secondYear: {
            name: 'Estimated Void period',
            value: estimatedVoidPeriod
          }
        }
      : {
        value: minYear.value,
        firstYear: {
          name: minYear.key,
          value: minYear.value
        }
      }
  if (marketRent > effectiveRent && rentReviewTypeId == RentReviewTypes.UpwardsOnlyMarketRent) {
    return {
      value: marketRent * Math.pow((1 + growthRateInVal), year.value),
      year
    }
  }
  if (rentReviewTypeId == RentReviewTypes.UpwardsDownwardsMarketRent) {
    return {
      value: marketRent * Math.pow((1 + growthRateInVal), year.value),
      year
    }
  }
  if (rentReviewTypeId == RentReviewTypes.RPI) {
    return {
      value: effectiveRent * Math.pow((1 + growthRateInVal), year.value),
      year
    }
  }
  if (rentReviewTypeId == RentReviewTypes.CPI) {
    return {
      value: effectiveRent * Math.pow((1 + growthRateInVal), year.value),
      year
    }
  }
  if (rentReviewTypeId == RentReviewTypes.FixedRentIncrease) {
    if (rentReviewDetailType === 'percentage') {
      return {
        value:effectiveRent + (effectiveRent * rentReviewDetail / 100)
      }
    }
    if (rentReviewDetailType === 'number') {
      return {
        value: effectiveRent + rentReviewDetail
      }
    }
    return {
      value: undefined
    }
  }

  return {
    value: effectiveRent
  }
}

function ypDualRateToYpSingleRate(
  allRiskYield: number,
  annualSinkingFund: number,
  taxRate: number,
  timingId: number,
  minYear: {key: string, value: number},
  subMinYear: {key: string, value: number}
) {
  const ary = allRiskYield / 100
  const asf = annualSinkingFund / 100

  const aryV = timingId == 1
    ? ary
    : timingId == 2
      ? (4 * (1 - Math.pow(1 + ary, -0.25)))
      : undefined
  const asfV = timingId == 1
    ? asf
    : timingId == 2
      ? (4 * (1 - Math.pow(1 + asf, -0.25)))
      : undefined

  const year = minYear.value - subMinYear.value
  const tax = 100 / (100 - taxRate)

  const asfPoweredToYear = Math.pow(1 + asf, year)
  const aryPoweredToYear = Math.pow(1 + ary, -year)

  const denom1 = aryV + (asfV/(asfPoweredToYear - 1)) * tax
  const valueTop = 1 / denom1
  const valueBottom = (1 - aryPoweredToYear) / aryV

  const value = valueTop / valueBottom

  return {
    value: rounddown4(value),
    headYear: minYear,
    subYear: subMinYear,
    year
  }
}

function profitRent2ndTerm(
  rentReviewTypeId: number,
  subLeaseReversion: number,
  passingRent: number,
  subLeaseTotalRecoverable: number,
  subLeaseTotalNonRecoverable: number,
  subLeaseEffectiveRent: number
): {value: number} {
  if (
    rentReviewTypeId === RentReviewTypes.FixedRentIncrease ||
    rentReviewTypeId === RentReviewTypes.CPI ||
    rentReviewTypeId === RentReviewTypes.RPI ||
    (rentReviewTypeId === RentReviewTypes.UpwardsOnlyMarketRent && subLeaseReversion === subLeaseEffectiveRent) ||
    rentReviewTypeId === undefined
  ) {
    return {value: subLeaseReversion - passingRent + subLeaseTotalRecoverable - subLeaseTotalNonRecoverable}
  }
  return {
    value: subLeaseReversion - passingRent
  }
}