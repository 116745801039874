<!-- begin:: Footer -->
<div class="footer footer-fixed bg-white py-4 d-flex flex-lg-column " id="kt_footer" [ngClass]="footerClasses">
    <div [ngClass]="footerContainerClasses" class=" d-flex flex-column flex-md-row align-items-center justify-content-between">
        <!--begin::Copyright-->
        <div class="text-dark order-2 order-md-1">
            {{today | date:'yyyy'}}&nbsp;&copy;&nbsp;
            <a href="https://www.interval-soft.com/" target="_blank" class="text-dark">Interval</a>&nbsp;
            <!-- <span> v{{version}} </span>&nbsp;&nbsp;&nbsp; -->
            <!-- <a href="https://github.com/revdev2018/InterVal/blob/main/CHANGELOG.md" target="_blank" class="text-dark">Changelog</a> -->
        </div>
        <!--end::Copyright-->

        <!--begin::Nav-->
        <div class="nav nav-dark order-1 order-md-2">
            <!--<a href="https://www.interval-soft.com/" target="_blank" class="kt-footer__menu-link kt-link">About</a>-->
            <a href="https://www.interval-soft.com/interval-license-agreement" target="_blank" class="kt-footer-menu-link nav-link">Terms and Conditions</a>
            <!-- <a href="https://www.interval-soft.com/" target="_blank" class="kt-footer-menu-link nav-link">Contact</a> -->
        </div>
    </div>
</div>
<!-- end:: Footer -->



