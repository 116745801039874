// NGRX
import {Action} from '@ngrx/store';
import {Update} from '@ngrx/entity';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Models
import {Client} from '../_models/client.model';
import {ClientContactModel} from '../';

export enum ClientActionTypes {

    AllClientsRequested = '[Clients Home Page] All Clients Requested',
    AllClientsRequestedMultimedia = '[Clients Home Page] All Clients Requested Multimedia',
    AllClientsLoaded = '[Clients API] All Clients Loaded',

    ClientOnServerCreated = '[Edit Client Dialog] Client On Server Created',
    ClientCreated = '[Edit Clients Dialog] Clients Created',
    ClientUpdated = '[Edit Client Dialog] Client Updated',
    ClientDeleted = '[Clients List Page] Client Deleted',

    ClientOnServerRestored = '[Client Trash] Client On Server Restored',
    ClientRestored = '[Client Trash] Client Restored',
    ClientOnServerAdminRestored = '[Client Admin Trash] Client On Server Restored',
    ClientAdminRestored = '[Client Trash] Client Restored from Admin trash',

    ClientDeletedFromTrash = '[Client Trash] Client deleted',
    ClientDeletedFromAdminTrash = '[Client Admin Trash] Client deleted',

    ClientTrash = 'Client Trashed',
    ClientTrashFlushed = 'Client Trash Flushed',

    ClientsPageRequested = '[Clients List Page] Clients Page Requested',
    ClientsPageLoaded = '[Clients API] Clients Page Loaded',
    ClientsPageCancelled = '[Clients API] Clients Page Cancelled',

    ClientsPageToggleLoading = '[Clients page] Clients Page Toggle Loading',
    ClientsActionToggleLoading = '[Clients] Clients Action Toggle Loading'
}

export class ClientOnServerCreated implements Action {
    readonly type = ClientActionTypes.ClientOnServerCreated;

    constructor(public payload: { client: Client, contacts: ClientContactModel[] }) {
    }
}

export class ClientCreated implements Action {
    readonly type = ClientActionTypes.ClientCreated;

    constructor(public payload: { client: Client }) {
    }
}

export class ClientUpdated implements Action {
    readonly type = ClientActionTypes.ClientUpdated;

    constructor(public payload: {
        partialClient: Update<Client>,
        client: Client
    }) {
    }
}

export class ClientDeleted implements Action {
    readonly type = ClientActionTypes.ClientDeleted;

    constructor(public payload: { id: number }) {
    }
}

export class ClientTrashFlushed implements Action {
    readonly type = ClientActionTypes.ClientTrashFlushed;

    constructor() {
    }
}

export class ClientsPageRequested implements Action {
    readonly type = ClientActionTypes.ClientsPageRequested;

    constructor(public payload: { page: QueryParamsModel }) {
    }
}

export class ClientsPageLoaded implements Action {
    readonly type = ClientActionTypes.ClientsPageLoaded;

    constructor(public payload: { clients: Client[], totalCount: number, page: QueryParamsModel, totalTrashed: number, totalAdminTrashed: number }) {
    }
}

export class ClientsPageCancelled implements Action {
    readonly type = ClientActionTypes.ClientsPageCancelled;
}

export class AllClientsRequested implements Action {
    readonly type = ClientActionTypes.AllClientsRequested;
}

export class AllClientsRequestedMultimedia implements Action {
    readonly type = ClientActionTypes.AllClientsRequestedMultimedia;
}

export class AllClientsLoaded implements Action {
    readonly type = ClientActionTypes.AllClientsLoaded;

    constructor(public payload: { clients: Client[] }) {
    }
}

export class ClientsPageToggleLoading implements Action {
    readonly type = ClientActionTypes.ClientsPageToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ClientsActionToggleLoading implements Action {
    readonly type = ClientActionTypes.ClientsActionToggleLoading;

    constructor(public payload: { isLoading: boolean }) {
    }
}

// Client Trash

export class ClientTrashRequested implements Action {
    readonly type = ClientActionTypes.ClientTrash;

    constructor(public payload: { isLoading: boolean }) {
    }
}

export class ClientOnServerRestored implements Action {
    readonly type = ClientActionTypes.ClientOnServerRestored;

    constructor(public payload: { clientId: number }) {
    }
}

export class ClientRestored implements Action {
    readonly type = ClientActionTypes.ClientRestored;

    constructor(public payload: { client: Client }) {
    }
}


export class ClientOnServerAdminRestored implements Action {
    readonly type = ClientActionTypes.ClientOnServerAdminRestored;

    constructor(public payload: { clientId: number }) {
    }
}

export class ClientAdminRestored implements Action {
    readonly type = ClientActionTypes.ClientAdminRestored;

    constructor(public payload: { item: Client }) {
    }
}

export class ClientDeletedFromTrash implements Action {
    readonly type = ClientActionTypes.ClientDeletedFromTrash;

    constructor(public payload: { clientId: number }) {
    }
}


export class ClientDeletedFromAdminTrash implements Action {
    readonly type = ClientActionTypes.ClientDeletedFromAdminTrash;

    constructor(public payload: { id: number }) {
    }
}

export type ClientActions = ClientCreated
    | ClientUpdated
    | ClientDeleted
    | ClientsPageRequested
    | ClientsPageLoaded
    | ClientsPageCancelled
    | AllClientsLoaded
    | AllClientsRequested
    | ClientOnServerCreated
    | ClientsPageToggleLoading
    | ClientsActionToggleLoading
    | ClientOnServerRestored
    | ClientRestored
    | ClientOnServerAdminRestored
    | ClientAdminRestored
    | ClientDeletedFromAdminTrash
    | ClientDeletedFromTrash
    | ClientTrashFlushed
    | ClientTrashRequested;
