import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { SubdomainService } from "src/app/core/_base/subdomain.service";
import { MadAuthService } from "src/app/core/mad-auth/mad-auth.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AssetClassificationService {
  constructor(
    private http: HttpClient,
    private authService: MadAuthService,
    private subdomainService: SubdomainService
  ) {}

  list() {
    return this.http.get<{data: {id: number, name: string}[]}>(
      environment.baseApiUrl + `api/v2/${this.subdomainService.subDomain}/asset-classifications/list`,
      {
        headers: this.authService.getAuthHeaders()
      }
    ).pipe(
      map(response => response.data)
    )
  }
}