import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, take } from 'rxjs/operators';
import { AssetClassHouseDeleted, AssetClassHouseDeletedFromAdminTrash, AssetClassHouseDeletedFromTrash, AssetClassHouseDuplicate, AssetClassHouseOnServerAdminRestored, AssetClassHouseOnServerRestored, AssetClassHousePageRequested, AssetClassHouseTrashFlushed } from 'src/app/core/comparable/_actions/asset-class-house.actions';
import { AssetClassHouseDataSource } from 'src/app/core/comparable/_data-sources/asset-class-house.datasource';
import { AssetClassHouseModel } from 'src/app/core/comparable/_models/asset-class-house.model';
import { selectAdminTrashedAssetClassHouseCount, selectAssetClassHouseLastQuery, selectTrashedAssetClassHouseCount } from 'src/app/core/comparable/_selectors/asset-class-house.selectors';
import { AssetClassHouseService } from 'src/app/core/comparable/_services/asset-class-house.service';
import { FileUploadService } from 'src/app/core/file-upload';
import { User, currentUser, hasPermission } from 'src/app/core/mad-auth/mad-auth.store';
import { AppState } from 'src/app/core/reducers';
import { LayoutUtilsService, QueryParamsModel } from 'src/app/core/_base/crud';
import { madCalculator } from 'src/app/core/_base/crud/utils/map-marker.util';
import { emptyFilter, FilterModel, FilterChange } from '../../../shared_components/filter-section/filter-section.component';
import { MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput } from '../../../shared_components/map-marker-info-window/map-marker-info-window.component';
import { ToMarkerItemConverterService } from '../../../shared_components/map-marker-info-window/to-marker-item-converter-service.service';
import { MarkerInfoDialogComponent } from '../../_sub/map-info-dialog/marker-info-dialog.component';
import { HouseFilterDialogComponent } from '../_sub/house-filter-dialog/house-filter-dialog.component';
import { environment } from 'src/environments/environment';
import { MapsService } from 'src/app/core/map/maps.service';
import {AgmMap, MapsAPILoader} from '@agm/core';

@Component({
  selector: 'kt-house-map',
  templateUrl: './house-map.component.html',
  styleUrls: ['./house-map.component.scss']
})
export class HouseMapComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('sort', {static: true}) sort: MatSort;
  @ViewChild('fileInput') fileInput;

  @ViewChild('search', {static: false})
  public searchElementRef: ElementRef;
  private geoCoder = new google.maps.Geocoder();

  calculator = madCalculator;
  dataSource: AssetClassHouseDataSource;
  displayedColumns = ['id', 'sub_type', 'size', 'consideration', 'source_date', 'actions'];

  filterCnt: number = 0;
  filterModel: FilterModel = emptyFilter();
  filter$: BehaviorSubject<FilterModel> = new BehaviorSubject(this.filterModel);
  filterObs$ = this.filter$.asObservable();

  lastQueryParams: QueryParamsModel;

  markerData = new Map();
  markerData$ = new BehaviorSubject<any[]>([]);
  private _centerLat$ = new BehaviorSubject<number>(null)
  centerLat$ = combineLatest([
    this._centerLat$,
    this.mapsService.latitude$
  ]).pipe(map(([initial, def]) => {
    return initial == null ? def : initial
  }))
  private _centerLng$ = new BehaviorSubject<number>(null)
  centerLng$ = combineLatest([
    this._centerLng$,
    this.mapsService.longitude$
  ]).pipe(map(([initial, def]) => {
    return initial == null ? def : initial
  }))
  currentCenter: { lat: 0, lng: 0 };
  map: any;
  zoom = 15;
  isFirst = true;
  latLngSW: google.maps.LatLng;
  latLngNE: google.maps.LatLng;
  _north = new BehaviorSubject<number>(47.92399006317647);
  _south = new BehaviorSubject<number>(47.9096089375195);
  _east = new BehaviorSubject<number>(106.9476215928344);
  _west = new BehaviorSubject<number>(106.8833344071655);
  private modal;
  previous;
  selectedId: string;
  icon = {
      url: './assets/media/icons/my_marker.svg'
  };

  private _subscriptions: Subscription[] = [];

  fileType = 'application/json';
  trashCnt = 0;
  adminTrashCnt = 0;
  menuItems = [
    {
      title: 'Import',
      icon: 'flaticon2-add',
      refId: 0,
      disabled: false
    },
    {
      title: 'Download Template',
      icon: 'flaticon2-download',
      refId: 1,
      disabled: false
    },
    {
      title: 'Export',
      icon: 'flaticon2-box-1',
      refId: 2,
      disabled: true
    }
  ];
  menuSubject = new BehaviorSubject<number>(-1); 
  canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))
  currentUser: User|null

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private ngZone: NgZone,
    private toMarkerItemService: ToMarkerItemConverterService,
    private activatedRoute: ActivatedRoute,
    private layoutService: LayoutUtilsService,
    private service: AssetClassHouseService,
    private fileUploadService: FileUploadService,
    public dialog: MatDialog,
    private mapsService: MapsService,
    private mapsAPILoader: MapsAPILoader,
  ) { }

  ngOnInit(): void {
    const userSub = this.store.select(currentUser).subscribe(user => this.currentUser = user)
    this.dataSource = new AssetClassHouseDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
      distinctUntilChanged()
    ).subscribe(res => {
      this.markerData.clear();
      res.forEach(el => {
        const key = el.locationData.latitude + '_' + el.locationData.longitude;
        if (this.markerData.has(key)) {
          const tmp = this.markerData.get(key);
          tmp.title = tmp.title + '/' + el.ref_num;
          tmp.data.push(el);
          this.markerData.set(key, tmp);
        } else {
          this.markerData.set(key, {
            data: [el], 
            title: '' + el.ref_num, 
            lat: el.locationData.latitude, 
            lng: el.locationData.longitude,
            label: {
              color: '#000000',
              fontFamily: '',
              fontSize: '14px',
              fontWeight: 'bold',
              className: 'bg-op',
              text: '' + el.ref_num,
            }
          });
        }
      })
      this.markerData$.next(Array.from(this.markerData.values()));
    }) 
    this._subscriptions.push(entitiesSubscription);

    this.lastQueryParams = new QueryParamsModel({});
    const lastQuerySubscription = this.store.pipe(
        select(selectAssetClassHouseLastQuery),
        take(1)
    ).subscribe(res => {
        if (res.isEmpty()) {
            return;
        }
        this.lastQueryParams = res;
        this._updateFilter(res.filter)
    });
    this._subscriptions.push(lastQuerySubscription);

    const menuSubjectSubscribe = this.menuSubject.asObservable().subscribe(refId => {
      switch (refId) {
        case 0:
          this.addFiles();
          break;
        case 1:
          this.downloadJsonTemplate();
          break;
        case 2:
          // TODO: export comparables
          break;
        default:
          break;
      }
    });
    this._subscriptions.push(menuSubjectSubscribe);

    const trashSubscription = this.store.pipe(
        select(selectTrashedAssetClassHouseCount)
    ).subscribe(res => {
        this.trashCnt = res;
    });
    this._subscriptions.push(trashSubscription);

    const adminTrashSubscription = this.store.pipe(
        select(selectAdminTrashedAssetClassHouseCount)
    ).subscribe(res => {
        this.adminTrashCnt = res;
    });
    this._subscriptions.push(adminTrashSubscription);
  }

  ngOnDestroy(): void {
    this._subscriptions.forEach(s => s.unsubscribe());
  }

  onFilterChange(event: FilterChange) {
      if (event.page != null) {
          this.paginator.pageIndex = event.page
      }
      this.filterModel = event.filter;
      this._centerLat$.next(event.filter.centerLat);
      this._centerLng$.next(event.filter.centerLng);
      this._loadList();
  }

  // Map Related
  mapReady(event: any) {

    // agmMap
    this.map = event;
    this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));

    this.map.addListener('dragend', () => {
        this._centerLat$.next(this.currentCenter.lat);
        this._centerLng$.next(this.currentCenter.lng);

        // this.mapAction.next(true);
        // this.paginator.pageIndex = 0;
        this._loadList();
    });

    this.mapsAPILoader.load().then(() => {
        this.geoCoder = new google.maps.Geocoder();
        if (this.searchElementRef) {
        const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            types: ['(cities)']
        });
        autocomplete.addListener('place_changed', () => {
            this.ngZone.run(() => {
            // get the place result
            const place: google.maps.places.PlaceResult = autocomplete.getPlace();
            // verify result
            if (place.geometry === undefined || place.geometry === null) {
                return;
            }

            // set latitude, longitude and zoom
            this._centerLat$.next(place.geometry.location.lat())
            this._centerLng$.next(place.geometry.location.lng())
            this.zoom = 15;
            });
        });
        }
    });
  }
  centerChange($event: any) {
    if ($event) {
        this.currentCenter = {lat: $event.lat, lng: $event.lng};
    }
  }

  mapClick(event: any) {
    if (this.modal) {
      this.modal.close();
    }

    this.modal = null;
  }
  zoomChanged($event) {
    this.isFirst = true;
  }

  boundsChanged(_latLngBound) {
    this.latLngNE = _latLngBound.getNorthEast();
    this.latLngSW = _latLngBound.getSouthWest();

    const xPadding = (this.latLngNE.lat() - this.latLngSW.lat()) * 0.08;
    this._north.next(this.latLngNE.lat() - xPadding);
    this._south.next(this.latLngSW.lat() + xPadding);

    this._east.next(this.latLngNE.lng() - xPadding);
    this._west.next(this.latLngSW.lng() + xPadding);
    const _new = Object.assign({}, this.filterModel) as FilterModel;
    if (_new.bounds == null) {
        _new.bounds = {ne_lat: 0, sw_lat: 0, ne_lng: 0, sw_lng: 0}
    } else {
        _new.bounds = Object.assign({}, _new.bounds);
    }
    _new.bounds.sw_lat = _latLngBound.getSouthWest().lat();
    _new.bounds.ne_lat = _latLngBound.getNorthEast().lat();
    _new.bounds.sw_lng = _latLngBound.getSouthWest().lng();
    _new.bounds.ne_lng = _latLngBound.getNorthEast().lng();

    this.filterModel = _new;

    if (this.isFirst) {
        if (this.modal) {
            this.modal.close();
        }
        this.previous = null;
        this.isFirst = !this.isFirst;
    }
    this._loadList();
  }

    showInfo(infoData) {
      const data = this.toMarkerItemService.convert('comparable', infoData.map(id => ({...id, ac_type: 17})));
      this.dialog.open<MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput>(MapMarkerInfoWindowComponent, {
          data: {
              modal_title: data.modal_title,
              entities: data.entities
          }
      });
    }

    setLocation() {
      this.setCurrentLocation();
    }

    // Get Current Location Coordinates
    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position.coords.latitude && position.coords.longitude) {
                    this._centerLat$.next(position.coords.latitude);
                    this._centerLng$.next(position.coords.longitude);
                    this.zoom = 15;
                }
            });
        } else {
            alert('Position not available');
        }
    }
    getLabel(title) {
      return {
        color: '#000000',
        fontFamily: '',
        fontSize: '14px',
        fontWeight: 'bold',
        className: 'bg-op',
        text: title,
      };
    }


  // Comparable related
  addComparable() {
    this.editComparable(null);
  }
  editComparable(ac: AssetClassHouseModel) {
    if (ac) {
      this.router.navigate(['../edit', ac.id], {relativeTo: this.activatedRoute});
    } else {
      this.router.navigate(['../add'], {relativeTo: this.activatedRoute})
    }
  }
  deleteComparable(ac: AssetClassHouseModel) {
    this.store.dispatch(new AssetClassHouseDeleted({id: ac.id}));
  }
  getItemCssClassByStatus(status: number): string {
    switch (status) {
      case 0:
        return 'warning';
      default:
        return 'success';
    }
  }
  getItemStatusString(status: number): string {
    switch (status) {
      case 0:
        return 'Draft';
      default:
        return 'Validated';
    }
  }

  goToList() {
    this.router.navigate(['../'], {relativeTo: this.activatedRoute});
  }

  private _loadList() {
    let queryParams = null;
    if (this.lastQueryParams.isEmpty()) {
        const _filter = Object.assign({}, this.filterModel) as FilterModel;

        queryParams = new QueryParamsModel(
            _filter,
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex + 1,
            this.paginator.pageSize
        );
    } else {
        queryParams = this.lastQueryParams;
    }
    this.lastQueryParams = new QueryParamsModel({});
    this.store.dispatch(new AssetClassHousePageRequested({page: queryParams}));
  }
  private _updateFilter(filter: FilterModel) {
    this.filter$.next(filter);
  }

  addFiles() {
    this.fileInput.nativeElement.click();
  }

  importComparable(event: any) {
    const file: File = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file, file.name);
    this.fileUploadService.uploadCompJson(formData).subscribe(
      (res) => {
        if (!res) {
          return;
        }
        if (res.success) {
          this._loadList();
        }
      },
      (err) => console
    );
  }
  downloadJsonTemplate() {
    this.fileUploadService.compTemplateDownload(17).subscribe(res => {
        if (res.type == 4) {
          let blob = new Blob([res.body], { type: 'application/json'});
          let url = window.URL.createObjectURL(blob);
          var anchor = document.createElement("a");
          anchor.download = "interval-comp-house-template.json";
          anchor.href = url;
          anchor.click();
        }
      },
      err => {
        alert("Problem while downloading the file.");
      }
    );
  }
  trash() {
    this.service.getTrashed().subscribe(res => {
        const items = [];
        res.data.forEach(elem => {
            items.push({
                text: `#${elem.ref_num}`,
                id: elem.id.toString(),
                date: elem.user_deleted,
                hasPermanentlyDelete: true,
                deletedUser: elem.userDeletedBy
            });
        });
        this._showTrashed(items);
    })
  }
  adminTrash() {
    this.service.getAdminTrashed().subscribe(res => {
        const items = [];
        res.data.forEach(elem => {
            items.push({
                text: `#${elem.ref_num}`,
                id: elem.id.toString(),
                date: elem.deleted_at,
                hasPermanentlyDelete: true,
                deletedUser: elem.deletedBy
            });
        });
        this._showTrashed(items, true);
    })
  }
  delete(id: number) {
      this.store.dispatch(new AssetClassHouseDeletedFromTrash({id}))
  }
  restore(id: number, isAdmin = false) {
      if (isAdmin) {
        this.store.dispatch(new AssetClassHouseOnServerAdminRestored({id: id}));
      } else {
        this.store.dispatch(new AssetClassHouseOnServerRestored({id: id}));
      }
  }
  hardDelete(id: number) {
    this.store.dispatch(new AssetClassHouseDeletedFromAdminTrash({id: id}))
  }
  flush() {
    this.store.dispatch(new AssetClassHouseTrashFlushed());
  }
  private _showTrashed(items, isAdmin = false) {
      let _title = 'Deleted House Comparables';
      if (isAdmin) {
          _title = 'Admin Deleted House Comparables';
      }
      this.layoutService.trashedEntities(_title, items, this, isAdmin);
  }

  duplicate(id: number) {
    this.store.dispatch(new AssetClassHouseDuplicate({id}))
  }
}
