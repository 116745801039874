// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap} from 'rxjs/operators';
// Services
import {CountryService} from '../_services/country.service';
// Actions
import {
    AllCountriesRequested,
    CountryActionTypes,
    CountriesActionToggleLoading,
    CountryCreated,
    CountryOnServerCreated,
    CountryUpdated,
    CountryDeleted,
    CountryDeletedFromAdminTrash,
    CountryDeletedFromTrash,
    CountryOnServerAdminRestored,
    CountryAdminRestored,
    CountryTrashFlushed,
    CountryOnServerRestored,
    CountryRestored, CountriesPageRequested, CountriesPageLoaded,
    AllCountriesRequestedMultimedia
} from '../_actions/country.actions';

import {AppState} from '../../reducers';
import {QueryParamsModel} from '../../_base/crud';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {forkJoin, of} from 'rxjs';
import {Store} from '@ngrx/store';

@Injectable()
export class CountryEffects {

    showActionLoadingDispatcher = new CountriesActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new CountriesActionToggleLoading({isLoading: false});


    @Effect()
    loadPageAgencies$ = this.actions$
        .pipe(
            ofType<CountriesPageRequested>(CountryActionTypes.CountriesPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                const requestToServer = this.service.find(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin([requestToServer, lastQuery]);
            }),
            map((response) => {

                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];

                return new CountriesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: lastQuery
                });
            })
        );


    @Effect()
    loadAllCountries$ = this.actions$
        .pipe(
            ofType<
                AllCountriesRequested>(CountryActionTypes.AllCountriesRequested),
            mergeMap(() => this.service.getAllCountries()),
            map((result: any) => {
                return new CountriesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: null
                });
            })
        );

    @Effect()
    loadAllCountriesMultimedia$ = this.actions$
        .pipe(
            ofType<
                AllCountriesRequestedMultimedia>(CountryActionTypes.AllCountriesRequestedMultimedia),
            mergeMap(() => this.service.getAllCountriesMultimedia()),
            map((result: any) => {
                return new CountriesPageLoaded({
                    items: result.data,
                    totalCount: result.pagination.total,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                    page: null
                });
            })
        );

    @Effect()
    deleteCountry$ = this.actions$
        .pipe(
            ofType<CountryDeleted>(CountryActionTypes.CountryDeleted),
            mergeMap(({payload}) => {
                    return this.service.deleteCountry(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateCountry$ = this.actions$
        .pipe(
            ofType<CountryUpdated>(CountryActionTypes.CountryUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateCountry(payload.item);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createCountry$ = this.actions$
        .pipe(
            ofType<CountryOnServerCreated>(CountryActionTypes.CountryOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createCountry(payload.item, payload.currency).pipe(
                    tap(res => {
                        this.store.dispatch(new CountryCreated({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    restoreCountry$ = this.actions$
        .pipe(
            ofType<CountryOnServerRestored>(CountryActionTypes.CountryOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CountryRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAdminCountry$ = this.actions$
        .pipe(
            ofType<CountryOnServerAdminRestored>(CountryActionTypes.CountryOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new CountryAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteFromTrashCountry$ = this.actions$
        .pipe(
            ofType<CountryDeletedFromTrash>(CountryActionTypes.CountryDeletedFromTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteFromAdminTrashCountry$ = this.actions$
        .pipe(
            ofType<CountryDeletedFromAdminTrash>(CountryActionTypes.CountryDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    @Effect()
    flushFromTrashCountry$ = this.actions$
        .pipe(
            ofType<CountryTrashFlushed>(CountryActionTypes.CountryTrashFlushed),
            mergeMap(() => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.flushTrash();
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: CountryService, private store: Store<AppState>) {
    }
}
