import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MadAuthService } from "src/app/core/mad-auth/mad-auth.service";
import { HttpUtilsService } from "src/app/core/_base/crud";
import { environment } from "src/environments/environment";
import { SubdomainService } from "src/app/core/_base/subdomain.service";

@Injectable()
export class ParticipantService {
    constructor(
        private http: HttpClient,
        private authService: MadAuthService,
        private subDomainService: SubdomainService
    ) {}

    fetch(agencyId: number, toeId: number, clientId: number): Observable<any> {
        return this.http.get(environment.baseApiUrl + `api/${this.subDomainService.subDomain}/participants`, {
            headers: this.authService.getAuthHeaders(),
            params: new HttpParams()
                .set('agencyId', agencyId.toString())
                .set('toeId', toeId.toString())
                .set('clientId', clientId ? clientId.toString() : '')
        });
    }
}