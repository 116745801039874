import { AssetClassConsiderationModel } from "../../asset_class/_models/asset-class-consideration.model";
import { AssetClassLandAreaComponentModel } from "../../asset_class/_models/asset-class-land-area-component.model";
import { AssetClassLandAreaModel } from "../../asset_class/_models/asset-class-land-area.model";
import { BaseModel } from "../../_base/crud";
import { AcSource } from "./ac-source.model";
import { AssetClassBuilding } from "./asset-class-building.model";
import { AssetClassSizeModel } from "./asset-class-size.model";
import { AssetClassTenure } from "./asset-class-tenure.model";
import { LocationData } from "./location.model";
import { EsgIndicator } from "../../v2/state/esg-indicator/type";

export class AssetClassParkingModel extends BaseModel {
    id: number;
    ref_num: string;
    status: number;

    formType: number;
    locationData: LocationData;

    add_info_on_land: boolean;
    land_parcel_name: string;
    coordinate_reference_system_id: number;
    points: any[];
    landAreaComponents: AssetClassLandAreaComponentModel[] = [];
    landArea: AssetClassLandAreaModel;

    sub_type_category_id: number;
    sub_category_id: number;
    num_of_units: number;
    parking_type_id: number;
    handover_standard_id: number;
    state_of_repair_id: number;
    floor_details: string;
    property_general_desc: string;
    automation: number;
    automation_type: string;

    cp_building_id: number;
    building_name: string;
    completion_year: string;
    is_part_of_property: boolean;
    info_about_property: string;
    building_type: number;
    building_type_comment: string;
    building_grade_id: number;
    energy_efficiency_grade_id: number;
    developer: string;
    anchor_tenant: string;
    foundation_type_id: number;
    building_description: string;
    b_pictures: any[];
    b_picture: string;

    esgIndicator: EsgIndicator

    serviceAndInfraData: {
        id: number,
        road: boolean;
        status_n_quality: string,
        access_points: string,
        length_of_road: string,
        ownership: string,

        central_heating: boolean,
        heatings: number[],
        other_heating: string,

        all_of_it: boolean,
        services: number[],
        other_service: string
    }

    ac_pictures: any[];
    picture: string;

    documents: any[];
    tenures: AssetClassTenure[];
    sizes: AssetClassSizeModel[];
    source: AcSource;

    has_parcel_consideration: boolean;
    land_comment: string;
    parcel_tenures: AssetClassConsiderationModel[];
    buildingInfo: AssetClassBuilding;
    valuation_id: number | null;

    clear() {
        this.ref_num = null;
        this.status = 0;
        this.formType = 2;
        this.locationData = {
            country_id: null,
            city_id: null,
            zip_code: null,
            longitude: null,
            latitude: null,
            time_offset: null,
            location_grade_id: null,
            address: null,
            location_surrounding: null,
            city_of_location: null
        }

        this.add_info_on_land = false;
        this.land_parcel_name = null;
        this.coordinate_reference_system_id = null;
        this.points = [];
        this.landAreaComponents = [];
        this.landArea = new AssetClassLandAreaModel();
        this.landArea.clear();

        this.sub_type_category_id = null;
        this.sub_category_id = null;
        this.num_of_units = null;
        this.parking_type_id = null;
        this.handover_standard_id = null;
        this.state_of_repair_id = null;
        this.floor_details = '';
        this.property_general_desc = null;
        this.automation = 0;
        this.automation_type = null;

        this.cp_building_id = null;
        this.building_name = null;
        this.completion_year = null;
        this.is_part_of_property = false;
        this.info_about_property = null;
        this.building_type = null;
        this.building_type_comment = null;
        this.building_grade_id = null;
        this.energy_efficiency_grade_id = null;
        this.developer = null;
        this.anchor_tenant = null;
        this.foundation_type_id = null;
        this.building_description = null;
        this.b_pictures = [];
        this.b_picture = null;

        this.serviceAndInfraData = {
            id: undefined,
            road: false,
            status_n_quality: null,
            access_points: null,
            length_of_road: null,
            ownership: null,

            central_heating: false,
            heatings: [],
            other_heating: null,

            all_of_it: false,
            services: [],
            other_service: null
        }

        this.ac_pictures = [];
        this.picture = '';

        this.documents = [];
        this.tenures = [];
        this.sizes = [];
        this.source = new AcSource();
        this.source.clear();

        this.parcel_tenures = [];
        this.has_parcel_consideration = false;
        this.land_comment = null;

        this.buildingInfo = new AssetClassBuilding();
        this.valuation_id = null;
    }
}