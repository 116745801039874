import { NgModule } from "@angular/core";
import { FormErrorComponent } from "./form-error.component";
import { PartialsModule } from "src/app/views/partials/partials.module";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    CommonModule,
    PartialsModule
  ],
  declarations: [
    FormErrorComponent
  ],
  exports: [
    FormErrorComponent
  ]
})
export class FormErrorModule {}