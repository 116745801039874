import {QueryParamsModel} from '../../_base/crud';
import {forkJoin} from 'rxjs';
// Angular
import {Injectable} from '@angular/core';
// RxJS
import {mergeMap, map, tap, switchMap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// Services
import {AssetClassResidentialsService} from '../_services/asset-class-residential.service';
// State
import {AppState} from '../../reducers';
// Actions
import {
    AssetClassResidentialActionTypes,
    AssetClassResidentialsPageRequested,
    AssetClassResidentialsPageLoaded,
    AssetClassResidentialActionToggleLoading,
    AssetClassResidentialsPageToggleLoading,
    AssetClassResidentialOnServerCreated,
    AssetClassResidentialOnServerUpdated,
    AssetClassResidentialCreated,
    AssetClassResidentialUpdated,
    AssetClassResidentialsTrashRequested,
    AssetClassResidentialRestored,
    AssetClassResidentialOnServerRestored,
    AssetClassResidentialOnServerAdminRestored,
    AssetClassResidentialAdminRestored,
    AssetClassResidentialDeletedFromTrash,
    OneAssetClassResidentialDeleted,
    ApartmentTrashFlushed,
    AssetClassResidentialGet, AssetClassMapPageRequested,
    AssetClassResidentialDeletedFromAdminTrash,
    AssetClassResidentialDuplicate
} from '../_actions/asset-class-residential.actions';
import {of} from 'rxjs';

@Injectable()
export class AssetClassResidentialEffects {
    showPageLoadingDispatcher = new AssetClassResidentialsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new AssetClassResidentialActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new AssetClassResidentialActionToggleLoading({isLoading: false});

    @Effect()
    loadAssetClassResidentialsPage$ = this.actions$.pipe(
        ofType<AssetClassResidentialsPageRequested>(AssetClassResidentialActionTypes.AssetClassResidentialsPageRequested),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.findAssetClassResidentials(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new AssetClassResidentialsPageLoaded({
                assetClassResidentials: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );


    @Effect()
    loadAssetClassMapPage$ = this.actions$.pipe(
        ofType<AssetClassMapPageRequested>(AssetClassResidentialActionTypes.AssetClassMapPageRequested),
        switchMap(({payload}) => {
            this.store.dispatch(this.showPageLoadingDispatcher);
            const requestToServer = this.service.getApartments(payload.page);
            const lastQuery = of(payload.page);
            return forkJoin(requestToServer, lastQuery);
        }),
        map(response => {
            const result: any = response[0];
            const lastQuery: QueryParamsModel = response[1];
            return new AssetClassResidentialsPageLoaded({
                assetClassResidentials: result.data,
                totalCount: result.pagination.total,
                totalTrashed: result.pagination.total_trashed,
                totalAdminTrashed: result.pagination.admin_trashed,
                page: lastQuery
            });
        })
    );

    @Effect()
    createAssetClassResidential$ = this.actions$.pipe(
        ofType<AssetClassResidentialOnServerCreated>(AssetClassResidentialActionTypes.AssetClassResidentialOnServerCreated),
        mergeMap(({payload}) => {
            this.store.dispatch(this.showActionLoadingDispatcher);
            return this.service.createAssetClassResidential(
                payload.assetClassResidential,
                payload.tenures,
                payload.sizes,
                payload.fileList,
                payload.selectedFacilities,
                payload.apartmentResidentialExternalAreas,
                payload.documents
            ).pipe(
                tap(res => {
                    this.store.dispatch(new AssetClassResidentialCreated({assetClassResidential: res.data}));
                })
            );
        }),
        map(() => {
            return this.hideActionLoadingDispatcher;
        }),
    );

    @Effect()
    updateAssetClassResidential$ = this.actions$
        .pipe(
            ofType<AssetClassResidentialOnServerUpdated>(AssetClassResidentialActionTypes.AssetClassResidentialOnServerUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateAssetClassResidential(
                    payload.assetClassResidential,
                    payload.tenures,
                    payload.sizes,
                    payload.fileList,
                    payload.selectedFacilities,
                    payload.apartmentResidentialExternalAreas,
                    payload.documents
                ).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassResidentialUpdated({assetClassResidential: res.data}));
                    })
                );
            }),
            map(() => {
                return this.showActionLoadingDispatcher;
            }),
        );

    @Effect()
    trashAssetClassResidentials$ = this.actions$
        .pipe(
            ofType<AssetClassResidentialsTrashRequested>(AssetClassResidentialActionTypes.AssetClassResidentialTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getTrashed();
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreAssetClassResidential$ = this.actions$
        .pipe(
            ofType<AssetClassResidentialOnServerRestored>(AssetClassResidentialActionTypes.AssetClassResidentialOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassResidentialRestored({assetClassResidential: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    restoreAdminAssetClassResidential$ = this.actions$
        .pipe(
            ofType<AssetClassResidentialOnServerAdminRestored>(AssetClassResidentialActionTypes.AssetClassResidentialOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.id).pipe(
                    tap(res => {
                        this.store.dispatch(new AssetClassResidentialAdminRestored({assetClassResidential: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    deleteAssetClassResidentialFromTrash$ = this.actions$
        .pipe(
            ofType<AssetClassResidentialDeletedFromTrash>(AssetClassResidentialActionTypes.AssetClassResidentialDeletedFromTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromTrash(payload.assetClassResidentialId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAssetClassResidentialFromAdminTrash$ = this.actions$
        .pipe(
            ofType<AssetClassResidentialDeletedFromAdminTrash>(AssetClassResidentialActionTypes.AssetClassResidentialDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromAdminTrash(payload.id);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteAssetClassResidential$ = this.actions$
        .pipe(
            ofType<OneAssetClassResidentialDeleted>(AssetClassResidentialActionTypes.OneAssetClassResidentialDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteAssetClassResidential(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    flushApartmentTrash$ = this.actions$
        .pipe(
            ofType<ApartmentTrashFlushed>(AssetClassResidentialActionTypes.ApartmentTrashFlushed),
            mergeMap(() => {
                    return this.service.flushTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    getAssetClassResidential$ = this.actions$
        .pipe(
            ofType<AssetClassResidentialGet>(AssetClassResidentialActionTypes.AssetClassResidentialGet),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getAssetClassResidential(payload.assetClassResidentialId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    
    @Effect()
    duplicateResidential$ = this.actions$.pipe(
        ofType<AssetClassResidentialDuplicate>(AssetClassResidentialActionTypes.AssetClassResidentialDuplicate),
        mergeMap(({payload}) => {
            return this.service.duplicate(payload.id);
        }),
        map((response) => {
           return new AssetClassResidentialCreated({assetClassResidential: response.data});
        })
    )

    constructor(private actions$: Actions,
                private service: AssetClassResidentialsService,
                private store: Store<AppState>) {
    }
}
