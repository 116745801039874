<kt-portlet>
    <kt-portlet-header [class]="'card-head-lg'" [viewLoading$]="dataSource.loading$">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="card-head-title">
                <span>{{ 'APARTMENT.MAP.TITLE.MAP_VIEW' | translate }}</span>
            </h3>
            <!-- For localisations we use @ngx-translate | See off. documentations => https://github.com/ngx-translate/core -->
            <!-- Localization libraries (en/fr) are here => '../../../.././config/i18n/en.ts|fr.ts' -->
        </ng-container>

        <ng-container ktPortletTools>
            <div>
                <input #fileInput type="file" (change)="importComparable($event)" style="display: none" multiple accept="{{fileType}}"/>
            </div>
            <!-- <kt-context-menu-mad
                [(menuItems)]="menuItems"
                [(menuSubject)]="menuSubject"
                [width]="'230'"
                menuName="Import"
                class="ml-2">
            </kt-context-menu-mad> -->
            <button mat-raised-button class="ml-2" disabled>
                <span class="mr-2">Import/Export</span>
                <span class="text-danger">(Coming Soon)</span>
            </button>
            <button (click)="addResidential()" mat-raised-button
                    class="ml-2"
                    [matTooltip]="'APARTMENT.LIST.BUTTON.NEW.TOOLTIP' | translate" color="primary" type="button">
                <span>{{ 'APARTMENT.LIST.BUTTON.NEW.LABEL' | translate }}</span>
            </button>
            <button (click)="goList()"
                    mat-raised-button
                    [matTooltip]="'APARTMENT.MAP.BUTTON.LIST_VIEW.TOOLTIP' | translate"
                    color="primary"
                    type="button"
                    class="ml-2">
                <span>{{ 'APARTMENT.MAP.BUTTON.LIST_VIEW.LABEL' | translate }}</span>
            </button>
            <button
                    (click)="trash()"
                    *ngIf="trashCnt != 0"
                    mat-raised-button
                    [matTooltip]="'APARTMENT.LIST.BUTTON.TRASHED.TOOLTIP' | translate"
                    type="button"
                    style="background-color: #c4c5d6 !important;"
                    class="ml-2">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt }})</span>
            </button>
            <button
                    (click)="adminTrash()"
                    *ngIf="adminTrashCnt != 0 && (canAccessAdminTrash$|async)"
                    mat-raised-button
                    [matTooltip]="'APARTMENT.LIST.BUTTON.ADMIN_TRASHED.TOOLTIP' | translate"
                    type="button"
                    style="background-color: #c4c5d6 !important;"
                    class="ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <div class="card-body pt-0" style="overflow-y: scroll;" >

            <div class="card-body-progress">
                <mat-spinner [diameter]="20"></mat-spinner>
            </div>

            <!-- start::FILTERS-->
            <!-- <div class="form">
                <div class="form-filtration">
                    <div class="row align-items-center active">


                        <mat-form-field class="col-md-2 mat-form-field-fluid">
                            <input matInput #searchInput [placeholder]="'GENERAL.PLACEHOLDER.SEARCH' | translate">
                        </mat-form-field>

                        <mat-form-field class="col-3 mat-form-field-fluid">
                            <input matInput type="text" (keydown.enter)="$event.preventDefault()"
                                   [placeholder]="'APARTMENT.LIST.FILTER.SEARCH_BY_CITY' | translate" autocorrect="off" autocapitalize="off"
                                   spellcheck="off" #searchLocation/>
                        </mat-form-field>
                        <div class="col-1">
                            <button (click)="showFilter()" mat-raised-button
                                    [matTooltip]="'APARTMENT.LIST.BUTTON.SHOW_FILTER.TOOLTIP' | translate" type="button"
                                    class="w-100 filter_button button-gray"
                                    [color]="filterCnt == 0 ? 'secondary' : 'primary'">
                                <span>{{ 'APARTMENT.LIST.BUTTON.SHOW_FILTER.LABEL' | translate:{
                                    cnt: filterCnt == 0 ? '' : '('+filterCnt+')'    
                                } }}</span>
                            </button>
                        </div>
                        <div class="col-1">
                            <button (click)="resetFilter()" mat-raised-button
                                    [matTooltip]="'GENERAL.TOOLTIP.RESET' | translate" type="button"
                                    class="w-100 button-gray"
                                    color="secondary">
                                <span>{{ 'GENERAL.BUTTON.RESET' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div> -->
            <kt-filter-section
                [acType]="1"
                [filter$]="filterObs$"
                (filterChange)="onFilterChange($event)"
                ></kt-filter-section>
            <!-- end::FILTERS-->

            <!-- start::MAP -->
            <div class="form-group kt-form__group row">
                <div class="col-lg-12 kt-margin-bottom-10-mobile md-2">
                    <mat-form-field class="mat-form-field-fluid">
                        <input matInput type="text" (keydown.enter)="$event.preventDefault()"
                            [placeholder]="'GENERAL.PLACEHOLDER.SEARCH_BY_CITY' | translate" autocorrect="off" autocapitalize="off"
                            spellcheck="off" #search/>
                    </mat-form-field>
                </div>
                <div class="col-lg-12 kt-margin-bottom-10-mobile md-2">
                    <agm-map
                            #AgmMap
                            [latitude]="centerLatitude$|async"
                            [longitude]="centerLongitude$|async"
                            [zoom]="zoom"
                            [disableDoubleClickZoom]="true"
                            [scrollwheel]="null"
                            [gestureHandling]="'cooperative'"
                            (mapReady)="mapReady($event)"
                            (mapClick)="mapClick($event)"
                            (centerChange)="centerChange($event)"
                            (zoomChange)="zoomChanged($event)"
                            (boundsChange)="boundsChanged($event)"
                            [clickableIcons]="false">
                        <agm-map-type-control></agm-map-type-control>

                        <button type="button" id="Profile" class="btn btn-light btn-icon mr-2 map-button" (click)="setLocation()">
                            <mat-icon>my_location</mat-icon>
                        </button>

                        <agm-marker-cluster 
                            [calculator]="calculator"
                            imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                            <!-- animation="DROP" -->
                            <ng-container *ngIf="markerData$|async as mData">
                                <agm-marker *ngFor="let key of mData.keys()"
                                        style="min-width: 200px;"
                                        [latitude]="mData.get(key).lat"
                                        [longitude]="mData.get(key).lng"
                                        [label]="getLabel(mData.get(key).title)"
                                        [iconUrl]="icon"
                                        (markerClick)="showInfo(key, mData.get(key).data)">
                                </agm-marker>
                            </ng-container>
                        </agm-marker-cluster>


                        <!--clickable-->
                        <!--east-->
                        <!--editable-->
                        <!--fillColor-->
                        <!--fillOpacity-->
                        <!--north-->
                        <!--rectangleDraggable-->
                        <!--south-->
                        <!--strokeColor-->
                        <!--strokeOpacity-->
                        <!--strokePosition-->
                        <!--strokeWeight-->
                        <!--visible-->
                        <!--west-->
                        <!--zIndex-->
                        <!--boundsChanged {"lat":47.923990063176475,"lng":106.94762159283447}-->
                        <!--apartment-map.component.ts:378 boundsChanged {"lat":47.90960893751951,"lng":106.88333440716552}-->
                        <!--<agm-rectangle-->
                        <!--[visible]="true"-->
                        <!--fillColor="#FF0000"-->
                        <!--[fillOpacity]="0.5"-->
                        <!--[north]="_north | async"-->
                        <!--[south]="_south | async"-->
                        <!--[east]="_east | async"-->
                        <!--[west]="_west | async"-->
                        <!--strokeColor="#FF0000"-->
                        <!--[strokeOpacity]="0.9"-->
                        <!--[strokeWeight]="2">-->

                        <!--</agm-rectangle>-->
                    </agm-map>
                </div>
            </div>
            <!-- end::MAP -->
            <div class="mat-table-wrapper">
                <mat-table class="lmat-elevation-z8 table-striped"
                           #table
                           [dataSource]="dataSource"
                           matSort
                           #sort1="matSort"
                           matSortActive="id"
                           matSortDirection="desc"
                           matSortDisableClear>
                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>#</mat-header-cell>
                    <mat-cell
                            *matCellDef="let acr">{{acr.ref_num}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="sub_type">
                    <mat-header-cell *matHeaderCellDef>
                        Sub-type
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        {{acr.sub_type_category_name == 'Unknown'
                            ? 'Unknown'
                            : (acr.sub_type_category_name)}} 
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="size">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APARTMENT.LIST.DATATABLE.SIZE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">{{acr.size}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="consideration">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APARTMENT.LIST.DATATABLE.CONSIDERATION' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        <div style="display: flex; flex-direction: column;" *ngIf="acr.display_tenures.length > 0 else no_data">
                            <div *ngFor="let t of acr.display_tenures">
                                <ng-container *ngIf="t.consideration_type_id === 1">
                                    {{t.total_consideration.toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{t.currency}}
                                </ng-container>
                                <ng-container *ngIf="t.consideration_type_id === 2">
                                    <ng-container *ngIf="t.rent_input_amount_type === 'total'">
                                        {{t.total_consideration.toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{t.currency}}/{{t.rent_basis}}
                                    </ng-container>
                                    <ng-container *ngIf="t.rent_input_amount_type === 'per_units'">
                                        {{(t.total_consideration * (t.rent_size ? t.rent_size.size : 0)).toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{t.currency}}/{{t.rent_basis}}
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <ng-template #no_data>
                            {{ 'No Data' }}
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="building_id">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APARTMENT.LIST.DATATABLE.BUILDING_ID' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">{{acr.building_info && acr.building_info.building_name ? acr.building_info.building_name : 'N/A'}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="source_date">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APARTMENT.LIST.DATATABLE.SOURCE_DATE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        <div style="display: flex; flex-direction: column;" *ngIf="acr.display_tenures.length > 0 else no_data">
                            <div *ngFor="let t of acr.display_tenures">
                                {{t.source.source_date | dateWithOffset:acr.locationData.time_offset}}
                            </div>
                        </div>
                        <ng-template #no_data>
                            {{ 'No Data' }}
                        </ng-template>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="used_times">
                    <mat-header-cell *matHeaderCellDef>
                        Used (times)
                    </mat-header-cell>
                    <mat-cell *matCellDef="let ac">
                        {{ac.used_times == 0
                            ? 'Never used'
                            : ('Used ' + ac.used_times + ' time' + (ac.used_times == 1 ? '' : 's'))}}
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="status">
                    <mat-header-cell *matHeaderCellDef>
                        Status
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        <b>
                            <span class="label label-inline label-rounded label-outline-{{getItemCssClassByStatus(acr.status)}} label-outline-2x label-lg"
                                style="width: 10rem;">
                                {{getItemStatusString(acr.status)}}
                            </span>
                        </b>
                    </mat-cell>
                </ng-container>


                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'APARTMENT.LIST.DATATABLE.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let acr">
                        <!-- Information Tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <p>
                                <b>Sub Category:</b> {{acr.sub_category_name}}
                            </p>
                            <p>
                                <b>Status:</b> {{getItemStatusString(acr.status)}}
                            </p>
                            <p>
                                <b>Used (times):</b>
                                {{acr.used_times == 0
                                    ? 'Never used'
                                    : ('Used ' + acr.used_times + ' time' + (acr.used_times == 1 ? '' : 's'))}}
                            </p>
                            <p [innerHTML] ="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
                                    username: acr.createdBy ? acr.createdBy : 'Unknown',
                                    date: (acr.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }">
                            </p>
                            <p [innerHTML] ="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                    username: acr.lastUpdatedBy ? acr.lastUpdatedBy : 'Unknown',
                                    date: (acr.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }">
                            </p>
                        </ng-template>
                        <button mat-icon-button 
                            *ngIf="acr.status == 1"
                            matTooltip="Duplicate the Comparable"
                            (click)="duplicate(acr.id)">
                            <i [style.color]="acr.status !== 0 ? 'green' : 'grey'" class="flaticon2-layers"></i>
                        </button>
                        <button mat-icon-button
                            color="primary"
                            [ngbPopover]="popContent"
                            [popoverTitle]="popTitle"
                            *ngIf="currentUser"
                        >
                            <mat-icon
                                [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                            >info</mat-icon>
                        </button>

                        <button mat-icon-button
                                color="primary"
                                [matTooltip]="'APARTMENT.LIST.BUTTON.EDIT.TOOLTIP' | translate"
                                (click)="editAssetClassResidential(acr)">
                            <mat-icon>create</mat-icon>
                        </button>&nbsp;

                            <button mat-icon-button
                                    color="warn"
                                    type="button"
                                    [disabled]="!acr.can_be_deleted"
                                    (click)="deleteAssetClassResidential(acr)">
                                <mat-icon [matTooltip]="!acr.can_be_deleted ? 'Cannot delete' : ('APARTMENT.LIST.BUTTON.DELETE.TOOLTIP'|translate)">delete</mat-icon>
                            </button>&nbsp;
                    </mat-cell>
                </ng-container>
                    <!-- Checkbox Column -->


                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                    <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                             [ngClass]="{gray: index%2}"></mat-row>
                </mat-table>
                <!-- Message for empty data  -->
                <div class="mat-table-message" *ngIf="!dataSource.hasItems">
                    {{ 'GENERAL.MESSAGE.NO_DATA' | translate }}
                </div>
                <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">
                    {{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}
                </div>
            </div>

            <!-- start: BOTTOM -->
            <div class="mat-table-bottom">
                <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
                <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
                <!-- MATERIAL PAGINATOR | Binded to dasources -->
                <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
                <mat-paginator [pageSize]="250" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
            </div>
            <!-- end: BOTTOM -->
        </div>
    </kt-portlet-body>
</kt-portlet>