import * as moment from "moment";
import { ConvertedCostRevenue } from "../../types/converted-cost-revenue";
import { ModelAssumptionsUtil } from "./model-assumptions-util";
import {
  totalRecoverable as calculateTR,
  totalNonRecoverable as calculateTNR
} from '../../utils'
import { basisMultiplier, miniFs, rounddown4, yieldPurchaseOnPerpetuityAnnually, yieldPurchaseOnPerpetuityQuarterly, yieldPurchaseUntilReviewAnnually, yieldPurchaseUntilReviewQuarterly } from "./utils";

export function calculateTermAndReversionHardcoreFroth(
  effectiveRent: number,
  costRevenue: ConvertedCostRevenue,
  valuationDate: Date,
  {timingId, allRiskYield, marketRent, activeBreakOffOption, purchaserCost, reversionaryYield}: {
    activeBreakOffOption: boolean,
    timingId: number,
    allRiskYield: number,
    marketRent: number,
    purchaserCost: number,
    reversionaryYield: number
  }
) {
  const today = moment(valuationDate)
  const _basisMultiplier = basisMultiplier(costRevenue.rentBasis)
  const unexpiredLeaseTerm = today ? ModelAssumptionsUtil.unexpiredLeaseTerm(costRevenue, today) : null
  const yearsToReview = today ? ModelAssumptionsUtil.yearsToReview(costRevenue, today) : null
  const yearsToWriteOff = today ? ModelAssumptionsUtil.yearsToWriteOff(costRevenue, today) : null
  const yearsToBreakOff = today ? ModelAssumptionsUtil.yearsToBreakOff(costRevenue, today) : null

  const totalRecoverable = calculateTR(costRevenue) * _basisMultiplier
  const totalNonRecoverable = calculateTNR(costRevenue) * _basisMultiplier
  const passingRent = effectiveRent + totalRecoverable - totalNonRecoverable

  const yieldPurchaseOnPerpetuity = timingId == 1
    ? yieldPurchaseOnPerpetuityAnnually(allRiskYield)
    : timingId == 2
      ? yieldPurchaseOnPerpetuityQuarterly(allRiskYield)
      : undefined
  const capitalValueOfTerm = yieldPurchaseOnPerpetuity * marketRent 

  const excessRent = passingRent - marketRent
  const minYear = miniFs(
    activeBreakOffOption
      ? {'Years to Review': yearsToReview, 'Years to Write off': yearsToWriteOff, 'Years to Break off': yearsToBreakOff}
      : {'Years to Review': yearsToReview, 'Years to Write off': yearsToWriteOff}
  )
  const yieldPurchaseUntilReview = timingId == 1 
    ? yieldPurchaseUntilReviewAnnually(reversionaryYield, minYear.value)
    : timingId == 2
      ? yieldPurchaseUntilReviewQuarterly(reversionaryYield, minYear.value)
      : undefined
  const capitalValueOfExcessRent = yieldPurchaseUntilReview * excessRent

  const capitalValueOfTermAndExcess = capitalValueOfTerm + capitalValueOfExcessRent
  const totalPurchaserCost = capitalValueOfTermAndExcess
    ? purchaserCost
      ? (purchaserCost / 100) * capitalValueOfTermAndExcess
      : 0
    : undefined
  const value = capitalValueOfTermAndExcess - totalPurchaserCost

  return {
    unexpiredLeaseTerm,
    yearsToReview,
    yearsToWriteOff,
    yearsToBreakOff,

    effectiveRent,
    basisMultiplier: _basisMultiplier,
    recoverable: {
      total: totalRecoverable,
      recoverablePropertyManagement: costRevenue.recoverablePropertyManagement * _basisMultiplier,
      recoverableLeasingExpenses: costRevenue.recoverableLeasingExpenses * _basisMultiplier, 
      recoverableUtilities: costRevenue.recoverableUtilities * _basisMultiplier,
      recoverableMaintenance: costRevenue.recoverableMaintenance * _basisMultiplier,
      recoverableInsurance: costRevenue.recoverableInsurance * _basisMultiplier,
      recoverableJanitorial: costRevenue.recoverableJanitorial * _basisMultiplier,
      recoverablePropertyTaxes: costRevenue.recoverablePropertyTaxes * _basisMultiplier,
      recoverableBusiness: costRevenue.recoverableBusiness * _basisMultiplier,
      recoverableOthers: costRevenue.recoverableOthers * _basisMultiplier,
    },
    nonRecoverable: {
      total: totalNonRecoverable,
      nonRecoverablePropertyManagement: costRevenue.nonRecoverablePropertyManagement * _basisMultiplier,
      nonRecoverableLeasingExpenses: costRevenue.nonRecoverableLeasingExpenses * _basisMultiplier, 
      nonRecoverableUtilities: costRevenue.nonRecoverableUtilities * _basisMultiplier,
      nonRecoverableMaintenance: costRevenue.nonRecoverableMaintenance * _basisMultiplier,
      nonRecoverableInsurance: costRevenue.nonRecoverableInsurance * _basisMultiplier,
      nonRecoverableJanitorial: costRevenue.nonRecoverableJanitorial * _basisMultiplier,
      nonRecoverablePropertyTaxes: costRevenue.nonRecoverablePropertyTaxes * _basisMultiplier,
      nonRecoverableBusiness: costRevenue.nonRecoverableBusiness * _basisMultiplier,
      nonRecoverableOthers: costRevenue.nonRecoverableOthers * _basisMultiplier,
    },
    passingRent,

    yieldPurchaseOnPerpetuity,
    capitalValueOfTerm,

    excessRent,
    yieldPurchaseUntilReview: {
      value: yieldPurchaseUntilReview,
      year: minYear
    },
    capitalValueOfExcessRent,

    capitalValueOfTermAndExcess,
    totalPurchaserCost,
    value
  }
}