import { Component, Input, OnDestroy } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { defer, Subject } from "rxjs";
import { exhaustMap, map, shareReplay, startWith, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { GradesService } from "src/app/core/v2/state/grades/grades.service";
import { DatepickerTzInputComponent } from "../../mad-forms/datepicker-tz-input/datepicker-tz-input.component";
import { EnergyGenerationMethodService } from "src/app/core/v2/state/energy-generation-method/energy-generation-method.service";
import { ClimateRiskService } from "src/app/core/v2/state/climate-risk/climate-risk.service";
import { formControlValueChanges } from "src/app/core/v2/utils";
import { EPCLabelService } from "src/app/core/v2/state/epc-label/epc-label.service";
import { MatDialog } from "@angular/material/dialog";
import { LabelEditDialogComponent } from "./label-edit-dialog.component";
import { Option } from './types'
import { EsgIndicator } from "src/app/core/v2/state/esg-indicator/type";
import { TypesUtilsService } from "src/app/core/_base/crud";
import { environment } from "src/environments/environment";
import { ImageViewerDialogComponent } from "../image-viewer/image-viewer.component";

type FormType = 'comparable' | 'inspection'

interface State {
  formType: FormType
  systemOfMeasurementId: number
  grades: Option[],
  energyRates: Option[],
  emissionRates: Option[],
  energyGenerationMethods: Option[],
  climateRisks: Option[],
  epcLabels: Option[]
}

@Component({
  selector: 'kt-esg-indicators',
  templateUrl: './esg-indicators.component.html',
  styleUrls: ['./esg-indicators.component.scss'],
  providers: [ComponentStore]
})
export class EsgIndicatorsComponent implements OnDestroy {
  @Input()
  set value(value: EsgIndicator) {
    if (!value) return

    this.formGroup.patchValue({
      energy_performance_certificate: value.energy_performance_certificate,
      primary_energy_consumption: value.primary_energy_consumption?.toString(),
      primary_energy_consumption_rate: value.primary_energy_consumption_rate,
      performance: value.performance?.toString(),
      performance_rate: value.performance_rate,
      final_energy_consumption: value.final_energy_consumption?.toString(),
      final_energy_consumption_rate: value.final_energy_consumption_rate,
      expiry_date: value.expiry_date ? new Date(value.expiry_date) : null,
      energy_intensity: value.energy_intensity?.toString(),
      energy_intensity_rate: value.energy_intensity_rate,
      improvements_since_last_energy_rating: value.improvements_since_last_energy_rating,
      co2_emissions: value.co2_emissions?.toString(),
      co2_emissions_rate: value.co2_emissions_rate,
      comments_or_justifications: value.comments_or_justifications,
      notes: value.notes,
      comments: value.comments,
    })

    this.formGroup.controls.renewable_energy_production.clear()
    value.renewable_energy_productions.forEach(item => {
      this.formGroup.controls.renewable_energy_production.push(new FormGroup({
        id: new FormControl(item.id),
        energy_generation_method_id: new FormControl(item.energy_generation_method_id),
        total_production: new FormControl(item.total_production?.toString()),
        total_production_rate: new FormControl(item.total_production_rate),
        share_of_energy_delivered_back: new FormControl(item.share_of_energy_delivered_back?.toString()),
        share_of_energy_used_onsite: new FormControl(item.share_of_energy_used_onsite?.toString()),
        share_of_final_energy_demand: new FormControl(item.share_of_final_energy_demand?.toString()),
        share_of_primary_energy_demand: new FormControl(item.share_of_primary_energy_demand?.toString())
      }))
    })

    this.formGroup.controls.exposed_risks.clear()
    value.exposed_risks.forEach(item => {
      this.formGroup.controls.exposed_risks.push(new FormGroup({
        id: new FormControl(item.id),
        climate_risk_id: new FormControl(item.climate_risk_id),
        mitigation_measure: new FormControl(item.mitigation_measure)
      }))
    })

    this.formGroup.controls.labels.clear()
    value.labels_and_certificates.forEach(item => {
      this.formGroup.controls.labels.push(new FormGroup({
        id: new FormControl(item.id),
        label: new FormControl(item.label_id),
        rating: new FormControl(item.rating_id),
        expiry_date: new FormControl(item.expiry_date ? new Date(item.expiry_date) : null),
        details: new FormControl(item.details),
        documents: new FormControl(item.document ? item.document : null)
      }))
    })
  }

  @Input()
  set formType(value: FormType) {
    this.store.patchState({ formType: value })
  }

  @Input()
  set systemOfMeasurement(value: number) {
    this.store.patchState({
      systemOfMeasurementId: value
    })

    if (value == 2) {
      this.store.patchState({
        energyRates: [{ value: 1, label: 'kWh/m2' }, { value: 2, label: 'kWh/m2/year' }],
        emissionRates: [{ value: 1, label: 'kgCO2e/m2' }, { value: 2, label: 'kgCO2e/m2/year' }]
      })
    }

    if (value == 1) {
      this.store.patchState({
        energyRates: [{ value: 1, label: 'kWh/sqft' }, { value: 2, label: 'kWh/sqft/year' }],
        emissionRates: [{ value: 1, label: 'kgCO2e/sqft' }, { value: 2, label: 'kgCO2e/sqft/year' }]
      })
    }
  }

  formGroup = new FormGroup({
    energy_performance_certificate: new FormControl<number>(null),
    primary_energy_consumption: new FormControl<string>(null),
    primary_energy_consumption_rate: new FormControl<number>(2),
    performance: new FormControl<string>(null),
    performance_rate: new FormControl<number>(2),
    final_energy_consumption: new FormControl<string>(null),
    final_energy_consumption_rate: new FormControl<number>(2),
    expiry_date: new FormControl<Date>(null),
    energy_intensity: new FormControl<string>(null),
    energy_intensity_rate: new FormControl<number>(2),
    improvements_since_last_energy_rating: new FormControl<string>(null),
    co2_emissions: new FormControl<string>(null),
    co2_emissions_rate: new FormControl<number>(1),
    comments_or_justifications: new FormControl<string>(null),
    renewable_energy_production: new FormArray<FormGroup<{
      id: FormControl<number>,
      energy_generation_method_id: FormControl<number>,
      total_production: FormControl<string>,
      total_production_rate: FormControl<number>,
      share_of_primary_energy_demand: FormControl<string>,
      share_of_final_energy_demand: FormControl<string>,
      share_of_energy_used_onsite: FormControl<string>,
      share_of_energy_delivered_back: FormControl<string>
    }>>([new FormGroup({
      id: new FormControl(null),
      energy_generation_method_id: new FormControl(null),
      total_production: new FormControl(null),
      total_production_rate: new FormControl(1),
      share_of_primary_energy_demand: new FormControl(null),
      share_of_final_energy_demand: new FormControl(null),
      share_of_energy_used_onsite: new FormControl(null),
      share_of_energy_delivered_back: new FormControl(null)
    })]),
    notes: new FormControl<string>(null),
    labels: new FormArray<FormGroup<{
      id: FormControl<number>,
      label: FormControl<number>,
      rating: FormControl<number>,
      details: FormControl<string>,
      expiry_date: FormControl<Date>,
      documents: FormControl<{name: string, path: string}>
    }>>([]),
    comments: new FormControl<string>(null),
    exposed_risks: new FormArray<FormGroup<{
      id: FormControl<number>,
      climate_risk_id: FormControl<number>,
      mitigation_measure: FormControl<string>
    }>>([new FormGroup({
      id: new FormControl(null),
      climate_risk_id: new FormControl(null),
      mitigation_measure: new FormControl(null)
    })]),
  })

  renewableEnergyProductionFormArray = this.formGroup.controls.renewable_energy_production
  exposedRiskFormArray = this.formGroup.controls.exposed_risks
  labelFormArray = this.formGroup.controls.labels

  labelTrackBy(index: number, _item: any) {
    return index
  }
  labelDataSource$ = defer(() => this.formGroup.controls.labels.valueChanges.pipe(
    startWith(this.formGroup.controls.labels.value)
  ))
    .pipe(shareReplay(1))
    .pipe(
      map(_values => {
        return this.formGroup.controls.labels.controls
          .map(formGroup => {
            return {
              form: formGroup,
              icon: this.setIcon(formGroup.controls.documents.value)
            }
          })
      })
    )

  grades$ = this.store.select(state => state.grades)
  energyRates$ = this.store.select(state => state.energyRates)
  emissionRates$ = this.store.select(state => state.emissionRates)
  energyGenerationMethods$ = this.store.select(state => state.energyGenerationMethods)
  climateRisks$ = this.store.select(state => state.climateRisks)
  epcLabels$ = this.store.select(state => state.epcLabels)

  private docs = ['xlsx', 'xls', 'doc', 'docx']
  private _onDestroy$ = new Subject<void>()

  constructor(
    private store: ComponentStore<State>,
    private _gradesService: GradesService,
    private _energyGenerationMethodService: EnergyGenerationMethodService,
    private _climateRiskService: ClimateRiskService,
    private _epcLabelService: EPCLabelService,
    private _dialog: MatDialog,
    private _typeUtilsService: TypesUtilsService
  ) {
    this.store.setState({
      systemOfMeasurementId: null,
      grades: [],
      energyRates: [],
      emissionRates: [],
      energyGenerationMethods: [],
      formType: null,
      climateRisks: [],
      epcLabels: []
    })

    // Effects
    this._getGrades()
    this._getEnergyGenerationMethods()
    this._getClimateRisks()
    this._getEPCLabels()
  }

  ngOnDestroy(): void {
  }

  onClearExpiryDate(datePicker: DatepickerTzInputComponent) {
    datePicker.clearDate()
    this.formGroup.controls.expiry_date.patchValue(null)
  }

  onAddRenewableEnergyProduction(index: number) {
    this.renewableEnergyProductionFormArray.insert(index + 1, new FormGroup({
      id: new FormControl(null),
      energy_generation_method_id: new FormControl(null, [Validators.required]),
      total_production: new FormControl(null),
      total_production_rate: new FormControl(1),
      share_of_primary_energy_demand: new FormControl(null),
      share_of_final_energy_demand: new FormControl(null),
      share_of_energy_delivered_back: new FormControl(null),
      share_of_energy_used_onsite: new FormControl(null)
    }))
  }
  onRemoveRenewableEnergyProduction(index: number) {
    this.renewableEnergyProductionFormArray.removeAt(index)
  }

  readonly onAddLabel = this.store.effect<void>(
    trigger$ => trigger$.pipe(
      withLatestFrom(this.store.state$),
      switchMap(([_, state]: [void, State]) => {
        const dialogRef = this._dialog.open(LabelEditDialogComponent, {
          width: '40vw',
          data: {
            type: 'add',
            labels: state.epcLabels,
            grades: state.grades
          }
        })
        return dialogRef.afterClosed().pipe(
          tap(res => {
            if (!res) return;
            this.labelFormArray.push(new FormGroup({
              id: new FormControl(res.id),
              label: new FormControl(res.label),
              rating: new FormControl(res.rating),
              details: new FormControl(res.details, [Validators.maxLength(200)]),
              expiry_date: new FormControl(res.expiryDate),
              documents: new FormControl(res.documents)
            }))
          })
        )
      })
    )
  )
  readonly onEditLabel = this.store.effect<number>(
    trigger$ => trigger$.pipe(
      withLatestFrom(this.store.state$),
      switchMap(([index, state]: [number, State]) => {
        const formGroup = this.labelFormArray.at(index)
        if (!formGroup) return
        const dialogRef = this._dialog.open(LabelEditDialogComponent, {
          width: '40vw',
          data: {
            type: 'edit',
            labels: state.epcLabels,
            grades: state.grades,
            value: formGroup.value
          }
        })
        return dialogRef.afterClosed().pipe(
          tap(res => {
            if (!res) return
            formGroup.patchValue({
              id: res.id,
              label: res.label,
              rating: res.rating,
              details: res.details,
              expiry_date: res.expiryDate,
              documents: res.documents
            })
          })
        )
      })
    )
  )
  onRemoveLabel(index: number) {
    this.labelFormArray.removeAt(index)
  }
  onSeeLabelDocument(file: {name: string, path: string}) {
    if (!file) return
    if (this.docs.indexOf(this.fileType(file.name)) > -1) {
      window.open(environment.baseApiUrl + file.path + '/' + file.name)
    } else {
      this._dialog.open(ImageViewerDialogComponent, {
        data: {
          picture: file.path + '/' + file.name,
          type: this.fileType(file.name)
        }
      })
    }
  }

  onAddExposedRisk(index: number) {
    this.exposedRiskFormArray.insert(index + 1, new FormGroup({
      id: new FormControl(null),
      climate_risk_id: new FormControl(null),
      mitigation_measure: new FormControl(null)
    }))
  }
  onRemoveExposedRisk(index: number) {
    this.exposedRiskFormArray.removeAt(index)
  }

  onReset() { 
    this.formGroup.reset()
  }

  submit(isDraft: boolean): {type: 'success', values: EsgIndicator} | {type: 'error', errors: unknown} {
    if (isDraft) return {
      type: 'success',
      values: this._prepare()
    } 
    if (this.formGroup.valid) return {
      type: 'success',
      values: this._prepare()
    }
    const controls = this.formGroup.controls
    let errors = []
    Object.keys(controls).forEach(controlName => {
      if (controls[controlName].invalid) {
        console.log(controlName)
        errors = [...errors, controlName]
      }
      controls[controlName].markAsTouched()
    })
    return {
      type: 'error',
      errors: errors
    }
  }

  private _prepare(): EsgIndicator {
    return {
      energy_performance_certificate: this.formGroup.controls.energy_performance_certificate.value,
      primary_energy_consumption: parseFloat(this.formGroup.controls.primary_energy_consumption.value),
      primary_energy_consumption_rate: this.formGroup.controls.primary_energy_consumption_rate.value,
      performance: parseFloat(this.formGroup.controls.performance.value),
      performance_rate: this.formGroup.controls.performance_rate.value,
      final_energy_consumption: parseFloat(this.formGroup.controls.final_energy_consumption.value),
      final_energy_consumption_rate: this.formGroup.controls.final_energy_consumption_rate.value,
      expiry_date: this.formGroup.controls.expiry_date.value 
        ? this._typeUtilsService.getDateStringFromDate(new Date(this.formGroup.controls.expiry_date.value))
        : null,
      energy_intensity: parseFloat(this.formGroup.controls.energy_intensity.value),
      energy_intensity_rate: this.formGroup.controls.energy_intensity_rate.value,
      improvements_since_last_energy_rating: this.formGroup.controls.improvements_since_last_energy_rating.value,
      co2_emissions: parseFloat(this.formGroup.controls.co2_emissions.value),
      co2_emissions_rate: this.formGroup.controls.co2_emissions_rate.value,
      comments_or_justifications: this.formGroup.controls.comments_or_justifications.value,
      renewable_energy_productions: this.formGroup.controls.renewable_energy_production.value.map(value => ({
        id: value.id,
        energy_generation_method_id: value.energy_generation_method_id,
        total_production: parseFloat(value.total_production),
        total_production_rate: value.total_production_rate,
        share_of_energy_delivered_back: parseFloat(value.share_of_energy_delivered_back),
        share_of_energy_used_onsite: parseFloat(value.share_of_energy_used_onsite),
        share_of_final_energy_demand: parseFloat(value.share_of_final_energy_demand),
        share_of_primary_energy_demand: parseFloat(value.share_of_primary_energy_demand)
      })),
      notes: this.formGroup.controls.notes.value,
      labels_and_certificates: this.formGroup.controls.labels.value.map(value => ({
        id: value.id,
        label_id: value.label,
        rating_id: value.rating,
        expiry_date: value.expiry_date
          ? this._typeUtilsService.getDateStringFromDate(new Date(value.expiry_date))
          : null,
        details: value.details,
        document: value.documents 
          ? value.documents
          : null
      })),
      comments: this.formGroup.controls.comments.value,
      exposed_risks: this.formGroup.controls.exposed_risks.value.map(value => ({
        id: value.id,
        climate_risk_id: value.climate_risk_id,
        mitigation_measure: value.mitigation_measure
      })),
    }
  }

  private readonly _getGrades = this.store.effect<void>(
    (trigger$) => trigger$.pipe(
      exhaustMap(() =>
        this._gradesService.fetchList().pipe(
          tapResponse(
            (items) => this.store.patchState({ grades: items.map(item => ({ value: item.id, label: item.name })) }),
            (error) => console.error(error)
          )
        )
      )
    )
  )

  private readonly _getEnergyGenerationMethods = this.store.effect<void>(
    (trigger$) => trigger$.pipe(
      exhaustMap(() =>
        this._energyGenerationMethodService.fetchList().pipe(
          tapResponse(
            (items) => this.store.patchState({ energyGenerationMethods: items.map(item => ({ value: item.id, label: item.name })) }),
            (error) => console.error(error)
          )
        )
      )
    )
  )

  private readonly _getClimateRisks = this.store.effect<void>(
    (trigger$) => trigger$.pipe(
      exhaustMap(() =>
        this._climateRiskService.fetchList().pipe(
          tapResponse(
            (items) => this.store.patchState({ climateRisks: items.map(item => ({ value: item.id, label: item.name })) }),
            (error) => console.error(error)
          )
        )
      )
    )
  )

  private readonly _getEPCLabels = this.store.effect<void>(
    trigger$ => trigger$.pipe(
      exhaustMap(() =>
        this._epcLabelService.fetchList().pipe(
          tapResponse(
            (items) => this.store.patchState({ epcLabels: items.map(item => ({ value: item.id, label: item.name })) }),
            (error) => console.error(error)
          )
        )
      )
    )
  )

  private setIcon(file: {name: string, path: string}) {
    if (!file) return null
    const extension = file.name.substring(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase()
    let ext = null
    switch (extension) {
      case 'png':
        ext = 'png';
        break;
      case 'jpeg':
        ext = 'jpg';
        break;
      case 'jpg':
        ext = 'jpg';
        break;
      case 'gif':
        ext = 'gif';
        break;
      case 'pdf':
        ext = 'pdf';
        break;
      case 'xls':
        ext = 'xls';
        break;
      case 'xlsx':
        ext = 'xls';
        break;
      case 'rtf':
        ext = 'doc';
        break;
      case 'doc':
        ext = 'doc';
        break;
      case 'docx':
        ext = 'doc';
        break;
      case 'mp3':
        ext = 'mp3';
        break;
      default:
        ext = 'file';
        break;
    }
    return './assets/media/files-alt/' + ext + '.svg';
  }

  private fileType(fileName: string) {
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length).toLowerCase()
  }
}