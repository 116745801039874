import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[percentRange]'
})
export class PercentRangeDirective {
    @Input() min: number = -100;
    constructor(el: ElementRef) {
    }

    @HostListener('keydown', ['$event'])
    private onKeydown(event: KeyboardEvent) {
        const targetElement: HTMLInputElement = event.target as HTMLInputElement;
        const numberKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
        const arrowKeys = ['ArrowRight', 'ArrowLeft'];

        if (numberKeys.includes(event.key)) {
            const selectionStart = targetElement.selectionStart
            const selectionEnd = targetElement.selectionEnd
            const currentValue = targetElement.value

            if (currentValue.length === 0) {
                targetElement.value = '%'
                targetElement.setSelectionRange(0, 0)
                return
            }

            if (currentValue.length === 1 && currentValue === '-') {
                targetElement.value = '-%'
                targetElement.setSelectionRange(1, 1)
                return
            }
            if (selectionStart !== null && selectionEnd !== null && selectionStart !== selectionEnd) {
                const beforeSelection = currentValue.substring(0, selectionStart)
                const afterSelection = currentValue.substring(selectionEnd)
                const newValue = beforeSelection + event.key + afterSelection

                const matches = newValue.match(/\d+/g)
                if (matches && matches.length > 0) {
                    if (!this.checkInRange(matches[0])) {
                        console.log('Why Am i here')
                        event.preventDefault()
                        return
                    }
                }
                return
            }

            if (currentValue.length > 0) {
                const matches = currentValue.match(/\d+/g)
                if (matches && matches.length > 0) {
                    if (!this.checkInRange(matches[0] + event.key )) {
                        event.preventDefault()
                    }
                }
            }
        } else if (event.key === '-') {
            if (this.min != -100) {
                event.preventDefault()
                return;
            }
            const pos = targetElement.selectionStart;
            if (pos !== 0) {
                event.preventDefault()
            }
        } else if (event.key === 'Backspace') {
            // if (targetElement.value.length == 2) {
            //     targetElement.value = '';
            //     targetElement.setSelectionRange(0, 0);
            // }
        } else if (arrowKeys.includes(event.key)) {
            
        } else {
            event.preventDefault();
        }
    }

    @HostListener('click', ['$event'])
    private onClick(event: MouseEvent) {
        const el: HTMLInputElement = event.target as HTMLInputElement;
    }

    private checkInRange(val: string): boolean {
        const numVal = Number(val)
        if (numVal > 100 || numVal < this.min) {
            return false
        }
        return true
    }
}