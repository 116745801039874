<div class="card card-custom">
  <div class="card-header">
    <div class="card-title">
      <h3 class="card-label">
        {{ title$ | async }}
      </h3>
    </div>
  </div>

  <div class="form form-group-separator-dashed">
    <form class="form" [formGroup]="formGroup">
      <div class="card-body">
        <mat-dialog-content>
          <div class="form-group row">
            <div class="col-12">
              <mat-form-field class="mat-form-field-fluid">
                <mat-select placeholder="Label" formControlName="label">
                  <mat-option *ngFor="let option of (epcLabels$|async)" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Label is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="mat-form-field-fluid">
                <mat-select placeholder="Rating" formControlName="rating">
                  <mat-option *ngFor="let option of (grades$|async)" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  Rating is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="mat-form-field-fluid">
                <input matInput placeholder="Details" formControlName="details"/>
                <mat-error>
                  Cannot be longer than 200 characters
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="mat-form-field-fluid">
                <kt-datepicker-tz-input #expiryDate placeholder="Date of expiry" formControlName="expiry_date" />
                <button mat-button mat-icon-button matSuffix type="button">
                  <mat-icon>today</mat-icon>
                </button>
                <button *ngIf="formGroup.controls.expiry_date.value" mat-button mat-icon-button matSuffix type="button"
                  (click)="onClearExpiryDate(expiryDate)">
                  <mat-icon>close</mat-icon>
                </button>
              </mat-form-field>
            </div>
            <div class="col-12">
              <kt-file-upload-field
                [file]="data.value?.documents"
                (fileChange)="onFileChange($event)"></kt-file-upload-field>
            </div>
          </div>
        </mat-dialog-content>
      </div>
    </form>

    <div class="card-footer p-0 b-0 text-right">
      <div class="form-actions form-actions-solid">
        <button type="button" mat-button mat-raised-button (click)="onClose()">
          Close
        </button>
        &nbsp;
        <button type="button" class="btn btn-success" mat-raised-button color="submit" (click)="onSubmit()">
          Save
        </button>
      </div>
    </div>
  </div>
</div>