<kt-portlet>
  <kt-portlet-header 
    [sticky]="true"
    title="Logistics"
    [class]="'kt-porlet-head-lg'"
    [viewLoading$]="loading$">
    <ng-container ktPortletTools>
      <a mat-raised-button matTooltip="Back to Toe dashboard" [routerLink]="['../../dashboard']">
        <mat-icon>arrow_back</mat-icon>
        <span class="kt-hidden-mobile">Close</span>
      </a>
      <button class="btn btn-success mr-2" color="warn" style="background-color: orange !important" mat-raised-button (click)="onSubmit(false)">
        <span>Save Draft</span>
      </button>
      <button class="btn btn-success mr-2" color="submit" mat-raised-button (click)="onSubmit(true)">
        <span>Save</span>
      </button>
    </ng-container>
  </kt-portlet-header>

  <kt-portlet-body>
    <div class="card-body pt-0">
      <form [formGroup]="form">
        <kt-tab-header
          [headerLabels]="tabHeaders"
          [selectedTabChange]="selectedTabChange$"
          (tabChange)="onTabChange($event)"></kt-tab-header>
        <mat-tab-group class="invinsible-tabs"
          [(selectedIndex)]="selectedTab">
          <mat-tab>
            <ng-template mat-tab-label></ng-template>

            <kt-form-error
                [hasFormErrors]="hasFormErrors"
                [tabHeaders]="tabHeaders"
                [error]="error"
                [erroTabMap]="errorTabMap()"
                (hasFormErrorsChange)="onHasFormErrorsChange($event)">
            </kt-form-error>

            <div class="background-wrapper" *ngIf="backgroundInfo$|async as data">
              <div class="grid-col-2">
                <agm-map
                  [latitude]="data.asset_class.latitude"
                  [longitude]="data.asset_class.longitude"
                  [zoom]="15"
                  [scrollwheel]="null"
                  [usePanning]="true"
                  [clickableIcons]="false">
                  <agm-marker [latitude]="data.asset_class.latitude" [longitude]="data.asset_class.longitude"></agm-marker>
                </agm-map>
              </div>

              <div class="card h-100">
                <div class="card-header info-header bg-brand-purple">
                  <span>Property specifics & Location</span>
                </div>
                <div class="card-body info-body bg-brand-purple-light">
                      <div class="info-row">
                        <div>Property Type :</div>
                        <div>{{data.asset_class.property_type}} / {{data.asset_class.property_sub_type}}</div>
                      </div>
                      <div class="info-row">
                        <div>Address :</div>
                        <div>{{data.asset_class.address}}</div>
                      </div>
                      <div class="info-row">
                        <div>Floor :</div>
                        <div>{{data.asset_class.floor}} ({{data.asset_class.floor_numbering_scheme}})</div>
                      </div>
                      <div class="info-row">
                        <div>City :</div>
                        <div>{{data.asset_class.city}}</div>
                      </div>
                      <div class="info-row">
                        <div>Zipcode :</div>
                        <div>{{data.asset_class.zip_code}}</div>
                      </div>
                </div>
              </div>

              <div class="card h-100">
                <div class="card-header info-header bg-brand-blue">
                  <span>Team Involvement</span>
                </div>
                <div class="card-body info-body bg-brand-blue-light">
                  <div class="team_involvement-wrapper">
                    <div class="team_involvement-label">Project Manager :</div>
                    <div>
                      <span 
                        class="clickable" 
                        [ngbPopover]="userInfoPopover"
                        [popoverTitle]="data.toe.team.project_manager.full_name"
                        placement="right"
                        triggers="manual"
                        #pPM="ngbPopover"
                        (click)="showUserInfo(pPM, data.toe.team.project_manager)">{{data.toe.team.project_manager.full_name}}</span> / <span>{{data.toe.team.project_manager.email}}</span>
                    </div>
                  </div>

                  <div class="team_involvement-wrapper">
                    <div  class="team_involvement-label">Lead Valuer :</div>
                    <div>
                      <span 
                        class="clickable" 
                        [ngbPopover]="userInfoPopover"
                        [popoverTitle]="data.toe.team.lead_valuer.full_name"
                        placement="right"
                        triggers="manual"
                        #pLV="ngbPopover"
                        (click)="showUserInfo(pLV, data.toe.team.lead_valuer)">{{data.toe.team.lead_valuer.full_name}}</span> / <span>{{data.toe.team.lead_valuer.email}}</span>
                    </div>
                  </div>

                  <ng-container *ngIf="data.toe.team.other_members.length > 0">
                    <div class="team_involvement-wrapper">
                      <div class="team_involvement-label">Other team members :</div>
                      <div *ngFor="let member of data.toe.team.other_members">
                        <span 
                          class="clickable" 
                          [ngbPopover]="userInfoPopover"
                          [popoverTitle]="member.full_name"
                          placement="right"
                          triggers="manual"
                          #pT="ngbPopover"
                          (click)="showUserInfo(pT, member)">{{member.full_name}}</span> / <span>{{member.email}}</span>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>

              <div class="card h-100">
                <div class="card-header info-header bg-brand-red">
                  <span>Job Info</span>
                </div>
                <div class="card-body info-body bg-brand-red-light">
                  <div class="info-row">
                    <div>Agency :</div> <div>{{data.toe.agency}}</div>
                  </div>
                  <div class="info-row">
                    <div>ToE ID :</div> <div>{{data.toe.name}}</div>
                  </div>
                  <div class="info-row">
                    <div>Expected Date of Investigation :</div> <div>{{data.asset_class.date_of_investigation | dateWithOffset:data.asset_class.timezone_offset}}</div>
                  </div>
                  <div class="info-row">
                    <div>Date of Instruction :</div> <div>{{data.toe.date_of_instruction | dateWithOffset:data.toe.timezone_offset}}</div>
                  </div>
                  <div class="info-row">
                    <div>Date of Completion :</div> <div>{{data.toe.date_of_completion | dateWithOffset:data.toe.timezone_offset}}</div>
                  </div>
                  <div class="info-row">
                    <div>Type of Investigation :</div> <div>{{data.asset_class.type_of_investigation}}</div>
                  </div>
                  <div class="info-row">
                    <div>Measurement :</div> <div>{{data.asset_class.measurement_standard}} / {{data.asset_class.unit_area_measurement}}</div>
                  </div>
                </div>
              </div>

              <div class="card h-100">
                <div class="card-header info-header bg-brand-gray">
                  <span>Client</span>
                </div>
                <div class="card-body info-body bg-brand-gray-light">
                  <div class="info-row">
                    <div>Client Name :</div>
                    <div>{{data.toe.client.name}}</div>
                  </div>
                  <div class="info-row" *ngIf="data.toe.client.representative">
                    <div>Representative :</div>
                    <div>{{data.toe.client.representative}}</div>
                  </div>
                  <div class="info-row">
                    <div>Phone :</div>
                    <div>{{data.toe.client.phone}}</div>
                  </div>
                  <div class="info-row">
                    <div>Email :</div>
                    <div>{{data.toe.client.email}}</div>
                  </div>
                </div>
              </div>

              <div class="card h-100 grid-col-3">
                <div class="card-header info-header bg-brand-green">
                  <span>Extent of Investigation</span>
                </div>
                <div class="card-body info-body bg-brand-green-light">
                  <div class="extent_of_investigation-row">
                    <mat-checkbox [checked]="data.asset_class.extent_of_investigation.included_all_equipment"></mat-checkbox>
                    <div class="extent_of_investigation-row-label">Including Landlord Equipment</div>
                    <ng-container *ngIf="!data.asset_class.extent_of_investigation.included_all_equipment">
                      <span>(Exclusions: {{data.asset_class.extent_of_investigation.included_all_equipment_descr}})</span>
                    </ng-container>
                  </div>
                  <div class="extent_of_investigation-row">
                    <mat-checkbox [checked]="data.asset_class.extent_of_investigation.included_furniture"></mat-checkbox>
                    <div class="extent_of_investigation-row-label">Including Occupier Equipment</div>
                    <ng-container *ngIf="data.asset_class.extent_of_investigation.included_furniture">
                      <span>(Inclusions: {{data.asset_class.extent_of_investigation.included_furniture_descr}})</span>
                    </ng-container>
                  </div>
                  <div class="extent_of_investigation-row">
                    <mat-checkbox [checked]="data.asset_class.extent_of_investigation.natural_accident_consideration"></mat-checkbox>
                    <div class="extent_of_investigation-row-label">Considering Natural and Non-Natural Hazards</div>
                    <ng-container *ngIf="data.asset_class.extent_of_investigation.natural_accident_consideration">
                      <span>(Specifics: {{data.asset_class.extent_of_investigation.natural_accident_consideration_descr}})</span>
                    </ng-container>
                  </div>
                  <div class="extent_of_investigation-row">
                    <mat-checkbox [checked]="data.asset_class.extent_of_investigation.material_accident_consideration"></mat-checkbox>
                    <div class="extent_of_investigation-row-label">Considering Hazardous Materials On-Site</div>
                    <ng-container *ngIf="data.asset_class.extent_of_investigation.material_accident_consideration">
                      <span>(Specifics: {{data.asset_class.extent_of_investigation.material_accident_consideration_descr}})</span>
                    </ng-container>
                  </div>
                  <div class="extent_of_investigation-row">
                    <mat-checkbox [checked]="data.asset_class.extent_of_investigation.diligence_check"></mat-checkbox>
                    <div class="extent_of_investigation-row-label">Additional Due Diligence</div>
                    <ng-container *ngIf="data.asset_class.extent_of_investigation.diligence_check">
                      <span>(Specifics: {{data.asset_class.extent_of_investigation.diligence_check_descr}})</span>
                    </ng-container>
                  </div>
                  <div class="extent_of_investigation-row">
                    <mat-checkbox [checked]="data.asset_class.extent_of_investigation.expected_limitations_restrictions"></mat-checkbox>
                    <div class="extent_of_investigation-row-label">Limitations on Inspection and Analysis</div>
                    <ng-container *ngIf="data.asset_class.extent_of_investigation.expected_limitations_restrictions">
                      <span>(Expected: {{data.asset_class.extent_of_investigation.expected_limitations_restrictions_descr}})</span>
                    </ng-container>
                  </div>
                  <div class="extent_of_investigation-row">
                    <mat-checkbox [checked]="data.asset_class.extent_of_investigation.instructed_to_measure"></mat-checkbox>
                    <div class="extent_of_investigation-row-label">Measurement of Property</div>
                    <ng-container *ngIf="data.asset_class.extent_of_investigation.instructed_to_measure">
                      <span>(Methodology: {{data.asset_class.extent_of_investigation.methodology}} / Purpose: {{data.asset_class.extent_of_investigation.purpose}})</span>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="card h-100 grid-col-3">
                <div class="card-header info-header bg-brand-black">
                  <div class="card-title mb-0">
                    <div class="card-label d-flex flex-row">
                      <span class="mr-2">Notes</span>
                    </div>
                  </div>
                </div>
                <div class="card-body info-body bg-brand-black-light">
                  <div class="d-flex justify-content-end">
                    <button mat-raised-button type="button" color="primary" class="filter_button button-gray" (click)="onAddNote()">
                      <span>Add Note</span>
                    </button>
                  </div>

                  <table class="table info-table" *ngIf="notes$|async as notes">
                    <thead>
                      <th class="notes-user">User</th>
                      <th class="notes-date">Date</th>
                      <th class="notes-input">Input</th>
                      <th class="notes-status">Status</th>
                      <th class="notes-action">Action</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let note of notes; let i = index" [ngClass]="{
                        'white': i % 2 == 0,
                        'whiter-gray': i%2 != 0
                      }">
                        <td class="notes-user">{{note.user.first_name}} {{note.user.last_name}}</td>
                        <td class="notes-date">{{note.updated_at | date: 'dd MMM yyyy HH:mm'}}</td>
                        <td class="notes-input">{{note.input}}</td>
                        <td class="notes-status">
                          <mat-checkbox [checked]="note.status" (change)="onStatusChange($event, i)"></mat-checkbox>
                        </td>
                        <td class="notes-action">
                          <ng-container *ngIf="currentUser && currentUser.id == note.user.id; else notAvailable">
                            <span class="mr-6" (click)="onEditNote(note, i)">
                              <mat-icon color="primary">edit</mat-icon>
                            </span>
                            <span (click)="onDeleteNote(i)">
                              <mat-icon color="warn">delete</mat-icon>
                            </span>
                          </ng-container>
                          <ng-template #notAvailable>
                            N/A
                          </ng-template>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot *ngIf="notes.length === 0">
                      <tr>
                        <td colspan="5" style="text-align: center;">
                          <strong>No Notes</strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>

          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label></ng-template>

            <kt-form-error
                [hasFormErrors]="hasFormErrors"
                [tabHeaders]="tabHeaders"
                [error]="error"
                [erroTabMap]="errorTabMap()"
                (hasFormErrorsChange)="onHasFormErrorsChange($event)">
            </kt-form-error>

            <div class="form-group kt-form-group row" *ngIf="inspectionDetails$|async as inspectionDetails">
              <div class="col-md-12">
                <h5 class="my-3 text-mad text-uppercase">
                  {{inspectionDetails.type_of_inspection_id == 1 ? 'Desktop Review Detail' : 'Details'}}
                </h5>
              </div>

              <ng-container *ngIf="inspectionDetails.type_of_inspection_id != 1">
                <div class="col-md-12">
                  <mat-form-field class="mat-form-field-fluid">
                    <span (click)="inspection_date.open()">
                      <input matInput [matDatepicker]="inspection_date" readonly placeholder="Date of Inspection" formControlName="inspection_date" />
                    </span>
                    <mat-error>Date of Inspection is <strong>required</strong></mat-error>
                    <mat-datepicker-toggle matSuffix [for]="inspection_date"></mat-datepicker-toggle>
                    <button mat-button matSuffix mat-icon-button matTooltip="Clear" *ngIf="form.controls.inspection_date.value" (click)="onClearDate(form.controls.inspection_date)">
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-datepicker #inspection_date></mat-datepicker>
                  </mat-form-field>
                </div>

                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field class="mat-form-field-fluid">
                        <kt-timepicker-tz-input placeholder="Time of Inspection" formControlName="time_of_inspection" [timezone]="inspectionDetails.timezone_offset"></kt-timepicker-tz-input>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="mat-form-field-fluid">
                        <span (click)="onOpenTimeField($event)">
                          <input matInput formControlName="duration_of_inspection" placeholder="Duration of Inspection" autocomplete="off" />
                        </span>
                        <mat-error>Duration of Inspection <strong>required</strong></mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="d-flex flex-column">
                    <div class="mb-1 d-flex justify-content-end">
                      <button class="btn btn-primary" color="primary" type="button" (click)="onAddParticipant()">
                        Add Participant
                      </button>
                    </div>

                    <div class="example-container mat-elevation-z0">
                      <mat-table [dataSource]="participants$">
                        <ng-container matColumnDef="name">
                          <mat-header-cell *matHeaderCellDef>Full Name</mat-header-cell>
                          <mat-cell *matCellDef="let participant">{{participant.name}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="side">
                          <mat-header-cell *matHeaderCellDef>Participant Side</mat-header-cell>
                          <mat-cell *matCellDef="let participant">{{participant.side}}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="descr">
                          <mat-header-cell *matHeaderCellDef>Extra Note</mat-header-cell>
                          <mat-cell *matCellDef="let participant; let i = index">
                            <textarea cols="30" rows="1" [value]="participant.descr" (change)="onParticipantDescriptionChange($event, participant, i)" class="description-input"></textarea>
                          </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="actions">
                          <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
                          <mat-cell *matCellDef="let participant; let i = index">
                            <mat-checkbox class="mr-2" [checked]="participant.included" (change)="onParticipantIncludedChange($event, participant, i)"></mat-checkbox>
                            <button mat-icon-button color="primary" type="button" (click)="onEditParticipant(participant, i)">
                              <mat-icon>create</mat-icon>
                            </button>
                            <button mat-icon-button type="button" color="warn" (click)="onDeleteParticipant(i)" *ngIf="!participant.is_main">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="['name', 'side', 'descr', 'actions']"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: ['name', 'side', 'descr', 'actions']"></mat-row>
                      </mat-table>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-3">
                  <label class="external-area-label-gray">Were there any limitations or restrictions during the inspection?</label>
                </div>
                <div class="col-md-12">
                  <mat-radio-group formControlName="has_limitations" class="tp-radio-group m2- mt-4 p-2">
                    <mat-radio-button [value]="true" class="tp-radio-button kt-margin-r-20">Yes</mat-radio-button>
                    <mat-radio-button [value]="false" class="tp-radio-button kt-margin-r-20">No</mat-radio-button>
                  </mat-radio-group>
                </div>
                <ng-container *ngIf="form.controls.has_limitations.value">
                  <div class="col-lg-12">
                    <div class="row">
                      <mat-label class="col-lg-11"></mat-label>
                      <div class="col-lg-1 text-center">
                        <button mat-icon-button type="button" (click)="onAddLimitations()" color="primary">
                          <mat-icon>add_circle</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12" *ngFor="let control of limitationsControls.controls; let i = index">
                    <div class="row" style="align-items: center;">
                      <mat-form-field class="mat-form-field-fluid col-lg-11">
                        <textarea matInput [placeholder]="'Limitations During Inspection (' + (i + 1) + ')'" [formControl]="control"></textarea>
                      </mat-form-field>
                      <div class="col-lg-1 text-center">
                        <button mat-icon-button color="warn" type="button" (click)="onLimitationsDelete(i)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="row">
                      <mat-label class="col-lg-11"></mat-label>
                      <div class="col-lg-1 text-center">
                        <button mat-icon-button type="button" color="primary" (click)="onAddInspectionNote()">
                          <mat-icon>add_circle</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12" *ngFor="let control of inspectionNotesControls.controls; let i = index">
                    <div class="row" style="align-items: center;">
                      <mat-form-field class="mat-form-field-fluid col-lg-11">
                        <textarea matInput [placeholder]="'Inspection note (' + (i + 1) +  ')'" [formControl]="control"></textarea>
                      </mat-form-field>
                      <div class="col-lg-1 text-center">
                        <button mat-icon-button color="warn" type="button" (click)="onInspectionNoteDelete(i)">
                          <mat-icon>delete</mat-icon>
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-container>

              </ng-container>
              <ng-container *ngIf="inspectionDetails.type_of_inspection_id == 1">
                 <div class="col-md-6">
                  <mat-form-field class="mat-form-field-fluid">
                    <kt-datepicker-tz-input #addPicker_information_date
                      placeholder="Date of information"
                      formControlName="information_date"
                      [timezone]="timezoneOffset$|async">
                    </kt-datepicker-tz-input>
                    <button mat-button mat-icon-button matSuffix type="button">
                      <mat-icon>today</mat-icon>
                    </button>
                    <button mat-button mat-icon-button matSuffix type="button" (click)="clearInformationDate(addPicker_information_date)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field>
                 </div>
                 <div class="col-md-12">
                  <mat-form-field class="mat-form-field-fluid">
                    <textarea matInput
                      rows="2"
                      placeholder="Source of information"
                      formControlName="source_of_information"></textarea>
                    <mat-error>Source of information is <strong>required</strong></mat-error>
                  </mat-form-field>
                 </div>
              </ng-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </form>
    </div>
  </kt-portlet-body>
</kt-portlet>

<ng-template #userInfoPopover let-role="role" let-qualification="qualification" let-email="email" let-agency="agency" let-user_pic="user_pic">
  <div style="display: flex; align-items: center;">
      <div class="image_container mr-3">
          <img [src]="user_pic" (error)="onImageError($event)">
      </div>
      <div>
          <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Role:</span> {{role}}</p>
          <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Qualification:</span> {{qualification}}</p>
          <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Email:</span> {{email}}</p>
          <p style="margin-bottom: 0.3rem !important;"><span class="font-weight-bold">Agency:</span> {{ agency}}</p>
      </div>
  </div>
</ng-template>