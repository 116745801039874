<input type="file" style="display: none;" #input (change)="onUploadFile($event)" />
<ng-container *ngIf="(hasUploadedFile$|async) as state">
  <ng-container *ngIf="!state.status">
    <button mat-raised-button class="btn" type="button" color="primary" (click)="onUploadFileClick(input)">
      <div class="btn-wrapper-inner">
        <mat-icon inline>cloud_upload</mat-icon> 
        <span>Upload file</span>
      </div>
    </button>
  </ng-container>
  <ng-container *ngIf="state.status">
    <div class="d-flex align-items-center">
      <button mat-raised-button class="btn remove-btn" color="primary" type="button" (click)="onRemoveFile()">
        Remove file
      </button>
      <div class="ml-4">
        {{state.file.name}}
      </div>
    </div>
  </ng-container>
</ng-container>