import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Client} from '../_models/client.model';
import {HttpUtilsService, QueryParamsModel} from '../../_base/crud';
import {environment} from '../../../../environments/environment';
import {ClientContactModel} from '../_models/client-contact.model';
import { MadAuthService } from '../../mad-auth/mad-auth.service';
import { SubdomainService } from '../../_base/subdomain.service';


@Injectable()
export class AdminService {
    API_CLIENTS_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/clients`;
    API_CONTACT_URL = environment.baseApiUrl + `api/${this.subDomainService.subDomain}/client-contacts`;
    constructor(private http: HttpClient, private authService: MadAuthService, private subDomainService: SubdomainService) {
    }

    // Clients
    getAllClients(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_CLIENTS_URL, {headers: httpHeaders});
    }

    getAllClientsMultimedia(): Observable<any> {
        return this.http.get<any>(
            `${environment.baseApiUrl}api/${this.subDomainService.subDomain}/multimedia/clients`
        );
    }

    getOpenExchangeRates(): Observable<any> {
        return this.http.get<any>('https://openexchangerates.org/api/latest.json?app_id=9ccf789ee53e48ec8aa34701aafc5495');
    }


    findClients(queryParams: QueryParamsModel): Observable<any> {
        // This code imitates server calls
        const httpHeaders = this.authService.getAuthHeaders();
        let params = new HttpParams()
            .set('sortOrder', queryParams.sortOrder)
            .set('sortField', queryParams.sortField)
            .set('page', queryParams.pageNumber.toString())
            .set('pageSize', queryParams.pageSize.toString())
        if (queryParams.filter) {
            Object.keys(queryParams.filter).forEach(key => {
                const val = queryParams.filter[key];
                if (typeof val == 'number')  {
                    params = params.set(`filter[${key}]`, val.toString());
                }
                if (typeof val == 'string' && val != '') {
                    params = params.set(`filter[${key}]`, val);
                }
                if (key == 'bounds' && val != null) {
                    params = params.set(`filter[bounds][sw_lat]`, val.sw_lat);
                    params = params.set(`filter[bounds][sw_lng]`, val.sw_lng);
                    params = params.set(`filter[bounds][ne_lng]`, val.ne_lng);
                    params = params.set(`filter[bounds][ne_lat]`, val.ne_lat);
                }
            })
        };
        return this.http.get<any>(this.API_CLIENTS_URL + '', {headers: httpHeaders, params: params});
    }

    getClientById(clientId: number): Observable<Client> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<Client>(this.API_CLIENTS_URL + `/${clientId}`, {headers: httpHeaders});
    }

    // CREATE =>  POST: add a new client to the server
    createClient(_client: Client, _contacts: ClientContactModel[]): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const body = {client: _client, contacts: _contacts};
        return this.http.post(this.API_CLIENTS_URL, body, {headers: httpHeaders});
    }

    // UPDATE => PUT: update the client on the server
    updateClient(client: Client): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_CLIENTS_URL + `/${client.id}`, client, {headers: httpHeaders});
    }

    // DELETE => delete the client from the server
    deleteClient(clientId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        const url = `${this.API_CLIENTS_URL}/${clientId}`;
        return this.http.delete(url, {headers: httpHeaders});
    }

    // TRASH
    getTrashedClients(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_CLIENTS_URL + `/trash`, {headers: httpHeaders});
    }

    // TRASH (Admin)
    getAdminTrashedClients(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get(this.API_CLIENTS_URL + `/admin/trash`, {headers: httpHeaders});
    }

    // delete from trash
    deleteFromTrash(clientId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_CLIENTS_URL + `/trash/${clientId}`, {headers: httpHeaders});
    }

    // delete from trash
    deleteFromAdminTrash(clientId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete<any>(this.API_CLIENTS_URL + `/admin/trash/${clientId}`, {headers: httpHeaders});
    }

    // restore from trash
    restoreFromTrash(clientId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_CLIENTS_URL + `/trash/${clientId}`, {headers: httpHeaders});
    }

    flushClientTrash(): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<any>(this.API_CLIENTS_URL + '/trash/flush', {headers: httpHeaders});
    }


    // Check Client Before deletion
    isClientAssignedToUsers(clientId: number): Observable<boolean> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.get<boolean>(this.API_CLIENTS_URL + '/checkIsRollAssignedToUser?clientId=' + clientId, {headers: httpHeaders});
    }

    // Contacts
    getContacts(clientId: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        if (!(clientId && clientId > 0)) {
            return this.http.get(this.API_CONTACT_URL);
        } else {
            return this.http.get(`${this.API_CONTACT_URL}?clientId=${clientId}`, {headers: httpHeaders});
        }
    }

    createContact(contact: ClientContactModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.post(this.API_CONTACT_URL, contact, {headers: httpHeaders});
    }


    deleteContact(id: number): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.delete(`${this.API_CONTACT_URL}/${id}`, {headers: httpHeaders});
    }

    // TRASH
    updateContact(contact: ClientContactModel): Observable<any> {
        const httpHeaders = this.authService.getAuthHeaders();
        return this.http.put(this.API_CONTACT_URL + `/${contact.id}`, contact, {headers: httpHeaders});
    }


    /*
     * Handle Http operation that failed.
     * Let the app continue.
    *
    * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: any) {
        return (error: any): Observable<any> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // Let the app keep running by returning an empty result.
            return of(result);
        };
    }
}
