// Angular
import {AfterViewChecked, AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
// RxJS
import {BehaviorSubject, combineLatest, Observable, of, Subject, Subscription, throwError} from 'rxjs';
// NGRX
import {select, Store} from '@ngrx/store';
import {Update} from '@ngrx/entity';
import {AppState} from '../../../../../core/reducers';
// Layout
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {LayoutUtilsService, MessageType, TypesUtilsService} from '../../../../../core/_base/crud';
// Services and Models
import {
    selectLastCreatedUserId,
    selectUserExistsStatus,
    selectUsersActionLoading,
    User,
    UserOnServerCreated,
    UserUpdated,
    UserService
} from '../../../../../core/auth';
import { MadRole, MadRolesRequested, selectRoles } from 'src/app/core/mad-auth/mad-roles.store';
import { currentUser, hasPermission, newUser } from 'src/app/core/mad-auth/mad-auth.store';
import {environment} from '../../../../../../environments/environment';
import {UploadFileComponent} from '../../../shared_components/upload-file/upload-file.component';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {AllUnitMeasurementsRequested, AllValuerQualificationsRequested, selectAllUnitMeasurements, selectAllValuerQualifications, UnitMeasurement, ValuerQualification} from '../../../../../core/linked-tables';
import {FileUploadService} from '../../../../../core/file-upload/_services';
import {CertificationFileModel} from '../../../../../core/file-upload';
import {CertificateEditDialogComponent} from '../../../../partials/content/crud/certificate-edit-dialog/certificate-edit-dialog.component';
import {catchError, filter, map, skip, takeUntil, tap} from 'rxjs/operators';
import {Location} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {emailAsyncValidator} from '../../../validators/custom-async-validator';
import {ImageViewerDialogComponent} from '../../../shared_components/image_viewer/image-viewer.dialog.component';
import { awConst } from 'src/app/app.constants';
import { TabHeader } from '../../../shared_components/tab-header/tab-header.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MembershipsTableComponent } from '../_subs/memberships-table/memberships-table.component';
import { OtherDocumentsComponent } from '../_subs/other-documents/other-documents.component';
import { TimezoneService } from 'src/app/core/system-settings/timezone.service';
import { MapsAPILoader } from '@agm/core';
import { MadAuthService } from 'src/app/core/mad-auth/mad-auth.service';

@Component({
    selector: 'kt-user-edit',
    styleUrls: ['./user-edit.component.scss'],
    templateUrl: './user-edit.component.html',
})
export class UserEditComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    // Public properties
    user: User;
    oldUser: User;
    loading$: Observable<boolean>;
    isPasswordTabSub = new BehaviorSubject<boolean>(false);
    userForm: UntypedFormGroup;
    hasFormErrors = false;
    // Private properties
    private subscriptions: Subscription[] = [];
    allRoles: MadRole[] = [];
    valuerQualifications: ValuerQualification[] = [];
    imgURL = new BehaviorSubject<string>('');
    awConst = awConst;

    file: File;
    uploadResponse = {status: '', message: '', filePath: ''};
    userNameExists$: Observable<boolean>;

    @ViewChild(MembershipsTableComponent, {static: false})
    private membershipTableComponent: MembershipsTableComponent;
    @ViewChild(OtherDocumentsComponent, {static: false})
    private otherDocComponents: OtherDocumentsComponent;

    basePath = '';
    certificates$ = new BehaviorSubject<CertificationFileModel[]>([]);

    selectedTab = 0;
    selectedtabchange: BehaviorSubject<number> = new BehaviorSubject(0);
    selectedtabchange$: Observable<number> = this.selectedtabchange.asObservable();
    tabHeaders: TabHeader[] = [
        {label: 'General Information', disabled: of(false)},
        {label: 'Valuation matter', disabled: of(false)},
        {label: 'Settings', disabled: of(false)},
    ]
    error:any = {
      msg: ' Missing Fields in total:',
      fields: []
    }
    units: UnitMeasurement[] = [];
    errors: Record<string, string[]> = {};

    canEdit$ = combineLatest([
      this.store.select(hasPermission(['user:edit'])),
      this.store.select(hasPermission(['profile:edit'])),
      this.store.select(currentUser).pipe(filter(user => !!user)),
      this.activatedRoute.params
    ]).pipe(map(([canEditUser, canEditProfile, currentUser, params]) => {
      if (canEditUser) {
        return true;
      }

      if (currentUser.is_account_user) {
        return true;
      }

      if (canEditProfile && params.id) {
        return currentUser.id == params.id
      }

      return false;
    }))

    timeZoneCtlr = new UntypedFormControl();
    primary_email = '';

    centerLat$ = new BehaviorSubject(environment.mapCenter.lat)
    centerLng$ = new BehaviorSubject(environment.mapCenter.lng)
    private map: google.maps.Map;
    private mapClickListener: google.maps.MapsEventListener
    @ViewChild('locationSearch', {static: false})
    public searchElementRef: ElementRef

    private _autoComplete: google.maps.places.Autocomplete = null;

    canUpdateRole$ = this.store.select(hasPermission(['update_role']))

    /**
     * Component constructor
     *
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param userFB: FormBuilder
     * @param subheaderService: SubheaderService
     * @param service
     * @param layoutUtilsService: LayoutUtilsService
     * @param fileUploadService
     * @param typesUtilsService
     * @param dialog
     * @param location
     * @param store: Store<AppState>
     * @param layoutConfigService: LayoutConfigService
     */
    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private userFB: UntypedFormBuilder,
                private subheaderService: SubheaderService,
                private service: UserService,
                private layoutUtilsService: LayoutUtilsService,
                private fileUploadService: FileUploadService,
                public typesUtilsService: TypesUtilsService,
                private dialog: MatDialog,
                private location: Location,
                private store: Store<AppState>,
                private layoutConfigService: LayoutConfigService,
                public timezoneService: TimezoneService,
                public ngZone: NgZone,
                private mapsApiLoader: MapsAPILoader,
                private _authService: MadAuthService) {
        this.basePath = environment.baseApiUrl;
        this.selectedTab = 0;
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
      this.userNameExists$ = this.store.pipe(
        select(selectUserExistsStatus)
      );

      this.loading$ = this.store.pipe(select(selectUsersActionLoading));

      const selectActivatedRoute = this.activatedRoute.data.subscribe(res => {
        if (res.data) {
          this.oldUser = Object.assign({}, res.data.data);
          this.user = this.oldUser;
          this.fetchPrimaryAccEmail();
          this.centerLat$.next(Number(this.user.settings.latitude))
          this.centerLng$.next(Number(this.user.settings.longitude))
          this.initUser();
          // if (this.user.id) {
          //   this.tabHeaders[3].disabled = of(false);
          // }
        }
      });

      this.subscriptions.push(selectActivatedRoute);

      const routeSubscription = this.activatedRoute.params.subscribe(params => {
        const id = params.id;
        if (id && id > 0) {
          // this.user = Object.assign({}, res);
          // this.oldUser = Object.assign({}, this.user);
          // this.initUser();
          // this.store.pipe(select(selectUserById(id))).subscribe(res => {
          //     if (res) {
          //         this.user = Object.assign({}, res);
          //         this.oldUser = Object.assign({}, this.user);
          //         this.initUser();
          //     }
          // });
        } else {
          this.user = newUser();
          this.oldUser = Object.assign({}, this.user);
          this.initUser();
          // this.tabHeaders[3].disabled = of(true);
        }
      });
      this.subscriptions.push(routeSubscription);
      this.store.dispatch(new MadRolesRequested({query: {page: -1, page_size: -1}}))
      const allUserRoles = this.store.pipe(select(selectRoles)).subscribe((res: MadRole[]) => {
        this.allRoles = Object.assign([], res);
      });
      this.subscriptions.push(allUserRoles);


      this.store.dispatch(new AllValuerQualificationsRequested());
      const valuerQualificationsSubscribe = this.store.pipe(
        // distinctUntilChanged(),
        select(selectAllValuerQualifications))
        .subscribe(res => {
          this.valuerQualifications = [];
          if (res) {
            this.valuerQualifications = res;
          }
        });
      this.subscriptions.push(valuerQualificationsSubscribe);

      this.store.dispatch(new AllUnitMeasurementsRequested());
      const unitsSubscription = this.store.pipe(
        select(selectAllUnitMeasurements))
        .subscribe(res => {
          this.units = [];
          if (res) {
            this.units = res;
          }
        });
      this.subscriptions.push(unitsSubscription);

      const timezoneSub = this.timeZoneCtlr.valueChanges
        .subscribe(value => {
          this.timezoneService.setFilter(value)
        })
      this.subscriptions.push(timezoneSub)


    }

    ngAfterViewInit(): void {
      this.mapsApiLoader.load().then(() => {
        this._loadAutoComplete()
      })
    }

    ngAfterViewChecked(): void {
      if (this.searchElementRef && this.userForm.controls.override_system_location.value) {
        this._loadAutoComplete()
      } else if (!this.userForm.controls.override_system_location.value) {
        this._autoComplete = null
      }
    }

    private _loadAutoComplete() {
      if (!this.searchElementRef) return
      if (this._autoComplete != null) return
      this._autoComplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: ['(cities)']
      })
      this._autoComplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place = this._autoComplete.getPlace()
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();
          this.centerLat$.next(latitude)
          this.centerLng$.next(longitude)
        })
      })
    }

    ngOnDestroy() {
      this.subscriptions.forEach(sb => sb.unsubscribe());
      if (this.mapClickListener) {
        this.mapClickListener.remove();
      }
    }

    /**
     * Init user
     */
    initUser() {
      this.certificates$.next(this.user.certificates);
      this.createForm();
    }

    /**
     * Create form
     */
    createForm() {
      this.userForm = this.userFB.group({
        first_name: [this.user.first_name, Validators.required],
        last_name: [this.user.last_name, Validators.required],
        email: [{value: this.user.email, disabled: true}, [Validators.required, Validators.email], emailAsyncValidator(this.service, this.user)],
        role_id: [this.user.mad_role_id, Validators.required],
        qualification_id: [this.user.qualification_id, Validators.required],
        valuer_experience: [this.user.valuer_experience, (this.user.qualification_id == 1 ? Validators.required : null)],
        insurance_policy: [this.user.insurance_policy, (this.user.qualification_id == 1 ? Validators.required : null)],
        standard_limitations: [this.user.standard_limitations, (this.user.qualification_id == 1 ? Validators.required : null)],
        picture: [''],
        is_active: [{value: this.user.is_active, disabled: true}, Validators.required],
        unit_of_measurement_id: [this.user.settings.unit_of_measurement_id],
        pronouns: [''],
        timezone: [this.user.settings.time_zone ?? Intl.DateTimeFormat().resolvedOptions().timeZone],
        override_system_location: [!this.user.settings.use_system_location]
      });

      if (this.user.picture && this.user.picture.length > 0) {
        this.imgURL.next(environment.baseApiUrl + this.user.picture);
      } else {
        this.imgURL.next('./assets/media/users/default.jpg');
      }
    }

    /**
     * Redirect to list
     *
     */
    goBackWithId() {
      const url = `${this.layoutConfigService.getCurrentMainRoute()}/user-management/users`;
      this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    back() {
      // this.router.navigate(['../..'], {relativeTo: this.activatedRoute})
      this.location.back()
    }

    /**
     * Refresh user
     *
     * @param isNew: boolean
     * @param id: number
     */
    refreshUser(isNew: boolean = false, id = 0) {
      let url = this.router.url;
      if (!isNew) {
        this.router.navigate([url], {relativeTo: this.activatedRoute});
        return;
      }
      url = `${this.layoutConfigService.getCurrentMainRoute()}/user-management/users/edit/${id}`;
      this.router.navigate([url], {relativeTo: this.activatedRoute});
    }

    /**
     * Reset
     */
    reset() {
      this.user = Object.assign({}, this.oldUser);
      this.createForm();
      this.hasFormErrors = false;
      this.userForm.markAsPristine();
      this.userForm.markAsUntouched();
      this.userForm.updateValueAndValidity();
    }

    /**
     * Save data
     *
     * @param withBack: boolean
     */
    onSubmit(withBack: boolean = false) {
      let errorFields = [];
      this.hasFormErrors = false;
      const controls = this.userForm.controls;
      /** check form */
      if (this.userForm.invalid) {
        Object.keys(controls).forEach(controlName => {
            if (controls[controlName].invalid) {
              errorFields = [...errorFields, controlName];
            }
            controls[controlName].markAsTouched();
          }
        );

        this.hasFormErrors = true;
      }

      if (this.membershipTableComponent) {
        const validation = this.membershipTableComponent.validate(controls.qualification_id.value);
        if (!validation.isValid && validation.error != null) {
          this.hasFormErrors = true;
          errorFields = [...errorFields, validation.error.key];
          this.errors['membership'] = [validation.error.message]
        }
      }

      if (this.hasFormErrors) {
        this.error.fields = errorFields;
        return;
      }

      const editedUser = this.prepareUser();

      if (editedUser.id > 0) {
        this.updateUser(editedUser);
        return;
      }

      this.addUser(editedUser, true);
    }

    /**
     * Returns prepared data for save
     */
    prepareUser(): User {
      const controls = this.userForm.controls;
      const _user = newUser();
      _user.id = this.user.id;
      _user.first_name = controls.first_name.value;
      _user.last_name = controls.last_name.value;
      _user.email = controls.email.value;
      _user.valuer_experience = controls.valuer_experience.value ? controls.valuer_experience.value : '';
      _user.insurance_policy = controls.insurance_policy.value ? controls.insurance_policy.value : '';
      _user.standard_limitations = controls.standard_limitations.value ? controls.standard_limitations.value : '';
      _user.picture = this.user.picture;
      _user.mad_role_id = controls.role_id.value;
      _user.qualification_id = controls.qualification_id.value;
      if (this.otherDocComponents) {
        _user.path = this.user.path && this.user.path.length > 0
          ? this.user.path
          : this.otherDocComponents.path;
        _user.reports = this.otherDocComponents.documents;
      }
      if (this.membershipTableComponent) {
        _user.certificates = this.membershipTableComponent.getData();
      }
      _user.is_active = controls.is_active.value;
      _user.settings.unit_of_measurement_id = controls.unit_of_measurement_id.value;
      _user.settings.time_zone = controls.timezone.value;
      _user.settings.time_zone_gmt = this.timezoneService.getGmt(_user.settings.time_zone);
      _user.settings.use_system_location = controls.override_system_location.value != true
      _user.settings.latitude = this.centerLat$.value
      _user.settings.longitude = this.centerLng$.value

      return _user;
    }

    /**
     * Add User
     *
     * @param _user: User
     * @param withBack: boolean
     */
    addUser(_user: User, withBack: boolean = false) {
      this.store.dispatch(new UserOnServerCreated({user: _user}));
      this.oldUser = _user;
      const addSubscription = this.store.pipe(select(selectLastCreatedUserId)).subscribe(newId => {
        this.goBackWithId();
      });
      this.subscriptions.push(addSubscription);
    }

    /**
     * Update user
     *
     * @param _user: User
     */
    updateUser(_user: User) {
      // tslint:disable-next-line:prefer-const
      const updatedUser: Update<User> = { id: _user.id, changes: _user };
      this.store.dispatch(new UserUpdated({ partialUser: updatedUser, user: _user }));
      this.oldUser = _user;
      this.layoutUtilsService.showActionNotification('Saved the changes', MessageType.Update, 3000, true, false);
      this.location.back()
    }

    /**
     * Returns component title
     */
    getComponentTitle() {
      return this.canEdit$.pipe(map(canEdit => {
        let result = 'Create user';
        if (!this.user || !this.user.id) {
          return result;
        }

        result = `Edit user - ${this.user.first_name} ${this.user.last_name}` + (canEdit ? '' : ' (View Only)');
        return result;
      }))
    }

    /**
     * Close Alert
     *
     * @param $event: Event
     */
    onAlertClose($event) {
      this.hasFormErrors = false;
    }

    onSelectFile(event) {
      if (event.target.files && event.target.files[0]) {
        this.file = event.target.files[0];

        const formData = new FormData();
        formData.append('file', this.file, `user-${this.user && this.user.id ? this.user.id : 'temp'}.jpeg`);

        this.service.upload(formData).subscribe(
          (res) => {
            this.uploadResponse = res;

            if (this.uploadResponse.status == 'done') {
              this.imgURL.next(this.basePath + res.filePath);
              const puser = Object.assign({}, this.user);
              puser.picture = res.filePath;
              this.user = puser;
            }
          },
          (err) => this.error = err
        );
      }
    }
    selectQualification(e) {
      if (e.value == 1) {
        this.userForm.controls.valuer_experience.setValidators([Validators.required]);
        this.userForm.controls.valuer_experience.updateValueAndValidity();

        this.userForm.controls.insurance_policy.setValidators([Validators.required]);
        this.userForm.controls.insurance_policy.updateValueAndValidity();
        this.userForm.controls.standard_limitations.setValidators([Validators.required]);
        this.userForm.controls.standard_limitations.updateValueAndValidity();
        this.userForm.updateValueAndValidity();
      } else {
        this.userForm.controls.valuer_experience.setValidators(null);
        this.userForm.controls.valuer_experience.updateValueAndValidity();

        this.userForm.controls.insurance_policy.setValidators(null);
        this.userForm.controls.insurance_policy.updateValueAndValidity();

        this.userForm.controls.standard_limitations.clearValidators();
        this.userForm.controls.standard_limitations.updateValueAndValidity();

        this.userForm.updateValueAndValidity();
      }
    }

    canDeactivate() {
      if (this.discard()) {
        if (window.confirm('Are you sure? All unsaved changes will be lost.')) {
          return true;
        } else {
          // ---------work around angular bug--------- reference: https://github.com/angular/angular/issues/13586
          const currentUrlTree = this.router.createUrlTree([], this.activatedRoute.snapshot);
          const currentUrl = currentUrlTree.toString();
          this.location.go(currentUrl);
          // ---------work around end-----------------
          return false;
        }
      }
      return true;
    }

    discard() {
      const _user = this.prepareUser();

      if (_user.first_name != this.oldUser.first_name ||
        _user.last_name != this.oldUser.last_name ||
        _user.email != this.oldUser.email ||
        _user.valuer_experience != this.oldUser.valuer_experience ||
        _user.insurance_policy != this.oldUser.insurance_policy ||
        _user.picture != this.oldUser.picture ||
        _user.role_id != this.oldUser.role_id ||
        _user.qualification_id != this.oldUser.qualification_id) {
        return true;
      } else {
        return false;
      }
    }
    public imgOnError(e) {
      e.target.src = awConst.IMAGE_NOT_FOUND
    }
    onTabChanged($event) {
      this.isPasswordTabSub.next($event.index == 3);
      const activeIndex = $event.index;
    }
    onTabChange(index: number) {
      this.selectedTab = index;
    }
    changeTab(section: string) {
      const section_tab = [
        {sections: ['location', 'building'], tab_index: 0},
        {sections: ['prop'], tab_index: 1},
        {sections: ['consideration'], tab_index: 2},
        {sections: ['pic'], tab_index: 3},
      ]
      const active_Tab = section_tab.find(item => item.sections.includes(section));
      this.selectedTab = active_Tab ? active_Tab.tab_index : 0;
      this.selectedtabchange.next(this.selectedTab);
    }
    erroTabMap() {
      return this.typesUtilsService.getUserTabErrorMap(this.error.fields, this.tabHeaders);
    }
    onHasFormErrorsChange(obj: {hasFormErrors: boolean}) {
      this.hasFormErrors = obj.hasFormErrors;
    }

    isExpired(dateValue) {
      if (dateValue == null || dateValue == undefined || dateValue.length == 0) {
        return false;
      }
      const date = this.typesUtilsService.getDateFromString(dateValue);
      return this.typesUtilsService.compareTwoDate(date, new Date()) <= 0;
    }

  onMapReady(event: any) {
    this.map = event
    this.mapClickListener = this.map.addListener('rightclick', (e: google.maps.MouseEvent) => {
        this.ngZone.run(() => {
          this.centerLat$.next(e.latLng.lat());
          this.centerLng$.next(e.latLng.lng());
        })
    })
  }

  fetchPrimaryAccEmail() {
    this._authService.primaryUserEmail().then((primary_acc_email) => {
      this.primary_email = primary_acc_email;

      const controls = this.userForm.controls;
      if (this.user.email == this.primary_email) {
        controls['is_active'].disable()
        controls['role_id'].disable()
      } else {
        controls['is_active'].disable() //NOTE: latest change ready only always
        controls['role_id'].enable()
      }
    })
  }
}
