import { Injectable } from "@angular/core";

export interface FormErrorGrouped {
  section: string
  fields: FormErrorField[]
}
interface FormErrorField {
  name: string
}

export type FormErrorMessageType = 'missing-fields'
export type FormType = 'consideration'

export interface FormError {
  formType: FormType
  fields: string[]
  messageType: FormErrorMessageType
  message?: string
}

@Injectable()
export class FormErrorService {
  getFields(error: FormError): FormErrorGrouped[] {
    if (error == null) return []
    if (error.formType === 'consideration') return this._considerationFormError(error.fields)
    return []
  }

  private _considerationFormError(fields: string[]): FormErrorGrouped[] {
    const _fields = [
      {
        section: 'Currency',
        fields: [
          {control_name: 'currency', name: 'Currenty'}
        ]
      },
      {
        section: 'Tenure and Offer',
        fields: [
          {control_name: 'tenure', name: 'Tenure'},
          {control_name: 'consideration_type_id', name: 'Consideration Type'}
        ]
      },
      {
        section: 'Freehold Term',
        fields: [
          {control_name: 'sale_input_amount', name: 'Total Consideration'},
          {control_name: 'rent_type', name: 'Rent Type'},
          {control_name: 'duration', name: 'Lease Duration'},
          {control_name: 'rent_input_amount_type', name: 'Rent Input Amount'}
        ]
      },
      {
        section: 'Sources',
        fields: [
          {control_name: 'source_type_id', name: 'Source Type'},
          {control_name: 'source_information_id', name: 'Source Information'},
          {control_name: 'source_credibility_id', name: 'Source Credibility'},
          {control_name: 'information_date', name: 'Date of Information'},
          {control_name: 'transaction_date', name: 'Date of Transaction'},
        ]
      }
    ]

    return _fields
      .map(section => {
        return {
          ...section,
          fields: section.fields
            .map(field => {
              if (fields.includes(field.control_name)) return {name: field.name} 
              return null
            })
            .filter(field => field !== null)
        }
      })
      .filter(section => section.fields.length > 0)
  }
}