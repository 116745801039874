import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { startsWith, uniq } from 'lodash';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { startWith, takeUntil } from 'rxjs/operators';
import { AllFloorNumberingSchemesRequested, AllFoundationTypesRequested, AllGradesRequested, AllHandoverStandardsRequested, AllParkingTypesRequested, AllStateRepairsRequested, FloorNumberingScheme, FoundationType, Grade, HandoverStandard, ParkingTypeModel, selectAllFloorNumberingSchemes, selectAllFoundationTypes, selectAllGrades, selectAllHandoverStandards, selectAllParkingTypes, selectAllStateRepairs, StateRepair } from 'src/app/core/linked-tables';
import { AllSubCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-category.actions';
import { AllSubTypeCategorysRequested } from 'src/app/core/linked-tables/_actions/sub-type-category.actions';
import { SubCategoryModel } from 'src/app/core/linked-tables/_models/sub-category.model';
import { SubTypeCategoryModel } from 'src/app/core/linked-tables/_models/sub-type-category.model';
import { selectAllSubCategorys } from 'src/app/core/linked-tables/_selectors/sub-category.selectors';
import { selectAllSubTypeCategorys } from 'src/app/core/linked-tables/_selectors/sub-type-category.selectors';
import { AppState } from 'src/app/core/reducers';
import { AccommodationLayoutTableComponent } from '../accommodation-layout-table/accommodation-layout-table.component';
import { Rooms } from '../size-module/mongolia-standard-table/mongolia-standard-table.types';

@Component({
  selector: 'kt-about-property',
  templateUrl: './about-property.component.html',
  styleUrls: ['./about-property.component.scss']
})
export class AboutPropertyComponent implements OnInit, OnDestroy {
  @Input() propertySubTypeID: number;
  @Input() inputData: {
    sub_type_category_id: number;
    sub_category_id: number;
    property_grade_id: number;
    general_desc: string;
    completion_year: string;
    apprx_year_extended: string;
    state_of_repair_id: number;
    handover_standard_id: number;
    foundation_type_id: number;
    above_floors: number;
    below_floors: number;
    construction_desc: string;
    floor_details: string;
    num_of_units: number;
    parking_type_id: number;
    automation: number;
    automation_type: string;
    floor_numbering_scheme_id: number;
  }
  @Input() accommodation: {
    id: number;
    data: any;
    others: any;
  }
  @Output() floorChanged = new EventEmitter<{floors: number[], scheme_id: number}>();
  @Output() subTypeCategoryChanged = new EventEmitter<number>();
  @Output() roomChange = new EventEmitter<Rooms>();

  @ViewChild(AccommodationLayoutTableComponent, {static: false})
  accommodationLayoutTableComponent: AccommodationLayoutTableComponent;

  form: UntypedFormGroup

  grades: Grade[] = [];
  parkingTypes: ParkingTypeModel[] = [];
  subTypeCategories: SubTypeCategoryModel[] = [];
  subCategories: SubCategoryModel[] = [];
  filteredSubCategories: SubCategoryModel[] = [];
  stateOfRepairs: StateRepair[] = [];
  handoverStandards: HandoverStandard[] = [];
  foundationTypes: FoundationType[] = [];
  floorNumberingSchemes: FloorNumberingScheme[] = [];
  aboveFloor$: BehaviorSubject<number> = new BehaviorSubject(0);
  belowFloor$: BehaviorSubject<number> = new BehaviorSubject(0);

  private subscriptions: Subscription[] = [];
  private _onDestroy: Subject<void> = new Subject();
  private _floors$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  public floors$: Observable<number[]> = this._floors$.asObservable();

  fieldVisibility = {
    property_grade_id: true,
    completion_year: true,
    apprx_year_extended: true,
    foundation_type_id: true,
    above_floors: true,
    below_floors: true,
    floor_details: false,
    construction_desc: true,
    number_of_units: false,
    parking_type_id: false,
    automation: false
  }
  errorFields = [];
  no0Floor: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>
  ) { }

  ngOnInit(): void {
    this.no0Floor = this.inputData.floor_numbering_scheme_id == 2;
    switch (this.propertySubTypeID) {
      case 1:
        this.fieldVisibility = {
          ...this.fieldVisibility,
          property_grade_id: false,
          completion_year: false,
          apprx_year_extended: false,
          above_floors: false,
          foundation_type_id: false,
          below_floors: false,
          floor_details: true,
          construction_desc: false,
          number_of_units: false,
          parking_type_id: false,
          automation: false
        }
        this.floorChanged.emit({floors: this._getFloorsFrom(this.inputData.floor_details), scheme_id: this.inputData.floor_numbering_scheme_id});
        this._floors$.next(this._getFloorsFrom(this.inputData.floor_details))
        break;
      case 3: 
        this.fieldVisibility = {
          ...this.fieldVisibility,
          property_grade_id: false,
          completion_year: false,
          apprx_year_extended: false,
          above_floors: false,
          foundation_type_id: false,
          below_floors: false,
          floor_details: true,
          construction_desc: false,
          number_of_units: false,
          parking_type_id: false,
          automation: false
        }
        this.floorChanged.emit({floors: this._getFloorsFrom(this.inputData.floor_details), scheme_id: this.inputData.floor_numbering_scheme_id});
        this._floors$.next(this._getFloorsFrom(this.inputData.floor_details))
        break;
      case 5:
        this.fieldVisibility = {
          ...this.fieldVisibility, 
          property_grade_id: false,
          completion_year: false,
          apprx_year_extended: false,
          foundation_type_id: false,
          above_floors: false,
          below_floors: false,
          floor_details: true,
          construction_desc: false,
        }
        this.floorChanged.emit({floors: this._getFloorsFrom(this.inputData.floor_details), scheme_id: this.inputData.floor_numbering_scheme_id});
        this._floors$.next(this._getFloorsFrom(this.inputData.floor_details))
        break;
      case 2:
        this.fieldVisibility = {
          ...this.fieldVisibility,
          property_grade_id: false,
          completion_year: false,
          apprx_year_extended: false,
          foundation_type_id: false,
          above_floors: false,
          below_floors: false,
          floor_details: true,
          construction_desc: false,
          number_of_units: true,
          parking_type_id: true,
          automation: true
        }
        this.floorChanged.emit({floors: this._getFloorsFrom(this.inputData.floor_details), scheme_id: this.inputData.floor_numbering_scheme_id});
        this._floors$.next(this._getFloorsFrom(this.inputData.floor_details))
        break;
      default:
        this._floors$.next(this._getFloorsFromAboveBelowFromAc());
        break;
    }
    this.form = this.formBuilder.group({
      sub_type_category_id: [this.inputData.sub_type_category_id, Validators.required],
      sub_category_id: [this.inputData.sub_category_id, Validators.required],
      property_grade_id: [this.inputData.property_grade_id, this.fieldVisibility.property_grade_id ? Validators.required : null],
      general_desc: [this.inputData.general_desc, this.propertySubTypeID == 1 ? null : Validators.required],
      completion_year: [this.inputData.completion_year, this.fieldVisibility.completion_year ? Validators.required : null],
      apprx_year_extended: [this.inputData.apprx_year_extended],
      state_of_repair_id: [this.inputData.state_of_repair_id, Validators.required],
      handover_standard_id: [this.inputData.handover_standard_id, Validators.required],
      foundation_type_id: [this.inputData.foundation_type_id],
      above_floors: [this.inputData.above_floors, this.fieldVisibility.above_floors ? Validators.required : null],
      below_floors: [this.inputData.below_floors, this.fieldVisibility.below_floors ? Validators.required : null],
      construction_desc: [this.inputData.construction_desc],
      floor_details: [this.inputData.floor_details && this.inputData.floor_details.length > 0 
          ? this.inputData.floor_details.split(',')
          : '', this.fieldVisibility.floor_details ? Validators.required : null],
      number_of_units: [this.inputData.num_of_units, this.fieldVisibility.number_of_units ? Validators.required : null],
      parking_type_id: [this.inputData.parking_type_id, this.fieldVisibility.parking_type_id ? Validators.required : null],
      automation: [this.inputData.automation, this.fieldVisibility.automation ? Validators.required : null],
      automation_type: [this.inputData.automation_type],
      floor_numbering_scheme_id: [this.inputData.floor_numbering_scheme_id]
    });
    // this.form.controls.above_floors.valueChanges.pipe(
    //   startWith(this.form.controls.above_floors.value),
    //   takeUntil(this._onDestroy)
    // ).subscribe(val => this.aboveFloor$.next(val));

    // this.form.controls.below_floors.valueChanges.pipe(
    //   startWith(this.form.controls.below_floors.value),
    //   takeUntil(this._onDestroy)
    // ).subscribe(val => this.belowFloor$.next(val));

    this.form.controls.sub_type_category_id.valueChanges.pipe(
      startWith(this.form.controls.sub_type_category_id.value),
      takeUntil(this._onDestroy)
    ).subscribe(val => {
      this.filteredSubCategories = this._filterSubCategories(val)
      if (this.propertySubTypeID == 2) {
        this.fieldVisibility.floor_details = val == 20;
      }


      if (this.filteredSubCategories.length == 0) {
        this.form.controls.sub_category_id.clearValidators();
      } else {
        this.form.controls.sub_category_id.setValidators([Validators.required]);
      }
      this.form.controls.sub_category_id.updateValueAndValidity();

      this.subTypeCategoryChanged.emit(val);
    })

    this.form.controls.floor_numbering_scheme_id.valueChanges
      .pipe(
        startWith(this.form.controls.floor_numbering_scheme_id.value),
        takeUntil(this._onDestroy)
      )
      .subscribe(val => {
        this.no0Floor = val == 2;
        if (val == 2) {
          _changeFloorsValuated(this.form.controls.floor_details.value, this.form.controls.floor_details);
          this.floorChanged.emit({floors: this._floors$.value.filter(f => {
            if (this.no0Floor && f == 0) {
              return false;
            }
            return true;
          }), scheme_id: val})
          this._floors$.next(this._floors$.value.filter(f => {
            if (this.no0Floor && f == 0) {
              return false;
            }
            return true;
          }));
        } else {
          if (!this.fieldVisibility['floor_details']) {
            this._floors$.next(this._getFloorsFromAboveBelow());
            this.floorChanged.emit({floors: this._getFloorsFromAboveBelow(), scheme_id: val})
          } else {
            this.floorChanged.emit({floors: this._floors$.value, scheme_id: val})
          }
        }
      })

    this.form.controls.floor_details.valueChanges
      .pipe(
        takeUntil(this._onDestroy)
      )
      .subscribe(val => {
        if (this.form.controls.floor_numbering_scheme_id.value == 2) {
          _changeFloorsValuated(val, this.form.controls.floor_details);
        }
      })

    this.store.dispatch(new AllGradesRequested());
    const gradeSubscription = this.store.pipe(
        select(selectAllGrades))
        .subscribe(res => {
            this.grades = [];
            if (res) {
                this.grades = res;
            }
        });
    this.subscriptions.push(gradeSubscription);

    this.store.dispatch(new AllSubTypeCategorysRequested());
    const subTypeSub = this.store.pipe(
      select(selectAllSubTypeCategorys)
    ).subscribe(res => {
      this.subTypeCategories = res ? res.filter(item => item.property_sub_type_id == this.propertySubTypeID) : [];
    });
    this.subscriptions.push(subTypeSub);

    this.store.dispatch(new AllSubCategorysRequested());
    const subCatSub = this.store.select(selectAllSubCategorys)
      .subscribe(res => {
        this.subCategories = res ? res : [];
        this.filteredSubCategories = this._filterSubCategories(this.inputData.sub_type_category_id);
      });
    this.subscriptions.push(subCatSub);

    this.store.dispatch(new AllStateRepairsRequested())
    const stateRepairSub = this.store.select(selectAllStateRepairs)
      .subscribe(res => {
        this.stateOfRepairs = res ? res : [];
      });
    this.subscriptions.push(stateRepairSub);

    this.store.dispatch(new AllHandoverStandardsRequested())
    const handoverSub = this.store.select(selectAllHandoverStandards)
      .subscribe(res => {
        this.handoverStandards = res ? res : []
      });
    this.subscriptions.push(handoverSub);

    this.store.dispatch(new AllFoundationTypesRequested())
    const foundationSub = this.store.select(selectAllFoundationTypes)
      .subscribe(res => {
        this.foundationTypes = res ? res : []
      });
    this.subscriptions.push(foundationSub);

    this.store.dispatch(new AllParkingTypesRequested())
    const parkingTypeSub = this.store.select(selectAllParkingTypes)
      .subscribe(res => {
        this.parkingTypes = res ? res : []
      });
    this.subscriptions.push(parkingTypeSub);

    this.store.dispatch(new AllFloorNumberingSchemesRequested())
    const floorNumberingSchemeSub = this.store.select(selectAllFloorNumberingSchemes)
      .subscribe(res => {
        this.floorNumberingSchemes = res ? res : []
      });
    this.subscriptions.push(floorNumberingSchemeSub);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private _filterSubCategories(stcId: number) {
    if (stcId == null) {
      return [];
    }
    return this.subCategories.filter(item => item.sub_type_category_id == stcId);
  }

  private _getFloorsFromAboveBelowFromAc(): number[] {
    const l = -1 * (this.inputData.below_floors ? this.inputData.below_floors : 0);
    const h = (this.inputData.above_floors ? this.inputData.above_floors : 0);
    const numbers: number[] = [];
    for (let i = l; i < 0; i++) {
      numbers.push(i);
    }
    for (let i = 0; i <= h; i++) {
      numbers.push(i);
    }
    return numbers.filter(n => {
      if (this.no0Floor && n == 0) {
        return false;
      }
      return true;
    });
  }

  private _getFloorsFromAboveBelow(): number[] {
    const l = -1 * (this.form.controls.below_floors.value ? this.form.controls.below_floors.value : 0);
    const h = this.form.controls.above_floors.value ? this.form.controls.above_floors.value : 0;
    const numbers: number[] = [];
    for (let i = l; i <= h; i++) {
      numbers.push(i);
    }
    return numbers.filter(n => {
      if (this.no0Floor && n == 0) {
        return false;
      }
      return true;
    });
  }

  onFloorInput(event: any, type: 'above' | 'below') {
    const val = event.target.value;
    let l = 0;
    let h = 0;
    switch (type) {
      case 'above': {
        l = -1 * (this.form.controls.below_floors.value ? this.form.controls.below_floors.value : 0);
        h = val;
        break;
      }
      case 'below': {
        l = -1 * val;
        h = this.form.controls.above_floors.value ? this.form.controls.above_floors.value : 0;
        break;
      }
      default: {
        return;
      }
    }
    const numbers: number[] = [];
    for (let i = l; i < 0; i++) {
      numbers.push(i);
    }
    for (let i = 0; i <= h; i++) {
      numbers.push(i);
    }

    const _numbers = numbers.filter(n => {
      if (this.no0Floor && n == 0) {
        return false;
      }
      return true;
    });
    this.floorChanged.emit({floors: _numbers, scheme_id: this.form.controls.floor_numbering_scheme_id.value});
    this._floors$.next(_numbers);
  }

  checkValidation(isComplete: boolean): boolean {
    let errorFields = [];
    let isValid = true;
    const controls = this.form.controls;
    if (isComplete && this.form.invalid) {
      Object.keys(controls).forEach(cname => {
        if (controls[cname].invalid) {
          if (cname == 'completion_year') {
            errorFields.push('completion_year_about');
          } else {
            errorFields.push(cname);
          }
        }
        controls[cname].markAsTouched();
      })
      isValid = false;
    }
    this.errorFields = errorFields;
    return isValid;
  }

  getData(): {
    about: AboutPropertyComponent['inputData']
    accommodation: AboutPropertyComponent['accommodation']
  } {
    const controls = this.form.controls;
    return {
      about: {
        sub_type_category_id: controls.sub_type_category_id.value,
        sub_category_id: controls.sub_category_id.value,
        property_grade_id: controls.property_grade_id.value,
        general_desc: controls.general_desc.value,
        completion_year: controls.completion_year.value,
        apprx_year_extended: controls.apprx_year_extended.value,
        state_of_repair_id: controls.state_of_repair_id.value,
        handover_standard_id: controls.handover_standard_id.value,
        foundation_type_id: controls.foundation_type_id.value,
        above_floors: controls.above_floors.value,
        below_floors: controls.below_floors.value,
        construction_desc: controls.construction_desc.value,
        floor_details: controls.floor_details.value.length > 0 
            ? controls.floor_details.value.join(',') 
            : '',
        num_of_units: controls.number_of_units.value,
        parking_type_id: controls.parking_type_id.value,
        automation: controls.automation.value,
        automation_type: controls.automation_type.value,
        floor_numbering_scheme_id: controls.floor_numbering_scheme_id.value 
      },
      accommodation: this.accommodationLayoutTableComponent 
        ? {...this.accommodationLayoutTableComponent.getData(), id: this.accommodation.id}
        : {data: null, others: [], id: this.accommodation.id}
    }
  }

  floorValidators: ValidatorFn[] = [
    (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;
      if (value == "" || value == undefined || value == null) {
        return {error: 'Value must be provided'}
      }
      if (!isNaN(parseInt(value))) {
        return null
      }
      const splits = value.split(":")
      if (splits.length == 2) {
        if (!isNaN(parseInt(splits[0])) && !isNaN(parseInt(splits[1]))) {
          return null;
        }
      }
      return {error: 'Floor error'}
    }
  ]
    onFloorAdd(event) {
        const floorStr = event.value;
        let floors = this._getFloors(floorStr);
        if (this.form.controls.floor_numbering_scheme_id.value == 2) {
          floors = floors.filter(f => f != 0);
        }
        const _floors = this._floors$.value;
        floors.forEach(floor => {
            if (_floors.findIndex(f => f == floor) > -1) {
                return;
            }
            _floors.push(floor);
        });
        _floors.sort();
        this.floorChanged.emit({floors: _floors, scheme_id: this.form.controls.floor_numbering_scheme_id.value});
        this._floors$.next(_floors);
    }
    onFloorRemove(event) {
      const existingFloors = this._getFloorsFromControl(this.form.controls.floor_details.value);
        const floors = this._getFloors(event);
        const _floors = this._floors$.value;
        floors.forEach(floor => {
            const index = _floors.findIndex(f => f == floor);
            if (index > -1) {
              const fIndex = existingFloors.findIndex(f => f == floor);
              if (fIndex == -1) {
                _floors.splice(index, 1);
              }
            }
        });
        _floors.sort();
        this.floorChanged.emit({floors: _floors, scheme_id: this.form.controls.floor_numbering_scheme_id.value});
        this._floors$.next(_floors);
    }

    private _getFloors(raw: string): number[] {
        const splits = raw.split(':');
        const floors: number[] = []
        if (splits.length == 2) {
          if (splits[0] == "")  {
            floors.push(parseInt(raw));
          } else {
            const lower = parseInt(splits[0]);
            const upper = parseInt(splits[1]);
            for (let i = lower; i <= upper; i++) {
                floors.push(i);
            }
          }
        } else {
            floors.push(parseInt(splits[0]));
        }
        return floors;
    }

    private _getFloorsFrom(data: string) {
        const floorRaws = data == null ? [] : data.split(',');
        let floors = [];
        floorRaws.forEach(raw => {
            const _floors = this._getFloors(raw);
            _floors.forEach(f => floors.push(f));
        });
        floors = uniq(floors);
        return floors;
    }

    private _getFloorsFromControl(data: string[]) {
        let floors = [];
        data.forEach(raw => {
            const _floors = this._getFloors(raw);
            _floors.forEach(f => floors.push(f));
        });
        floors = uniq(floors);
        return floors;
    }

    onRoomChanged(event: Rooms) {
      this.roomChange.emit(event);
    }
}

function _changeFloorsValuated(val: any, control: AbstractControl) {
  if ((typeof val) != 'string') {
    const _val = val.map(v => {
      const splits = v.split(':');
      if (splits.length == 2 && splits[0] == '0') {
        return {value: `1-${splits[1]}`, update: true}
      } else if (splits.length == 1 && splits[0] == '0') {
        return {value: null, update: true}
      }
      return {value: v, update: false}
    })
    const shouldUpdate = _val.find(v => v.update);
    if (shouldUpdate) {
      control.setValue(_val.filter(v => v.value != null).map(v => v.value));
      control.updateValueAndValidity();
    }
  }
}