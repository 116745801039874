import {AgmMap, MapsAPILoader} from '@agm/core';
import {Location} from '@angular/common';
import {Component, OnDestroy, OnInit, ViewChild, AfterViewInit, ElementRef, NgZone} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {Store, select} from '@ngrx/store';
import {BehaviorSubject, combineLatest, fromEvent, merge, Observable, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
// tslint:disable-next-line: max-line-length
import {
    AcSource, AssetClassOfficeDeletedFromAdminTrash, AssetClassOfficeDeletedFromTrash, AssetClassOfficeMapPageRequested,
    AssetClassOfficeModel, AssetClassOfficeOnServerAdminRestored, AssetClassOfficeOnServerRestored, AssetClassOfficesDataSource,
    AssetClassOfficesService,
    OfficeTrashFlushed,
    OneAssetClassOfficeDeleted,
    selectAdminTrashedAssetClassOfficeCount,
    selectAssetClassOfficeLastQuery,
    selectTrashedAssetClassOfficeCount
} from '../../../../../core/comparable';
import {AppState} from '../../../../../core/reducers';
import {LayoutUtilsService, QueryParamsModel, TypesUtilsService} from '../../../../../core/_base/crud';
import {LayoutConfigService, SubheaderService} from '../../../../../core/_base/layout';
import {debounceTime, distinctUntilChanged, tap, take, map} from 'rxjs/operators';
import {FilterOfficeDialogComponent} from '../../_sub/filter-office-dialog/filter-office-dialog.component';
import {MarkerInfoDialogComponent} from '../../_sub/map-info-dialog/marker-info-dialog.component';
import {Overlay} from '@angular/cdk/overlay';
import { madCalculator } from 'src/app/core/_base/crud/utils/map-marker.util';
import { ToMarkerItemConverterService } from '../../../shared_components/map-marker-info-window/to-marker-item-converter-service.service';
import { MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput } from '../../../shared_components/map-marker-info-window/map-marker-info-window.component';
import { emptyFilter, FilterChange, FilterModel } from '../../../shared_components/filter-section/filter-section.component';
import { FileUploadService } from 'src/app/core/file-upload';
import { AssetClassOfficeActionToggleLoading, AssetClassOfficeDuplicate } from 'src/app/core/comparable/_actions/asset-class-office.actions';
import { hasPermission } from 'src/app/core/mad-auth/mad-auth.store';
import { environment } from 'src/environments/environment';
import { MapsService } from 'src/app/core/map/maps.service';

@Component({
    selector: 'kt-office-edit',
    templateUrl: './office-map.component.html',
    styleUrls: ['./office-map.component.scss'],
})
export class OfficeMapComponent implements OnInit, OnDestroy, AfterViewInit {

    calculator = madCalculator;
    icon = {
        url: './assets/media/icons/my_marker.svg'
    }
    icons = [
        {
            labelOrigin: {x: 20, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 44,
                height: 44
            }
        }, {
            labelOrigin: {x: 20, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 44,
                height: 44
            }
        },
        {
            labelOrigin: {x: 50, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 94,
                height: 44
            }
        },
        {
            labelOrigin: {x: 80, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 144,
                height: 44
            }
        },
        {
            labelOrigin: {x: 110, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 194,
                height: 44
            }
        },
        {
            labelOrigin: {x: 140, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 244,
                height: 44
            }
        },
        {
            labelOrigin: {x: 170, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 294,
                height: 44
            }
        },
        {
            labelOrigin: {x: 200, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 354,
                height: 44
            }
        },
        {
            labelOrigin: {x: 230, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 424,
                height: 44
            }
        },
        {
            labelOrigin: {x: 260, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 504,
                height: 44
            }
        },
        {
            labelOrigin: {x: 290, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 604,
                height: 44
            }
        },
        {
            labelOrigin: {x: 320, y: 22},
            url: './assets/media/icons/marker.svg',
            scaledSize: {
                width: 710,
                height: 44
            }
        },

    ];

    displayedColumns = ['id',
        'sub_type',
        'building_id',
        'size',
        'consideration',
        'source_date',
        'actions'];

    dataSource: AssetClassOfficesDataSource;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    @ViewChild('fileInput') fileInput;
    @ViewChild('search', {static: false})
    public searchElementRef: ElementRef;
    private geoCoder = new google.maps.Geocoder();
    assetClassOfficeResult: AssetClassOfficeModel[] = [];

    marker: google.maps.Marker;
    markerData = new Map();
    selectedId: string;
    mapAction = new BehaviorSubject<boolean>(true);
    previous;


    private componentSubscriptions: Subscription;
    subscriptions: Subscription[] = [];
    saveState: boolean;

    loading$: Observable<boolean>;
    hasFormErrors = false;

    // map
    @ViewChild('AgmMap', {static: true}) agmMap: AgmMap;
    isFirst = true;
    latLngSW: google.maps.LatLng;
    latLngNE: google.maps.LatLng;
    map: any;
    zoom = 15;
    private _centerLat$ = new BehaviorSubject<number>(null)
    centerLat$ = combineLatest([
        this._centerLat$,
        this.mapsService.latitude$
    ]).pipe(
        map(([initial, def]) => initial == null ? def : initial)
    )
    private _centerLng$ = new BehaviorSubject<number>(null)
    centerLng$ = combineLatest([
        this._centerLng$,
        this.mapsService.longitude$
    ]).pipe(map(([initial, def]) => initial == null ? def : initial))
    currentCenter: { lat: 0, lng: 0 };

    _north = new BehaviorSubject<number>(47.92399006317647);
    _south = new BehaviorSubject<number>(47.9096089375195);
    _east = new BehaviorSubject<number>(106.9476215928344);
    _west = new BehaviorSubject<number>(106.8833344071655);

    filterModel: FilterModel = emptyFilter();
    filterCnt = 0;
    filter$: BehaviorSubject<FilterModel> = new BehaviorSubject(this.filterModel);
    filterObs$ = this.filter$.asObservable();

    // Last Query Params
    lastQueryParams: QueryParamsModel;

    trashCnt = 0;
    adminTrashCnt = 0;
    fileType = 'application/json';

    menuItems = [
        {
            title: 'Import',
            icon: 'flaticon2-add',
            refId: 0,
            disabled: false
        },
        {
            title: 'Download Template',
            icon: 'flaticon2-download',
            refId: 1,
            disabled: false
        },
        {
            title: 'Export',
            icon: 'flaticon2-box-1',
            refId: 2,
            disabled: true
        }
    ];
    menuSubject = new BehaviorSubject<number>(-1);
    canAccessAdminTrash$ = this.store.pipe(select(hasPermission(['admin_trash'])))

    /**
     * Component constructor
     *
     * @param assetClassOfficesService: AssetClassOfficesService
     * @param activatedRoute: ActivatedRoute
     * @param router: Router
     * @param fb: FormBuilder
     * @param location: Location
     * @param subheaderService: SubheaderService
     * @param typesUtilsService: TypesUtilsService
     * @param layoutUtilsService: LayoutUtilsService
     * @param store: Store
     * @param dialog: Dialog
     * @param overlay: Overlay
     * @param mapsAPILoader: MapsAPILoader
     * @param ngZone: NgZone
     * @param layoutConfigService: LayoutConfigService
     * @param translate: TranslateService
     */
    constructor(public assetClassOfficesService: AssetClassOfficesService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private fb: UntypedFormBuilder,
                private location: Location,
                private subheaderService: SubheaderService,
                public typesUtilsService: TypesUtilsService,
                private toMarkerItemService: ToMarkerItemConverterService,
                public service: AssetClassOfficesService,
                private layoutUtilsService: LayoutUtilsService,
                private store: Store<AppState>,
                public dialog: MatDialog,
                public overlay: Overlay,
                private mapsAPILoader: MapsAPILoader,
                private fileUploadService: FileUploadService,
                private ngZone: NgZone,
                private layoutConfigService: LayoutConfigService,
                private translate: TranslateService,
                private mapsService: MapsService) {
        this.saveState = false;
    }

    ngAfterViewInit(): void {

    }

    ngOnInit() {
        this.loading$ = this.mapAction.asObservable();

        this.paginator._intl.itemsPerPageLabel = 'Display';
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => this.loadList())
        ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);


        this.dataSource = new AssetClassOfficesDataSource(this.store);
        const entitiesSubscription = this.dataSource.entitySubject.subscribe(res => {
            this.assetClassOfficeResult = res;
            this.markerData.clear();
            res.forEach(el => {
                const key = el.locationData.latitude + '_' + el.locationData.longitude;
                if (this.markerData.has(key)) {
                    const tmp = this.markerData.get(key);
                    tmp.title = tmp.title + '/' + el.ref_num
                    tmp.data.push(el);
                    this.markerData.set(key, tmp);
                } else {
                    this.markerData.set(key, {data: [el], title: '' + el.ref_num, lat: el.locationData.latitude, lng: el.locationData.longitude});
                }
            });
            this.mapAction.next(false);
        });

        this.subscriptions.push(entitiesSubscription);



        // Last Query Params
        this.lastQueryParams = new QueryParamsModel({});
        const lastyQueryParamsSubscriptions = this.store.pipe(
            select(selectAssetClassOfficeLastQuery),
            take(1)
        ).subscribe(res => {
            if (res.isEmpty()) {
                return;
            }
            this.lastQueryParams = res;
            this.updateFilter(res.filter);
        });
        this.subscriptions.push(lastyQueryParamsSubscriptions);

        const selectTrashedSubscription = this.store.pipe(
            select(selectTrashedAssetClassOfficeCount)
        ).subscribe(res => {
            this.trashCnt = res;
        });
        this.subscriptions.push(selectTrashedSubscription);

        const selectAdminTrashedSubscription = this.store.pipe(
            select(selectAdminTrashedAssetClassOfficeCount)
        ).subscribe(res => {
            this.adminTrashCnt = res;
        });
        this.subscriptions.push(selectAdminTrashedSubscription);

        const menuSubjectSubscribe = this.menuSubject.asObservable().subscribe(refId => {
            switch (refId) {
                case 0:
                    this.addFiles();
                    break;
                case 1:
                    this.downloadJsonTemplate();
                    break;
                case 2:
                    // TODO: export comparables
                    break;
                default:
                    break;
            }
        });
        this.subscriptions.push(menuSubjectSubscribe);
    }

    zoomChanged($event) {
        this.isFirst = true;
    }

    onFilterChange(event: FilterChange) {
        if (event.page != null) {
            this.paginator.pageIndex = event.page
        }
        this.filterModel = event.filter;
        this._centerLat$.next(event.filter.centerLat);
        this._centerLng$.next(event.filter.centerLng);
        this.loadList();
    }

    boundsChanged(_latLngBound) {
        this.latLngNE = _latLngBound.getNorthEast();
        this.latLngSW = _latLngBound.getSouthWest();

        const xPadding = (this.latLngNE.lat() - this.latLngSW.lat()) * 0.08;
        this._north.next(this.latLngNE.lat() - xPadding);
        this._south.next(this.latLngSW.lat() + xPadding);

        this._east.next(this.latLngNE.lng() - xPadding);
        this._west.next(this.latLngSW.lng() + xPadding);


        const _new = Object.assign({}, this.filterModel) as FilterModel;
        if (_new.bounds == null) {
            _new.bounds = {ne_lat: 0, sw_lat: 0, ne_lng: 0, sw_lng: 0}
        } else {
            _new.bounds = Object.assign({}, _new.bounds);
        }
        _new.bounds.sw_lat = _latLngBound.getSouthWest().lat();
        _new.bounds.ne_lat = _latLngBound.getNorthEast().lat();
        _new.bounds.sw_lng = _latLngBound.getSouthWest().lng();
        _new.bounds.ne_lng = _latLngBound.getNorthEast().lng();

        this.filterModel = _new;

        if (this.isFirst) {

            if (this.previous) {
                this.previous.close();
            }
            this.previous = null;


            this.mapAction.next(true);
            this.isFirst = !this.isFirst;
            this.paginator.pageIndex = 0;
        }
        this.loadList();
    }

    showInfo(mId, infoData) {
        const data = this.toMarkerItemService.convert('comparable', infoData.map(id => ({...id, ac_type: 3})));
        this.dialog.open<MapMarkerInfoWindowComponent, MapMarkerInfoWindowDialogInput>(MapMarkerInfoWindowComponent, {
            data: {
                modal_title: data.modal_title,
                entities: data.entities
            }
        });
    }


    mapReady(event: any) {

        // agmMap
        this.map = event;
        this.map.controls[google.maps.ControlPosition.RIGHT_CENTER].push(document.getElementById('Profile'));

        this.map.addListener('dragend', () => {
            this._centerLat$.next(this.currentCenter.lat);
            this._centerLng$.next(this.currentCenter.lng);
            this.paginator.pageIndex = 0;
            this.loadList();
        });

        this.mapsAPILoader.load().then(() => {
            this.geoCoder = new google.maps.Geocoder();
            if (this.searchElementRef) {
            const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                types: ['(cities)']
            });
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                // get the place result
                const place: google.maps.places.PlaceResult = autocomplete.getPlace();
                // verify result
                if (place.geometry === undefined || place.geometry === null) {
                    return;
                }

                // set latitude, longitude and zoom
                this._centerLat$.next(place.geometry.location.lat())
                this._centerLng$.next(place.geometry.location.lng())
                this.zoom = 15;
                });
            });
            }
        });
    }

    centerChange($event: any) {
        if ($event) {
            this.currentCenter = {lat: $event.lat, lng: $event.lng};
        }
    }

    ngOnDestroy(): void {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
        this.subscriptions.forEach(_subscription => {
            _subscription.unsubscribe();
        });
    }

    back() {
        this.location.back();
    }

    getWhat3words(latLng: google.maps.LatLng): void {
        this.assetClassOfficesService.getWhat3Word(latLng).subscribe(res => {
            // this.assetClassOfficeForm.controls.what3words.patchValue(res.words);
        });
    }

    /**
     * Alert close event
     */
    onAlertClose($event) {
        this.hasFormErrors = false;
    }

    navToList() {
        // this.router.navigate(['../'], {relativeTo: this.activatedRoute});
        const url = `${this.layoutConfigService.getCurrentMainRoute()}/comparable/offices`;
        this.router.navigate([url], {relativeTo: this.activatedRoute});
    }


    getLabel(title) {
        return {
            color: '#000000',
            fontFamily: '',
            fontSize: '14px',
            fontWeight: 'bold',
            className: 'bg-op',
            text: title,
        };
    }


    /** ACTIONS */
    /**
     * Deleting comparabable office into trash
     *
     * @param _item: AssetClassOfficeModel
     */
    deleteAssetClassOffice(_item: AssetClassOfficeModel) {
        this.store.dispatch(new OneAssetClassOfficeDeleted({id: _item.id}));
        //
        // const index = this.assetClassOfficeResult.findIndex(e => e.id == _item.id);
        // if (index) {
        //     this.assetClassOfficeResult.slice(index, 1);
        //     // this.dataSource.data = this.assetClassOfficeResult;
        // }
    }

    addOffice() {
        this.editAssetClassOffice(null);
    }

    editAssetClassOffice(assetClassOfficeModel: AssetClassOfficeModel) {
        if (assetClassOfficeModel) {
            this.router.navigate(['../edit', assetClassOfficeModel.id], {relativeTo: this.activatedRoute});
        } else {
            this.router.navigate(['../add'], {relativeTo: this.activatedRoute});
        }
    }

    loadList() {
        let queryParams = null;
        if (this.lastQueryParams.isEmpty()) {
            queryParams = new QueryParamsModel(
                this.filterModel,
                this.sort.direction,
                this.sort.active,
                this.paginator.pageIndex + 1,
                this.paginator.pageSize
            );
        } else {
            queryParams = this.lastQueryParams;
        }
        // Call request from server
        this.lastQueryParams = new QueryParamsModel({});
        this.store.dispatch(new AssetClassOfficeMapPageRequested({page: queryParams}));
    }


    updateFilter(filter: FilterModel) {
        this.filter$.next(filter);
    }

    getDate(_source: AcSource): Date {
        const _dateStr = _source.source_type_id == 1 ? _source.transaction_date : _source.information_date;
        return new Date(_dateStr);
    }

    goList() {
        this.router.navigate(['../'], {relativeTo: this.activatedRoute});
    }


    setLocation() {
        this.setCurrentLocation();
    }

    // Get Current Location Coordinates
    private setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                if (position.coords.latitude && position.coords.longitude) {
                    this._centerLat$.next(position.coords.latitude);
                    this._centerLng$.next(position.coords.longitude);
                    this.zoom = 15;
                }
            });
        } else {
            alert('Position not available');
        }
    }

    mapClick(event: any) {
        if (this.previous) {
            this.previous.close();
        }
    }
    getItemCssClassByStatus(status: number): string {
        switch (status) {
            case 0:
                return 'warning';
            case 2:
                return 'info';
            default:
                return 'success';
        }
    }
    getItemStatusString(status: number): string {
        switch (status) {
            case 0:
                return 'Draft';
            case 2:
                return 'imported';
            default:
                return 'Validated';
        }
    }

    trash() {
        this.service.getTrashed().subscribe(res => {
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.ref_num} - ${elem.office_layout_name}`,
                    id: elem.id.toString(),
                    date: elem.user_deleted,
                    hasPermanentlyDelete: true,
                    deletedUser: elem.userDeletedBy,
                });
            });
            this.show_trash(items);
        });
    }


    // Fetch admin trash data
    adminTrash() {
        this.store.dispatch(new AssetClassOfficeActionToggleLoading({isLoading: true}));
        this.service.getAdminTrashed().subscribe(res => {

            this.store.dispatch(new AssetClassOfficeActionToggleLoading({isLoading: false}));
            const items = [];
            res.data.forEach(elem => {
                items.push({
                    text: `#${elem.ref_num} - ${elem.office_layout_name}`,
                    id: elem.id.toString(),
                    hasPermanentlyDelete: true,
                    date: elem.deleted_at,
                    deletedUser: elem.deletedBy,
                });
            });
            this.show_trash(items, true);
        });
    }

    show_trash(items, isAdmin = false) {
        let _title = 'Deleted Office Comparables';
        if (isAdmin) {
            _title = 'Admin Deleted Office Comparables';
        }
        this.layoutUtilsService.trashedEntities(_title, items, this, isAdmin);
    }

    restore(_id: number, isAdmin = false) {
        if (isAdmin) {
            this.store.dispatch(new AssetClassOfficeOnServerAdminRestored({id: _id}));
        } else {
            this.store.dispatch(new AssetClassOfficeOnServerRestored({id: _id}));
        }
    }

    delete(id: number) {
        this.store.dispatch(new AssetClassOfficeDeletedFromTrash({assetClassOfficeId: id}));
    }

    hardDelete(id: number) {
        this.store.dispatch(new AssetClassOfficeDeletedFromAdminTrash({id}));
    }


    flush() {
        this.store.dispatch(new OfficeTrashFlushed());
    }

    addFiles() {
        this.fileInput.nativeElement.click();
    }
    
    importComparable(event: any) {
        const file: File = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file, file.name);
        this.fileUploadService.uploadCompJson(formData).subscribe(
            (res) => {
                if (!res) {
                    return;
                }
                if (res.success) {
                    this.loadList();
                }
            },
            (err) => console
        );
    }
    downloadJsonTemplate() {
        this.fileUploadService.compTemplateDownload(3).subscribe(res => {
                if (res.type == 4) {
                    let blob = new Blob([res.body], { type: 'application/json'});
                    let url = window.URL.createObjectURL(blob);
                    var anchor = document.createElement("a");
                    anchor.download = "interval-comp-office-template.json";
                    anchor.href = url;
                    anchor.click();
                }
            },
            err => {
                alert("Problem while downloading the file.");
            }
        );
    }

    duplicate(id: number) {
        this.store.dispatch(new AssetClassOfficeDuplicate({id}));
    }
}
