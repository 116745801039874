<!-- <p>about-property works!</p>
<button (click)="addFloor1()">Add Floor 1</button>
<button (click)="addFloor2()">Add Floor 2</button> -->

<div class="form-group kt-form__group row section-margin-top-60" [formGroup]="form" *ngIf="form">
    <div class="col-lg-12 kt-margin-bottom-20-mobile">
        <h5 class="my-3 text-mad text-uppercase">
            About the property 
        </h5>
        <hr class="active">
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Sub-type category" formControlName="sub_type_category_id">
                <mat-option *ngFor="let item of subTypeCategories" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error>
                Sub-type category <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="filteredSubCategories.length != 0">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Sub-category" formControlName="sub_category_id">
                <mat-option *ngFor="let item of filteredSubCategories" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error>
                Sub-category <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="fieldVisibility['number_of_units']">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput type="number" placeholder="Number of unit(s)" formControlName="number_of_units" />
            <mat-error>
                Number of unit(s) <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="fieldVisibility['parking_type_id']">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Parking Type" formControlName="parking_type_id">
                <mat-option *ngFor="let item of parkingTypes" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error>
                Parking type <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="fieldVisibility['property_grade_id']">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Property grade" formControlName="property_grade_id">
                <mat-option *ngFor="let item of grades" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error>
                Property grade <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>


    <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="fieldVisibility['completion_year']">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Completion year" formControlName="completion_year"/>
            <mat-error>
                Completion year <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="fieldVisibility['apprx_year_extended']">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput placeholder="Approximate year the property was extended" formControlName="apprx_year_extended"/>
        </mat-form-field>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="State of repair" formControlName="state_of_repair_id">
                <mat-option *ngFor="let item of stateOfRepairs" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error>
                State of Repair <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-4">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Floor Numbering Scheme" formControlName="floor_numbering_scheme_id">
                <mat-option *ngFor="let item of floorNumberingSchemes" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-icon
                    matSuffix
                    class="cursor-pointer icon-gray"
                    container="body"
                    popoverClass="my-popover"
                    [ngbPopover]="floorNumberingSchemeBody"
                    (click)="$event.stopPropagation();">help
            </mat-icon>
        </mat-form-field>
    </div>

    <div class="col-lg-8 kt-margin-bottom-10-mobile" *ngIf="fieldVisibility['floor_details']">
        <tag-input class="floor" matInput formControlName="floor_details"
                    [placeholder]="'APARTMENT.FORM.FIELD.CHARACTERISTICS.FLOOR_DETAILS.PLACEHOLDER' | translate"
                    [secondaryPlaceholder]="'APARTMENT.FORM.FIELD.CHARACTERISTICS.FLOOR_DETAILS.PLACEHOLDER' | translate"
                    (onAdd)="onFloorAdd($event)"
                    (onRemove)="onFloorRemove($event)"
                    [validators]="floorValidators"
                    modelAsStrings="true"
                    style="width: 100%"
        >
        </tag-input>
    </div>            


    <div class="col-lg-4 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Handover standard" formControlName="handover_standard_id">
                <mat-option *ngFor="let item of handoverStandards" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
            <mat-error>
                Handover standard <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-12" *ngIf="fieldVisibility['automation']">
    <div class="row" >
        <div class="col-lg-4 kt-margin-bottom-20-mobile">
            <mat-form-field class="mat-form-field-fluid">
                <mat-select placeholder="Automation" formControlName="automation">
                    <mat-option [value]="1">Yes</mat-option>
                    <mat-option [value]="0">No</mat-option>
                </mat-select>
                <mat-error>
                    Automation <strong>required</strong>
                </mat-error>
            </mat-form-field>
        </div>

        <div class="col-lg-8 kt-margin-bottom-20-mobile" style="align-self: center;" *ngIf="form.controls.automation.value == 1">
            <!-- <div class="row"> -->
                <!-- <div class="col-lg-12"> -->
                    <mat-radio-group   formControlName="automation_type">
                        <mat-radio-button [value]="'full'">Automated parking system</mat-radio-button>
                        <mat-radio-button [value]="'semi'">Semi-automated parking system</mat-radio-button>
                    </mat-radio-group>
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="fieldVisibility['foundation_type_id']">
        <mat-form-field class="mat-form-field-fluid">
            <mat-select placeholder="Foundation type" formControlName="foundation_type_id">
                <mat-option *ngFor="let item of foundationTypes" [value]="item.id">{{item.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="fieldVisibility['above_floors']">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput type="text" mask="0000" [validation]="false" placeholder="Number of floors (above ground)" formControlName="above_floors"
                (change)="onFloorInput($event, 'above')"/>
            <mat-error>
                Number of floors <strong>required</strong>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="fieldVisibility['below_floors']">
        <mat-form-field class="mat-form-field-fluid">
            <input matInput type="text" mask="0000" [validation]="false" placeholder="Basement floors" formControlName="below_floors"
                (change)="onFloorInput($event, 'below')"/>
        </mat-form-field>
    </div>

    <div class="col-lg-12 kt-margin-bottom-20-mobile">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput placeholder="Property general description" formControlName="general_desc"></textarea>
        </mat-form-field>
    </div>

    <div class="col-lg-12 kt-margin-bottom-20-mobile" *ngIf="fieldVisibility['construction_desc']">
        <mat-form-field class="mat-form-field-fluid">
            <textarea matInput rows="3"  placeholder="Construction description" formControlName="construction_desc"></textarea>
        </mat-form-field>
    </div>

    <div class="col-lg-12 mt-10" *ngIf="propertySubTypeID != 2">
        <kt-accommodation-layout-table
            [propertySubTypeID]="propertySubTypeID"
            [aboveFloor]="aboveFloor$"
            [belowFloor]="belowFloor$"
            [schemeId]="this.form.controls.floor_numbering_scheme_id.value"
            [floors$]="floors$"
            [initData]="accommodation.data"
            [initOthers]="accommodation.others"
            (roomChange)="onRoomChanged($event)"></kt-accommodation-layout-table>
    </div>
</div>

<ng-template #floorNumberingSchemeBody>
    <table class="table tooltip-table">
        <thead>
            <th>Height relative to ground (storeys)</th>
            <th>European scheme</th>
            <th>North American scheme</th>
        </thead>
        <tbody>
            <tr>
                <th>Top floor</th>
                <td colspan="2">
                    Penthouse (PH), Roof (R/RF), nth floor
                </td>
            </tr>
            <tr>
                <th>2 levels above ground storey</th>
                <td>2nd floor (2/2F)</td>
                <td>3rd floor (3/3F)</td>
            </tr>
            <tr>
                <th>1 level above ground storey</th>
                <td>1st floor (1/1F)</td>
                <td>2nd floor (2/2F)</td>
            </tr>
            <tr>
                <th>Partially above ground storey</th>
                <td colspan="2">
                    Upper ground (UG), Upper level (UL), Mezzanine (M), etc.
                </td>
            </tr>
            <tr>
                <th rowspan="2">Ground storey</th>
                <td colspan="2">
                    Ground floor (G/GF), Lobby (L), Street (S)
                </td>
            </tr>
            <tr>
                <td>0th floor (0/0F)</td>
                <td>1st floor (1/1F)</td>
            </tr>
            <tr>
                <th>Partially below ground storey</th>
                <td colspan="2">
                    Lower ground (LG), Lower Level (LL), Concourse (C), Parking (P), etc.
                </td>
            </tr>
            <tr>
                <th>1 level below ground storey</th>
                <td colspan="2">1st basement (-1/-1F/B1)</td>
            </tr>
            <tr>
                <th>2 levels below ground storey</th>
                <td colspan="2">2nd basement (-2/-2F/B2)</td>
            </tr>
        </tbody>
    </table>
</ng-template>