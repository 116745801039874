<kt-alert 
  *ngIf="hasError$|async"
  type="warn" 
  [duration]="30000"
  [showCloseButton]="true"
  (close)="onAlertClose()">
  <div class="row">
    <div class="col-lg-12 kt-margin-bottom-20-mobile mb-2 pl-0">
      <b>{{errorMessage$|async}}</b>
    </div>
    <div class="col-lg-12 mb-2" *ngFor="let item of (fields$|async)">
      <b>{{item.section}}</b>
      <ul class="col-lg-12 pl-5">
        <li *ngFor="let field of item.fields">
          {{ field.name }}
        </li>
      </ul>
    </div>
  </div>
</kt-alert>