import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { PDFProgressData } from "ng2-pdf-viewer";
import { environment } from "../../../../../environments/environment";
import { awConst } from "src/app/app.constants";
import { SubdomainService } from "src/app/core/_base/subdomain.service";

@Component({
    selector: "kt-image-viewer-dialog",
    templateUrl: "./image-viewer.component.html",
    styleUrls: ["./image-viewer.component.scss"],
})
export class ImageViewerDialogComponent implements OnInit {
    // Public properties
    obj: any;
    path: string;
    basePath: string;
    loading: boolean;
    hasError: boolean = false;
    withoutViewer: boolean = false;
    awConst = awConst;
    viewer_type: 'Default' | 'Report Design' = 'Default';
    rating: {title: string, rate: string, description?: string} = null

    /**
     * Component constructor
     *
     * @param dialogRef: MatDialogRef<ClientContactModelEditDialogComponent>
     * @param data: any
     */
    constructor(
        public dialogRef: MatDialogRef<ImageViewerDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        private subDomainService: SubdomainService
    ) {
        this.obj = data;
        this.basePath = environment.baseApiUrl;
        this.loading = true;
        if (data.withoutViewer) {
            this.withoutViewer = true;
        }
        if (data.viewer_type) {
            this.viewer_type = data.viewer_type
            this.rating = data.rating
        }
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        let picture = this.obj.picture;
        if (this.obj.picture.charAt(0) == "/") {
            picture = this.obj.picture.substring(1, this.obj.picture.length);
        }
        // this.path =  picture;
        this.path = this.basePath + `api/${this.subDomainService.subDomain}/files/download?path=` + picture;
    }

    public imgLoading(e) {
        this.loading = false;
    }

    public imgOnError(e) {
        this.loading = false;
        e.target.src = awConst.IMAGE_NOT_FOUND;
    }

    public pdfLoadingProgress(progressData: PDFProgressData) {
        if (progressData.loaded) {
            this.loading = false;
        }
    }

    public onError(e: any) {
        this.loading = false;
        this.hasError = true;
    }
}
