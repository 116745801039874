// Angular
import {Injectable} from '@angular/core';
// RxJS
import {of, forkJoin} from 'rxjs';
import {mergeMap, map, withLatestFrom, filter, tap} from 'rxjs/operators';
// NGRX
import {Effect, Actions, ofType} from '@ngrx/effects';
import {Store, select} from '@ngrx/store';
// CRUD
import {QueryParamsModel} from '../../_base/crud';
// Services
import {AdminService} from '../_services/admin.service';
// State
import {AppState} from '../../reducers';
// Selectors
import {allClientsLoaded} from '../_selectors/client.selectors';
// Actions
import {
    AllClientsLoaded,
    AllClientsRequested,
    ClientActionTypes,
    ClientsPageRequested,
    ClientsPageLoaded,
    ClientUpdated,
    ClientsPageToggleLoading,
    ClientDeleted,
    ClientOnServerCreated,
    ClientCreated,
    ClientsActionToggleLoading,
    ClientTrashRequested,
    ClientDeletedFromTrash,
    ClientRestored,
    ClientAdminRestored,
    ClientOnServerRestored,
    ClientTrashFlushed,
    AllClientsRequestedMultimedia
} from '../_actions/client.actions';
import {ClientDeletedFromAdminTrash, ClientOnServerAdminRestored} from '../';

@Injectable()
export class ClientEffects {
    showPageLoadingDispatcher = new ClientsPageToggleLoading({isLoading: true});
    showActionLoadingDispatcher = new ClientsActionToggleLoading({isLoading: true});
    hideActionLoadingDispatcher = new ClientsActionToggleLoading({isLoading: false});

    @Effect()
    loadAllClients$ = this.actions$
        .pipe(
            ofType<AllClientsRequested>(ClientActionTypes.AllClientsRequested),
            withLatestFrom(this.store.pipe(select(allClientsLoaded))),
            filter(([action, isAllClientsLoaded]) => !isAllClientsLoaded),
            mergeMap(() => this.service.getAllClients()),
            map(res => {
                return new AllClientsLoaded({clients: res.data});
            })
        );

    @Effect()
    loadAllClientsMultimedia$ = this.actions$
        .pipe(
            ofType<AllClientsRequestedMultimedia>(ClientActionTypes.AllClientsRequestedMultimedia),
            withLatestFrom(this.store.pipe(select(allClientsLoaded))),
            filter(([action, isAllClientsLoaded]) => !isAllClientsLoaded),
            mergeMap(() => this.service.getAllClientsMultimedia()),
            map(res => {
                return new AllClientsLoaded({clients: res.data});
            })
        );

    @Effect()
    loadClientsPage$ = this.actions$
        .pipe(
            ofType<ClientsPageRequested>(ClientActionTypes.ClientsPageRequested),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showPageLoadingDispatcher);
                const requestToServer = this.service.findClients(payload.page);
                const lastQuery = of(payload.page);
                return forkJoin(requestToServer, lastQuery);
            }),
            map(response => {
                const result: any = response[0];
                const lastQuery: QueryParamsModel = response[1];
                return new ClientsPageLoaded({
                    clients: result.data,
                    totalCount: result.pagination.total,
                    page: lastQuery,
                    totalTrashed: result.pagination.total_trashed,
                    totalAdminTrashed: result.pagination.admin_trashed,
                });
            }),
        );

    @Effect()
    deleteClient$ = this.actions$
        .pipe(
            ofType<ClientDeleted>(ClientActionTypes.ClientDeleted),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteClient(payload.id);
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    updateClient$ = this.actions$
        .pipe(
            ofType<ClientUpdated>(ClientActionTypes.ClientUpdated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.updateClient(payload.client);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    createClient$ = this.actions$
        .pipe(
            ofType<ClientOnServerCreated>(ClientActionTypes.ClientOnServerCreated),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.createClient(payload.client, payload.contacts).pipe(
                    tap(res => {
                        this.store.dispatch(new ClientCreated({client: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    trashClients$ = this.actions$
        .pipe(
            ofType<ClientTrashRequested>(ClientActionTypes.ClientTrash),
            mergeMap(() => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.getTrashedClients();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );


    @Effect()
    restoreClient$ = this.actions$
        .pipe(
            ofType<ClientOnServerRestored>(ClientActionTypes.ClientOnServerRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.clientId).pipe(
                    tap(res => {
                        this.store.dispatch(new ClientRestored({client: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    restoreClientAdmin$ = this.actions$
        .pipe(
            ofType<ClientOnServerAdminRestored>(ClientActionTypes.ClientOnServerAdminRestored),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.restoreFromTrash(payload.clientId).pipe(
                    tap(res => {
                        this.store.dispatch(new ClientAdminRestored({item: res.data}));
                    })
                );
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteClientFromTrash$ = this.actions$
        .pipe(
            ofType<ClientDeletedFromTrash>(ClientActionTypes.ClientDeletedFromTrash),
            mergeMap(({payload}) => {
                    this.store.dispatch(this.showActionLoadingDispatcher);
                    return this.service.deleteFromTrash(payload.clientId);
                }
            ),
            map((response) => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    @Effect()
    deleteClientFromAdminTrash$ = this.actions$
        .pipe(
            ofType<ClientDeletedFromAdminTrash>(ClientActionTypes.ClientDeletedFromAdminTrash),
            mergeMap(({payload}) => {
                this.store.dispatch(this.showActionLoadingDispatcher);
                return this.service.deleteFromAdminTrash(payload.id);
            }),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );
    @Effect()
    flushClientTrash$ = this.actions$
        .pipe(
            ofType<ClientTrashFlushed>(ClientActionTypes.ClientTrashFlushed),
            mergeMap(() => {
                    return this.service.flushClientTrash();
                }
            ),
            map(() => {
                return this.hideActionLoadingDispatcher;
            }),
        );

    constructor(private actions$: Actions, private service: AdminService, private store: Store<AppState>) {
    }
}
