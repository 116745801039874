<div class="kt-login-body">
  <!--begin::Signin-->
  <div class="kt-login-form">
    <div class="mad-login-form">
      <a href="https://interval-soft.com" target="_blank">
        <img src="assets/media/interval/logo.png" class="interval-blue-logo" alt="InterVal logo">
      </a>
      <ng-container *ngIf="!(hasConfirmationToken$|async)">
        <a href="javascript:;" [ngbPopover]="registerHelp" placement="right" container="body" class="helper-modal">
          Having trouble with this form?
        </a>
      </ng-container>
      <div class="form_wrapper">
        <div class="free_trial_wrapper">
          <img src="assets/media/interval/free30daysred.png" class="free_trial_img"/>
        </div>
        <div id="signup-embed"></div>
      </div>
    </div>
    <ng-container *ngIf="!(hasConfirmationToken$|async)">
      <div class="sign-up">
        <span class="center">
          <span class="kt-login-signup-label">Already have an account?</span>&nbsp;&nbsp;
          <a href="/mad-auth/login" class="kt-link kt-login-signup-link">
            Login
          </a>
        </span>
      </div>
    </ng-container>
  </div>
</div>

<ng-template #registerHelp>
  <div>
    <p>
      <strong>Organization</strong>: This will be used to define your server web address, e.g., https://your-organization.eu.interval-soft.com. Please keep it simple
    </p>
    <p>
      <strong>Payment</strong>: During the 15-day trial, you can cancel your plan at any time, and no changes will be applied
    </p>
  </div>
</ng-template>