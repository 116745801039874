<kt-portlet>
    <kt-portlet-header [title]="getComponentTitle()"
                       [class]="'card-head--lg'"
                       [tooltipTitle]="'TOOLTIP.APARTMENT.FORM'+(assetClassResidential && assetClassResidential.id > 0 ? '_EDIT':'')+'.TITLE' | translate"
                       [tooltipDesc]="'TOOLTIP.APARTMENT.FORM'+(assetClassResidential && assetClassResidential.id > 0 ? '_EDIT':'')+'.DESCRIPTION' | translate"
                       [viewLoading$]="loading$"
                       [sticky]="true">
        <ng-container ktAdditionalTools>
            <div class="btn-group btn-group-toggle" role="group" ngbRadioGroup name="radioBasic" [formControl]="formSelectionCtrl">
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-right-white">
                    <input ngbButton type="radio" class="btn-check" [value]="2"> Simple
                  </label>
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-left-white">
                  <input ngbButton type="radio" class="btn-check" [value]="1"> Advanced
                </label>
            </div>
        </ng-container>
        <ng-container ktPortletTools>
            <a href="javascript:;"
               (click)="back()"
               class="btn mr-2" mat-raised-button
               [matTooltip]="'GENERAL.TOOLTIP.BACK' | translate:{
                    back_page: ('APARTMENT.FORM.TITLE.LIST' | translate)
                }">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">
                    {{ 'GENERAL.BUTTON.CLOSE' | translate }}
                </span>
            </a>
            <a href="javascript:;"
                class="btn btn-success mr-2"
                color="warn"
                style="background-color: orange !important;"
                (click)="onSubmit(false)"
                mat-raised-button
                matTooltip="Save to Draft">
                <span>Save draft</span>
            </a>
            <a href="javascript:;"
               mat-raised-button
               class="btn btn-success mr-2"
               color="submit"
               (click)="onSubmit(true)"

               [matTooltip]="'GENERAL.TOOLTIP.SAVE' | translate">
                <span class="kt-hidden-mobile">{{ 'GENERAL.BUTTON.SAVE' | translate }}</span>
            </a>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <form class="form" [formGroup]="assetClassResidentialForm" *ngIf="assetClassResidentialForm">
            <kt-tab-header 
                [headerLabels]="tabHeaders" 
                (tabChange)="onTabChange($event)"
                [selectedTabChange]="selectedtabchange$">
            </kt-tab-header>
            <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event);">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Location & building</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <div class="card-body-progress">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </div>
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        
                        <!-- Location Section -->
                        <kt-map-location
                            [title]="'Location'"
                            [showMap]="isShown"
                            [lockMarker]="false"
                            [isComplexForm]="isComplexForm"
                            [locationData]="assetClassResidential.locationData"
                            [parentType]="'comparable'"
                            [(centerLat)]="centerLat"
                            [(centerLng)]="centerLng"
                            (timezoneChange)="onTimezoneChange($event)"
                        ></kt-map-location>

                        <kt-building-form
                            *ngIf="isComplexForm"
                            [(centerLat)]="centerLat"
                            [(centerLng)]="centerLng"
                            (centerChange)="onCenterChange($event)"
                            (picChange)="onPicChange($event)"
                            [buildingPictures]="assetClassResidential.building_info.pictures"
                            [buildingPicturePath]="assetClassResidential.building_info.picture"
                            [isComplexForm]="isComplexForm"
                            [acBuilding]="assetClassResidential.buildingInfo"
                            ></kt-building-form>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Property information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="form-group kt-form__group row section-margin-top-60">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                   About the property 
                                </h5>
                                <hr class="active">
                            </div>
        
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Sub-type category" formControlName="sub_type_category_id">
                                        <mat-option *ngFor="let item of (filteredSubTypeCategories|async)" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Sub-type category <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="filteredSubCategories.length != 0">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Sub-category" formControlName="sub_category_id">
                                        <mat-option *ngFor="let item of filteredSubCategories" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Sub-category <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
        
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Handover standard" formControlName="handover_standard_id">
                                        <mat-option *ngFor="let item of handoverStandards" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Handover standard <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="!isComplexForm">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput type="text" mask="0000" 
                                        [validation]="false" 
                                        placeholder="Number of rooms" formControlName="number_of_rooms"/>
                                    <mat-error>
                                        Number of rooms <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="!isComplexForm">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput type="text" mask="0000" 
                                        [validation]="false" 
                                        placeholder="Number of bedrooms" formControlName="number_of_bedrooms"/>
                                    <mat-error>
                                        Number of bedrooms <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="!isComplexForm">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput type="text" mask="0000" 
                                        [validation]="false" 
                                        placeholder="Number of bathrooms" formControlName="number_of_bathrooms"/>
                                    <mat-error>
                                        Number of bathrooms <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-8 kt-margin-bottom-10-mobile">
                                <tag-input class="floor" matInput formControlName="floor_details"
                                        [placeholder]="'RETAIL_SHOP.FORM.FIELD.CHARACTERISTICS.FLOOR_DETAILS.PLACEHOLDER' | translate"
                                        [secondaryPlaceholder]="'RETAIL_SHOP.FORM.FIELD.CHARACTERISTICS.FLOOR_DETAILS.PLACEHOLDER' | translate"
                                        (onAdd)="onFloorAdd($event)"
                                        (onRemove)="onFloorRemove($event)"
                                        modelAsStrings="true"
                                        style="width: 100%"
                                >
                                </tag-input>
                                <mat-error class="tag-custom-error"
                                    *ngIf="floorLocationFormControl?.touched && floorLocationFormControl?.hasError('required')">
                                    Floor location <strong>required</strong>
                                </mat-error>
                            </div>            
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="State of repair" formControlName="state_of_repair_id">
                                        <mat-option *ngFor="let item of stateOfRepairs" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        State of Repair <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Energy Efficiency Grade" formControlName="energy_efficiency_grade_id">
                                        <mat-option *ngFor="let grade of grades" [value]="grade.id">
                                            {{grade.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4" *ngIf="!isComplexForm">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Building ID" formControlName="building_name">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4" *ngIf="!isComplexForm">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Building Type" formControlName="building_type">
                                        <mat-option *ngFor="let type of buildingTypes" [value]="type.id">
                                            {{type.name}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Building type <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
        
                            </div>
                            <div class="col-lg-12" *ngIf="!isComplexForm && assetClassResidentialForm.controls.building_type.value != null">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput placeholder="Building Type Comment" formControlName="building_type_comment"></textarea>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput placeholder="Property general description" formControlName="general_desc"></textarea>
                                </mat-form-field>
                            </div>
        
                            <div class="col-lg-12 mt-10" *ngIf="isComplexForm">
                                <kt-accommodation-layout-table
                                    [propertySubTypeID]="1"
                                    [aboveFloor]="aboveFloor$"
                                    [belowFloor]="belowFloor$"
                                    [floors$]="floors$"
                                    [rooms$]="rooms$"
                                    [initData]="assetClassResidential.accommodation.data"
                                    [initOthers]="assetClassResidential.accommodation.others"></kt-accommodation-layout-table>
                            </div>
                        </div>
        
                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="isComplexForm">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                   Internal Aspect 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-internal-aspects 
                                    [propertySubTypeID]="1" 
                                    [internalAspectData]="assetClassResidential.internalAspectData"></kt-internal-aspects>
                            </div>
                        </div>
                        <div class="form-group kt-form__group row section-margin-top-60">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                   Grounds & External Areas
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-ac-grounds 
                                    [isComplexForm]="isComplexForm" 
                                    [propertySubTypeID]="1"
                                    [initData]="assetClassResidential.grounds"></kt-ac-grounds>
                            </div>
                        </div>
                        <kt-ac-size class="section-margin-top-60" [sizesSubject]="sizesSubject" [assetClassType]="'1'"></kt-ac-size>
                        <mat-error class="tag-custom-error mt-2" *ngIf="hasFormErrors && sizesSubject.value.length == 0"
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('RETAIL_SHOP.FORM.FIELD.SIZES.SECTION_TITLE' | translate)
                            }"
                        >
                        </mat-error>
                    </div>
                </mat-tab>
                <mat-tab>
                    <kt-esg-indicators 
                        formType="comparable"
                        [value]="assetClassResidential.esgIndicator"
                        [systemOfMeasurement]="userUnitOfMeasurementId$|async"/>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Lease & sale information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <kt-consideration-and-source
                            style="margin-top: 60px;"
                            [inputConsiderations]="tenuresSubject|async"
                            [landAreaComponentListSubject]="comps"
                            [hasParcelInfo$]="hasParcel"
                            [timezoneOffset]="timezoneOffset"
                            [sizes]="sizes$|async"></kt-consideration-and-source>
                        <mat-error class="tag-custom-error mt-2" *ngIf="hasFormErrors && (considerationError$|async)"
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('APARTMENT.FORM.FIELD.CONSIDERATIONS.TITLE' | translate)
                            }"
                        >
                        </mat-error>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Pictures & documents</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <div class="form-group row section-margin-top-60">
                            <div class="col-lg-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Property Pictures
                                </h5>
                                <hr class="active">
                            </div>
        
                            <kt-upload-file #simple class="col-lg-12"
                                [filesOnEditMode]="assetClassResidential.ac_pictures"
                                [folderOnEditMode]="assetClassResidential.picture"
                                [readOnly]="false"
                                [parentForm]="'land'"
                                [typeField]="false"
                                fileType="image/*"
                                split="1"
                                [featuredPictureAllowed]="true"
                                [hasThumbnail]="true"
                                [addButtonLabel]="'Add Apartment Picture'">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && uploadFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>

                        <div class="form-group row section-margin-top-60" *ngIf="isComplexForm">
                            <div class="col-lg-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Building Pictures
                                </h5>
                                <hr class="active">
                            </div>
                            <kt-upload-file #buildingPic class="col-lg-12"
                                [filesOnEditMode]="assetClassResidential.building_info.pictures"
                                [folderOnEditMode]="assetClassResidential.building_info.picture"
                                [readOnly]="false"
                                [parentForm]="''"
                                [typeField]="false"
                                fileType="image/*"
                                split="2"
                                [featuredPictureAllowed]="true"
                                [hasThumbnail]="true"
                                [addButtonLabel]="'Add Building Picture'">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && buildingFileComponent && buildingFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>
        
                        <kt-ac-document-upload class="section-margin-top-60" [files]="assetClassResidential.documents" *ngIf="isComplexForm">
                        </kt-ac-document-upload>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </kt-portlet-body>
</kt-portlet>
