// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {TagInputModule} from 'ngx-chips';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
// Translate
import {TranslateModule} from '@ngx-translate/core';
import {PartialsModule} from '../../partials/partials.module';
// Services
import {HttpUtilsService, TypesUtilsService, 
    // InterceptService, 
    LayoutUtilsService} from '../../../core/_base/crud';
// Shared
// Material
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';

import {NgbModule, NgbPopoverModule} from '@ng-bootstrap/ng-bootstrap';
import {
    AssetClassResidentialEffects,
    assetClassResidentialsReducer,
    AssetClassResidentialsService,
    AssetClassResidentialFacilityEffects,
    assetClassResidentialFacilitiesReducer,
    AssetClassResidentialFacilitiesService,
    buildingsReducer,
    BuildingEffects,
    BuildingService,
    AssetClassOfficeEffects,
    assetClassOfficesReducer,
    AssetClassOfficesService,
    AssetClassOfficeFacilityEffects,
    assetClassOfficeFacilitiesReducer,
    AssetClassOfficeFacilitiesService,
    AssetClassRetailEffects,
    assetClassRetailsReducer,
    AssetClassRetailsService,
    AssetClassRetailFacilityEffects,
    assetClassRetailFacilitiesReducer,
    AssetClassRetailFacilitiesService,
} from '../../../core/comparable';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';

import {ComparableComponent} from './comparable.component';
import {ApartmentListComponent} from './apartments/list/apartments-list.component';
import {ApartmentEditComponent} from './apartments/edit/apartment-edit.component';
import {OfficeListComponent} from './offices/list/offices-list.component';
import {OfficeEditComponent} from './offices/edit/office-edit.component';
import {RetailListComponent} from './retails/list/retails-list.component';
import {RetailEditComponent} from './retails/edit/retail-edit.component';
import {SharedModule} from '../shared_components/shared.module';
import {AssetClassResidentialGetResolver} from './apartments/edit/apartment-get-resolver';
import {AssetClassResidentialFacilitiesGetResolver} from './apartments/edit/apartment-facilities-get-resolver';
import {AssetClassResidentialFacilitiesBuildingGetResolver} from './apartments/edit/apartment-facilities-building-get-resolver';
import {AssetClassOfficeGetResolver} from './offices/edit/office-get-resolver';
import {AssetClassOfficeFacilitiesGetResolver} from './offices/edit/office-facilities-get-resolver';
import {AssetClassOfficeFacilitiesExclusiveGetResolver} from './offices/edit/office-facilities-exclusive-get-resolver';
import {AssetClassOfficeFacilitiesBuildingGetResolver} from './offices/edit/office-facilities-building-get-resolver';
import {AssetClassRetailGetResolver} from './retails/edit/retail-get-resolver';
import {AssetClassRetailFacilitiesGetResolver} from './retails/edit/retail-facilities-get-resolver';
import {AssetClassRetailFacilitiesExclusiveGetResolver} from './retails/edit/retail-facilities-exclusive-get-resolver';
import {AssetClassRetailFacilitiesBuildingGetResolver} from './retails/edit/retail-facilities-building-get-resolver';
import {CanDeactivateGuard} from '../../../core/auth';
import {AgmCoreModule} from '@agm/core';
import {AcTenureComponent} from './_sub/ac-tenure/ac-tenure.component';
import {AddTenureDialogComponent} from './_sub/add-tenure-dialog/add-tenure-dialog.component';
import {BuildingListComponent} from './building/list/building-list.component';
import {BuildingEditComponent} from './building/edit/building-edit.component';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask, IConfig} from 'ngx-mask';
import {BuildingGetResolver} from './building/edit/building-get-resolver';
import {BuildingFacilitiesGetResolver} from './building/edit/building-facilities-get-resolver';
import {ApartmentMapComponent} from './apartments/map/apartment-map.component';
import {OfficeMapComponent} from './offices/map/office-map.component';
import {RetailMapComponent} from './retails/map/retail-map.component';
import {FilterDialogComponent} from './_sub/filter-dialog/filter-dialog.component';
import {FilterOfficeDialogComponent} from './_sub/filter-office-dialog/filter-office-dialog.component';
import {NpnSliderModule} from 'npn-slider';
import {NgxDaterangepickerMd} from 'ngx-daterangepicker-material';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {AgmMarkerClustererModule} from '@agm/markerclusterer';
import {MarkerInfoDialogComponent} from './_sub/map-info-dialog/marker-info-dialog.component';
import {environment} from '../../../../environments/environment';
import { ComingSoonComponent } from '../shared_components/coming-soon/coming-soon.component';
import { CountryEditComponent } from '../admin-management/countries/country-edit/country-edit.component';
import { BuildingMarkerInfoDialogComponent } from './_sub/building-marker-info-dialog/building-marker-info-dialog.component';
import { LandComparableListComponent } from './lands/list/land-comparable-list.component';
import { LandComparableEditComponent } from './lands/edit/land-comparable-edit.component';
import { assetClassLandReducer } from 'src/app/core/comparable/_reducers/asset-class-land.reducers';
import { AssetClassLandEffects } from 'src/app/core/comparable/_effects/asset-class-land.effects';
import { AssetClassLandService } from 'src/app/core/comparable/_services/asset-class-land.service';
import { AssetClassLandGetResolver } from './lands/edit/asset-class-land-get-resolver';
import { HouseEditComponent } from './house/edit/house-edit.component';
import { HouseListComponent } from './house/list/house-list.component';
import { WarehouseEditComponent } from './warehouse/edit/warehouse-edit.component';
import { WarehouseListComponent } from './warehouse/list/warehouse-list.component';
import { RetailBuildingEditComponent } from './retail-building/edit/retail-building-edit.component';
import { RetailBuildingListComponent } from './retail-building/list/retail-building-list.component';
import { assetClassHouseReducer } from 'src/app/core/comparable/_reducers/asset-class-house.reducers';
import { AssetClassHouseEffects } from 'src/app/core/comparable/_effects/asset-class-house.effects';
import { AssetClassHouseService } from 'src/app/core/comparable/_services/asset-class-house.service';
import { AssetClassHouseGetResolver } from './house/edit/asset-class-house-get-resolver';
import { assetClassWarehouseReducer } from 'src/app/core/comparable/_reducers/asset-class-warehouse.reducers';
import { AssetClassWarehouseEffects } from 'src/app/core/comparable/_effects/asset-class-warehouse.effects';
import { AssetClassWarehouseService } from 'src/app/core/comparable/_services/asset-class-warehouse.service';
import { assetClassRetailBuildingReducer } from 'src/app/core/comparable/_reducers/asset-class-retail-building.reducers';
import { AssetClassRetailBuildingEffects } from 'src/app/core/comparable/_effects/asset-class-retail-building.effects';
import { AssetClassRetailBuildingService } from 'src/app/core/comparable/_services/asset-class-retail-building.service';
import { HouseMapComponent } from './house/map/house-map.component';
import { HouseFilterDialogComponent } from './house/_sub/house-filter-dialog/house-filter-dialog.component';
import { AssetClassWarehouseGetResolver } from './warehouse/edit/asset-class-warehouse-get-resolver';
import { AssetClassRetailBuildingGetResolver } from './retail-building/edit/asset-class-retail-building-get-resolver';
import { WarehouseMapComponent } from './warehouse/map/warehouse-map.component';
import { WarehouseFilterDialogComponent } from './warehouse/_sub/warehouse-filter-dialog/warehouse-filter-dialog.component';
import { assetClassRetailShopReducer } from 'src/app/core/comparable/_reducers/asset-class-retail-shop.reducer';
import { AssetClassRetailShopEffects } from 'src/app/core/comparable/_effects/asset-class-retail-shop.effects';
import { AssetClassRetailShopService } from 'src/app/core/comparable/_services/asset-class-retail-shop.service';
import { RetailBuildingMapComponent } from './retail-building/map/retail-building-map.component';
import { RetailBuildingFilterDialogComponent } from './retail-building/_sub/retail-building-filter-dialog/retail-building-filter-dialog.component';
import { ParkingListComponent } from './parkings/list/parking-list.component';
import { ParkingMapComponent } from './parkings/map/parking-map.component';
import { ParkingEditComponent } from './parkings/edit/parking-edit.component';
import { AssetClassParkingGetResolver } from './parkings/_sub/asset-class-parking-get-resolver';
import { assetClassParkingReducer } from 'src/app/core/comparable/_reducers/asset-class-parking.reducers';
import { AssetClassParkingEffects } from 'src/app/core/comparable/_effects/asset-class-parking.effects';
import { AssetClassParkingService } from 'src/app/core/comparable/_services/asset-class-parking.service';
import { ParkingFilterDialogComponent } from './parkings/_sub/parking-filter-dialog/parking-filter-dialog.component';
import { ConsiderationAndSourceComponent } from './_sub/consideration-and-source/consideration-and-source.component';
import { AddConsiderationDialogComponent } from './_sub/consideration-and-source/add-consideration-dialog/add-consideration-dialog.component';
import { AddConsiderationDialogV2Component } from './_sub/consideration-and-source/add-consideration-dialog-v2/add-consideration-dialog-v2.component';
import { ParcelConsiderationTableComponent } from './_sub/consideration-and-source/parcel-consideration-table/parcel-consideration-table.component';
import { ComparableStatsComponent } from './_sub/comparable-stats/comparable-stats.component';
import { AssetClassStatsService } from 'src/app/core/comparable/_services/asset-class-stats.service';
import { LandsMapComponent } from './lands/map/lands-map.component';
import { ApartmentModalComponent } from './apartments/modal/apartment-modal.component';
import { ValuationCheckerModalComponent } from './_sub/valuation-checker-modal/valuation-checker-modal.component';
import { HouseModalComponent } from './house/modal/house-modal.component';
import { OfficeModalComponent } from './offices/modal/office-modal.component';
import { ParkingModalComponent } from './parkings/modal/parking-modal.component';
import { RetailBuildingModalComponent } from './retail-building/modal/retail-building-modal.component';
import { RetailsModalComponent } from './retails/modal/retails-modal.component';
import { WarehouseModalComponent } from './warehouse/modal/warehouse-modal.component';
import { LandModalComponent } from './lands/modal/land-modal.component';
import { DateWithOffsetPipe } from 'src/app/core/_base/date-with-offset.pipe';
import { CoreModule } from 'src/app/core/core.module';
import { MadDateAdapter } from 'src/app/core/date/mad-date-adapter';
import { MadFormsModule } from '../mad-forms/mad-form.module';
import { FormErrorModule } from '../mad_shared/form-error/form-error.module';
import { EsgIndicatorsModule } from '../mad_shared/esg-indicators/esg-indicators.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

const routes: Routes = [
    {
        path: '',
        component: ComparableComponent,
        children: [
            {
                path: '',
                redirectTo: 'apartments',
                pathMatch: 'full'
            },
            {
                path: 'apartments',
                component: ApartmentListComponent
            },
            {
                path: 'apartments/map',
                component: ApartmentMapComponent,
            },

            {
                path: 'apartments/add',
                component: ApartmentEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'apartments/edit/:apartment_id',
                component: ApartmentEditComponent,
                resolve: {
                    data: AssetClassResidentialGetResolver,
                    data2: AssetClassResidentialFacilitiesGetResolver,
                    data3: AssetClassResidentialFacilitiesBuildingGetResolver
                },
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'offices',
                component: OfficeListComponent
            },
            {
                path: 'offices/map',
                component: OfficeMapComponent,
            },

            {
                path: 'offices/add',
                component: OfficeEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'offices/edit/:office_id',
                component: OfficeEditComponent,
                resolve: {
                    data: AssetClassOfficeGetResolver,
                    data2: AssetClassOfficeFacilitiesGetResolver,
                    data3: AssetClassOfficeFacilitiesBuildingGetResolver,
                    data4: AssetClassOfficeFacilitiesExclusiveGetResolver,
                },
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'retail-shops',
                component: RetailListComponent 
            },
            {
                path: 'retail-shops/map',
                component: RetailMapComponent,
            },

            {
                path: 'retail-shops/add',
                component: RetailEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'retail-shops/edit/:retail_id',
                component: RetailEditComponent,
                resolve: {
                    data: AssetClassRetailGetResolver,
                //     data2: AssetClassRetailFacilitiesGetResolver,
                //     data3: AssetClassRetailFacilitiesBuildingGetResolver,
                //     data4: AssetClassRetailFacilitiesExclusiveGetResolver,
                },
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'buildings',
                component: BuildingListComponent
            },
            {
                path: 'buildings/add',
                component: BuildingEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'buildings/edit/:building_id',
                component: BuildingEditComponent,
                resolve: {
                    data: BuildingGetResolver, 
                },
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'lands',
                component: LandComparableListComponent
            },
            {
                path: 'lands/map',
                component: LandsMapComponent,
            },
            {
                path: 'lands/add',
                component: LandComparableEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'lands/edit/:land_id',
                component: LandComparableEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    data: AssetClassLandGetResolver
                }
            },
            {
                path: 'houses',
                component: HouseListComponent 
            },
            {
                path: 'houses/map',
                component: HouseMapComponent
            },
            {
                path: 'houses/add',
                component: HouseEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'houses/edit/:house_id',
                component: HouseEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    data: AssetClassHouseGetResolver
                }
            },
            {
                path: 'warehouses',
                component: WarehouseListComponent 
            },
            {
                path: 'warehouses/map',
                component: WarehouseMapComponent
            },
            {
                path: 'warehouses/add',
                component: WarehouseEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'warehouses/edit/:warehouse_id',
                component: WarehouseEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    data: AssetClassWarehouseGetResolver
                }
            },
            {
                path: 'retail-buildings',
                component: RetailBuildingListComponent 
            },
            {
                path: 'retail-buildings/map',
                component: RetailBuildingMapComponent
            },
            {
                path: 'retail-buildings/add',
                component: RetailBuildingEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'retail-buildings/edit/:retailbuilding_id',
                component: RetailBuildingEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    data: AssetClassRetailBuildingGetResolver
                }
            },
            {
                path: 'parkings',
                component: ParkingListComponent
            },
            {
                path: 'parkings/map',
                component: ParkingMapComponent,
            },
            {
                path: 'parkings/add',
                component: ParkingEditComponent,
                canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'parkings/edit/:parking_id',
                component: ParkingEditComponent,
                canDeactivate: [CanDeactivateGuard],
                resolve: {
                    data: AssetClassParkingGetResolver
                }
            }
        ]
    }
];

@NgModule({
    imports: [
        CommonModule,
        CoreModule,
        HttpClientModule,
        PartialsModule,
        NgbModule,
        NgbPopoverModule,
        RouterModule.forChild(routes),
        FormsModule,
        NativeDateModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatListModule,
        MatCardModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatExpansionModule,
        MatTabsModule,
        MatTooltipModule,
        MatDialogModule,
        MadFormsModule,
        NgxMatSelectSearchModule,
        TagInputModule,
        SharedModule,
        NpnSliderModule,
        NgxDaterangepickerMd.forRoot(),
        NgxMaskDirective,
        NgxMaskPipe,
        StoreModule.forFeature('assetClassResidentials', assetClassResidentialsReducer),
        StoreModule.forFeature('assetClassResidentialFacilities', assetClassResidentialFacilitiesReducer),
        StoreModule.forFeature('assetClassOffices', assetClassOfficesReducer),
        StoreModule.forFeature('assetClassOfficeFacilities', assetClassOfficeFacilitiesReducer),
        StoreModule.forFeature('assetClassRetails', assetClassRetailsReducer),
        StoreModule.forFeature('assetClassRetailFacilities', assetClassRetailFacilitiesReducer),
        StoreModule.forFeature('buildings', buildingsReducer),
        StoreModule.forFeature('assetClassLands', assetClassLandReducer),
        StoreModule.forFeature('assetClassHouses', assetClassHouseReducer),
        StoreModule.forFeature('assetClassWarehouses', assetClassWarehouseReducer),
        StoreModule.forFeature('assetClassRetailShops', assetClassRetailShopReducer),
        StoreModule.forFeature('assetClassRetailBuildings', assetClassRetailBuildingReducer),
        StoreModule.forFeature('assetClassParkings', assetClassParkingReducer),
        EffectsModule.forFeature([
            AssetClassResidentialEffects,
            AssetClassResidentialFacilityEffects,
            BuildingEffects,
            AssetClassOfficeEffects,
            AssetClassOfficeFacilityEffects,
            AssetClassRetailEffects,
            AssetClassRetailFacilityEffects,
            AssetClassLandEffects,
            AssetClassHouseEffects,
            AssetClassWarehouseEffects,
            AssetClassRetailShopEffects,
            AssetClassRetailBuildingEffects,
            AssetClassParkingEffects
        ]),
        AgmCoreModule.forRoot({
            apiKey: environment.agMapKey,
            libraries: ['places', 'drawing', 'geometry']
        }),
        AgmMarkerClustererModule,
        FormErrorModule,
        EsgIndicatorsModule
    ],
    providers: [
        // InterceptService,
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: InterceptService,
        //     multi: true
        // },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                maxHeight: 'auto',
                width: '900px'
            }
        },
        {
            provide: DateAdapter,
            useClass: MadDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS, useValue: {
                parse: {
                    dateInput: 'DD MMM YYYY',
                },
                display: {
                    dateInput: 'DD MMM YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            }
        },
        HttpUtilsService,
        TypesUtilsService,
        LayoutUtilsService,
        AssetClassResidentialsService,
        AssetClassOfficesService,
        AssetClassRetailsService,
        BuildingService,
        AssetClassResidentialFacilitiesService,
        AssetClassOfficeFacilitiesService,
        AssetClassRetailFacilitiesService,
        AssetClassResidentialGetResolver,
        AssetClassResidentialFacilitiesGetResolver,
        AssetClassResidentialFacilitiesBuildingGetResolver,
        AssetClassOfficeGetResolver,
        AssetClassOfficeFacilitiesGetResolver,
        AssetClassOfficeFacilitiesExclusiveGetResolver,
        AssetClassOfficeFacilitiesBuildingGetResolver,
        AssetClassRetailGetResolver,
        AssetClassRetailFacilitiesGetResolver,
        AssetClassRetailFacilitiesExclusiveGetResolver,
        AssetClassRetailFacilitiesBuildingGetResolver,
        BuildingGetResolver,
        BuildingFacilitiesGetResolver,
        CanDeactivateGuard,
        AssetClassLandService,
        AssetClassLandGetResolver,
        AssetClassHouseService,
        AssetClassHouseGetResolver,
        AssetClassWarehouseGetResolver,
        AssetClassWarehouseService,
        AssetClassRetailShopService,
        AssetClassRetailBuildingService,
        AssetClassRetailBuildingGetResolver,
        AssetClassParkingService,
        AssetClassParkingGetResolver,
        AssetClassStatsService,
        provideNgxMask(options)
    ],
    declarations: [
        ComparableComponent,
        BuildingListComponent,
        BuildingEditComponent,
        ApartmentListComponent,
        ApartmentEditComponent,
        ApartmentMapComponent,
        OfficeListComponent,
        OfficeEditComponent,
        OfficeMapComponent,
        RetailListComponent,
        RetailEditComponent,
        RetailMapComponent,
        AcTenureComponent,
        FilterDialogComponent,
        FilterOfficeDialogComponent,
        MarkerInfoDialogComponent,
        AddTenureDialogComponent,
        BuildingMarkerInfoDialogComponent,
        LandComparableListComponent,
        LandComparableEditComponent,
        HouseEditComponent,
        HouseListComponent,
        WarehouseEditComponent,
        WarehouseListComponent,
        RetailBuildingEditComponent,
        RetailBuildingListComponent,
        HouseMapComponent,
        HouseFilterDialogComponent,
        WarehouseMapComponent,
        WarehouseFilterDialogComponent,
        RetailBuildingMapComponent,
        RetailBuildingFilterDialogComponent,
        ParkingFilterDialogComponent,
        ParkingListComponent,
        ParkingEditComponent,
        ParkingMapComponent,
        ConsiderationAndSourceComponent,
        AddConsiderationDialogComponent,
        AddConsiderationDialogV2Component,
        ParcelConsiderationTableComponent,
        ComparableStatsComponent,
        LandsMapComponent,
        ApartmentModalComponent,
        ValuationCheckerModalComponent,
        HouseModalComponent,
        OfficeModalComponent,
        ParkingModalComponent,
        RetailBuildingModalComponent,
        RetailsModalComponent,
        WarehouseModalComponent,
        LandModalComponent,
    ],
    exports: [
        ApartmentModalComponent,
        HouseModalComponent,
        OfficeModalComponent,
        ParkingModalComponent,
        RetailBuildingModalComponent,
        RetailsModalComponent,
        WarehouseModalComponent,
        LandModalComponent,
        ValuationCheckerModalComponent
    ]

})

export class ComparableModule {
}
