<kt-portlet>
    <!--[viewLoading$]="dataSource.loading$"-->
    <kt-portlet-header [class]="'kt-portlet-head--lg'"
                       [viewLoading$]="dataSource.loading$"
                       [tooltipTitle]="'TOOLTIP.USER.LIST.TITLE' | translate"
                       [tooltipDesc]="'TOOLTIP.USER.LIST.DESCRIPTION' | translate">
        <!-- PORTLET LOADING | Binded to TABLE Datasource -->

        <ng-container ktPortletTitle>
            <h3 class="kt-portlet-head-title">
                <span>{{ 'USER.LIST.TITLE' | translate }}</span>
            </h3>
        </ng-container>

        <ng-container ktPortletTools  *ngIf="canEdit$|async">
            <button (click)="addUser()" mat-raised-button [matTooltip]="'USER.LIST.TOOLTIP.NEW_USER' | translate" color="primary" type="button" class="mr-2">
                <span>{{ 'USER.LIST.BUTTON.NEW_USER' | translate }}</span>
            </button>
            <button *ngIf="(trashCnt$| async) != 0" (click)="trash()" mat-raised-button [matTooltip]="'USER.LIST.TOOLTIP.TRASHED_USERS' | translate" type="button" class="button-gray">
                <span>{{ 'GENERAL.BUTTON.TRASHED' | translate }} ({{trashCnt$ | async }})</span>
            </button>
            <button *ngIf="(adminTrashCnt$| async) != 0 && (canAdminThrash$|async)" (click)="adminTrash()" mat-raised-button [matTooltip]="'USER.LIST.TOOLTIP.TRASHED_USERS' | translate" type="button"
                    class="button-gray ml-2">
                <span>{{ 'GENERAL.BUTTON.ADMIN_TRASHED' | translate }} ({{adminTrashCnt$ | async }})</span>
            </button>
        </ng-container>
    </kt-portlet-header>
    <!-- end::Header -->

    <!-- start::Body (attribute: ktPortletBody) -->
    <kt-portlet-body >
        <div class="kt-margin-bottom-20-mobile p-1 section-margin-bottom-60" *ngIf="userStatusCnts$ | async as vm;">
            <div class="row kt-margin-bottom-20-mobile" style="text-align: right; font-weight: 600; font-size: large">

                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #999999 !important;">
                    <div class="float-left h-100 d-flex justify-content-center align-items-center my-3">
                        <span style="vertical-align: middle; color: #E8E8E8;">
                            {{ vm.total_users }}
                        </span>
                    </div>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0">
                            <span style="color: #cccccc;">TOTAL USERS</span>
                        </p>
                    </div>
                </div>
                <div class="col rounded-sm p-4 m-1 d-flex align-items-center" style="color: white; background-color: #f44d5e !important;">
                    <span style="vertical-align: middle; color: #E8E8E8;">
                        {{ vm.active_users }}
                    </span>
                    <div class="float-right align-content-end w-100 text-uppercase text-white">
                        <p class="m-0"><span style="color: #E8E8E8;">ACTIVE USERS</span></p>
                    </div>
                </div>
            </div>
        </div>

        <kt-filter
            [config]="{
                search: {
                    main: true,
                    location: false,
                    reset: false
                }
            }"
            (filterChange)="onFilterChange($event)"
        >
            <kt-f-selection
                class="mr-2 mt-2"
                [key]="'qualification_id'"
                [bg]="'#187916'"
                [placeholder]="'Qualification'"
                [selections]="qualificationSelections"></kt-f-selection>
            <kt-f-selection
                class="mr-2 mt-2"
                [key]="'role_id'"
                [bg]="'#67a262'"
                [placeholder]="'Role'"
                [selections]="roleSelections"></kt-f-selection>
        </kt-filter>

        <div class="kt-margin-bottom-20-mobile mb-10" *ngIf="currentUser.email == primary_email">
            <div class="kt-margin-bottom-20-mobile" style="text-align: center; font-weight: 200; font-size: medium">

                <div class="col d-flex align-items-center" style="color: white; background-color: #be1010 !important;">
                    <div class="w-100 h-100 d-flex justify-content-center align-items-center my-3">
                        <span style="vertical-align: middle; color: #ffffff;">
                            You are currently utilizing <b>{{licenseUsage}} available licenses</b> on your account.  <b><a (click)="addUser()"><u>Click here</u></a></b> to manage license assignments or <b><a (click)="modifyPlan()"><u>here</u></a></b> to purchase additional licenses.
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <!-- MATERIAL TABLE | Binded to datasources -->
        <!-- See off.documentations 'https://material.angular.io/components/table/overview' -->
        <div class="mat-table-wrapper">
            <mat-table class="lmat-elevation-z8"
                       #table
                       [dataSource]="dataSource"
                       matSort
                       #sort1="matSort"
                       matSortActive="is_active"
                       matSortDirection="desc"
                       matSortDisableClear>
                <!-- Checkbox Column -->

                <ng-container matColumnDef="id">
                    <!-- ATTRIBUTE mat-sort-header  for sorting | https://material.angular.io/components/sort/overview -->
                    <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
                    <mat-cell *matCellDef="let user">{{user.id}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="full_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'USER.LIST.DATATABLE.FULL_NAME' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <div class="user-tooltip-wrapper">
                            <kt-user-tooltip [user]="convertUser(user)"></kt-user-tooltip>
                            {{user.full_name}}
                        </div>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="qualification_name">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'USER.LIST.DATATABLE.QUALIFICATION' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">{{user.qualification_name}}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="_roles">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'USER.LIST.DATATABLE.ROLE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <span>{{ getUserRolesStr(user) }}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="toe_cnt">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="text-center row h80-p">
                            <div class="col-12 h50-percent pt-4 pl-2">
                                <span>{{ 'USER.LIST.DATATABLE.TOE_CNT' | translate }}</span>
                            </div>
                            <div class="col-4 text-left bg-gray h50-percent pt-4"><span class="pl-2">{{ 'AGENCY.LIST.DATATABLE.TOE_CNT_DRAFT' | translate }}</span></div>
                            <div class="col-4 text-left bg-gray h50-percent pt-4"><span>{{ 'AGENCY.LIST.DATATABLE.TOE_CNT_ONGOING' | translate }}</span></div>
                            <div class="col-4 text-left bg-gray h50-percent pt-4"><span>{{ 'AGENCY.LIST.DATATABLE.TOE_CNT_COMPLETE' | translate }}</span></div>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <span class="col-4 text-left pl-2">{{draft_cnt(user)}}</span>
                        <span class="col-4 text-left pl-2">{{ongoing_cnt(user)}}</span>
                        <span class="col-4 text-left pl-2">{{complete_cnt(user)}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="assignment_cnt">
                    <mat-header-cell *matHeaderCellDef>
                        <div class="text-left">
                            <span>{{ 'USER.LIST.DATATABLE.ASSIGNMENT_CNT' | translate }}</span>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <span class="col-4 text-left pl-2">{{user.assignment_manager}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="is_active">
                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                        <div class="text-left">
                            <span>{{ 'USER.LIST.DATATABLE.STATUS' | translate }}</span>
                        </div>
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <b>
                            <span class="label label-inline label-rounded label-{{ user.email == primary_email ? 'warning' : getItemCssClassByStatus(user.is_active) }} label-lg" style="width: 150px;">
                                {{ user.email == primary_email ? 'ACCOUNT OWNER' : getItemStatusString(user) }}
                            </span>
                        </b>
                        <!-- NOTE: removed cause issue 0337 -->
                        <!-- <ng-container *ngIf="user.email != primary_email">
                            <ng-container *ngIf="canEdit$|async">
                                <mat-checkbox class="ml-2" [checked]="user.is_active == 1" (change)="changeStatus($event, user)"></mat-checkbox>
                            </ng-container>
                        </ng-container> -->
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'USER.LIST.DATATABLE.ACTIONS' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let user">
                        <!-- Information tooltip -->
                        <ng-template #popTitle>
                            {{ 'GENERAL.INFORMATION_TOOLTIP.TITLE' | translate }}
                        </ng-template>
                        <ng-template #popContent>
                            <p [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.CREATED_BY' | translate:{
								username: user.createdBy ? user.createdBy : 'Unknown',
								date: (user.created_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
								}">
                            </p>
                            <p [innerHTML]="'GENERAL.INFORMATION_TOOLTIP.LAST_EDITED_BY' | translate:{
                                username: user.lastUpdatedBy ? user.lastUpdatedBy: 'Unknown',
                                date: (user.updated_at | date: 'dd MMM yyyy':currentUser.settings.time_zone_gmt)
                                }">
                            </p>
                            <div>
                                {{ 'USER.LIST.INFORMATION_TOOLTIP.QUALIFICATION' | translate:{
                                qual: user.qualification_name
                                } }}
                            </div>
                        </ng-template>
                        <button mat-icon-button
                                color="primary"
                                [ngbPopover]="popContent"
                                [popoverTitle]="popTitle"
                                *ngIf="currentUser"
                        >
                            <mat-icon
                            [matTooltip]="'GENERAL.TOOLTIP.INFORMATION' | translate"
                            >info</mat-icon>
                        </button>

                        <ng-container *ngIf="canEdit$|async as canEdit">
                            <ng-container *ngIf="canEditUser(user.id, canEdit.currentUserId, canEdit.canEditProfile, canEdit.canEditUser)">
                                <button (click)="editUser(user.id)" mat-icon-button color="primary" [matTooltip]="'USER.LIST.TOOLTIP.EDIT_USER' | translate">
                                    <mat-icon>create</mat-icon>
                                </button>&nbsp;
                            </ng-container>
                            <ng-container *ngIf="!canEditUser(user.id, canEdit.currentUserId, canEdit.canEditProfile, canEdit.canEditUser)">
                                <button (click)="editUser(user.id)" mat-icon-button color="primary" [matTooltip]="'View User'">
                                    <mat-icon>remove_red_eye</mat-icon>
                                </button>&nbsp;
                            </ng-container>
                        </ng-container>

                        <!-- NOTE: trash icon removed by Josh's request -->
                        <!-- <span *ngIf="currentUser && currentUser.id !== user.id && (canEdit$|async)" [matTooltip]="(user.using_place_cnt == 0 ? 'USER.LIST.TOOLTIP.DELETE_USER' : 'USER.LIST.TOOLTIP.DELETE_USER_CANNOT') | translate">
                            <button mat-icon-button
                                    color="warn"
                                    type="button"
                                    [disabled]="(canEdit$|async) && user.using_place_cnt != 0" type="button" (click)="deleteUser(user)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </span> -->
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

                <mat-row *matRowDef="let row; columns: displayedColumns;let index = index"
                         [ngClass]="{gray: index%2}"></mat-row>
            </mat-table>
            <!-- Message for empty data  -->
            <div class="mat-table-message" *ngIf="!dataSource.hasItems">{{ 'GENERAL.MESSAGE.NO_DATA' | translate }}</div>
            <div class="mat-table-message" *ngIf="dataSource.isPreloadTextViewed$ | async">{{ 'GENERAL.MESSAGE.PLEASE_WAIT' | translate }}</div>

        </div>

        <!-- start: BOTTOM -->
        <div class="mat-table-bottom">
            <!-- MATERIAL SPINNER | Url: 'https://material.angular.io/components/progress-spinner/overview' -->
            <mat-spinner [diameter]="20" *ngIf="dataSource.loading$ | async"></mat-spinner>
            <!-- MATERIAL PAGINATOR | Binded to dasources -->
            <!-- See off.documentations 'https://material.angular.io/components/paginator/overview' -->
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25, 50, 100]" [length]="dataSource.paginatorTotal$ | async" [showFirstLastButtons]="true"></mat-paginator>
        </div>
        <!-- end: BOTTOM -->
    </kt-portlet-body>
    <!-- end::Body -->
</kt-portlet>
