import { AssetClassConsiderationModel, AssetClassLandAreaComponentModel, AssetClassLandAreaModel} from "../../asset_class";
import { BaseModel } from "../../_base/crud";
import { AcSource } from "./ac-source.model";
import { ExternalAspectData, newExternalAspectData } from "./asset-class-external-aspect.model";
import { AcGroundData, newAcGroundData } from "./asset-class-ground.model";
import { InternalAspectData, newInternalAspectData } from "./asset-class-internal-aspect.model";
import { AssetClassSizeModel } from "./asset-class-size.model";
import { AcConsideration, AssetClassTenure } from "./asset-class-tenure.model";
import { LocationData } from "./location.model";
import { EsgIndicator } from '../../v2/state/esg-indicator/type';

export class AssetClassHouseModel extends BaseModel {
    id: number;
    ref_num: string;
    status: number;

    formType: number;
    locationData: LocationData;

    add_info_on_land: boolean;
    land_parcel_name: string;
    coordinate_reference_system_id: number;
    points: any[];
    landAreaComponents: AssetClassLandAreaComponentModel[] = [];
    landArea: AssetClassLandAreaModel;

    sub_type_category_id: number;
    sub_category_id: number;
    property_grade_id: number;
    general_desc: string;
    completion_year: string;
    apprx_year_extended: string;
    state_of_repair_id: number;
    handover_standard_id: number;
    foundation_type_id: number;
    above_floors: number;
    below_floors: number;
    energy_efficiency_grade_id: number;
    construction_desc: string;
    number_of_rooms: number;
    number_of_bedrooms: number;
    number_of_bathrooms: number;

    has_parcel_consideration: boolean;
    land_comment: string;

    accommodation: {
        id: number;
        data: Record<number, Record<string, number>>,
        others: {id: number, title: string}[]
    }
    serviceAndInfraData: {
        id: number,
        road: boolean;
        status_n_quality: string,
        access_points: string,
        length_of_road: string,
        ownership: string,

        central_heating: boolean,
        heatings: number[],
        other_heating: string,

        all_of_it: boolean,
        services: number[],
        other_service: string
    }

    externalAspectData: ExternalAspectData
    internalAspectData: InternalAspectData

    grounds: AcGroundData 

    ac_pictures: any[];
    picture: string;

    documents: any[];
    tenures: AssetClassTenure[];
    sizes: AssetClassSizeModel[];
    source: AcSource;

    parcel_tenures: AssetClassConsiderationModel[];

    esgIndicator: EsgIndicator

    userDeletedBy: string;
    deletedBy: string;
    createdBy: string;
    lastUpdatedBy: string;

    user_deleted: Date;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    valuation_id: number | null = null;

    clear() {
        this.ref_num = null;
        this.status = 0;

        this.formType = 2;

        this.locationData = {
            country_id: null,
            city_id: null,
            zip_code: null,
            longitude: null,
            latitude: null,
            time_offset: null,
            location_grade_id: null,
            address: null,
            location_surrounding: null,
            city_of_location: null
        }

        this.add_info_on_land = false;
        this.land_parcel_name = null;
        this.coordinate_reference_system_id = null;
        this.points = [];
        this.landAreaComponents = [];
        this.landArea = new AssetClassLandAreaModel();
        this.landArea.clear();

        this.sub_type_category_id = null;
        this.sub_category_id = null;
        this.property_grade_id = null;
        this.general_desc = null;
        this.completion_year = null;
        this.apprx_year_extended = null;
        this.state_of_repair_id = null;
        this.handover_standard_id = null;
        this.foundation_type_id = null;
        this.above_floors = null;
        this.below_floors = null;
        this.energy_efficiency_grade_id = null;
        this.construction_desc = null;

        this.accommodation = {
            id: undefined,
            data: {},
            others: []
        };


        this.serviceAndInfraData = {
            id: undefined,
            road: false,
            status_n_quality: null,
            access_points: null,
            length_of_road: null,
            ownership: null,

            central_heating: false,
            heatings: [],
            other_heating: null,

            all_of_it: false,
            services: [],
            other_service: null
        }

        this.externalAspectData = newExternalAspectData() 

        this.internalAspectData = newInternalAspectData() 

        this.grounds = newAcGroundData() 

        this.ac_pictures = [];
        this.picture = '';

        this.documents = [];
        this.tenures = [];
        this.sizes = [];
        this.source = new AcSource();
        this.source.clear();

        this.parcel_tenures = [];
        this.has_parcel_consideration = false;
        this.land_comment = null;

        this.userDeletedBy = null;
        this.deletedBy = null;
        this.createdBy = null;
        this.lastUpdatedBy = null;

        this.user_deleted = null;
        this.created_at = null;
        this.updated_at = null;
        this.deleted_at = null;
        this.valuation_id = null;
    }
}