<kt-portlet>
    <kt-portlet-header
        [title]="getComponentTitle()"
        [class]="'class-head-lg'"
        [tooltipTitle]="'TOOLTIP.WAREHOUSE.FORM' + (acWarehouse && acWarehouse.id > 0 ? '_EDIT' : '') + '.TITLE' | translate"
        [tooltipDesc]="'TOOLTIP.WAREHOUSE.FORM' + (acWarehouse && acWarehouse.id > 0 ? '_EDIT' : '') + '.DESCRIPTION' | translate"
        [viewLoading$]="loading$"
        [sticky]="true">
        <ng-container ktAdditionalTools>
            <div class="btn-group btn-group-toggle" role="group" ngbRadioGroup name="radioBasic" [formControl]="formSelectionCtrl">
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-right-white">
                    <input ngbButton type="radio" class="btn-check" [value]="2"> Simple
                  </label>
                <label ngbButtonLabel class="btn btn-secondary width-130 btn-border-left-white">
                  <input ngbButton type="radio" class="btn-check" [value]="1"> Advanced
                </label>
            </div>
        </ng-container>
        <ng-container ktPortletTools>
            <a href="javascript:;"
                (click)="back()"
                class="btn mr-2"
                mat-raised-button
                matTooltip="Back to Land List">
                <mat-icon>arrow_back</mat-icon>
                <span class="kt-hidden-mobile">Close</span>
            </a>
            <a href="javascript:;"
                color="warn"
                (click)="onSubmit(false)"
                class="btn btn-success mr-2"
                style="background-color: orange !important;"
                mat-raised-button
                matTooltip="Save to Draft">
                <span>Save draft</span>
            </a>
            <a href="javascript:;"
                color="submit"
                (click)="onSubmit(true)"
                class="btn btn-success mr-2"
                mat-raised-button
                matTooltip="Save">
                <span class="kt-hidden-mobile">Save</span>
            </a>
        </ng-container>
    </kt-portlet-header>

    <kt-portlet-body>
        <form class="form" [formGroup]="formGroup">
            <kt-tab-header 
                [headerLabels]="tabHeaders" 
                (tabChange)="onTabChange($event)"
                [selectedTabChange]="selectedtabchange$">
            </kt-tab-header>
            <mat-tab-group class="invinsible-tabs" [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event);">
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Location & Land Identification</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <!-- Location section -->
                        <kt-map-location
                            [title]="'Location'"
                            [showMap]="true"
                            [lockMarker]="false"
                            [isComplexForm]="isComplexForm"
                            [locationData]="acWarehouse.locationData"
                            [parentType]="'comparable'"
                            [(centerLat)]="centerLat"
                            [(centerLng)]="centerLng"
                            (markerChange)="onMarkerChange($event)"
                            (timezoneChange)="onTimezoneChange($event)"
                        >
                        ></kt-map-location>

                        <!-- Parcel Identification section -->
                        <div class="form-group kt-form__group row" *ngIf="isComplexForm">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile section-margin-top-60">
                                <h5 class="my-3 text-mad text-uppercase">
                                Parcel Identification 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12 mb-2">
                                <mat-label>Would you like to add information on the land</mat-label>
                                <mat-radio-group class="ml-4" formControlName="add_info_on_land">
                                    <mat-radio-button [value]="true">Yes</mat-radio-button>
                                    <mat-radio-button [value]="false">No</mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <ng-container *ngIf="formGroup.controls.add_info_on_land.value">
                                <div class="col-lg-4">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input matInput placeholder="Land Parcel ID" formControlName="land_parcel_name" type="text">
                                        <mat-error>
                                            Land Parcel ID <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-6">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <mat-select placeholder="Coordinate Reference System" formControlName="coordinate_reference_system_id">
                                            <mat-option *ngFor="let coordinateReferenceSystem of coordinateReferenceSystems" [value]="coordinateReferenceSystem.id">{{coordinateReferenceSystem.name}}</mat-option>
                                        </mat-select>
                                        <mat-icon
                                            matSuffix
                                            class="cursor-pointer"
                                            (click)="$event.stopPropagation();"
                                            container="body"
                                            [ngbPopover]="'TOOLTIP.LAND_PARCEL.CRS.DESCRIPTION' | translate">help
                                        </mat-icon>
                                        <mat-error>
                                            Coordinate Reference System
                                            <strong>required</strong>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-12 mt-2">
                                    <mat-label style="color: gray;"> 
                                        Please draw on the map below the coordinates of each boundary cover or pivot point of the land parcel:
                                    </mat-label>
                                    <mat-icon
                                        matSuffix
                                        class="cursor-pointer"
                                        (click)="$event.stopPropagation();"
                                        container="body"
                                        [ngbPopover]="'TOOLTIP.LAND_PARCEL.MAP.DESCRIPTION' | translate">help
                                    </mat-icon>
                                </div>
                                <div class="col-lg-12 mb-10">
                                    <kt-land-parcel-map
                                        [points]="points"
                                        [markerInfo]="markerInfo"
                                        [centerLat]="centerLat"
                                        [centerLng]="centerLng"
                                        [staticUnit]="2"
                                        [staticUnitLengthName]="'M'"
                                        [staticUnitName]="'sqm'">
                                    </kt-land-parcel-map>
                                </div>
                                <kt-land-area-list
                                    class="col-lg-12"
                                    [landAreaComponentListSubject]="landAreaComponentListSubject"
                                    [landAreaSubject]="landAreaSubject"
                                    [showOtherFields]="true">
                                </kt-land-area-list>
                            </ng-container>
                        </div>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Property information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <!-- About the property section -->
                        <div class="form-group kt-form__group row section-margin-top-60">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                About the property 
                                </h5>
                                <hr class="active">
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Sub-type category" formControlName="sub_type_category_id">
                                        <mat-option *ngFor="let item of subTypeCategories" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Sub-type category <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="filteredSubCategories.length != 0">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Sub-category" formControlName="sub_category_id">
                                        <mat-option *ngFor="let item of filteredSubCategories" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Sub-category <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Property grade" formControlName="property_grade_id">
                                        <mat-option *ngFor="let item of grades" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Property grade <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="isComplexForm">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Property general description" formControlName="general_desc"/>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Completion year" formControlName="completion_year"/>
                                    <mat-error>
                                        Completion year <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="isComplexForm">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Approximate year the property was extended" formControlName="apprx_year_extended"/>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="State of repair" formControlName="state_of_repair_id">
                                        <mat-option *ngFor="let item of stateOfRepairs" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        State of Repair <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Handover standard" formControlName="handover_standard_id">
                                        <mat-option *ngFor="let item of handoverStandards" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Handover standard <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile" *ngIf="isComplexForm">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select placeholder="Foundation type" formControlName="foundation_type_id">
                                        <mat-option *ngFor="let item of foundationTypes" [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput type="text" mask="0000" [validation]="false" placeholder="Number of floors (above ground)" formControlName="above_floors"/>
                                    <mat-error>
                                        Number of floors <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput type="text" mask="0000" [validation]="false" placeholder="Basement floors" formControlName="below_floors"/>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput rows="3"  placeholder="Construction description" formControlName="construction_desc"></textarea>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-12 mt-10" *ngIf="isComplexForm">
                                <kt-accommodation-layout-table
                                    [propertySubTypeID]="7"
                                    [aboveFloor]="aboveFloor$"
                                    [belowFloor]="belowFloor$"
                                    [floors$]="floors$"
                                    [initData]="acWarehouse.accommodation.data"
                                    [initOthers]="acWarehouse.accommodation.others"></kt-accommodation-layout-table>
                            </div>
                        </div>


                        <div class="form-group kt-form__group row section-margin-top-60">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                Services and Infrastructures 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-services-and-infrastructures 
                                    [isComplexForm]="isComplexForm"
                                    [serviceAndInfraData]="acWarehouse.serviceAndInfraData"
                                    ></kt-services-and-infrastructures>
                            </div>
                        </div>

                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="isComplexForm">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                External Aspect 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-external-aspects 
                                    [propertySubTypeID]="7" 
                                    [externalAspectData]="acWarehouse.externalAspectData"></kt-external-aspects>
                            </div>
                        </div>

                        <div class="form-group kt-form__group row section-margin-top-60" *ngIf="isComplexForm">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                Internal Aspect 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-internal-aspects 
                                    [propertySubTypeID]="7" 
                                    [internalAspectData]="acWarehouse.internalAspectData"></kt-internal-aspects>
                            </div>
                        </div>

                        <div class="form-group kt-form__group row section-margin-top-60">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">
                                Grounds 
                                </h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-12">
                                <kt-ac-grounds 
                                    [isComplexForm]="isComplexForm" 
                                    [propertySubTypeID]="7"
                                    [initData]="acWarehouse.grounds"></kt-ac-grounds>
                            </div>
                        </div>
                        <kt-ac-size class="section-margin-top-60" [sizesSubject]="sizesSubject" [assetClassType]="'7'"></kt-ac-size>
                        <mat-error class="tag-custom-error mt-2" *ngIf="hasFormErrors && sizesSubject.value.length == 0"
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('APARTMENT.FORM.FIELD.SIZES.SECTION_TITLE' | translate)
                            }"
                        >
                        </mat-error>
                    </div>
                </mat-tab>
                <mat-tab>
                    <kt-esg-indicators 
                        formType="comparable"
                        [value]="acWarehouse.esgIndicator"
                        [systemOfMeasurement]="userUnitOfMeasurementId$|async"/>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Lease & sale information</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>

                        <kt-consideration-and-source
                            style="margin-top: 60px;"
                            [inputConsiderations]="tenuresSubject|async"
                            [landAreaComponentListSubject]="landAreaComponentListSubject"
                            [hasParcelInfo$]="hasParcelInfo$"
                            [timezoneOffset]="timezoneOffset"
                            [sizes]="sizes$|async"></kt-consideration-and-source>
                        <mat-error class="tag-custom-error mt-2" *ngIf="hasFormErrors && (considerationError$|async)"
                                [innerHtml]="'GENERAL.VALIDATION.REQUIRED' | translate:{
                                field: ('APARTMENT.FORM.FIELD.CONSIDERATIONS.TITLE' | translate)
                            }"
                        >
                        </mat-error>
                    </div>
                </mat-tab>
                <mat-tab>
                    <ng-template mat-tab-label>
                        <span>Pictures & documents</span>
                    </ng-template>
                    <div class="card-body pt-0 mad-container">
                        <kt-form-error
                            [hasFormErrors]="hasFormErrors"
                            [tabHeaders]="tabHeaders"
                            [error]="error"
                            [erroTabMap]="erroTabMap()"
                            (hasFormErrorsChange)="onHasFormErrorsChange($event)">
                        </kt-form-error>
                        
                        <div class="form-group row section-margin-top-60">
                            <div class="col-lg-12">
                                <h5 class="my-3 text-mad text-uppercase">
                                    Property Pictures
                                </h5>
                                <hr class="active">
                            </div>
        
                            <kt-upload-file #simple class="col-lg-12"
                                [filesOnEditMode]="acWarehouse.ac_pictures"
                                [folderOnEditMode]="acWarehouse.picture"
                                [readOnly]="false"
                                [parentForm]="'land'"
                                [typeField]="false"
                                fileType="image/*"
                                split="1"
                                [featuredPictureAllowed]="true"
                                [hasThumbnail]="true"
                                [addButtonLabel]="'Add Warehouse Picture'">
                            </kt-upload-file>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && uploadFileComponent.uploadFiles.length == 0">
                                    At least 1 Picture <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && showTitleRequiredError$|async">
                                    Picture should have a <strong>Title</strong>
                                </mat-error>
                            </div>
                        </div>
        
                        <kt-ac-document-upload *ngIf="isComplexForm" class="section-margin-top-60" [files]="acWarehouse.documents">
                        </kt-ac-document-upload>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </form>
    </kt-portlet-body>
</kt-portlet>